import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  label: string
  children: React.ReactNode
}

const StyledTh = styled.th`
  width: 227px;
  padding: 32px 16px;
  font-size: 16px;
  ${mediaSp`
    width: auto;
    display: block;
    padding: 0 0 0.8rem;
    font-size: 1.4rem;
    color: ${(props): string => props.theme.darkGray};
  `}
`

const StyledTd = styled.td`
  padding: 32px 0;
  font-size: 20px;
  font-weight: 500;
  ${mediaSp`
    display: block;
    padding: 0;
    font-size: 1.6rem;
  `}
`

const StyledTableRow = styled.tr`
  border-bottom: 1px solid ${(props): string => props.theme.gray};

  ${mediaSp`
    display: block;
    padding: 2.4rem 0;
  `}
`

export const TableRow: React.FC<Props> = ({ className = '', label, children }) => {
  return (
    <StyledTableRow className={className}>
      <StyledTh>{label}</StyledTh>
      <StyledTd>{children}</StyledTd>
    </StyledTableRow>
  )
}
