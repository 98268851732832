export const APP_NAME = '物撮りピクトル'
export const APP_URL = 'https://pictol-studio.com'

// お問い合わせURL
export const CONTACT_URL = 'https://pintoforyou.tayori.com/form/a12bd0dbea38d7bd1d92230810eebb1a2d833d00'

export const CONTACT_TEL = '03-6804-7558'
export const CONTACT_TEL_FOR_LINK = '0368047558'
// よくある質問URL
export const FAQ_URL = 'https://pintoforyou.tayori.com/faq/8e4a0f4f5bd829c31212ee394e7cef4092474622'
// 注文CSVテンプレートのダウンロードパス
export const ORDER_TEMPLATE_CSV_DOWNLOAD_PATH = '/order_template.csv'
// 注文CSVテンプレートのダウンロードファイル名
export const ORDER_TEMPLATE_CSV_DOWNLOAD_FILE_NAME = 'order_template'
// 注文CSVサンプルのダウンロードパス
export const ORDER_SAMPLE_CSV_DOWNLOAD_PATH = '/order_sample.csv'
// 注文CSVサンプルのダウンロードファイル名
export const ORDER_SAMPLE_CSV_DOWNLOAD_FILE_NAME = 'order_sample'
export const SERVICE_NAME = '物撮りピクトル'
// FIXME: 仮 description
// https://www.pivotaltracker.com/story/show/183636805
export const META_DESCRIPTION = '物撮りピクトルは、オプション込み込み・最短1営業日で納品可能な物撮りサービスです。'

export const RETURN_ADDRESS_LIMIT_NUM = 5
