import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

/* components */
import { OrderDetailTableForDocument, CorporateInfo } from 'components/organisms'

/* lib, types, const */
import { Invoice } from 'types/graphql'
import { mediaSp } from 'lib/media-query'
import { SERVICE_NAME } from 'const'

type Props = {
  className?: string
  nameOnDocument: string
  honorificTitle: string
  orderDetail: Invoice
}

const Wrapper = styled.div`
  .inner {
    max-width: 980px;
    margin: 30px auto 0;
    padding: 0 20px;
    p {
      font-size: 14px;
      line-height: 1.6;
    }
    .date {
      text-align: right;
      margin: 0 0 20px;
    }
    > .name {
      font-size: 21px;
      font-weight: 700;
      margin: 0 0 20px;
      &.only-honorific-title {
        width: 350px;
        text-align: right;
      }
    }
    .top-row {
      margin: 0 0 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .block {
        p {
          margin: 0 0 10px;
        }
        &.left {
          min-width: 300px;
          margin: 0 20px 40px 0;
          .price-row {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid ${(props): string => props.theme.black};
            p {
              font-size: 24px;
              font-weight: 700;
              span {
                font-size: 18px;
                font-weight: 700;
              }
            }
          }
        }
        &.right {
          width: 300px;
        }
      }
    }
    .remarks {
      padding: 0 0 30px;
      p {
        margin: 0 0 10px;
      }
    }
  }

  ${mediaSp`
    .inner {
      margin: 20px auto;
      .name {
        &.only-honorific-title {
          width: 100%;
          max-width: 330px;
          text-align:right;
        }
      }
    }
  `}
`
export const OrderEstimateTpl: React.FC<Props> = ({ nameOnDocument, honorificTitle, orderDetail }) => {
  return (
    <Wrapper>
      <div className="inner">
        <p className="date">発⾏⽇: {dayjs().format('YYYY年MM月DD日')}</p>
        <p className={nameOnDocument ? 'name' : 'name only-honorific-title'}>
          {nameOnDocument} {honorificTitle}
        </p>
        <div className="top-row">
          <div className="block left">
            <p>件名： {SERVICE_NAME} 撮影サービスのお見積り</p>
            <p>下記の通りお見積り申し上げます。</p>
            <div className="price-row">
              <p>ご請求金額</p>
              <p>
                <span>¥</span> {orderDetail.total?.toLocaleString()}
                <span>-</span>
              </p>
            </div>
          </div>
          <div className="block right">
            <CorporateInfo />
          </div>
        </div>
        <OrderDetailTableForDocument orderDetail={orderDetail} />
        <div className="remarks">
          <p>▼備考</p>
          <p>
            ・往復送料はお客様のご負担となります。
            <br />
            ・銀行振込の場合、お振込み手数料はお客様でご負担をお願い致します。
          </p>
        </div>
      </div>
    </Wrapper>
  )
}
