import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { ReturnItemStatus } from 'types/graphql'

type Props = {
  className?: string
  status: ReturnItemStatus
}

const StyledReturnStatus = styled.div<Props>`
  color: ${(props) => (props.status === ReturnItemStatus.Unreturned ? props.theme.orange : props.theme.green)};
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  display: inline-block;

  ${mediaSp`
    font-size: 1.8rem;
  `}
`

export const ReturnStatus: React.FC<Props> = ({ className = '', status }) => {
  const getReturnStatusName = (status: ReturnItemStatus): string => {
    switch (status) {
      case ReturnItemStatus.Unreturned:
        return '未返送'
        break
      case ReturnItemStatus.Returned:
        return '返送済'
        break
      default:
        return ''
        break
    }
  }

  return (
    <StyledReturnStatus className={className} status={status}>
      {getReturnStatusName(status)}
    </StyledReturnStatus>
  )
}
