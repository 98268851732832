import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

/* lib, types*/
import { mediaTab, mediaSp } from 'lib/media-query'
import { CONTACT_URL } from 'const'

/* images */
import orderIcon from 'assets/images/icon/order.svg'
import contactIcon from 'assets/images/icon/contact.svg'

type Props = {
  className?: string
}

const Wrapper = styled.div`
  position: fixed;
  right: 24px;
  bottom: 88px;
  z-index: ${(props): number => props.theme.zIndex.sideButtons};
  ${mediaSp`
    right: 1.2rem;
    bottom: 2rem;

    &.download-page {
      bottom: 7.6rem;
    }
  `}
`
const SideButton = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  font-weight: 500;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(${(props): string => props.theme.blackRgb}, 0.25);
  transition: all 0.3s;
  &.order {
    background: ${(props): string => props.theme.yellow};
    color: ${(props): string => props.theme.black};
    font-size: 16px;
    margin: 0 0 20px;
    &:hover {
      background: rgba(${(props): string => props.theme.yellowRgb}, 0.5);
    }
    img {
      width: 32px;
      margin: 0 auto 3.5px;
    }
  }
  &.contact {
    background: ${(props): string => props.theme.black};
    color: ${(props): string => props.theme.white};
    font-size: 12px;
    &:hover {
      background: rgba(${(props): string => props.theme.blackRgb}, 0.7);
    }
    img {
      width: 38px;
      margin: 0 auto 6px;
    }
  }
  ${mediaSp`
    width: 7rem;
    height: 7rem;
    &.order {
      font-size: 1.2rem;
      margin:0 0 1.6rem;
      img {
        width: 2.6rem;
        margin: 0 auto 0.2rem;
      }
    }
    &.contact {
      background: ${(props): string => props.theme.black};
      color: ${(props): string => props.theme.white};
      font-size: 1rem;
      img {
        width: 2.8rem;
        margin: 0 auto 0.5rem;
      }
  `}
  ${mediaTab`
    width: 5rem;
    height: 5rem;
    &.order {
      font-size: 1rem;
      margin:0 0 1rem;
      img {
        width: 1.8rem;
      }
    }
    &.contact {
      font-size: 0.7rem;
      img {
        width: 2rem;
        margin: 0 auto 0.5rem;
      }
    }
  `}
`

export const SideButtons: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isDownloadPage = pathname.match('/mypage/order/download/') !== null
  const isOrderPage = pathname === '/order' || pathname === '/order/confirm'

  const goToOrder = (): void => {
    navigate('/order')
  }
  const goToContact = (): void => {
    window.open(CONTACT_URL, '_blank', 'noreferrer')
  }

  return (
    <Wrapper className={`${className} ${isDownloadPage ? 'download-page' : ''}`}>
      {!isOrderPage && (
        <SideButton className="order" onClick={goToOrder}>
          <img src={orderIcon} alt="ご注文" />
          ご注文
        </SideButton>
      )}
      <SideButton className="contact" onClick={goToContact}>
        <img src={contactIcon} alt="お問い合わせ" />
        お問い合わせ
      </SideButton>
    </Wrapper>
  )
}
