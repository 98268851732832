import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  message: string
}

const StyledErrorMessage = styled.p`
  padding-top: 0.8rem;
  color: ${(props): string => props.theme.errorText};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.05em;

  ${mediaSp`
    font-size: 1.2rem;
    line-height: 1.4rem;
  `}
`

export const ErrorMessage: React.FC<Props> = ({ className = '', message }) => {
  return <StyledErrorMessage className={className}>{message}</StyledErrorMessage>
}
