import { Dispatch, SetStateAction, useState } from 'react'
import * as Sentry from '@sentry/react'

/* lib, types, const */
import { OrderItemInput, Invoice, useUserQuotationLazyQuery, useUserGenerateUploadOrderCsvLinkLazyQuery } from 'types/graphql'
import { OrderInputErrors, FlashMessageType } from 'types/myTypes'
import { validateCsv, validateOrderItem } from 'lib/validate'

type OrderCsvArgs = {
  errors: OrderInputErrors
  setErrors: Dispatch<SetStateAction<OrderInputErrors>>
  setFlashMessage: Dispatch<SetStateAction<FlashMessageType | null>>
}

type OrderCsvStates = {
  orderItems: OrderItemInput[]
  orderDetail?: Invoice
  csvFile?: File
  isCsvHowToModalOpen: boolean
  setOrderItems: Dispatch<SetStateAction<OrderItemInput[]>>
  setOrderDetail: Dispatch<SetStateAction<Invoice | undefined>>
  setCsvFile: Dispatch<SetStateAction<File | undefined>>
  setIsCsvHowToModalOpen: Dispatch<SetStateAction<boolean>>
}

type OrderCsvValues = {
  userQuotationLazyQueryLoading: boolean
  userGenerateUploadOrderCsvLinkLazyQueryLoading: boolean
  onChangeCsv: (file: File) => void
  onClickResetCsv: () => void
  onClickOpenCsvHowtoModal: () => void
  onClickCloseCsvHowtoModal: () => void
}

export type OrderCsvProps = OrderCsvStates & OrderCsvValues

export const useOrderCsv = (args: OrderCsvArgs): OrderCsvProps => {
  const { errors, setErrors, setFlashMessage } = args

  const [orderItems, setOrderItems] = useState<OrderItemInput[]>([])
  const [orderDetail, setOrderDetail] = useState<Invoice>()
  const [csvFile, setCsvFile] = useState<File>()
  const [isCsvHowToModalOpen, setIsCsvHowToModalOpen] = useState<boolean>(false)

  const [userQuotationLazyQuery, { loading: userQuotationLazyQueryLoading }] = useUserQuotationLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data || !data.UserQuotation) return
      setOrderDetail(data.UserQuotation)
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userGenerateUploadOrderCsvLinkLazyQuery, { loading: userGenerateUploadOrderCsvLinkLazyQueryLoading }] =
    useUserGenerateUploadOrderCsvLinkLazyQuery({
      onCompleted: async (data) => {
        await uploadCsvToS3(data.UserGenerateUploadOrderCsvLink.url as string)
      },
      onError: (e) => {
        setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
        Sentry.captureException(e)
      },
    })

  // csvをOrderItemInput型の配列に変更
  const parseCsvToOrderItemInputArray = (csvFile: File): Promise<OrderItemInput[]> => {
    return new Promise<OrderItemInput[]>((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const csvContent = fileReader.result as string
        const csvLines = csvContent.split(/\r\n|\n|\r/g)
        const csvLinesArray = csvLines.map((line) => line.split(','))

        // csvの中身がない場合 or 見出し行のみの場合はreject
        if (csvLinesArray.length <= 1) reject('csvの内容が正しくありません。')

        // 1つ目の要素は見出し行なので削除
        csvLinesArray.shift()

        const orderItemInputArray: OrderItemInput[] = csvLinesArray.map((line) => {
          const [
            order_number,
            jan_code,
            product_code,
            product_category,
            color,
            style,
            cut_num,
            has_movie,
            // FIXME: 360度撮影復活したら
            // https://www.pivotaltracker.com/story/show/183552747
            // has_photo360,
            extension,
            photo_size,
            remarks,
          ] = line
          return {
            order_number: Number(order_number),
            jan_code,
            product_code,
            product_category,
            color,
            style,
            cut_num: cut_num ? Number(cut_num) : null,
            has_movie: !!has_movie,
            // FIXME: 360度撮影復活したら
            // https://www.pivotaltracker.com/story/show/183552747
            // has_photo360: !!has_photo360,
            extension: extension || null,
            photo_size: photo_size ? Number(photo_size) : null,
            remarks,
          }
        })
        // 注文作成時のmutation用
        setOrderItems(orderItemInputArray)
        resolve(orderItemInputArray)
      }
      fileReader.onerror = (e) => {
        reject('ファイルをアップロードできませんでした。')
        Sentry.captureException(e)
      }
      fileReader.readAsText(csvFile)
    })
  }

  // csvをs3にアップロード
  const uploadCsvToS3 = async (url: string): Promise<unknown> => {
    return new Promise(() => {
      const xhr = new XMLHttpRequest()

      xhr.open('PUT', url)
      xhr.setRequestHeader('Content-Type', 'multipart/form-data')
      xhr.send(csvFile)
    }).catch((e) => {
      setFlashMessage({ type: 'error', message: 'csvをアップロードできませんでした' })
      Sentry.captureException(e)
    })
  }

  // csvから注文情報を取得
  const getOrderDetailFromCsv = (file: File): void => {
    setFlashMessage(null)

    // csvファイルをAPIのinputの形に変更してAPI実行
    parseCsvToOrderItemInputArray(file)
      .then((orderItemInputArray) => {
        const validateResult = orderItemInputArray.map((orderItem) => {
          return validateOrderItem(orderItem)
        })
        const orderItemErrors = validateResult.filter((result) => result !== null)
        if (!!orderItemErrors.length) {
          setErrors({ ...errors, csv: orderItemErrors[0] })
          return
        }

        userQuotationLazyQuery({
          variables: {
            input: {
              order_items: orderItemInputArray,
            },
          },
        })
        // csvをS3にアップロードする
        userGenerateUploadOrderCsvLinkLazyQuery()
      })
      .catch((e) => {
        setFlashMessage({ type: 'error', message: e })
      })

    // 確認画面へ遷移するときにアップロード処理をするためにsetしておく
    setCsvFile(file)
  }

  const onChangeCsv = (file: File): void => {
    const errorMessage = validateCsv(file)
    setErrors({ ...errors, csv: errorMessage })
    if (!errorMessage) {
      getOrderDetailFromCsv(file)
    }
  }

  // アップロードしたCSVデータをリセット
  const onClickResetCsv = (): void => {
    setOrderDetail(undefined)
    setCsvFile(undefined)
  }

  // 入力方法モーダル表示
  const onClickOpenCsvHowtoModal = (): void => {
    setIsCsvHowToModalOpen(true)
  }

  // 入力方法モーダル閉じる
  const onClickCloseCsvHowtoModal = (): void => {
    setIsCsvHowToModalOpen(false)
  }

  return {
    /* state */
    orderItems,
    orderDetail,
    csvFile,
    isCsvHowToModalOpen,
    setOrderItems,
    setOrderDetail,
    setCsvFile,
    setIsCsvHowToModalOpen,
    /* value */
    userQuotationLazyQueryLoading,
    userGenerateUploadOrderCsvLinkLazyQueryLoading,
    onChangeCsv,
    onClickResetCsv,
    onClickOpenCsvHowtoModal,
    onClickCloseCsvHowtoModal,
  }
}
