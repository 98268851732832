import React from 'react'
import styled from 'styled-components'

/* components */
import { TextButton } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  headingText: string
  buttonText: string
  onClick: () => void
}

const StyledSubHeading = styled.div`
  width: 100%;
  border-bottom: 0.3rem solid ${(props): string => props.theme.yellow};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > h3 {
    padding: 0 0 18px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.05em;
  }

  .button {
    padding: 0 0 13px;
    font-size: 14px;
  }

  ${mediaSp`
    h3 {
      font-size: 1.6rem;
      padding: 0 0 0.8rem;
    }

    .button {
      padding: 0 0 1rem;
      font-size: 1.2rem;
    }
  `}
`

export const SubHeadingWithTextButton: React.FC<Props> = ({ className = '', headingText, buttonText, onClick }) => {
  return (
    <StyledSubHeading className={className}>
      <h3>{headingText}</h3>
      <TextButton onClick={onClick} className="button">
        {buttonText}
      </TextButton>
    </StyledSubHeading>
  )
}
