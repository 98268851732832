import React, { useState } from 'react'
import styled from 'styled-components'

/* components */
import { ErrorMessage } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  name?: string
  onChange: (file: File) => void
}

type StyleProps = {
  errorMessage?: string | null
}

const Wrapper = styled.div`
  width: 100%;
`

const StyledInputWrapper = styled.div<StyleProps>`
  width: 100%;
  height: auto;
  padding: 122px 0 150px;
  background: ${(props): string => props.theme.lightGray};
  border: 0.2rem solid ${(props): string => (!!props.errorMessage ? props.theme.errorText : 'transparent')};
  border-radius: 20px;
  transition: 0.3s all;

  &.drag-over {
    border: 0.2rem solid ${(props): string => props.theme.black};
  }

  .text {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.7;
    text-align: center;
    letter-spacing: 0.05em;
    display: block;

    &.or {
      font-size: 18px;
    }
  }

  .select-file-button {
    width: 277px;
    height: 60px;
    margin: 0 auto;
    color: ${(props): string => props.theme.white};
    font-size: 20px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.05em;
    border-radius: 30px;
    background: ${(props): string => props.theme.black};
    display: block;
    cursor: pointer;

    &:hover {
      background: rgba(${(props): string => props.theme.blackRgb}, 0.7);
    }
  }

  ${mediaSp`
    padding: 4.8rem 0;
    border-radius: 1rem;

    .text {
      margin-bottom: 1.2rem;
      font-size: 1.6rem;

      &.or {
        font-size: 1.2rem;
      }
    }

    .select-file-button {
      width: 23.9rem;
      height: 5.2rem;
      font-size: 1.6rem;
      line-height: 5.2rem;
      border-radius: 2.6rem;
    }
  `}
`

const StyledInput = styled.input`
  display: none;
`

export const CsvFileInput: React.FC<Props & StyleProps> = ({ className = '', name, errorMessage, onChange }) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false)

  const onDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    setIsDragOver(true)
  }

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    setIsDragOver(false)
  }

  const onDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    onChange(file)
    setIsDragOver(false)
  }

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files !== null && e.target.files[0] !== undefined) {
      const file = e.target.files[0]
      onChange(file)
    }
  }

  return (
    <Wrapper>
      <StyledInputWrapper
        className={isDragOver ? `${className} drag-over` : className}
        errorMessage={errorMessage}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <span className="text">ここにファイルをドロップ</span>
        <span className="text or">または</span>
        {/* Buttonコンポーネントを置くとinputが動かなくなるのでspanタグ使用 */}
        <label htmlFor="csv_input" className="select-file-button">
          ファイルを選択
        </label>
        <StyledInput
          type="file"
          id="csv_input"
          name={name}
          onChange={onChangeInput}
          onClick={(e) => {
            e.currentTarget.value = ''
          }}
          accept=".csv"
        />
      </StyledInputWrapper>
      {!!errorMessage && <ErrorMessage message={errorMessage} />}
    </Wrapper>
  )
}
