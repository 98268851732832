import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { AuthCodeTpl } from 'components/templates'

/* lib, types */
import { useUserApproveAuthorizationCodeMutation } from 'types/graphql'
import { FlashMessageType, AddressInput } from 'types/myTypes'

export const MypageAccountEditAuthCode: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [authCode, setAuthCode] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const navigate = useNavigate()
  const location = useLocation()
  const { state, pathname } = location as unknown as Location & {
    state: { email: string; user_id: string; expires: string; signature: string; addressInput: AddressInput }
  }

  const [userApproveAuthorizationCodeMutation, { loading }] = useUserApproveAuthorizationCodeMutation({
    onCompleted: () => {
      navigate('/mypage/account', {
        state: {
          redirectFrom: pathname,
        },
      })
      return
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: e.message ?? '注文者情報の更新に失敗しました。' })
      Sentry.captureException(e)
    },
  })

  const onClickAuth = (): void => {
    const { email, user_id, expires, signature } = state
    userApproveAuthorizationCodeMutation({
      variables: {
        input: {
          email,
          user_id,
          expires: Number(expires),
          signature,
          code: authCode,
        },
      },
    })
  }

  useEffect(() => {
    setEmail(state.email)
  }, [state])

  return (
    <>
      <Meta title="メールアドレス認証" />
      {loading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <AuthCodeTpl
        backHref="/mypage/account/edit/user"
        backState={{ addressInput: state.addressInput, email }}
        email={email}
        authCode={authCode}
        setAuthCode={(authCode: string) => setAuthCode(authCode)}
        onClickAuth={onClickAuth}
      />
    </>
  )
}
