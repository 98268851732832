import React from 'react'
import { useLocation } from 'react-router-dom'

/* components */
import { Meta } from 'components/meta'
import { OrderCompleteTpl } from 'components/templates'

export const OrderComplete: React.FC = () => {
  const location = useLocation()
  const orderId = new URLSearchParams(location.search).get('number') ?? ''

  return (
    <>
      <Meta title="注文完了" />
      <OrderCompleteTpl orderId={orderId} />
    </>
  )
}
