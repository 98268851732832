import React, { useEffect } from 'react'
import styled from 'styled-components'

/* lib, types */
import { mediaSp } from 'lib/media-query'

/* images */
import { ReactComponent as ModalCloseIconSvg } from 'assets/images/icon/modal-close.svg'
import { ReactComponent as SampleCsvSvg } from 'assets/images/order/sample-csv.svg'

type Props = {
  closeModal: () => void
}

const Wrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(${(props): string => props.theme.blackRgb}, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props): number => props.theme.zIndex.modal};
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay-for-close {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: ${(props): number => props.theme.zIndex.modal};
  }

  .close-button {
    position: absolute;
    width: 35px;
    height: 32px;
    top: 40px;
    right: 40px;
    z-index: ${(props): number => props.theme.zIndex.modal};
    cursor: pointer;
  }

  > .contents {
    width: 840px;
    max-width: 96%;
    max-height: 76.5vh;
    padding: 4rem;
    background: ${(props): string => props.theme.white};
    z-index: ${(props): number => props.theme.zIndex.modal + 1};
    overflow: scroll;
    border-radius: 3rem;

    > .inner {
      width: 760px;
      max-width: 100%;
      margin: 0 auto;

      .title {
        width: 100%;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 700;
        line-height: 60px;
        text-align: center;
        background: ${(props): string => props.theme.yellow};
        border-radius: 0.8rem;
      }

      .sample-img {
        width: 100%;
        margin-bottom: 10px;
        overflow: scroll;
      }
    }
  }

  ${mediaSp`
    .close-button {
      width: 3rem;
      height: 2.8rem;
      top: 1.6rem;
      right: 1.6rem;
    }

    >.contents {
      width: 80.5vw;
      max-width: 80.5vw;
      max-height: 81.4vh;
      padding: 2.4rem 1.3rem;
      border-radius: 1.5rem;
      >.inner {
        width: 27.6rem;

        .title {
          width: 100%;
          margin-bottom: 0.9rem;
          font-size: 1.4rem;
          line-height: 4rem;
          border-radius: 0.4rem;
        }

        .sample-img-wrapper {
          width: calc(100% + 1.3rem);
          margin-bottom: 0.7rem;
          padding-right: 1.3rem;
          overflow-x: scroll;

          .sample-img {
            width: auto;
          }
        }

      }
    }
  `}
`

const StyledRow = styled.div`
  padding: 9px 0;
  margin: 0 8px;
  display: flex;
  border-bottom: 1px solid ${(props): string => props.theme.lightGray};

  &:last-child {
    border-bottom: none;
  }

  .heading {
    width: 162px;
    height: 26px;
    margin-right: 37px;
    padding-left: 8px;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 26px;
    background: ${(props): string => props.theme.lightGray};
    border-radius: 0.4rem;

    span {
      margin-left: 0.4rem;
      color: ${(props): string => props.theme.errorText};
      font-size: 1.2rem;
      line-height: 26px;
      letter-spacing: 0.04em;
      vertical-align: top;
      display: inline-block;
    }
  }

  .description {
    padding-top: 3px;
    font-size: 1.2rem;
    line-height: 1.7;

    .bold {
      font-weight: 500;
    }
  }

  &.with-note {
    display: block;

    > div {
      margin-bottom: 1.2rem;
      display: flex;
    }

    .note {
      padding-left: 10px;
      font-size: 1.2rem;
      font-weight: 500;
      color: ${(props): string => props.theme.errorText};
      line-height: 1.9;
      letter-spacing: 0.04em;
    }
  }

  ${mediaSp`
    margin-bottom: 2.6rem;
    padding: 0;
    display: block;
    border-bottom: none;

    &:last-child {
      margin-bottom: 0;
    }

    .heading {
      width: 100%;
      height: 2.8rem;
      margin: 0 0 1.2rem 0;
      padding-left: 1.2rem;
      line-height: 2.8rem;

      span {
        line-height: 2.8rem;
      }
    }

    .description {
      padding: 0 1.2rem;
    }

    &.with-note {
      >div {
        display: block;
      }

      .note {
        padding-left: 0;
      }
    }
  `}
`

export const OrderCsvHowToModal: React.FC<Props> = ({ closeModal }) => {
  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'

    return () => {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  })

  return (
    <Wrapper>
      <div className="overlay-for-close" onClick={closeModal} />
      <div className="close-button" onClick={closeModal}>
        <ModalCloseIconSvg />
      </div>
      <div className="contents">
        <div className="inner">
          <p className="title">注文情報CSV 入力方法</p>
          <div className="sample-img-wrapper">
            <SampleCsvSvg className="sample-img" />
          </div>
          <StyledRow>
            <div className="heading">JANコード</div>
            <div className="description">JANコードがある場合は入力してください。</div>
          </StyledRow>
          <StyledRow>
            <div className="heading">
              商品コード<span>*</span>
            </div>
            <div className="description">商品コードは必須です。</div>
          </StyledRow>
          <StyledRow>
            <div className="heading">
              商品分類<span>*</span>
            </div>
            <div className="description">「シャツ」「パンツ」等の商品分類をご入力ください。</div>
          </StyledRow>
          <StyledRow>
            <div className="heading">
              カラー<span>*</span>
            </div>
            <div className="description">
              商品のカラーをご入力ください。
              <br />
              商品のカラーバリエーションがある場合は行を分けてご入力ください。
            </div>
          </StyledRow>
          <StyledRow>
            <div className="heading">
              撮影スタイル<span>*</span>
            </div>
            <div className="description">「小物類」「平置き」「トルソー」のいずれかを入力してください。</div>
          </StyledRow>
          <StyledRow>
            <div className="heading">
              商品撮影カット数<span>※</span>
            </div>
            <div className="description">1〜74の間でご指定ください。</div>
          </StyledRow>
          <StyledRow className="with-note">
            <div>
              <div className="heading">
                動画撮影<span>※</span>
              </div>
              <div className="description">
                動画撮影が必要な場合は○を記入してください。
                <br />
                撮影スタイルが平置きの場合は動画撮影はできません。
              </div>
            </div>
            <p className="note">※商品撮影カット数、動画撮影 の少なくともいずれかは、必須です</p>
          </StyledRow>
          {/* FIXME: 360度撮影が追加されたら戻す */}
          {/* https://www.pivotaltracker.com/story/show/183552747 */}
          {/* <StyledRow className="with-note">
            <div>
              <div className="heading">
                360度撮影<span>※</span>
              </div>
              <div className="description">
                360度撮影が必要な場合は○を記入してください。
                <br />
                撮影スタイルが平置きの場合は360度撮影はできません。
              </div>
            </div>
            <p className="note">※商品撮影カット数、動画撮影、360度撮影 の少なくともいずれかは、必須です</p>
          </StyledRow> */}
          <StyledRow className="with-note">
            <div>
              <div className="heading">
                拡張子<span>※</span>
              </div>
              <div className="description">
                「png」「jpeg」「tiff」の中からご指定ください。
                <br />
                <span className="bold">画像背景は「png」「tiff」の場合は透明、「jpeg」の場合は白になります。</span>
              </div>
            </div>
            <p className="note">※商品撮影カット数を入力した場合、拡張子は必須です</p>
          </StyledRow>
          <StyledRow>
            <div className="heading">商品撮影画像サイズ（px）</div>
            <div className="description">
              縦横比1:1の正方形となりますので、一辺のサイズ(px)をご入力ください。
              <br />
              指定がない場合1200pxになります。
            </div>
          </StyledRow>
          <StyledRow>
            <div className="heading">備考</div>
            <div className="description">その他に撮影リクエストがありましたらご記入ください。</div>
          </StyledRow>
        </div>
      </div>
    </Wrapper>
  )
}
