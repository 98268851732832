import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

/* components */
import { SubHeading, OrderStatus, ReturnStatus, Button, ButtonLink, TextExternalLink } from 'components/atoms'
import { OrderDetailTable, AddressTable } from 'components/organisms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { Order, Invoice, ReturnItem, ReturnItemStatus, OrderStatus as OrderStatusType, PaymentStatus } from 'types/graphql'
import dayjs from 'dayjs'

/* images */
import { ReactComponent as DownloadIconSvg } from 'assets/images/icon/download.svg'
import { ReactComponent as NextIconSvg } from 'assets/images/icon/next-icon.svg'

type Props = {
  order: Order
  invoice: Invoice
  returnItem: ReturnItem
  onClickDownloadCsv: () => void
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
  padding: 64px 0 120px;

  .sub-heading {
    margin-bottom: 32px;
  }
  .contents-title {
    padding: 0 20px 24px;
    font-size: 20px;
    font-weight: 500;
  }
  .order-detail {
    padding: 0 0 6.4rem;
    .row {
      padding: 0 20px 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .status-wrapper {
        display: flex;
        align-items: center;
        p {
          font-size: 18px;
          padding: 0 16px 0 0;
        }
      }
      .download-button-wrapper {
        width: 200px;
        text-align: center;
        .button {
          position: relative;
          display: block;
          width: 200px;
          height: 4.8rem;
          font-size: 1.6rem;
          padding: 0 6px 0 0;
          width: 100%;
          .next-icon {
            position: absolute;
            top: 16px;
            right: 16px;
            fill: ${(props): string => props.theme.white};
            width: 1.6rem;
            height: 1.6rem;
            fill: ${(props): string => props.theme.black};
          }
          &:disabled {
            .next-icon {
              fill: ${(props): string => props.theme.darkGray};
            }
          }
        }
        .expired-at {
          font-weight: 500;
          text-align: right;
          font-size: 1.2rem;
          padding: 0.8rem 0 0;
          color: ${(props): string => props.theme.darkGray};
        }
      }
    }
    .csv-download-button {
      margin: 32px 0 0 auto;
      position: relative;
      display: block;
      width: 32.2rem;
      height: 5.4rem;
      font-size: 1.6rem;
      line-height: 48px;
      padding: 0 0 0 29px;

      .icon {
        position: absolute;
        top: 1.5rem;
        left: 4.8rem;
        width: 1.8rem;
        height: 1.8rem;
        fill: ${(props): string => props.theme.black};
        transition: 0.3s all;
      }
      &:hover {
        .icon {
          fill: ${(props): string => props.theme.white};
        }
      }
    }
  }
  .invoice-address-detail {
    margin: 0 0 6.4rem;
    .sub-heading {
      margin: 0;
    }
    .address-table {
      max-width: 796px;
    }
  }
  .return-detail {
    margin: 0 0 6.4rem;
    .rows {
      margin: 0 0 48px;
      .row {
        padding: 0 20px;
        display: flex;
        align-items: center;
        p {
          font-size: 18px;
          padding: 0 16px 0 0;
        }

        & + .row {
          padding: 8px 20px 0;
        }
      }
    }
    .return-address-table {
      max-width: 796px;
      border-top: 1px solid ${(props): string => props.theme.gray};
    }
  }
  .to-order-list {
    width: 31.2rem;
    margin: 0 auto;
  }
  ${mediaSp`
    padding: 0rem 0 12rem;

    .sub-heading {
      margin-bottom: 4rem;
    }
    .contents-title {
      padding: 0 0 1.6rem;
      font-size: 1.6rem;
    }
    .row {
      padding: 0 0 5.6rem;
      p {
        font-size: 1.6rem;
        padding: 0 1.2rem 0 0;
      }
    }
    .order-detail {
      .row {
        display: block;
        .status-wrapper {
          margin: 0 0 4rem;
          p {
            font-size: 1.6rem;
            padding: 0 1.2rem 0 0;
          }
        }
        .download-button-wrapper {
          margin: 0 auto;
          width: 32.2rem;
          .expired-at {
            font-size: 0.1rem;
            padding: 0 0 0.4rem;
            color: ${(props): string => props.theme.darkGray};
          }
          .button {
            width: 32.2rem;
            padding: 0 1.6rem 0 0;
            .next-icon {
              top: 1.6rem;
              right: 9.3rem;
            }
            &:disabled {
              .next-icon {
                fill: ${(props): string => props.theme.darkGray};
              }
            }
          }
        }
      }
      .csv-download-button {
        margin: 4rem auto 0;
        padding: 0 0 0 2.6rem;
      }
    }
    .return-detail {
      .rows {
        margin: 0 0 4.2rem;

        .row {
          padding: 0;
          p {
            font-size: 1.6rem;
            padding: 0 1.2rem 0 0;
          }

          & + .row {
            padding: 0.8rem 0 0;
          }
        }
      }
      .return-address-table {
        width: 100%;
        border-top:  1px solid ${(props): string => props.theme.gray};
      }
    }
  `}
`

export const MypageOrderDetailTpl: React.FC<Props> = ({ order, invoice, returnItem, onClickDownloadCsv }) => {
  const navigate = useNavigate()

  const goToDownloadPage = (orderId: string): void => {
    navigate(`/mypage/order/download/${orderId}`)
  }

  const isDisableDownload = (): boolean => {
    return !(order.status === OrderStatusType.Inspecting || order.status === OrderStatusType.Inspected)
  }

  const isVisibleExpiredAt = (): boolean => {
    return order.status === OrderStatusType.Inspected || order.status === OrderStatusType.Expired
  }

  return (
    <Wrapper>
      <div className="order-detail">
        <SubHeading text="注文情報" className="sub-heading" />
        <div className="row">
          <div className="status-wrapper">
            <p>注文状況:</p>
            <OrderStatus status={order.status as OrderStatusType} />
          </div>
          <div className="download-button-wrapper">
            <Button
              className="button"
              buttonType="yellow"
              disabled={isDisableDownload()}
              onClick={() => goToDownloadPage(order.id as string)}
            >
              ダウンロード
              <NextIconSvg className="next-icon" />
            </Button>
            {isVisibleExpiredAt() && <p className="expired-at">有効期限: {dayjs(order.download_expired_at).format('YYYY/MM/DD')}</p>}
          </div>
        </div>
        <p className="contents-title">注文内容</p>
        <OrderDetailTable
          orderDetail={invoice}
          isOrderFinished={true}
          orderId={order.id as string}
          orderPaymentStatus={order.payment_status as PaymentStatus}
          companyName={invoice.company_name as string | undefined}
          name={invoice.name as string}
        />
        <Button className="csv-download-button" buttonType="blackBorder" onClick={onClickDownloadCsv}>
          <DownloadIconSvg className="icon" />
          注文情報CSVダウンロード
        </Button>
      </div>

      <div className="invoice-address-detail">
        <SubHeading text="注文者情報(請求先)" className="sub-heading" />
        <AddressTable className="address-table" address={invoice} />
      </div>
      <div className="return-detail">
        <SubHeading text="返送情報" className="sub-heading" />
        <div className="rows">
          <div className="row">
            <p>返送状況:</p>
            <ReturnStatus status={returnItem.status as ReturnItemStatus} />
          </div>
          {returnItem.status === ReturnItemStatus.Returned && returnItem.shipping_service && returnItem.shipping_code && (
            <div className="row">
              <p>追跡番号:</p>
              <TextExternalLink
                text={returnItem.shipping_code}
                // 佐川急便のみ
                href={`https://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=${returnItem.shipping_code}`}
              />
            </div>
          )}
        </div>
        <p className="contents-title">返送先住所</p>
        <AddressTable className="return-address-table" address={returnItem} />
      </div>

      <ButtonLink buttonType="black" href="/mypage/order" className="to-order-list">
        注文履歴一覧へ戻る
      </ButtonLink>
    </Wrapper>
  )
}
