import React from 'react'
import styled from 'styled-components'

/* components */
import { PageTitle } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  pageTitle: string
  children: React.ReactNode
}

const Wrapper = styled.div`
  > .inner {
    width: 98%;
    max-width: 840px;
    margin: 0 auto;
    padding: 64px 0 0;

    .page-title {
      margin-bottom: 48px;
      text-align: center;
    }
  }

  ${mediaSp`
    > .inner {
      width: 35rem;
      max-width: 35rem;
      padding-top: 4rem;

      .page-title {
        margin-bottom: 3.2rem;
      }
    }
  `}
`

export const MypageLayout: React.FC<Props> = ({ className = '', pageTitle, children }) => {
  return (
    <Wrapper className={className}>
      <div className="inner">
        <PageTitle title={pageTitle} className="page-title" />
        <div>{children}</div>
      </div>
    </Wrapper>
  )
}
