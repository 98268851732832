import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

/* components */
import { Button, TextLink } from 'components/atoms'
import { Pagination } from 'components/molecules'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { DownloadFileInfo, Order, OrderStatus } from 'types/graphql'
import dayjs from 'dayjs'

/* images */
import { ReactComponent as DownloadIconSvg } from 'assets/images/icon/download.svg'

type Props = {
  order: Order
  deliveredItems: DownloadFileInfo[]
  currentPage: number
  lastPage: number
  itemTotal: number
  onClickDownloadItem: (name: string) => void
  onClickDownloadAllItems: () => void
  onClickFinishInspection: () => void
}

const Wrapper = styled.div`
  margin: 61px auto 0;
  padding: 0 0 12rem;
  .note {
    font-size: 16px;
  }
  .row {
    display: flex;
    justify-content: start;
    padding: 0 0 16px;
    .order-id {
      font-size: 1.6rem;
      font-weight: 500;
      display: flex;
      margin: 0 32px 0 0;
      .detail-link {
        padding: 0 0 0 8px;
        a {
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
    }
    .expired-at {
      font-size: 1.6rem;
      font-weight: 500;
    }
    .page-count {
      font-size: 1.4rem;
      margin-left: auto;
    }
  }
  .list-wrapper {
    padding: 0 0 72px;
    .list-title {
      background: ${(props): string => props.theme.lightGray};
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
      border-radius: 0.8rem;
      p {
        font-size: 18px;
        font-weight: 500;
      }
    }
    ul {
      li {
        padding: 24px;
        font-size: 1.6rem;
        border-bottom: 1px solid ${(props): string => props.theme.gray};
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.3s all;
        &:hover {
          color: rgba(${(props): string => props.theme.blackRgb}, 0.4);
          .path {
            .icon {
              fill: rgba(${(props): string => props.theme.blackRgb}, 0.4);
            }
          }
        }
        .path {
          display: flex;
          .icon {
            width: 1.8rem;
            height: 1.8rem;
            margin: 0 1.6rem 0 0;
            fill: ${(props): string => props.theme.black};
            transition: 0.3s all;
          }
        }
      }
    }
  }
  .note {
    font-size: 16px;
    text-align: center;
    padding: 0 0 40px;
  }
  .pagination {
    margin: 5.6rem 0 0;
  }
  .button-bar-wrapper {
    height: 100px;
    .button-bar {
      height: 100px;
      padding: 20px 0 0;
      background: ${(props): string => props.theme.white};
      &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0px -2px 4px rgba(${(props): string => props.theme.grayRgb}, 0.4);
      }
      .button-bar-inner {
        display: flex;
        justify-content: center;
        .button {
          width: 280px;
          margin: 0 12px;
          .icon {
            width: 1.8rem;
            height: 1.8rem;
            margin: 0 16px 0 0;
            fill: ${(props): string => props.theme.white};
            transition: 0.3s all;
          }
        }
      }
      .note {
        font-size: 1.2rem;
        color: ${(props): string => props.theme.errorText};
      }
    }
  }
  .expired,
  .no-data {
    padding: 6.4rem 0 0;
    font-size: 16px;
  }

  ${mediaSp`
    width: 33.5rem;
    margin: 3.2rem auto 0;
    .note {
      font-size: 1.4rem;
      padding: 0 0 4.8rem;
    }
    .row {
      display: block;
      padding: 0 0 1.1rem;
      .order-id {
        margin: 0 0 0.3rem;
      }
      .expired-at {
        padding: 0 0 1rem;
      }
      .attention {
        font-size: 1.2rem;
        padding: 0 0 3.2rem;
      }
      .page-count {
        text-align: right;
      }
    }
    .list-wrapper {
      .list-title {
        padding: 1.6rem 2rem;
        p {
          font-size: 1.4rem;
        }
      }
      ul {
        li {
          padding: 1.6rem 0;
        }
      }
    }
    .button-bar-wrapper {
      height: 6.8rem;
      .button-bar {
        width: 100%;
        height: 6.8rem;
        margin: 0 auto;
        &.fixed {
          padding: 1rem 2rem 0;
        }
        .button-bar-inner {
          justify-content: space-between;
          .button {
            width: 16rem;
            height: 4.4rem;
            margin: 0;
            font-size: 1.2rem;
            .icon {
              width: 1.4rem;
              height: 1.4rem;
              margin: 0 0.9rem 0 0;
            }
          }
        }
        .note {
          text-align: right;
          font-size: 1rem;
        }
      }
    }
    .expired,
    .no-data {
      padding: 4rem 0 0;
      font-size: 1.4rem;
    }
  `}
`

export const MypageOrderDownloadTpl: React.FC<Props> = ({
  order,
  deliveredItems,
  currentPage,
  lastPage,
  itemTotal,
  onClickDownloadItem,
  onClickDownloadAllItems,
  onClickFinishInspection,
}) => {
  const [isFixedButtonBar, setIsFixedButtonBar] = useState<boolean>(true)
  const buttonBarRef = useRef<HTMLDivElement>(null)

  const PER_PAGE = 20

  const canDownload = order.status === OrderStatus.Inspecting || order.status === OrderStatus.Inspected

  const displayFrom = (): number => {
    return 1 + (currentPage - 1) * PER_PAGE
  }
  const displayTo = (): number => {
    if (currentPage * PER_PAGE < itemTotal) {
      return currentPage * PER_PAGE
    }
    return itemTotal
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollForButtonBar = (): void => {
    if (buttonBarRef?.current) {
      const currentScrollY = window.scrollY + window.innerHeight
      const { top, height } = buttonBarRef.current.getBoundingClientRect()
      const fixedPosition = window.pageYOffset + top + height

      if (fixedPosition < currentScrollY) {
        setIsFixedButtonBar(false)
      }
      if (fixedPosition > currentScrollY) {
        setIsFixedButtonBar(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollForButtonBar)
    return () => window.removeEventListener('scroll', handleScrollForButtonBar)
  }, [handleScrollForButtonBar])

  return (
    <Wrapper>
      {canDownload && (
        <>
          <div className="row">
            <div className="order-id">
              注文: <TextLink text={order.id as string} href={`/mypage/order/${order.id}`} className="detail-link" />
            </div>
            {order.status === OrderStatus.Inspected && (
              <div className="expired-at">有効期限: {dayjs(order.download_expired_at).format('YYYY/MM/DD')}</div>
            )}
            <p className="sp attention">スマートフォンやタブレットで一括ダウンロードする場合は、Wi-Fi 環境でのご利用を推奨します。</p>
            {itemTotal > PER_PAGE ? (
              <div className="page-count">
                {itemTotal}件中 {displayFrom()}-{displayTo()}件を表示
              </div>
            ) : (
              <div className="page-count">全{itemTotal}件表示</div>
            )}
          </div>
          <div className="list-wrapper">
            <div className="list-title">
              <p>ファイル名</p>
              <p>サイズ</p>
            </div>
            <ul>
              {deliveredItems.map((deliveredItem, index) => (
                <li key={index} onClick={() => onClickDownloadItem(deliveredItem.name as string)}>
                  <div className="path">
                    <DownloadIconSvg className="icon" />
                    <p>{deliveredItem.name}</p>
                  </div>
                  <p>
                    {deliveredItem.size} {deliveredItem.unit}
                  </p>
                </li>
              ))}
            </ul>
            <Pagination className="pagination" currentPage={currentPage} lastPage={lastPage} />
          </div>
          <div className="button-bar-wrapper" ref={buttonBarRef}>
            <div className={isFixedButtonBar ? 'button-bar fixed' : 'button-bar'}>
              <div className="button-bar-inner">
                <Button className="button" buttonType="black" onClick={onClickDownloadAllItems}>
                  <DownloadIconSvg className="icon" />
                  一括ダウンロード
                </Button>
                <div>
                  {order.status !== OrderStatus.Inspected ? (
                    <Button className="button" buttonType="yellow" onClick={onClickFinishInspection}>
                      検収完了
                    </Button>
                  ) : (
                    <Button className="button" buttonType="yellow" disabled onClick={() => undefined}>
                      検収済み
                    </Button>
                  )}
                  <p className="note pc">※5日後に自動的に検収完了となります</p>
                </div>
              </div>
              <p className="note sp">※5日後に自動的に検収完了となります</p>
            </div>
          </div>
        </>
      )}
      {order.status === OrderStatus.Expired && <div className="expired">ダウンロードの有効期限が切れています。</div>}
      {!canDownload && order.status !== OrderStatus.Expired && <div className="no-data">ダウンロードできるファイルはありません。</div>}
    </Wrapper>
  )
}
