import React, { useState } from 'react'
import styled from 'styled-components'

/* components */
import { SubHeading, ErrorMessage, TextLink, Button } from 'components/atoms'
import { TextInputWithLabel } from 'components/molecules'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { EditPasswordInput, EditPasswordInputErrors } from 'types/myTypes'
import { validateRequiredPassword, validatePasswordAndPasswordConfirm, validateRequired } from 'lib/validate'

type Props = {
  editPasswordInput: EditPasswordInput
  onChangeState: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickEdit: () => void
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0rem 0 12rem;

  .sub-heading {
    margin-bottom: 4rem;
  }
  .current-password {
    margin-bottom: 3.2rem;
    &::after {
      content: '';
      display: block;
      clear: both;
    }
    .error-message {
      display: inline-block;
    }
    .forget-password {
      padding-top: 0.8rem;
      float: right;
    }
  }
  .new-password {
    margin-bottom: 3.2rem;
  }
  .new-password-confirm {
    margin-bottom: 6.4rem;
  }
  .password-reset {
    width: 31.2rem;
    margin: 0 auto;
    display: block;
  }

  ${mediaSp`
    width: 33.5rem;
    max-width: 33.5rem;
    padding: 0rem 0 12rem;

    .sub-heading: {
      margin-bottom: 3.2rem;
    }

    .current-password {
      .forget-password {
        a {
          font-size: 1.2rem;
          line-height: 1.4rem;
        }
      }
    }

    .new-password-confirm {
      margin-bottom: 4.8rem;
    }

  `}
`

export const MypageAccountEditPasswordTpl: React.FC<Props> = ({ editPasswordInput, onChangeState, onClickEdit }) => {
  const [editPasswordInputErrors, setEditPasswordInputErrors] = useState<EditPasswordInputErrors>({
    current_password: null,
    new_password: null,
    new_password_confirm: null,
  })

  const validate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    switch (name) {
      case 'current_password':
        setEditPasswordInputErrors({
          ...editPasswordInputErrors,
          current_password: validateRequiredPassword(value),
        })
        break
      case 'new_password':
        setEditPasswordInputErrors({
          ...editPasswordInputErrors,
          new_password: validateRequiredPassword(value),
          new_password_confirm: validateRequiredPassword(value)
            ? editPasswordInputErrors.new_password_confirm
            : validatePasswordAndPasswordConfirm(value, editPasswordInput.new_password_confirm),
        })
        break
      case 'new_password_confirm':
        setEditPasswordInputErrors({
          ...editPasswordInputErrors,
          [name]: validateRequired(value) || validatePasswordAndPasswordConfirm(editPasswordInput.new_password, value),
        })
        break
      default:
        break
    }
  }

  const isDisable = (): boolean => {
    const { current_password, new_password, new_password_confirm } = editPasswordInput
    const isRequiredItemsFilled = current_password && new_password && new_password_confirm
    if (!isRequiredItemsFilled) return true

    const errorArray = Object.values(editPasswordInputErrors).filter((error) => error !== null)
    if (errorArray.length > 0) return true

    return false
  }

  return (
    <Wrapper>
      <SubHeading text="パスワード" className="sub-heading" />

      <div className="current-password">
        <TextInputWithLabel
          labelText="現在のパスワード"
          type="password"
          required
          value={editPasswordInput.current_password}
          name="current_password"
          placeholder=""
          onChange={(e) => {
            onChangeState(e)
            validate(e)
          }}
          error={!!editPasswordInputErrors.current_password}
        />
        {!!editPasswordInputErrors.current_password && (
          <ErrorMessage className="error-message" message={editPasswordInputErrors.current_password} />
        )}
        <TextLink className="forget-password" href="/reset-password/email" text="パスワードをお忘れの場合" />
      </div>

      <div className="new-password">
        <TextInputWithLabel
          labelText="新しいパスワード"
          type="password"
          required
          value={editPasswordInput.new_password}
          name="new_password"
          placeholder=""
          onChange={(e) => {
            onChangeState(e)
            validate(e)
          }}
          error={!!editPasswordInputErrors.new_password}
        />
        {!!editPasswordInputErrors.new_password && <ErrorMessage message={editPasswordInputErrors.new_password} />}
      </div>

      <div className="new-password-confirm">
        <TextInputWithLabel
          labelText="新しいパスワード(確認用)"
          type="password"
          required
          value={editPasswordInput.new_password_confirm}
          name="new_password_confirm"
          placeholder=""
          onChange={(e) => {
            onChangeState(e)
            validate(e)
          }}
          error={!!editPasswordInputErrors.new_password_confirm}
        />
        {!!editPasswordInputErrors.new_password_confirm && <ErrorMessage message={editPasswordInputErrors.new_password_confirm} />}
      </div>

      <Button className="password-reset" buttonType="yellow" disabled={isDisable()} onClick={onClickEdit}>
        変更
      </Button>
    </Wrapper>
  )
}
