import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'

/* components */
import { PageTitle, SubHeading, Button, TextButton, Textarea, ErrorMessage } from 'components/atoms'
import { OrderFormSteps, CsvFileInput, RadioButton, Checkbox } from 'components/molecules'
import { OrderDetailTable, AddressTable, AddressForm, RadioButtonReturnAddressWithButton } from 'components/organisms'

/* lib, types, const*/
import { mediaSp } from 'lib/media-query'
import {
  OrderCsvProps,
  OrderUserProps,
  OrderReturnAddressProps,
  OrderPaymentMethodProps,
  OrderEstimatedShipmentDateProps,
  OrderOtherTextProps,
} from 'lib/order'
import { PaymentMethod } from 'types/graphql'
import { OrderInputErrors } from 'types/myTypes'
import {
  ORDER_TEMPLATE_CSV_DOWNLOAD_PATH,
  ORDER_TEMPLATE_CSV_DOWNLOAD_FILE_NAME,
  ORDER_SAMPLE_CSV_DOWNLOAD_PATH,
  ORDER_SAMPLE_CSV_DOWNLOAD_FILE_NAME,
} from 'const'

/* images */
import { ReactComponent as DownloadIconSvg } from 'assets/images/icon/download.svg'
import { ReactComponent as CalendarIconSvg } from 'assets/images/icon/calendar.svg'

type Props = {
  csvProps: OrderCsvProps
  userProps: OrderUserProps
  returnAddressProps: OrderReturnAddressProps
  paymentMethodProps: OrderPaymentMethodProps
  estimatedShipmentDateProps: OrderEstimatedShipmentDateProps
  otherTextProps: OrderOtherTextProps
  errors: OrderInputErrors
  goToConfirm: () => void
}

const Wrapper = styled.div`
  width: 98%;
  max-width: 998px;
  margin: 0 auto;
  padding: 80px 0 120px;

  .page-title,
  .steps {
    margin: 0 auto 6.4rem;
  }

  .page-title {
    text-align: center;
  }

  .confirm-button {
    width: 31.2rem;
    margin: 0 auto;
    display: block;
  }

  ${mediaSp`
    width: 33.5rem;
    max-width: 33.5rem;
    margin: 0 auto;
    padding: 7.2rem 0 14.4rem;

    .page-title {
      margin-bottom: 4.6rem;
    }

    .steps {
      margin-bottom: 4.8rem;
    }
  `}
`

const StyledItem = styled.div`
  max-width: 840px;
  margin: 0 auto 6.4rem;

  .sub-heading {
    margin-bottom: 40px;
  }

  &.csv {
    .csv-info {
      margin-bottom: 4rem;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.05em;
    }

    .template-download-button {
      width: 506px;
      margin: 0 auto 0.8rem;
      padding-left: 33px;
      position: relative;
      display: block;

      .icon {
        width: 19px;
        height: 19px;
        position: absolute;
        top: 50%;
        left: 60px;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        fill: ${(props): string => props.theme.black};
        transition: 0.3s all;
      }
    }

    .row {
      margin: 0 0 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;

      .howto-modal-button {
        font-size: 14px;
      }

      .sample-download-button {
        width: 248px;
        height: 39px;
        padding-left: 56px;
        font-size: 14px;
        text-align: left;
        position: relative;
        display: block;

        .icon {
          width: 1.5rem;
          height: 1.5rem;
          position: absolute;
          top: 50%;
          left: 39.5px;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          fill: ${(props): string => props.theme.black};
          transition: 0.3s all;
        }

        &:hover {
          .icon {
            fill: ${(props): string => props.theme.white};
          }
        }
      }
    }
  }

  &.user {
    .address-table {
      margin-top: -32px;
    }

    .edit-user-button {
      padding-top: 32px;
      margin: 0 20px 0 auto;
      font-size: 18px;
    }
  }

  &.return-address {
    .check-same {
      margin-bottom: 3.2rem;

      label {
        font-size: 16px;
      }
    }

    .note {
      margin: 0 0 16px 0;
      padding: 0 8px 0 0;
      color: ${(props): string => props.theme.errorText};
      font-size: 16px;
      line-height: 1.5;
      text-align: right;
      letter-spacing: 0.05em;
    }
  }

  &.estimated-shipment-date {
    .input-wrapper {
      margin-bottom: 27px;

      > .inner {
        display: flex;
        align-items: center;

        .react-datepicker-wrapper {
          width: 400px;

          .date-picker {
            width: 100%;
            height: 56px;
            padding: 0 24px;
            color: ${(props): string => props.theme.black};
            font-size: 18px;
            font-weight: 500;
            line-height: 100%;
            border: 2px solid ${(props): string => props.theme.gray};
            border-radius: 0.8rem;
            background: ${(props): string => props.theme.white};
            transition: 0.3s all;
            box-sizing: border-box;

            &:focus {
              outline: 0;
              border: 2px solid ${(props): string => props.theme.black};
            }

            &.error {
              border: 2px solid ${(props): string => props.theme.errorText};
            }

            &::placeholder {
              color: ${(props): string => props.theme.gray};
            }
          }
        }

        .calendar-button {
          width: 55px;
          height: 55px;
          margin-left: 16px;
          border-radius: 8px;
        }
      }
    }

    .info {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.05em;
    }
  }

  &.other {
    .info {
      margin-bottom: 3.2rem;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.05em;
    }
  }

  ${mediaSp`
    width: 100%;

    .sub-heading {
      margin-bottom: 3.2rem;
    }

    &.csv {
      .csv-info {
        font-size: 1.4rem;
      }

      .template-download-button {
        width: 31.2rem;
        padding-left: 2.3rem;
        font-size: 1.3rem;

        .icon {
          width: 1.5rem;
          height: 1.5rem;
          left: 2.9rem;
        }
      }

      .row {
        margin: 0 0 5.6rem;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .howto-modal-button {
          font-size: 1.2rem;
        }

        .sample-download-button {
          width: 31.2rem;
          height: 5.2rem;
          padding-left: 9.2rem;
          font-size: 1.3rem;

          .icon {
            left: 7.65rem;
          }
        }
      }
    }

    &.user {
      .address-table {
        margin-top: -2.4rem;
      }

      .edit-user-button {
        padding-top: 2.8rem;
        margin: 0 0 0 auto;
        font-size: 1.2rem;
      }
    }

    &.return-address {
      .check-same {
        label {
          font-size: 1.2rem;
        }
      }

      .note {
        margin: 0 0 0.9rem 0;
        padding: 0;
        font-size: 1.2rem;
      }
    }

    &.estimated-shipment-date {
      .input-wrapper {
        margin-bottom: 1.6rem;

        >.inner {
          .react-datepicker-wrapper {
            width: 27.6rem;

            .date-picker {
              height: 5rem;
              padding: 0 1.6rem;
              font-size: 1.4rem;
            }
          }

          .calendar-button {
            width: 4.5rem;
            height: 4.5rem;
            margin-left: 1.3rem;
            border-radius: 0.6rem;
          }
        }
      }

      .info {
        font-size: 1.2rem;
      }
    }

    &.other {
      .info {
        font-size: 1.2rem;
      }
    }
  `}
`

export const OrderTpl: React.FC<Props> = ({
  csvProps,
  userProps,
  returnAddressProps,
  paymentMethodProps,
  estimatedShipmentDateProps,
  otherTextProps,
  errors,
  goToConfirm,
}) => {
  const { orderDetail, onClickOpenCsvHowtoModal, onChangeCsv, onClickResetCsv } = csvProps
  const { user, userInput, onChangeUser } = userProps
  const {
    checkedReturnAddressId,
    returnAddressOptions,
    returnAddressInputForNew,
    returnAddressInputForEdit,
    returnAddressInputForAdd,
    isCheckedSame,
    onChangeCheckSame,
    onChangeReturnAddressForNew,
    onClickEditReturnAddress,
    onChangeReturnAddressInputForEdit,
    onClickCompleteEditReturnAddress,
    onChangeReturnAddressInputForAdd,
    onClickCancelAddReturnAddress,
    onClickCompleteAddReturnAddress,
    onClickDeleteReturnAddress,
  } = returnAddressProps
  const { checkedPaymentMethod, onChangePaymentMethod } = paymentMethodProps
  const { estimatedShipmentDate, isCalendarOpen, onChangeEstimatedShipmentDate, setIsCalendarOpen } = estimatedShipmentDateProps
  const { otherText, onChangeOtherText } = otherTextProps

  const paymentMethodRadioButtons = [
    {
      id: 'stripe',
      name: 'paymentMethod',
      value: PaymentMethod.Stripe,
      label: 'クレジットカード',
      onChange: () => {
        onChangePaymentMethod(PaymentMethod.Stripe)
      },
    },
    {
      id: 'bank',
      name: 'paymentMethod',
      value: PaymentMethod.Bank,
      label: '銀行振込',
      onChange: () => {
        onChangePaymentMethod(PaymentMethod.Bank)
      },
    },
  ]

  const [isClickedEditUser, setIsClickedEditUser] = useState<boolean>(false)
  const userInfoRef = useRef<HTMLDivElement>(null)
  const returnAddressRef = useRef<HTMLDivElement>(null)

  // 注文者情報の変更ボタンクリック
  const onClickEditUser = (): void => {
    // フォームの上部まで移動
    if (userInfoRef?.current) {
      const { top } = userInfoRef.current.getBoundingClientRect()
      let headerHeight = 110
      if (window.matchMedia('(max-width: 768px)').matches) {
        const spHeaderVw = 0.19
        headerHeight = spHeaderVw * window.innerWidth
      }
      const scrollPosition = window.pageYOffset + top - headerHeight
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
    }

    setIsClickedEditUser(true)
  }

  const scrollReturnAddressContentsTop = (): void => {
    if (returnAddressRef?.current) {
      const { top } = returnAddressRef.current.getBoundingClientRect()
      let headerHeight = 110
      if (window.matchMedia('(max-width: 768px)').matches) {
        const spHeaderVw = 0.19
        headerHeight = spHeaderVw * window.innerWidth
      }
      const scrollPosition = window.pageYOffset + top - headerHeight
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
    }
  }

  const isDisabled = (): boolean => {
    const { name, name_kana, telephone_number, post_code, prefecture, address } = userInput
    const {
      name: returnName,
      name_kana: returnNameKana,
      telephone_number: returnTelephoneNumber,
      post_code: returnPostCode,
      prefecture: returnPrefecture,
      address: returnAddress,
    } = returnAddressInputForNew
    const isRequiredUserInputFilled = name && name_kana && telephone_number && post_code && prefecture && address
    const isRequiredReturnAddressInputForNewFilled =
      returnName && returnNameKana && returnTelephoneNumber && returnPostCode && returnPrefecture && returnAddress

    if (!orderDetail) return true
    if ((!user?.business?.name || isClickedEditUser) && !isRequiredUserInputFilled) return true
    if (!returnAddressOptions.length && !isRequiredReturnAddressInputForNewFilled) return true
    if (!estimatedShipmentDate) return true

    const userErrorArray = Object.values(errors.user).filter((error) => error !== null)
    const returnAddressForNewErrorArray = Object.values(errors.returnAddressForNew).filter((error) => error !== null)
    if (!!errors.csv || userErrorArray.length > 0 || returnAddressForNewErrorArray.length > 0 || !!errors.estimatedShipmentDate) return true

    return false
  }

  return (
    <Wrapper>
      <PageTitle title="注文フォーム" className="page-title" />

      <OrderFormSteps currentStep={1} className="steps" />

      <StyledItem className="csv">
        <SubHeading text="注文情報CSVアップロード" className="sub-heading" />
        <p className="csv-info">
          注文情報CSVファイルをダウンロードしてご入力後、
          <br className="pc" />
          以下に注文情報CSVファイルをアップロードをして下さい。
        </p>
        <a href={ORDER_TEMPLATE_CSV_DOWNLOAD_PATH} download={ORDER_TEMPLATE_CSV_DOWNLOAD_FILE_NAME}>
          <Button className="template-download-button" buttonType="yellowBorder">
            <DownloadIconSvg className="icon" />
            注文CSVテンプレートをダウンロード
          </Button>
        </a>
        <div className="row">
          <TextButton onClick={onClickOpenCsvHowtoModal} className="howto-modal-button">
            注文情報CSVの入力方法はこちら
          </TextButton>
          <a href={ORDER_SAMPLE_CSV_DOWNLOAD_PATH} download={ORDER_SAMPLE_CSV_DOWNLOAD_FILE_NAME}>
            <Button className="sample-download-button" buttonType="blackBorder">
              <DownloadIconSvg className="icon" />
              サンプルをダウンロード
            </Button>
          </a>
        </div>
        {orderDetail ? (
          <OrderDetailTable
            orderDetail={orderDetail}
            isOrderFinished={false}
            showReset
            companyName={user?.business?.company_name || ''}
            name={!user?.business?.company_name && !!user?.business?.name ? user.business?.name : ''}
            onClickReset={onClickResetCsv}
          />
        ) : (
          <CsvFileInput errorMessage={errors.csv} onChange={onChangeCsv} />
        )}
      </StyledItem>

      <StyledItem className="user" ref={userInfoRef}>
        <SubHeading text="注文者情報(請求先)" className="sub-heading" />
        {
          // nameがあればその他必要情報も揃ってるはずなのでnameで分岐
          user?.business?.name && !isClickedEditUser ? (
            <>
              <AddressTable address={user.business} className="address-table" />
              <TextButton onClick={onClickEditUser} className="edit-user-button">
                注文者情報を変更する
              </TextButton>
            </>
          ) : (
            <AddressForm addressInput={userInput} errors={errors.user} onChangeState={onChangeUser} />
          )
        }
      </StyledItem>

      <StyledItem className="return-address" ref={returnAddressRef}>
        <SubHeading text="返送先住所" className="sub-heading" />
        {!!returnAddressOptions.length ? (
          <>
            <p className="note">返送先住所は5件までです</p>
            <RadioButtonReturnAddressWithButton
              checkedValue={checkedReturnAddressId}
              options={returnAddressOptions}
              addressInputForEdit={returnAddressInputForEdit}
              addressInputForAdd={returnAddressInputForAdd}
              onClickEditAddress={onClickEditReturnAddress}
              onChangeAddressInputForEdit={onChangeReturnAddressInputForEdit}
              onClickCompleteEdit={onClickCompleteEditReturnAddress}
              onChangeAddressInputForAdd={onChangeReturnAddressInputForAdd}
              onClickCancelAdd={onClickCancelAddReturnAddress}
              onClickCompleteAdd={onClickCompleteAddReturnAddress}
              onClickDelete={onClickDeleteReturnAddress}
              scrollContentsTop={scrollReturnAddressContentsTop}
            />
          </>
        ) : (
          <>
            <Checkbox
              checkedValue={isCheckedSame}
              options={[
                {
                  id: 'true',
                  value: 'true',
                  label: '返送先がご注文者と同じ場合はチェック',
                },
              ]}
              onChange={onChangeCheckSame}
              className="check-same"
            />
            <AddressForm
              addressInput={returnAddressInputForNew}
              errors={errors.returnAddressForNew}
              onChangeState={onChangeReturnAddressForNew}
            />
          </>
        )}
      </StyledItem>

      <StyledItem className="payment-method">
        <SubHeading text="お支払方法" className="sub-heading" />
        <RadioButton checkedValue={checkedPaymentMethod} buttons={paymentMethodRadioButtons} />
      </StyledItem>

      <StyledItem className="estimated-shipment-date">
        <SubHeading text="撮影する商品のご発送予定日" className="sub-heading" />
        <div className="input-wrapper">
          <div className="inner">
            <DatePicker
              selected={estimatedShipmentDate ? new Date(estimatedShipmentDate) : null}
              onChange={onChangeEstimatedShipmentDate}
              onClickOutside={() => {
                setIsCalendarOpen(false)
              }}
              open={isCalendarOpen}
              minDate={new Date()}
              onFocus={() => {
                setIsCalendarOpen(true)
              }}
              dateFormat="yyyy/MM/dd"
              placeholderText={dayjs().format('YYYY/MM/DD')}
              className={!!errors.estimatedShipmentDate ? 'error date-picker' : 'date-picker'}
            />
            <Button
              buttonType="yellow"
              onClick={() => {
                setIsCalendarOpen(true)
              }}
              className="calendar-button"
            >
              <CalendarIconSvg className="icon" />
            </Button>
          </div>
          {!!errors.estimatedShipmentDate && <ErrorMessage message={errors.estimatedShipmentDate} />}
        </div>
        <p className="info">
          誠に恐れ入りますが、撮影商品の発送・返却時の送料はお客様にてご負担をお願いいたします。
          <br />
          ご希望の運送会社がございましたら、商品発送時に送り状の同梱をお願いいたします。
        </p>
      </StyledItem>

      <StyledItem className="other">
        <SubHeading text="その他" className="sub-heading" />
        <p className="info">※ 商品の撮影イメージのご希望がございましたら、CSVの備考欄にご記入ください</p>
        <Textarea value={otherText} onChange={onChangeOtherText} />
      </StyledItem>

      <Button buttonType="yellow" disabled={isDisabled()} onClick={goToConfirm} className="confirm-button">
        確認画面へ
      </Button>
    </Wrapper>
  )
}
