import React from 'react'
import styled from 'styled-components'

/* components */
import { TableRow } from 'components/molecules'

/* lib, types*/
import { Business, ReturnAddress, ReturnItem, Invoice } from 'types/graphql'

import { AddressInput } from 'types/myTypes'

type Props = {
  className?: string
  address: Business | ReturnAddress | AddressInput | ReturnItem | Invoice
  email?: string
}

const TableWrapper = styled.table`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 0.05em;
`

export const AddressTable: React.FC<Props> = ({ className = '', address, email }) => {
  return (
    <TableWrapper className={className}>
      <tbody>
        <TableRow label="氏名">
          {address.name}({address.name_kana})
        </TableRow>
        {email && <TableRow label="メールアドレス">{email}</TableRow>}
        <TableRow label="電話番号">{address.telephone_number}</TableRow>
        <TableRow label="住所">
          &#12306;{address.post_code}
          <br />
          {address.prefecture}
          {address.address}
          <br />
          {address.building_name}
        </TableRow>
        {(address.company_name || address.company_name_kana) && (
          <TableRow label="会社名">
            {address.company_name}
            {address.company_name_kana && `(${address.company_name_kana})`}
          </TableRow>
        )}
        {address.department && <TableRow label="部署名">{address.department}</TableRow>}
        {address.store_name && <TableRow label="店舗名">{address.store_name}</TableRow>}
      </tbody>
    </TableWrapper>
  )
}
