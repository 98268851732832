import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { OrderTpl, OrderCsvHowToModal } from 'components/templates'

/* lib, types, const, context */
import {
  useOrderCsv,
  useOrderUser,
  useOrderReturnAddress,
  useOrderPaymentMethod,
  useOrderEstimatedShipmentDate,
  useOrderOtherText,
} from 'lib/order'
import { CreateOrderContext } from 'context'
import { AddressInput, OrderInputErrors, CreateOrder as CreateOrderType, FlashMessageType } from 'types/myTypes'

export const Order: React.FC = () => {
  const addressInitialInput: AddressInput = {
    name: '',
    name_kana: '',
    company_name: '',
    company_name_kana: '',
    department: '',
    store_name: '',
    telephone_number: '',
    post_code: '',
    prefecture: '',
    address: '',
    building_name: '',
  }

  const orderInputInitialErrors: OrderInputErrors = {
    csv: null,
    user: {
      name: null,
      name_kana: null,
      company_name: null,
      company_name_kana: null,
      telephone_number: null,
      post_code: null,
      prefecture: null,
      address: null,
    },
    returnAddressForNew: {
      name: null,
      name_kana: null,
      company_name: null,
      company_name_kana: null,
      telephone_number: null,
      post_code: null,
      prefecture: null,
      address: null,
    },
    estimatedShipmentDate: null,
  }

  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const [errors, setErrors] = useState<OrderInputErrors>(orderInputInitialErrors)

  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location as { state: { redirectFrom: string } }
  const { createOrder, updateCreateOrder } = useContext(CreateOrderContext)

  /* csv関連処理 */
  const { ...csvProps } = useOrderCsv({ errors, setErrors, setFlashMessage })

  /* 注文者情報関連処理 */
  const { ...userProps } = useOrderUser({ addressInitialInput, errors, setErrors, setFlashMessage })

  /* 返信先住所関連処理 */
  const { ...returnAddressProps } = useOrderReturnAddress({
    userInput: userProps.userInput,
    addressInitialInput,
    errors,
    setFlashMessage,
    setErrors,
  })

  /* お支払い方法関連処理 */
  const { ...paymentMethodProps } = useOrderPaymentMethod()

  /* 発送予定日関連処理 */
  const { ...estimatedShipmentDateProps } = useOrderEstimatedShipmentDate({ errors, setErrors })

  /* その他関連処理 */
  const { ...otherTextProps } = useOrderOtherText()

  /* 確認画面へ処理 */
  const goToConfirm = (): void => {
    const { orderDetail, orderItems, csvFile } = csvProps
    const { userInput } = userProps
    const { returnAddresses, checkedReturnAddressId, returnAddressInputForNew } = returnAddressProps
    const { checkedPaymentMethod } = paymentMethodProps
    const { estimatedShipmentDate } = estimatedShipmentDateProps
    const { otherText } = otherTextProps

    const returnAddress = checkedReturnAddressId
      ? returnAddresses.filter((address) => address.id === checkedReturnAddressId)[0]
      : returnAddressInputForNew
    if (!orderDetail || !orderItems || !csvFile) return
    const createOrder: CreateOrderType = {
      csvFile,
      orderDetail,
      // useEffectでuserをuserInputにsetしているので、情報変更してもしなくてもこれでOK
      user: userInput,
      returnAddress,
      paymentMethod: checkedPaymentMethod,
      estimatedShipmentDate,
      otherText,
      orderItems,
    }

    // ブラウザバック用にcontextにデータセット
    updateCreateOrder(createOrder)
    // 確認ページ用にはリロード対策のためstateでデータ渡す
    navigate('/order/confirm', {
      state: {
        createOrder,
      },
    })
  }

  /* ブラウザリロードした時にアラート出す */
  const onBeforeUnload = (e: BeforeUnloadEvent): void => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  useEffect(() => {
    const { setCsvFile, setOrderDetail, setOrderItems } = csvProps
    const { currentUserLazyQuery, setUser, setUserInput } = userProps
    const { userReturnAddressesLazyQuery, setCheckedReturnAddressId, setReturnAddressInputForNew } = returnAddressProps
    const { setCheckedPaymentMethod } = paymentMethodProps
    const { setEstimatedShipmentDate } = estimatedShipmentDateProps
    const { setOtherText } = otherTextProps

    userReturnAddressesLazyQuery()
    if (createOrder) {
      // useContextに値があればセットする
      const { csvFile, orderDetail, orderItems, user, returnAddress, paymentMethod, estimatedShipmentDate, otherText } = createOrder
      setCsvFile(csvFile)
      setOrderDetail(orderDetail)
      setOrderItems(orderItems)
      setUser({ business: user })
      setUserInput(user)
      if (!!returnAddress.id) {
        setCheckedReturnAddressId(returnAddress.id as string)
      } else {
        // 新規注文で返送先住所未登録の場合
        setReturnAddressInputForNew(returnAddress as AddressInput)
      }
      setCheckedPaymentMethod(paymentMethod)
      setEstimatedShipmentDate(estimatedShipmentDate)
      setOtherText(otherText)

      // useContextに値があればuserの情報はそっちを優先してapiは叩かない
      return
    }
    currentUserLazyQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrder, userProps.currentUserLazyQuery])

  useEffect(() => {
    if (!state) return
    if (!!state.redirectFrom) {
      // 注文時stripe決済キャンセルからのリダイレクト
      if (state.redirectFrom === '/order/failed') {
        setFlashMessage({
          type: 'error',
          message: 'クレジットカード決済ができませんでした。注文をやり直してください',
        })
      }
    }
  }, [state])

  // state.* = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (state && !!state.redirectFrom) {
      navigate('/order', {
        replace: true,
        state: {
          redirectFrom: '',
        },
      })
    }
  }, [navigate, state])

  const isLoading =
    csvProps.userQuotationLazyQueryLoading ||
    csvProps.userGenerateUploadOrderCsvLinkLazyQueryLoading ||
    userProps.currentUserQueryLoading ||
    returnAddressProps.userReturnAddressesLazyQueryLoading ||
    returnAddressProps.userUpsertReturnAddressMutationLoding ||
    returnAddressProps.userDeleteReturnAddressMutationLoading

  return (
    <>
      <Meta title="注文フォーム" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {userProps.user && (
        <OrderTpl
          csvProps={csvProps}
          userProps={userProps}
          returnAddressProps={returnAddressProps}
          paymentMethodProps={paymentMethodProps}
          estimatedShipmentDateProps={estimatedShipmentDateProps}
          otherTextProps={otherTextProps}
          errors={errors}
          /* 確認画面へボタン */
          goToConfirm={goToConfirm}
        />
      )}
      {csvProps.isCsvHowToModalOpen && <OrderCsvHowToModal closeModal={csvProps.onClickCloseCsvHowtoModal} />}
    </>
  )
}
