import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
}

const StyledLoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background: rgba(${(props): string => props.theme.whiteRgb}, 0.7);
  z-index: ${(props): number => props.theme.zIndex.loading};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLoading = styled.div`
  position: relative;
  left: -100vw;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 1.3rem;
  background-color: ${(props): string => props.theme.yellow};
  color: ${(props): string => props.theme.yellow};
  box-shadow: 100vw 0 0 -0.5rem ${(props): string => props.theme.yellow};
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 1.3rem;
    background-color: ${(props): string => props.theme.yellow};
    color: ${(props): string => props.theme.yellow};
  }
  &:before {
    box-shadow: calc(100vw - 3rem) 0 0 -1.3rem ${(props): string => props.theme.yellow};
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
  &:after {
    box-shadow: calc(100vw + 3rem) 0 0 -1.3rem ${(props): string => props.theme.yellow};
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: calc(100vw - 3rem) 0 0 -1.3rem ${(props): string => props.theme.yellow};
    }
    30% {
      box-shadow: calc(100vw - 3rem) 0 0 0.2rem ${(props): string => props.theme.yellow};
    }
    60%,
    100% {
      box-shadow: calc(100vw - 3rem) 0 0 -1.3rem ${(props): string => props.theme.yellow};
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 100vw 0 0 -1.3rem ${(props): string => props.theme.yellow};
    }
    30% {
      box-shadow: 100vw 0 0 0.2rem ${(props): string => props.theme.yellow};
    }
    60%,
    100% {
      box-shadow: 100vw 0 0 -1.3rem ${(props): string => props.theme.yellow};
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: calc(100vw + 3rem) 0 0 -1.3rem ${(props): string => props.theme.yellow};
    }
    30% {
      box-shadow: calc(100vw + 3rem) 0 0 0.2rem ${(props): string => props.theme.yellow};
    }
    60%,
    100% {
      box-shadow: calc(100vw + 3rem) 0 0 -1.3rem ${(props): string => props.theme.yellow};
    }
  }

  ${mediaSp`
    font-size: 1.8rem;
  `}
`

export const Loading: React.FC<Props> = ({ className = '' }) => {
  return (
    <StyledLoadingWrapper className={className}>
      <StyledLoading />
    </StyledLoadingWrapper>
  )
}
