import React from 'react'

/* components */
import { MainVisual, TopEstimateSimulation, TopPlan, TopOrderFlow, TopContact, TopShootingSample } from 'components/organisms'

/* lib, types*/
import { OrderPlan, Invoice } from 'types/graphql'

type Props = {
  orderDetail?: Invoice
  csvInputError: string | null
  onClickOpenCsvHowtoModal: () => void
  onChangeCsv: (file: File) => void
  onClickResetCsv: () => void
  onClickOpenPlanModal: (type: OrderPlan) => void
}

export const TopTpl: React.FC<Props> = ({
  orderDetail,
  csvInputError,
  onClickOpenCsvHowtoModal,
  onChangeCsv,
  onClickResetCsv,
  onClickOpenPlanModal,
}) => {
  return (
    <>
      <div id="top">
        <MainVisual />
      </div>
      <div id="plan" className="contents">
        <TopPlan onClickOpenModal={onClickOpenPlanModal} />
      </div>
      <div id="order-flow" className="contents">
        <TopOrderFlow onClickOpenCsvHowtoModal={onClickOpenCsvHowtoModal} />
      </div>
      <div id="sample" className="contents">
        <TopShootingSample />
      </div>
      <div id="estimate" className="contents">
        <TopEstimateSimulation
          orderDetail={orderDetail}
          csvInputError={csvInputError}
          onClickOpenCsvHowtoModal={onClickOpenCsvHowtoModal}
          onChangeCsv={onChangeCsv}
          onClickResetCsv={onClickResetCsv}
        />
      </div>
      <div id="contact" className="contents">
        <TopContact />
      </div>
    </>
  )
}
