import React from 'react'
import styled from 'styled-components'

/* components */
import { PageTitle } from 'components/atoms'
import { ButtonLink } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
}

const Wrapper = styled.div`
  .inner {
    width: 100%;
    margin: 0 auto;
    padding: 8rem 2rem 0;

    .page-title {
      margin-bottom: 6.4rem;
      text-align: center;
    }
    p {
      margin: 0 auto 4rem;
      font-size: 1.8rem;
      text-align: center;
      line-height: 1.7;
      .sp {
        display: none;
      }
    }
    .to-home {
      width: 31.2rem;
      margin: 0 auto;
      display: block;
    }
  }
  ${mediaSp`
    .inner {
      padding: 7.2rem 3.1rem 12.2rem;

      .page-title {
        margin-bottom: 3.2rem;
      }
      p {
        margin: 0 auto 7.4rem;
        font-size: 1.4rem;
        .sp {
          display: block;
        }
      }
    }
  `}
`

export const ResetPasswordCompleteEmailTpl: React.FC<Props> = ({ className = '' }) => {
  return (
    <Wrapper className={className}>
      <div className="inner">
        <PageTitle title="再設定メール送信完了" className="page-title" />
        <p>
          メールに記載の再設定URLから
          <br className="sp" />
          パスワードの再設定を行ってください。
        </p>
        <ButtonLink buttonType="black" href="/" className="to-home">
          HOMEへ
        </ButtonLink>
      </div>
    </Wrapper>
  )
}
