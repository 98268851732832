import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'

/* components */
import { Meta } from 'components/meta'
import { DocumentLayout, OrderInvoiceTpl } from 'components/templates'

/* lib, types */
import { useValidateToken } from 'lib/validate-token'
import { mediaSp } from 'lib/media-query'

const NoDataText = styled.p`
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  ${mediaSp`
    width: 80%;
    font-size: 1.4rem;
  `}
`

export const OrderInvoice: React.FC = () => {
  const [cookie, , removeCookie] = useCookies(['nameOnDocument', 'honorificTitle', 'orderDetail'])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeInvoiceCookies = (): void => {
    removeCookie('nameOnDocument', { path: '/' })
    removeCookie('honorificTitle', { path: '/' })
    removeCookie('orderDetail', { path: '/' })
  }

  // ヘッダーなしのページなので個別に読み込み
  useValidateToken()

  useEffect(() => {
    window.addEventListener('unload', removeInvoiceCookies)
    return () => window.removeEventListener('unload', removeInvoiceCookies)
  }, [removeInvoiceCookies])

  return (
    <>
      <Meta title="御請求書" />
      {!!cookie.nameOnDocument && !!cookie.honorificTitle && !!cookie.orderDetail ? (
        <DocumentLayout title="御請求書">
          <OrderInvoiceTpl nameOnDocument={cookie.nameOnDocument} honorificTitle={cookie.honorificTitle} order={cookie.orderDetail} />
        </DocumentLayout>
      ) : (
        <NoDataText>一度タブを閉じて注文詳細から再度請求書を発行してください。</NoDataText>
      )}
    </>
  )
}
