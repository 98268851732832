import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { TermsTitle, TermsBlock } from '../molecules'
import { APP_NAME } from '../../const'
import { mediaPc } from '../../lib/media-query'

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.white};
  padding: 7.7vw 7.2vw;

  ${mediaPc`
  width: 100%;
    padding: 72px 20px 190px;
  `}
`

const Inner = styled.div`
  .terms-title {
    margin-bottom: 5.6vw;
  }

  ${mediaPc`
    max-width: 1000px;
    margin: 0 auto;

    .terms-title {
      margin-bottom: 72px;
    }
  `}
`

export const TermsOfUseTpl: React.FC = () => {
  return (
      <Wrapper>
        <Inner>
          <TermsTitle title="ご利用規約" className="terms-title" />
          <TermsBlock withoutSubtitle>
            本サービスのご利用を希望される方もしくは本サービスを既に利用している方（以下「{APP_NAME}
            会員」と表示します）は、下記サービス利用規約を必ずお読みください。
          </TermsBlock>
          <TermsBlock subtitle="第1条(サービスの内容及び変更)">
            1. 本サービスは、商品の撮影サービスであり、その詳細については、本規約に定めるほか、別途本サービスのウェブサイト等で定めるものとします。
            <br />
            2. 当社は、本サービスの内容を、利用者の事前の承諾なく変更することがあります。当社は、変更後のサービス内容について、本サービスのウェブサイトへ掲載その他当社が適当と認める方法により告知し、利用者が本サービス変更後に本サービスを利用した場合は、利用者は当該変更後の本サービスについて同意したものとみなされます。
          </TermsBlock>
          <TermsBlock subtitle="第2条(定義)">
            本規約で使用する語句の定義は以下の通りとします。
            <br />
            1. 「利用者」とは、本サービスを利用する（特に断りがない限り、本サービスの利用を申し込むものも含む。）法人又は個人事業主をいいます。
            <br />
            2. 「商品」とは、本サービスにおける撮影の対象として、本規約の定めるところにより、利用者が指定する物品をいいます。
          </TermsBlock>
          <TermsBlock subtitle="第3条(利用契約の成立)">
            1. 本サービスの利用を希望する者は、本規約、本サービスに関して当社が指定する規則、ガイドライン等（併せて「本規約等」という。）のすべてを自己の責任においてよく読んで確認し、その内容につき承諾した上で、当社が別途定める必要事項を当社の定める方法により当社に送信して、本サービスの利用申込みを行なうものとします。
            <br />
            2. 当社は、利用申込みを受けた場合において、申し込みを行った者（以下「申込者」という。）に対して、お見積りシートを交付することがあります。申込者は、当社からお見積りシートの交付を受けた場合は、速やかにお見積りシートに必要事項を記入し当社に対して返送するものとします。
            <br />
            3. 当社は、第１項の利用申込みについて審査を行います。審査の結果、申込みを承諾する場合は、申込時に申込者が届け出た連絡先電子メールアドレスに対し、その旨を通知します。利用者は、上記通知を受け取った後、速やかに当社の指定する銀行口座に第９条の利用料金を振込送金する方法でこれを支払うものとします。
            <br />
            4. 本サービスの利用を承諾しない場合でも、審査結果、審査内容及びその他審査に関する事項の開示、再審査の実施、損害等及び結果について責任を負いません。また、この場合の理由等について、開示する義務を負わないものとします。
            <br />
            5. 本サービスの利用に関する契約は、本規約等及びお見積りシートの記載内容に従い、当社が第３項の振込を確認した時点で成立するものとします。
          </TermsBlock>
          <TermsBlock subtitle="第4条(商品)">
            1. 商品は、三辺（縦・横・高さ）の合計が160センチ以下で、1商品あたりの重量が25キロ以下のものに限ります。上記以外の商品の取扱いについては、当社が別途定めるところに従うものとします。なお、下記のものは商品として指定することができません。
            <br />
            ①わいせつ、児童ポルノ、アダルトに属する物品
            <br />
            ②違法な薬物、銃剣類、その他違法な物品
            <br />
            ③発火剤、火薬、爆薬等の爆発物・発火物
            <br />
            ④有毒物、劇薬、強酸性物質、強アルカリ性物質、放射性物質等
            <br />
            ⑤病原体等
            <br />
            ⑥その他、当社が適切ではないと判断した物品
          </TermsBlock>
          <TermsBlock subtitle="第5条(商品の権利関係)">
            ①利用者は本サービス利用のために必要となる、商品その他の関連物に関する一切の権利処理（著作権、商標権、タレント・人物等の肖像権・パブリシティ権、プライバシー権、その他知的財産権の権利処理や所有権の取得等を含む。）を自己の責任と費用負担で、申込み前までに行なうものとします。
            <br />
            ②利用者の本サービス利用に関連して、当社に対し、前項に定める権利者、その他の第三者から異議の申立て、クレーム、その他の請求がなされた場合、利用者はこれらの紛争を自己の責任と費用負担で解決し、当社を免責、防御するものとします。
          </TermsBlock>
          <TermsBlock subtitle="第6条(商品の発送)">
            1. 申込者は、利用契約の成立後、当社の別途定める書類等を提出し、併せて、商品を別途当社が指定する場所に発送するものとします。商品の発送に係る運送料その他の費用に関しては、利用者が負担するものとします。
            <br />
            2. 当社は、前項で定める当社の指定先に到着した時点での現状有姿のまま取り扱うものとします。
            <br />
            3. 商品が到着するまでに商品に生じた破損、毀損、紛失その他の損害については、当社は一切責任を負わないものとします。
          </TermsBlock>
          <TermsBlock subtitle="第7条(写真の撮影、加工及び納品等)">
            1. 当社は、前条の商品について、撮影を行ない、以下に定める仕様で写真データ及び動画データを作成します。但し、ヒアリングシートにおいてこれと異なる仕様が指定されている場合は、当該指定に従うものとします。
            <br />
            ①写真データ
            <br />
            フォーマット　：　デジタルデータ(PNG形式)
            <br />
            サイズ　　　　：　最大（横）3000ピクセル × （縦）3000ピクセル
            <br />
            画像加工　　　：　背景切り抜き加工処理
            <br />
            ②動画データ
            <br />
            フォーマット　：　デジタルデータ（MP4形式）
            <br />
            2. 当社は、前項の写真データ及び動画データを、電子メールへの添付、又は当社が別途定める方法で利用者に納品するものとします。但し、利用者がCD-ROM等の記録媒体による方法等で納品を希望する場合は、協議のうえ当該納品方法を決定するものとし、この作成、送付等に要する費用は利用者が負担するものとします。
            <br />
            3. 利用者は納品された写真データ及び動画データを確認、検査し、その合否結果を納品日から3営業日以内に当社に通知するものとします。
            <br />
            4. 前項の検査結果が合格の場合は、その旨の通知が当社に到達したときをもって納品完了とし、不合格の場合は、不備箇所及び納品期間等につき協議の上、当社は、一回に限り、当社の別途定める方法で修補するものとします。但し、当社は商品の再撮影については受け付けないものとします。
            <br />
            5. 当社は、第３項に定める期間内に利用者から何ら通知がなされないときは、検査に合格し、納品が完了したものとみなします。
          </TermsBlock>
          <TermsBlock subtitle="第8条(著作権等)">
            本サービスの利用により、当社が利用者に納品した写真データ及びキャプションの所有権その他の権利（著作権法第21条乃至第27条に定められた権利を含む。）は、前条に定める納品完了時に利用者に移転するものとします。
          </TermsBlock>
          <TermsBlock subtitle="第9条(商品の返送)">
            1. 当社は、納品物の納入完了後、商品を利用者が指定する場所に発送するものとします。但し、商品の発送に係る運送料その他の費用に関しては、利用者が負担するものとします。
            <br />
            2. 利用者の指定先に到着するまでに商品に生じた、破損、毀損、紛失その他の損害については、当社は一切責任を負わないものとします。
          </TermsBlock>
          <TermsBlock subtitle="第10条(利用料金)">
            1. 本サービスの利用料金は、本サービスのウェブサイト等において定めます。
            <br />
            2. 当社は、理由の如何を問わず、一度受領した利用料金については、これを利用者に返金しないものとします。
          </TermsBlock>
          <TermsBlock subtitle="第11条(秘密保持)">
            当社及び利用者は、本サービスの利用に関して相手方から秘密である旨を明示した上で開示された情報を、当該情報を開示した当事者の事前の承諾を得た場合を除いて、第三者に対して開示又は提供してはならないものとし、かつ、本サービスの提供又は利用に必要となる場合を除いて、態様の如何を問わず利用してはならないものとします。
          </TermsBlock>
          <TermsBlock subtitle="第12条(サービスの停止、解除等)">
            1. 当社は、利用者が以下の各号の何れかに該当したときは、通知・催告その他の手続きを要することなく、直ちに、本サービスの提供停止、納品した写真等の使用の差止め、利用契約の解除を行うことができるものとします。
            <br />
            (1) 利用者が本規約等に違反し、又は違反するおそれがあるとき
            <br />
            (2) 支払停止又は支払不能となったとき
            <br />
            (3) 手形又は小切手が不渡りとなったとき
            <br />
            (4) 差押え、仮差押え若しくは競売の申立があったとき、又は、公租公課の滞納処分を受けたとき
            <br />
            (5) 破産手続開始、会社更生手続開始もしくは民事再生手続開始の申立があったとき
            <br />
            (6) 信用状態に重大な不安が生じたとき
            <br />
            (7) 監督官庁から営業許可の取消、停止等の処分を受けたとき
            <br />
            (8) 解散、減資、事業の全部又は重要な一部の譲渡等の決議をしたとき
            <br />
            (9) 暴力団、反政府組織、その他反社会的組織、及びその構成員若しくはそれら関係者となった場合、又はそれらの者と何らかの関係があることが明らかとなったとき
            <br />
            (10) 名称、住所、連絡先、身分等を偽っていることが明らかとなったとき
            <br />
            (11) 利用契約を履行することが困難となる事由が生じたとき
            <br />
            2. 当社は、前項に定める措置を講じたことに起因して、利用者に生じる損害について一切の責任を負わないものとします。
            <br />
            3. 本条第１項による利用契約の解除は、当社の利用者に対する損害賠償請求を何ら妨げないものとします。
          </TermsBlock>
          <TermsBlock subtitle="第13条(損害賠償)">
            1. 当社は、本サービスの利用に関して、当社の故意又は重大な過失による場合を除いて、利用者に発生した損害について一切責任を負わないものとします。
            <br />
            2. 前項にかかわらず、当社の故意又は重大な過失により利用者に損害が発生した場合は、利用者から既に受領した利用料金のうち、損害が発生した利用契約の利用料金を上限として、その損害を賠償するものとします。
            <br />
            3. 利用者が本サービスの利用に関して、当社に損害を与えたときはその損害を賠償するものとします。なお、その損害には、利用者と第三者との紛争に関し、当社が負担した費用（その対応のために要した合理的な弁護士費用を含む）を含むものとします。
          </TermsBlock>
          <TermsBlock subtitle="第14条(免責)">
            当社は以下の各号に定める事項のほか、本サービスの完全性、正確性、特定の目的への適合性、商業利用の可能性、その他一切の事項について保証しないものとします。
            <br />
            1. 納品物を含む、本サービスに関するデータの毀損、滅失
            <br />
            2. 本サービスの利用による売上の向上、販売数の増加
            <br />
            3. 不可抗力
          </TermsBlock>
          <TermsBlock subtitle="第15条(専属的管轄裁判所)">
            本サービスの利用に関するすべての紛争については、訴額に応じて東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </TermsBlock>
          <TermsBlock subtitle="第16条(規約の変更)">
            1. 当社は、利用者の事前の承諾なく、本規約を変更することができるものとします。
            <br />
            2. 当社は、本サービスのウェブサイトへの掲載、その他当社が適当と認める方法により、前項の変更を利用者に告知するものとします。利用者が告知後に本サービスを利用した場合は、当該変更に同意しているものとみなします。
          </TermsBlock>
        </Inner>
      </Wrapper>
  )
}
