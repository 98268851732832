import { Dispatch, SetStateAction, useState } from 'react'

/* lib, types, const */
import { PaymentMethod } from 'types/graphql'

type OrderPaymentMethodStates = {
  checkedPaymentMethod: PaymentMethod
  setCheckedPaymentMethod: Dispatch<SetStateAction<PaymentMethod>>
}

type OrderPaymentMethodValues = {
  onChangePaymentMethod: (value: PaymentMethod) => void
}

export type OrderPaymentMethodProps = OrderPaymentMethodStates & OrderPaymentMethodValues

export const useOrderPaymentMethod = (): OrderPaymentMethodProps => {
  const [checkedPaymentMethod, setCheckedPaymentMethod] = useState<PaymentMethod>(PaymentMethod.Stripe)

  const onChangePaymentMethod = (value: PaymentMethod): void => {
    setCheckedPaymentMethod(value)
  }

  return {
    /* state */
    checkedPaymentMethod,
    setCheckedPaymentMethod,
    /* value */
    onChangePaymentMethod,
  }
}
