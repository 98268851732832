import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  step: number
  stepTitle: string
  stepImage: string
  children?: React.ReactNode
}

type StyleProps = {
  imagePaddingTop: number
  imagePaddingLeft: number
}

const Wrapper = styled.div<StyleProps>`
  width: 100%;
  padding: 0 0 40px 0;
  border: 0.3rem dashed ${(props): string => props.theme.gray};
  display: flex;

  .step {
    margin-bottom: 1.6rem;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;

    > span {
      margin-right: 10px;
      color: ${(props): string => props.theme.yellow};
      font-size: 20px;
      font-weight: 800;
      line-height: 36px;
    }
  }

  .image-wrapper {
    width: 233px;
    padding: ${(props): string => `${props.imagePaddingTop}px 0 0 ${props.imagePaddingLeft}px`};
  }

  .text-wrapper {
    padding: 40px 0 0 0;
  }

  ${mediaSp`
    padding: 2.4rem;
    display: block;

    .step {
      margin-bottom: 1.6rem;
      font-size: 1.8rem;
      text-align: center;

      >span {
        margin-right: 0.7rem;
        font-size: 1.4rem;
        line-height: 2.7rem;
      }
    }

    .image-wrapper {
      width: 14rem;
      margin: 0 14rem 1.4rem 5.5rem;
      padding: 0;

      &.step4 {
        width: 9rem;
        margin: 0 auto 1.4rem;
      }

      &.step5 {
        margin: 0 11rem 1.4rem 8.5rem;
      }

      >img {
        width: 100%;
      }
    }

    .text-wrapper {
      padding: 0;
    }
  `}
`

export const TopOrderFlowItem: React.FC<Props & StyleProps> = ({
  className,
  step,
  stepTitle,
  stepImage,
  children,
  imagePaddingTop,
  imagePaddingLeft,
}) => {
  return (
    <Wrapper imagePaddingTop={imagePaddingTop} imagePaddingLeft={imagePaddingLeft} className={className}>
      <p className="step sp">
        <span>STEP 0{step}</span>
        {stepTitle}
      </p>
      <div className={`image-wrapper step${step}`}>
        <img src={stepImage} alt={`step${step}`} />
      </div>
      <div className="text-wrapper">
        <p className="step pc">
          <span>STEP 0{step}</span>
          {stepTitle}
        </p>
        {children}
      </div>
    </Wrapper>
  )
}
