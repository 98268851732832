import React from 'react'
import styled from 'styled-components'

/* images */
import stamp from 'assets/images/stamp.png'

type Props = {
  className?: string
  showEmail?: boolean
}

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  .info {
    margin: 0 20px 0 0;
  }
  img {
    width: 110px;
  }
`

export const CorporateInfo: React.FC<Props> = ({ className = '', showEmail = true }) => {
  return (
    <Wrapper className={className}>
      <div className="info">
        <p>株式会社Rolo</p>
        <p>
          〒151-0066
          <br />
          東京都渋谷区西原3-19-10
          <br />
          アパートメント上原B1F
          <br />
        </p>
        <p>
          TEL: 03-6277-5707
          <br />
          FAX: 03-6745-8474
          <br />
        </p>
        {showEmail && <p>info@rolo.jp</p>}
      </div>
      <img src={stamp} alt="株式会社Rolo" />
    </Wrapper>
  )
}
