import React from 'react'
import styled from 'styled-components'

/* components */
import { Button, TextButton } from 'components/atoms'
import { CsvFileInput } from 'components/molecules'
import { OrderDetailTable } from 'components/organisms'

/* lib, types, const*/
import { mediaSp } from 'lib/media-query'
import { Invoice } from 'types/graphql'
import {
  ORDER_TEMPLATE_CSV_DOWNLOAD_PATH,
  ORDER_TEMPLATE_CSV_DOWNLOAD_FILE_NAME,
  ORDER_SAMPLE_CSV_DOWNLOAD_PATH,
  ORDER_SAMPLE_CSV_DOWNLOAD_FILE_NAME,
} from 'const'

/* images */
import { ReactComponent as DownloadIconSvg } from 'assets/images/icon/download.svg'

type Props = {
  orderDetail?: Invoice
  csvInputError: string | null
  onClickOpenCsvHowtoModal: () => void
  onChangeCsv: (file: File) => void
  onClickResetCsv: () => void
}

const Wrapper = styled.div`
  background: ${(props): string => props.theme.lightGray};

  > .inner {
    padding: 80px 0 120px;
    background: ${(props): string => props.theme.white};
    border-radius: 100px 0 0 0;

    .content {
      width: 98%;
      max-width: 840px;
      margin: 0 auto;

      .section-title {
        margin-bottom: 30px;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
      }

      .csv-info {
        margin-bottom: 4rem;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.05em;
      }

      .template-download-button {
        width: 506px;
        margin: 0 auto 0.8rem;
        padding-left: 33px;
        position: relative;
        display: block;

        .icon {
          width: 19px;
          height: 19px;
          position: absolute;
          top: 50%;
          left: 60px;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          fill: ${(props): string => props.theme.black};
          transition: 0.3s all;
        }
      }

      > .row {
        margin: 0 0 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .howto-modal-button {
          font-size: 14px;
        }

        .sample-download-button {
          width: 248px;
          height: 39px;
          padding-left: 56px;
          font-size: 14px;
          text-align: left;
          position: relative;
          display: block;

          .icon {
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            top: 50%;
            left: 39.5px;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            fill: ${(props): string => props.theme.black};
            transition: 0.3s all;
          }

          &:hover {
            .icon {
              fill: ${(props): string => props.theme.white};
            }
          }
        }
      }
    }
  }

  ${mediaSp`
    > .inner {
      padding: 5.5rem 0 8rem;
      border-radius: 3rem 0 0 0;

      .content {
        width: 33.5rem;
        max-width: 33.5rem;

        .section-title {
          margin-bottom: 3.2rem;
          font-size: 2.4rem;
        }

        .csv-info {
          font-size: 1.4rem;
        }

        .template-download-button {
          width: 31.2rem;
          padding-left: 2.3rem;
          font-size: 1.3rem;

          .icon {
            width: 1.5rem;
            height: 1.5rem;
            left: 2.9rem;
          }
        }

        >.row {
          margin: 0 0 5.6rem;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          gap: 16px;

          .howto-modal-button {
            font-size: 1.2rem;
          }

          .sample-download-button {
            width: 31.2rem;
            height: 5.2rem;
            padding-left: 9.2rem;
            font-size: 1.3rem;

            .icon {
              left: 7.65rem;
            }
          }
        }
      }
    }
  `}
`
export const TopEstimateSimulation: React.FC<Props> = ({
  orderDetail,
  csvInputError,
  onClickOpenCsvHowtoModal,
  onChangeCsv,
  onClickResetCsv,
}) => {
  return (
    <Wrapper>
      <div className="inner">
        <div className="content">
          <h2 className="section-title">見積りシミュレーション</h2>
          <p className="csv-info">
            注文情報CSVファイルをダウンロードしてご入力後、
            <br className="pc" />
            以下に注文情報CSVファイルをアップロードをして下さい。
          </p>
          <a href={ORDER_TEMPLATE_CSV_DOWNLOAD_PATH} download={ORDER_TEMPLATE_CSV_DOWNLOAD_FILE_NAME}>
            <Button className="template-download-button" buttonType="yellowBorder">
              <DownloadIconSvg className="icon" />
              注文CSVテンプレートをダウンロード
            </Button>
          </a>
          <div className="row">
            <TextButton onClick={onClickOpenCsvHowtoModal} className="howto-modal-button">
              注文情報CSVの入力方法はこちら
            </TextButton>
            <a href={ORDER_SAMPLE_CSV_DOWNLOAD_PATH} download={ORDER_SAMPLE_CSV_DOWNLOAD_FILE_NAME}>
              <Button className="sample-download-button" buttonType="blackBorder">
                <DownloadIconSvg className="icon" />
                サンプルをダウンロード
              </Button>
            </a>
          </div>
          {orderDetail ? (
            <OrderDetailTable orderDetail={orderDetail} isOrderFinished={false} showReset onClickReset={onClickResetCsv} />
          ) : (
            <CsvFileInput errorMessage={csvInputError} onChange={onChangeCsv} />
          )}
        </div>
      </div>
    </Wrapper>
  )
}
