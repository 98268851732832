import React, { useState } from 'react'
import styled from 'styled-components'

/* components */
import { TextButton, Button } from 'components/atoms'
import { AddressForm } from 'components/organisms'

/* lib, types, const */
import { mediaSp } from 'lib/media-query'
import { ReturnAddress } from 'types/graphql'
import { AddressInput, AddressInputErrors } from 'types/myTypes'
import {
  validateKana,
  validateAddressInput,
  validateCompanyNameWithCompanyNameKana,
  validateCompanyNameKanaWithCompanyName,
} from 'lib/validate'
import { RETURN_ADDRESS_LIMIT_NUM } from 'const'

type Props = {
  className?: string
  options: { id?: string; name?: string; address: ReturnAddress; onChange: (value: string) => void }[]
  checkedValue: string
  addressInputForEdit: AddressInput
  addressInputForAdd: AddressInput
  onClickEditAddress: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void
  onChangeAddressInputForEdit: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void
  onClickCompleteEdit: (id: string) => void
  onChangeAddressInputForAdd: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void
  onClickCancelAdd: () => void
  onClickCompleteAdd: () => void
  onClickDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void
  scrollContentsTop: () => void
}

const Wrapper = styled.div`
  width: 100%;
`

const StyledRadioWrapper = styled.div`
  padding: 32px 40px;
  border: 1px solid ${(props): string => props.theme.gray};
  cursor: pointer;

  & + & {
    border-top: none;
  }

  ${mediaSp`
    padding: 2rem 1.5rem;
  `}
`

const StyledRadioButton = styled.input`
  display: none;
`

const StyledRadioLabelWrapper = styled.div`
  ${StyledRadioButton}:checked + &::after {
    opacity: 1;
  }
  ${StyledRadioButton}:checked + &::before {
    border: 0.2rem solid ${(props): string => props.theme.yellow};
  }

  ${StyledRadioButton} + & {
    padding-left: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;

    &::before {
      position: absolute;
      top: calc(50% - 13px);
      left: 0;
      width: 26px;
      height: 26px;
      content: '';
      box-sizing: border-box;
      border: 0.2rem solid ${(props): string => props.theme.black};
      border-radius: 50%;
    }
    &::after {
      width: 16px;
      height: 16px;
      position: absolute;
      top: calc(50% - 8px);
      left: 5px;
      content: '';
      background: ${(props): string => props.theme.yellow};
      border-radius: 50%;
      opacity: 0;
      transition: 0.3s all;
    }

    .return-info-wrapper,
    .button-wrapper {
      display: flex;
      align-items: center;
    }

    .return-info-wrapper {
      .name {
        width: 147px;
        margin-right: 40px;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.05em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .address {
        width: 354px;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.05em;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .button-wrapper {
      width: 123px;

      .button {
        font-size: 18px;

        & + .button {
          padding-left: 51px;
        }
      }
    }
  }

  ${mediaSp`
    ${StyledRadioButton} + & {
      padding-left: 3.5rem;
      display: block;

      &::before {
        top: calc(50% - 1.15rem);
        width: 2.3rem;
        height: 2.3rem;
      }
      &::after {
        width: 1.3rem;
        height: 1.3rem;
        top: calc(50% - 0.65rem);
        left: 0.5rem;
      }

      .return-info-wrapper {
        margin-bottom: 2.2rem;
        display: block;

        .name {
          width: auto;
          margin-right: 0;
          margin-bottom: 0.4rem;
          font-size: 1.6rem;
          line-height: 1.5;
        }
    
        .address {
          width: 100%;
          font-size: 1.2rem;
        }
      }

      .button-wrapper {
        width: auto;
        padding-left: 18rem;

        .button {
          font-size: 1.4rem;

          & + .button {
            padding-left: 3.2rem;
          }
        }
      }
    }
  `}
`

const StyledAddWrapper = styled.div`
  padding: 32px 40px 48px;
  border: solid ${(props): string => props.theme.gray};
  border-width: 0 1px 1px 1px;

  .buttons {
    padding-top: 3.2rem;
    display: flex;
    justify-content: center;
    gap: 32px;

    > button {
      width: 21.6rem;
      height: 4.8rem;
      font-size: 1.6rem;
    }
  }

  ${mediaSp`
    padding: 1.5rem 1.6rem 4rem;

    .buttons {
      flex-direction: column;
      align-items: center;
      gap: 2.6rem;
    }
  `}
`

const StyledEditWrapper = styled(StyledAddWrapper)`
  &.border-all {
    border-width: 1px;
  }

  &.border-top {
    border-width: 1px 1px 0 1px;
  }
`

const StyledAddButtonWrapper = styled.div`
  padding-top: 23px;

  .add-button {
    width: 256px;
    height: 48px;
    margin: 0 0 0 auto;
    font-size: 1.6rem;
    display: block;
  }

  ${mediaSp`
    padding-top: 3.2rem;

    .add-button {
      width: 31.2rem;
      height: 5.2rem;
      margin: 0 auto;
    }
  `}
`

export const RadioButtonReturnAddressWithButton: React.FC<Props> = ({
  className = '',
  options,
  checkedValue,
  addressInputForEdit,
  addressInputForAdd,
  onClickEditAddress,
  onChangeAddressInputForEdit,
  onClickCompleteEdit,
  onChangeAddressInputForAdd,
  onClickCancelAdd,
  onClickCompleteAdd,
  onClickDelete,
  scrollContentsTop,
}) => {
  const [edittingAddressId, setEdittingAddressId] = useState<string>('')
  const [isAdding, setIsAdding] = useState<boolean>(false)
  const [addressInputForEditErrors, setAddressInputForEditErrors] = useState<AddressInputErrors>({
    name: null,
    name_kana: null,
    company_name: null,
    company_name_kana: null,
    telephone_number: null,
    post_code: null,
    prefecture: null,
    address: null,
  })
  const [addressInputForAddErrors, setAddressInputForAddErrors] = useState<AddressInputErrors>({
    name: null,
    name_kana: null,
    company_name: null,
    company_name_kana: null,
    telephone_number: null,
    post_code: null,
    prefecture: null,
    address: null,
  })

  /* 変更系処理 */
  const onClickCancelEdit = (): void => {
    setEdittingAddressId('')
  }

  /* 追加系処理 */
  const onClickAdd = (): void => {
    setIsAdding(true)
  }

  /* バリデーション */
  const validateForEdit = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    switch (name) {
      case 'name':
      case 'prefecture':
      case 'address':
      case 'name_kana':
      case 'telephone_number':
      case 'post_code':
        setAddressInputForEditErrors({ ...addressInputForEditErrors, [name]: validateAddressInput(name, value) })
        break
      case 'company_name':
        setAddressInputForEditErrors({
          ...addressInputForEditErrors,
          company_name: validateCompanyNameWithCompanyNameKana(value, addressInputForEdit.company_name_kana),
          company_name_kana: validateCompanyNameKanaWithCompanyName(value, addressInputForEdit.company_name_kana),
        })
        break
      case 'company_name_kana':
        setAddressInputForEditErrors({
          ...addressInputForEditErrors,
          company_name: validateCompanyNameWithCompanyNameKana(addressInputForEdit.company_name, value),
          company_name_kana: validateCompanyNameKanaWithCompanyName(addressInputForEdit.company_name, value) || validateKana(value),
        })
        break
      default:
        break
    }
  }
  const validateForAdd = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    switch (name) {
      case 'name':
      case 'prefecture':
      case 'address':
      case 'name_kana':
      case 'telephone_number':
      case 'post_code':
        setAddressInputForAddErrors({ ...addressInputForAddErrors, [name]: validateAddressInput(name, value) })
        break
      case 'company_name':
        setAddressInputForAddErrors({
          ...addressInputForAddErrors,
          company_name: validateCompanyNameWithCompanyNameKana(value, addressInputForAdd.company_name_kana),
          company_name_kana: validateCompanyNameKanaWithCompanyName(value, addressInputForAdd.company_name_kana),
        })
        break
      case 'company_name_kana':
        setAddressInputForAddErrors({
          ...addressInputForAddErrors,
          company_name: validateCompanyNameWithCompanyNameKana(addressInputForAdd.company_name, value),
          company_name_kana: validateCompanyNameKanaWithCompanyName(addressInputForAdd.company_name, value) || validateKana(value),
        })
        break
      default:
        break
    }
  }
  const isDisableEdit = (): boolean => {
    const { name, name_kana, telephone_number, post_code, prefecture, address } = addressInputForEdit
    const isRequiredItemsFilled = name && name_kana && telephone_number && post_code && prefecture && address
    if (!isRequiredItemsFilled) return true

    const errorArray = Object.values(addressInputForEditErrors).filter((error) => error !== null)
    if (errorArray.length > 0) return true

    return false
  }
  const isDisableAdd = (): boolean => {
    const { name, name_kana, telephone_number, post_code, prefecture, address } = addressInputForAdd
    const isRequiredItemsFilled = name && name_kana && telephone_number && post_code && prefecture && address
    if (!isRequiredItemsFilled) return true

    const errorArray = Object.values(addressInputForAddErrors).filter((error) => error !== null)
    if (errorArray.length > 0) return true

    return false
  }

  return (
    <Wrapper className={className}>
      {options.map((option, index) =>
        edittingAddressId && edittingAddressId === option.address.id ? (
          <StyledEditWrapper className={index === 0 ? (options.length === 1 ? 'border-all' : 'border-top') : ''} key={option.address.id}>
            <AddressForm
              addressInput={addressInputForEdit}
              errors={addressInputForEditErrors}
              onChangeState={(e) => {
                onChangeAddressInputForEdit(e)
                validateForEdit(e)
              }}
            />
            <div className="buttons">
              <Button
                buttonType="darkGray"
                onClick={() => {
                  onClickCancelEdit()
                  scrollContentsTop()
                }}
              >
                キャンセル
              </Button>
              <Button
                buttonType="black"
                disabled={isDisableEdit()}
                onClick={() => {
                  onClickCompleteEdit(option.address.id as string)
                  setEdittingAddressId('')
                  scrollContentsTop()
                }}
              >
                変更
              </Button>
            </div>
          </StyledEditWrapper>
        ) : (
          <StyledRadioWrapper
            key={option.address.id}
            onClick={() => {
              option.onChange(option.address.id as string)
            }}
          >
            <StyledRadioButton
              type="radio"
              checked={checkedValue === option.address.id}
              onChange={(e) => {
                option.onChange(e.target.value)
              }}
              id={option.id}
              name={option.name}
              value={option.address.id as string}
            />
            <StyledRadioLabelWrapper>
              <div className="return-info-wrapper">
                <p className="name">{option.address.name}</p>
                <div className="address">
                  <p>&#12306;{option.address.post_code}</p>
                  <p>
                    {option.address.prefecture}
                    {option.address.address}
                  </p>
                  {option.address.building_name && <p>{option.address.building_name}</p>}
                </div>
              </div>
              <div className="button-wrapper">
                <TextButton
                  onClick={(e) => {
                    onClickEditAddress(e, option.address.id as string)
                    setEdittingAddressId(option.address.id as string)
                  }}
                  className="button"
                >
                  変更
                </TextButton>
                {index !== 0 && (
                  <TextButton
                    onClick={(e) => {
                      onClickDelete(e, option.address.id as string)
                    }}
                    className="button"
                  >
                    削除
                  </TextButton>
                )}
              </div>
            </StyledRadioLabelWrapper>
          </StyledRadioWrapper>
        ),
      )}
      {isAdding && (
        <StyledAddWrapper>
          <AddressForm
            addressInput={addressInputForAdd}
            errors={addressInputForAddErrors}
            onChangeState={(e) => {
              onChangeAddressInputForAdd(e)
              validateForAdd(e)
            }}
          />
          <div className="buttons">
            <Button
              buttonType="darkGray"
              onClick={() => {
                onClickCancelAdd()
                setIsAdding(false)
                scrollContentsTop()
              }}
            >
              キャンセル
            </Button>
            <Button
              buttonType="black"
              disabled={isDisableAdd()}
              onClick={() => {
                onClickCompleteAdd()
                setIsAdding(false)
                scrollContentsTop()
              }}
            >
              追加
            </Button>
          </div>
        </StyledAddWrapper>
      )}
      {options.length < RETURN_ADDRESS_LIMIT_NUM && !isAdding && (
        <StyledAddButtonWrapper>
          <Button buttonType="black" onClick={onClickAdd} className="add-button">
            新しく返送先を追加する
          </Button>
        </StyledAddButtonWrapper>
      )}
    </Wrapper>
  )
}
