import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  buttons: { id: string; name: string; value: string; label: string; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }[]
  checkedValue: string
}

const Wrapper = styled.div`
  display: flex;
`

const RadioWrapper = styled.div`
  display: flex;

  & + & {
    margin-left: 32px;
    ${mediaSp`
      margin-left: 2.4rem;
  `}
  }
`

const StyledRadioButton = styled.input`
  display: none;
`

const StyledRadioLabel = styled.label`
  ${StyledRadioButton}:checked + &::after {
    opacity: 1;
  }
  ${StyledRadioButton}:checked + &::before {
    border: 0.2rem solid ${(props): string => props.theme.yellow};
  }

  ${StyledRadioButton} + & {
    padding-left: 42px;
    position: relative;
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 26px;
      height: 26px;
      content: '';
      box-sizing: border-box;
      border: 0.2rem solid ${(props): string => props.theme.black};
      border-radius: 50%;
    }
    &::after {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 5px;
      left: 5px;
      content: '';
      background: ${(props): string => props.theme.yellow};
      border-radius: 50%;
      opacity: 0;
      transition: 0.3s all;
    }
  }
  ${mediaSp`
    ${StyledRadioButton} + & {
      padding-left: 3.1rem;
      font-size: 1.4rem;
      line-height: 2.3rem;
      &::before {
        width: 2.3rem;
        height: 2.3rem;
      }
      &::after {
        width: 1.3rem;
        height: 1.3rem;
        top: 0.5rem;
        left: 0.5rem;
      }
    }
  `}
`

export const RadioButton: React.FC<Props> = ({ className = '', buttons, checkedValue }) => {
  return (
    <Wrapper className={className}>
      {buttons.map((button) => (
        <RadioWrapper key={button.value}>
          <StyledRadioButton
            type="radio"
            checked={checkedValue === button.value}
            onChange={button.onChange}
            id={button.id}
            name={button.name}
            value={button.value}
          />
          <StyledRadioLabel htmlFor={button.id}>{button.label}</StyledRadioLabel>
        </RadioWrapper>
      ))}
    </Wrapper>
  )
}
