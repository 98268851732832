import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { ResetPasswordEmailTpl } from 'components/templates'

/* lib, types, const */
import { useGuestSendPasswordResetEmailMutation } from 'types/graphql'
import { FlashMessageType } from 'types/myTypes'

export const ResetPasswordEmail: React.FC = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const location = useLocation()
  const { state } = location as { state: { redirectFrom: string } }

  const [guestSendPasswordResetEmailMutation, { loading }] = useGuestSendPasswordResetEmailMutation({
    onCompleted: () => {
      navigate('/reset-password/complete-email')
    },
    onError: (e) => {
      Sentry.captureException(e)
      setFlashMessage({ type: 'error', message: e.message ?? 'メール送信に失敗しました。' })
    },
  })

  const onChangeState = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setEmail(event.target.value)
  }

  const onClickSendEmail = async (): Promise<void> => {
    await guestSendPasswordResetEmailMutation({
      variables: {
        input: {
          email,
        },
      },
    })
  }

  useEffect(() => {
    if (!state) return
    if (!!state.redirectFrom) {
      // パスワード再設定からのリダイレクト
      if (state.redirectFrom === '/reset-password/') {
        setFlashMessage({
          type: 'error',
          message: 'パスワードリセットに失敗しました。メールの送信からやり直してください。',
        })
        return
      }
    }
  }, [state])

  // state.redirect = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (state && !!state.redirectFrom) {
      navigate('/reset-password/email', {
        replace: true,
        state: {
          redirectFrom: '',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <>
      <Meta title="パスワード再設定" />
      {loading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <ResetPasswordEmailTpl
        email={email}
        onChangeState={(e) => {
          onChangeState(e)
        }}
        onClickSendEmail={onClickSendEmail}
      />
    </>
  )
}
