import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { MypageLayout, MypageAccountTopTpl } from 'components/templates'
import { useCookies } from 'react-cookie'

/* lib, types*/
import { FlashMessageType } from 'types/myTypes'
import {
  useLogoutUserMutation,
  useCurrentUserLazyQuery,
  useUserReturnAddressesLazyQuery,
  useUserDeleteReturnAddressMutation,
  User,
  ReturnAddress,
} from 'types/graphql'

export const MypageAccountTop: React.FC = () => {
  const [user, setUser] = useState<User>()
  const [returnAddresses, setReturnAddresses] = useState<ReturnAddress[]>([])
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const navigate = useNavigate()
  const [, , removeCookie] = useCookies(['isLoggedIn'])

  const location = useLocation()
  const { state } = location as { state: { redirectFrom: string } }

  const [logoutUserMutation, { loading: logoutUserMutationLoading }] = useLogoutUserMutation({
    onCompleted: () => {
      removeCookie('isLoggedIn', { path: '/' })
      navigate('/login')
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: 'ログアウトに失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [currentUserLazyQuery, { loading: currentUserLazyQueryLoading }] = useCurrentUserLazyQuery({
    onCompleted: (data) => {
      if (!data || !data.CurrentUser) return
      setUser(data.CurrentUser)
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userReturnAddressesLazyQuery, { loading: userReturnAddressesLazyQueryLoading }] = useUserReturnAddressesLazyQuery({
    onCompleted: (data) => {
      if (!data) return
      setReturnAddresses(data.UserReturnAddresses as ReturnAddress[])
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userDeleteReturnAddressMutation, { loading: userDeleteReturnAddressMutationLoading }] = useUserDeleteReturnAddressMutation({
    onCompleted: () => {
      userReturnAddressesLazyQuery()
      setFlashMessage({ type: 'success', message: '返送先住所を削除しました' })
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: '削除に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const onClickLogout = (): void => {
    setFlashMessage(null)
    logoutUserMutation()
  }

  const onClickDeleteReturnAddress = (id: string): void => {
    const confirmRes = confirm('返送先住所を削除しますか？')
    if (!confirmRes) return

    setFlashMessage(null)
    userDeleteReturnAddressMutation({
      variables: {
        input: {
          business_return_address_id: id,
        },
      },
    })
  }

  useEffect(() => {
    currentUserLazyQuery()
    userReturnAddressesLazyQuery()
  }, [currentUserLazyQuery, userReturnAddressesLazyQuery])

  useEffect(() => {
    if (!state) return
    if (!!state.redirectFrom) {
      // パスワード更新からのリダイレクト
      if (state.redirectFrom === '/mypage/account/edit/password') {
        setFlashMessage({
          type: 'success',
          message: 'パスワードを更新しました。',
        })
      }
      // 返送先住所登録からのリダイレクト
      if (state.redirectFrom === '/mypage/account/add/return-address') {
        setFlashMessage({
          type: 'success',
          message: '返送先住所を登録しました。',
        })
      }
      // 返送先住所更新からのリダイレクト
      if (state.redirectFrom === '/mypage/account/edit/return-address') {
        setFlashMessage({
          type: 'success',
          message: '返送先住所を更新しました。',
        })
      }
      // 注文者情報更新からのリダイレクト
      if (state.redirectFrom === '/mypage/account/edit/user') {
        setFlashMessage({
          type: 'success',
          message: '注文者情報を更新しました。',
        })
      }
      // 注文者情報更新メールアドレス認証からのリダイレクト
      if (state.redirectFrom === '/mypage/account/edit/auth-code') {
        setFlashMessage({
          type: 'success',
          message: 'メールアドレスを更新しました。',
        })
      }
    }
  }, [state])

  // state.* = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (state && !!state.redirectFrom) {
      navigate('/mypage/account', {
        replace: true,
        state: {
          redirectFrom: '',
        },
      })
    }
  }, [navigate, state])

  const isLoading =
    logoutUserMutationLoading ||
    currentUserLazyQueryLoading ||
    currentUserLazyQueryLoading ||
    userReturnAddressesLazyQueryLoading ||
    userDeleteReturnAddressMutationLoading

  return (
    <>
      <Meta title="アカウント情報" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <MypageLayout pageTitle="アカウント情報">
        {user && (
          <MypageAccountTopTpl
            user={user}
            returnAddresses={returnAddresses}
            onClickDeleteReturnAddress={onClickDeleteReturnAddress}
            onClickLogout={onClickLogout}
          />
        )}
      </MypageLayout>
    </>
  )
}
