import React from 'react'
import styled from 'styled-components'

/* components */
import { Label, Selectbox } from 'components/atoms'

type Props = {
  className?: string
  labelText: string
  required?: boolean
  name?: string
  id?: string
  value: string
  options: { value: string; label: string }[]
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

type StyleProps = {
  error?: boolean
}

const Wrapper = styled.div`
  width: 100%;

  .label {
    margin-bottom: 1.6rem;
    display: block;
  }
`

export const SelectboxWithLabel: React.FC<Props & StyleProps> = ({
  className = '',
  labelText,
  required = true,
  name,
  id,
  value,
  options,
  error,
  onChange,
}) => {
  return (
    <Wrapper className={className}>
      <Label text={labelText} required={required} className="label" />
      <Selectbox name={name} id={id} value={value} options={options} error={error} onChange={onChange} />
    </Wrapper>
  )
}
