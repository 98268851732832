import React from 'react'
import styled from 'styled-components'

/* components */
import { PageTitle, ButtonLink } from 'components/atoms'
import { OrderFormSteps } from 'components/molecules'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  orderId: string
}

const Wrapper = styled.div`
  .inner {
    width: 100%;
    max-width: 102.8rem;
    margin: 0 auto;
    padding: 8rem 2rem 12rem;

    .page-title {
      margin-bottom: 6.7rem;
      text-align: center;
    }
    .order-form-steps {
      margin-bottom: 6.4rem;
    }
    .thanks {
      margin-bottom: 2.4rem;
      font-size: 2.4rem;
      font-weight: 700;
      text-align: center;
    }
    .order-id {
      margin-bottom: 4.3rem;
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
    }
    .attention {
      width: 56rem;
      max-width: 100%;
      margin: 0 auto 5.6rem;
      font-size: 1.4rem;
      line-height: 1.5;
    }
    .go-to-orders {
      width: 31.2rem;
      margin: 0 auto;
      display: block;
    }
  }

  ${mediaSp`
    .inner {
      max-width: 100%;
      padding: 7.2rem 2rem 12rem;

      .page-title {
        margin-bottom: 4.6rem;
      }
      .order-form-steps {
        margin-bottom: 4rem;
      }
      .thanks {
        font-size: 1.8rem;
      }
      .order-id {
        margin-bottom: 4rem;
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
  `}
`

export const OrderCompleteTpl: React.FC<Props> = ({ orderId }) => {
  return (
    <Wrapper>
      <div className="inner">
        <PageTitle title="注文完了" className="page-title" />

        <OrderFormSteps className="order-form-steps" currentStep={4} />
        <p className="thanks">ご注文ありがとうございました</p>
        <p className="order-id">注文番号:{orderId}</p>
        <p className="attention">
          {/* FIXME 注意書きは仮 */}
          {/* https://www.pivotaltracker.com/story/show/183552796 */}
          注意書きテキスト注意書きテキスト注意書きテキスト注意書きテキスト注意書きテキスト
          <br />
          注意書きテキスト注意書きテキスト注意書きテキスト注意書きテキスト注意書きテキスト
        </p>

        <ButtonLink className="go-to-orders" buttonType="black" href="/mypage/order/">
          注文履歴一覧へ
        </ButtonLink>
      </div>
    </Wrapper>
  )
}
