import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

/* components */
import { Button } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  title: string
  children: React.ReactNode
}

const Wrapper = styled.div`
  width: 100vw;
  padding: 0 0 30px;
  background: ${(props): string => props.theme.white};
  * {
    font-family: '游ゴシック', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif;
  }
  .title {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
  }

  .button {
    width: 200px;
    height: 48px;
    margin: 0 auto 15px;
    display: block;
    font-size: 14px;
  }
  ${mediaSp`
    .title {
      font-size: 26px;
    }
  `}
`
const ReloadNoteWrapper = styled.p`
  margin: 0 0 40px;
  color: ${(props): string => props.theme.errorText};
  font-size: 14px;
  font-weight: 500;
  font-family: 'M PLUS Rounded 1c', Hiragino Sans, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, sans-serif;
  line-height: 21px;
  text-align: center;

  ${mediaSp`
    margin: -1rem 0 2rem;
    padding: 0 20px;
    font-size: 1.2rem;
    text-align: left;
  `}

  @media print {
    display: none;
  }
`

const ButtonWrapper = styled.p`
  @media print {
    display: none;
  }
`

export const DocumentLayout: React.FC<Props> = ({ className = '', title, children }) => {
  const { pathname } = useLocation()

  const onClickPrint = (): void => {
    window.print()
  }

  const onClickClose = (): void => {
    window.close()
  }

  return (
    <Wrapper className={className}>
      <div className="inner">
        {pathname === '/order/estimate' && (
          <ReloadNoteWrapper>
            ※リロードすると表示されなくなります。
            <br className="sp" />
            宛名を変更したい場合は一度タブを閉じて注文フォームから入力し直してください。
          </ReloadNoteWrapper>
        )}
        {pathname === '/order/invoice' && (
          <ReloadNoteWrapper>
            ※リロードすると表示されなくなります。
            <br className="sp" />
            再度発行したい場合は一度タブを閉じて注文詳細から発行してください。
          </ReloadNoteWrapper>
        )}
        <p className="title">{title}</p>
        <div>{children}</div>
        <ButtonWrapper>
          <Button className="button" buttonType="yellow" onClick={() => onClickPrint()}>
            印刷する
          </Button>
          <Button className="button" buttonType="blackBorder" onClick={() => onClickClose()}>
            閉じる
          </Button>
        </ButtonWrapper>
      </div>
    </Wrapper>
  )
}
