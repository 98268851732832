import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  children: React.ReactNode
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const StyledTextButton = styled.button<Props>`
  color: ${(props): string => props.theme.darkGray};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration-line: underline;
  display: block;
  transition: 0.3s all;
  &:hover {
    opacity: 0.5;
  }
`

export const TextButton: React.FC<Props> = ({ className = '', children, onClick }) => {
  return (
    <StyledTextButton className={className} onClick={onClick}>
      {children}
    </StyledTextButton>
  )
}
