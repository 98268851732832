import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

/* components */
import { PageTitle, SubHeading, Button } from 'components/atoms'
import { OrderFormSteps } from 'components/molecules'
import { OrderDetailTable, AddressTable } from 'components/organisms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { PaymentMethod } from 'types/graphql'
import { CreateOrder } from 'types/myTypes'

/* images */
import { ReactComponent as DownloadIconSvg } from 'assets/images/icon/download.svg'

type Props = {
  createOrder: CreateOrder
  onClickDownloadCsv: () => void
  onClickBack: () => void
  onClickOrder: () => void
}

const Wrapper = styled.div`
  width: 98%;
  max-width: 998px;
  margin: 0 auto;
  padding: 80px 0 12rem;

  .page-title,
  .steps {
    margin: 0 auto 64px;
  }

  .page-title {
    text-align: center;
  }

  .order-button,
  .back-button {
    width: 31.2rem;
    margin: 0 auto;
    display: block;
  }

  .order-button {
    margin-bottom: 1.6rem;
  }

  ${mediaSp`
    width: 33.5rem;
    max-width: 33.5rem;
    margin: 0 auto;
    padding: 7.2rem 0 12rem;

    .page-title {
      margin-bottom: 4.6rem;
    }

    .steps {
      margin-bottom: 4.8rem;
    }
  `}
`

const StyledItem = styled.div`
  max-width: 840px;
  margin: 0 auto 6.4rem;

  .sub-heading {
    margin-bottom: 40px;
  }

  &.description {
    > p {
      font-size: 16px;
      line-height: 1.7;
      text-align: center;
      letter-spacing: 0.05em;
    }
  }

  &.csv {
    .csv-download-button {
      margin: 32px 0 0 auto;
      position: relative;
      display: block;
      width: 32.2rem;
      height: 5.4rem;
      font-size: 1.6rem;
      line-height: 48px;
      padding: 0 0 0 29px;

      .icon {
        position: absolute;
        top: 1.5rem;
        left: 4.8rem;
        width: 1.8rem;
        height: 1.8rem;
        fill: ${(props): string => props.theme.black};
        transition: 0.3s all;
      }
      &:hover {
        .icon {
          fill: ${(props): string => props.theme.white};
        }
      }
    }
  }

  &.user,
  &.return-address {
    .address-table {
      margin-top: -32px;
    }
  }

  &.payment-method,
  &.estimated-shipment-date,
  &.other {
    .content {
      padding-left: 2rem;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      word-wrap: break-word;
      white-space: pre-line;
    }
  }

  ${mediaSp`
    max-width: 33.5rem;

    .sub-heading {
      margin-bottom: 3.2rem;
    }

    &.description {
      >p {
        font-size: 1.4rem;
      }
    }

    &.csv {
      .csv-download-button {
        margin: 4rem auto 0;
        padding: 0 0 0 2.6rem;
      }
    }

    &.user,
    &.return-address {
      .address-table {
        margin-top: -2.4rem;
      }
    }

    &.payment-method,
    &.estimated-shipment-date,
    &.other {
      .content {
        font-size: 1.6rem;
      }
    }
  `}
`

export const OrderConfirmTpl: React.FC<Props> = ({ createOrder, onClickDownloadCsv, onClickBack, onClickOrder }) => {
  return (
    <Wrapper>
      <PageTitle title="内容確認" className="page-title" />

      <OrderFormSteps currentStep={2} className="steps" />

      <StyledItem className="description">
        <p>
          ご注文内容をご確認ください。
          <br />
          下記の内容でよろしければ、「注文する」ボタンを押してください。
        </p>
      </StyledItem>

      <StyledItem className="csv">
        <SubHeading text="注文情報CSVアップロード" className="sub-heading" />
        <OrderDetailTable
          orderDetail={createOrder.orderDetail}
          isOrderFinished={false}
          companyName={createOrder.user?.company_name || ''}
          name={!createOrder.user?.company_name && !!createOrder.user?.name ? createOrder.user.name : ''}
        />
        <Button className="csv-download-button" buttonType="blackBorder" onClick={onClickDownloadCsv}>
          <DownloadIconSvg className="icon" />
          注文情報CSVダウンロード
        </Button>
      </StyledItem>

      <StyledItem className="user">
        <SubHeading text="注文者情報(請求先)" className="sub-heading" />
        <AddressTable address={createOrder.user} className="address-table" />
      </StyledItem>

      <StyledItem className="return-address">
        <SubHeading text="返送先住所" className="sub-heading" />
        <AddressTable address={createOrder.returnAddress} className="address-table" />
      </StyledItem>

      <StyledItem className="payment-method">
        <SubHeading text="お支払方法" className="sub-heading" />
        <p className="content">{createOrder.paymentMethod === PaymentMethod.Stripe ? 'クレジットカード' : '銀行振込'}</p>
      </StyledItem>

      <StyledItem className="estimated-shipment-date">
        <SubHeading text="撮影する商品のご発送予定日" className="sub-heading" />
        <p className="content">{dayjs(createOrder.estimatedShipmentDate).format('YYYY/MM/DD')}</p>
      </StyledItem>

      <StyledItem className="other">
        <SubHeading text="その他" className="sub-heading" />
        <p className="content">{createOrder.otherText}</p>
      </StyledItem>

      <Button buttonType="yellow" onClick={onClickOrder} className="order-button">
        注文する
      </Button>
      <Button buttonType="blackBorder" onClick={onClickBack} className="back-button">
        戻る
      </Button>
    </Wrapper>
  )
}
