import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { AuthCodeTpl } from 'components/templates'

/* lib, types */
import { FlashMessageType } from 'types/myTypes'
import { useGuestApproveAuthorizationCodeMutation, useLoginUserMutation } from 'types/graphql'

type LocationState = {
  email: string
  password: string
  expires: string
  user_id: string
  signature: string
}

export const RegistrationAuthCode: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [authCode, setAuthCode] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location as { state: LocationState }
  const [, setCookie] = useCookies(['isLoggedIn'])

  const [guestApproveAuthorizationCodeMutation] = useGuestApproveAuthorizationCodeMutation({
    onCompleted: async () => {
      // ログインしてからマイページに遷移しないとcookieにtokenがセットされないので
      await loginUserMutation({
        variables: {
          input: {
            email,
            password: state.password,
          },
        },
      })
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: e.message ?? '認証できませんでした' })
      Sentry.captureException(e)
    },
  })

  const [loginUserMutation] = useLoginUserMutation({
    onCompleted: (data) => {
      setIsLoading(false)
      setCookie('isLoggedIn', 'true', { path: '/', expires: new Date(data.LoginUser.expires * 1000) })
      navigate('/mypage/order', {
        state: {
          registrationFinish: true,
        },
      })
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: e.message ?? '認証できませんでした' })
      Sentry.captureException(e)
    },
  })

  const onClickAuth = async (): Promise<void> => {
    setIsLoading(true)
    setFlashMessage(null)

    const { user_id, expires, signature } = state
    await guestApproveAuthorizationCodeMutation({
      variables: {
        input: {
          code: authCode,
          expires: Number(expires),
          user_id,
          signature,
        },
      },
    })
  }

  useEffect(() => {
    setEmail(state.email)
  }, [state.email])

  return (
    <>
      <Meta title="メールアドレス認証" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <AuthCodeTpl
        backHref="/registration"
        backState={{ email }}
        email={email}
        authCode={authCode}
        setAuthCode={(authCode: string) => setAuthCode(authCode)}
        onClickAuth={onClickAuth}
      />
    </>
  )
}
