import React, { useState } from 'react'
import styled from 'styled-components'

/* components */
import { PageTitle, Button, ErrorMessage, TextLink } from 'components/atoms'
import { TextInputWithLabel } from 'components/molecules'

/* lib, types、const */
import { mediaSp } from 'lib/media-query'
import { validateRequiredAuthCode } from 'lib/validate'
import { CONTACT_URL } from 'const'

type Props = {
  email: string
  authCode: string
  backHref: string
  backState?: object
  setAuthCode: (authCode: string) => void
  onClickAuth: () => void
}

const Wrapper = styled.div`
  padding: 80px 0 120px;

  .inner {
    width: 685px;
    margin: 0 auto;

    .page-title {
      margin-bottom: 4.8rem;
      text-align: center;
    }

    .description {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 1.7;
      letter-spacing: 0.05em;
      text-align: center;
    }

    .input-wrapper {
      width: 310px;
      margin: 0 auto 40px;

      label {
        margin-bottom: 0.8rem;
        font-size: 18px;
      }
    }

    .auth-botton {
      width: 31.2rem;
      margin: 0 auto 48px;
      display: block;
    }

    .info {
      > p {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 500;
        letter-spacing: 0.05em;
      }

      > div {
        font-size: 1.2rem;
        line-height: 2.1;
        letter-spacing: 0.05em;

        .to-registration {
          display: inline;
        }

        > a {
          color: ${(props): string => props.theme.darkGray};
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 1.5;
          text-decoration-line: underline;
          transition: 0.3s all;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  ${mediaSp`
    padding: 7.2rem 0 14rem;

    .inner {
      width: 33.6rem;
      
      .description {
        margin-bottom: 4.8rem;
        font-size: 1.4rem;
        letter-spacing: 0;
      }

      .input-wrapper {
        width: 100%;
        margin: 0 auto 4.8rem;

        label {
          font-size: 1.4rem;
        }
      }

      .auth-botton {
        margin: 0 auto 4rem;
      }
    }
  `}
`

export const AuthCodeTpl: React.FC<Props> = ({ email, authCode, backHref, backState, setAuthCode, onClickAuth }) => {
  const [authCodeError, setAuthCodeError] = useState<string | null>(null)

  const onChangeAuthCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setAuthCode(value)
    setAuthCodeError(validateRequiredAuthCode(value))
  }

  return (
    <Wrapper>
      <div className="inner">
        <PageTitle title="メールアドレス認証" className="page-title" />
        <p className="description">
          ご入力いただいたメールアドレスに
          <br className="sp" />
          認証コードを送信しました。
          <br />
          メールで受け取った認証コードを入力してください。
        </p>
        <div className="input-wrapper">
          <TextInputWithLabel
            labelText="認証コード"
            value={authCode}
            placeholder="6桁のコードを入力"
            onChange={onChangeAuthCode}
            error={!!authCodeError}
          />
          {!!authCodeError && <ErrorMessage message={authCodeError} />}
        </div>
        <Button buttonType="yellow" disabled={!authCode || !!authCodeError} onClick={onClickAuth} className="auth-botton">
          認証
        </Button>
        <div className="info">
          <p>メールが届かない場合</p>
          <div>
            ・迷惑メールフォルダや他のフォルダに保管されていないかご確認ください。
            <br />
            ・ご入力いただいたメールアドレス {email} に大文字・小文字などの入力ミスがないかご確認ください。
            <br />
            　正しくなかった場合は
            <TextLink href={backHref} state={backState} text="入力フォーム" className="to-registration" />
            からもう一度ご入力ください。
            <br />
            ・上記当てはまらない場合は、お手数ですが
            <a href={CONTACT_URL} rel="noopener noreferrer" target="_blank">
              こちら
            </a>
            からお問い合わせください。
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
