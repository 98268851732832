import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

/* images */
import selectIcon from 'assets/images/icon/select.png'

type Props = {
  className?: string
  name?: string
  id?: string
  value: string
  options: { value: string; label: string }[]
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

type StyleProps = {
  error?: boolean
}

const StyledSelectWrapper = styled.div`
  width: 100%;
  position: relative;

  &:before {
    width: 24px;
    height: 24px;
    content: '';
    position: absolute;
    top: 17px;
    right: 22px;
    background: url(${selectIcon}) center no-repeat;
    background-size: 100%;
  }

  ${mediaSp`
    &::before {
      width: 1.8rem;
      height: 1.8rem;
      top: 1.6rem;
      right: 1.4rem;
    }
  `}
`

const StyledSelect = styled.select<StyleProps>`
  width: 100%;
  height: 56px;
  padding: 0 24px;
  color: ${(props): string => props.theme.gray};
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  border: 2px solid ${(props): string => (props.error ? props.theme.errorText : props.theme.gray)};
  border-radius: 0.8rem;
  display: block;
  transition: 0.3s all;
  box-sizing: border-box;

  &.selected {
    color: ${(props): string => props.theme.black};
  }

  ${mediaSp`
    height: 5rem;
    padding: 0 1.6rem;
    font-size: 1.4rem;
  `}
`

export const Selectbox: React.FC<Props & StyleProps> = ({
  className = '',
  name = '',
  id = '',
  value = '',
  options = [],
  error = false,
  onChange = () => {},
}) => {
  return (
    <StyledSelectWrapper className={className}>
      <StyledSelect className={value === '' ? '' : 'selected'} name={name} id={id} value={value} error={error} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </StyledSelectWrapper>
  )
}
