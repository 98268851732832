import React from 'react'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { OrderStatus as OrderStatusType } from 'types/graphql'

type Props = {
  className?: string
  status: OrderStatusType
}

const getStyleByOrderStatus = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  switch (props.status) {
    case OrderStatusType.Init:
      return `
          background: ${props.theme.darkGray};
        `
    case OrderStatusType.Preparing:
      return `
          background: ${props.theme.orange};
        `
    case OrderStatusType.Shooting:
      return `
          background: ${props.theme.red};
        `
    case OrderStatusType.Inspecting:
      return `
          background: ${props.theme.blue};
        `
    case OrderStatusType.Inspected:
      return `
          background: ${props.theme.green};
        `
    case OrderStatusType.Expired:
      return `
          color: ${props.theme.darkGray};
          background: ${props.theme.gray};
        `
    case OrderStatusType.Cancel:
      return `
          color: ${props.theme.darkGray};
          background: ${props.theme.gray};
        `
    default:
      return ''
  }
}

const StyledOrderStatus = styled.div<Props>`
  padding: 0.8rem 2.4rem;
  color: ${(props): string => props.theme.white};
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  border-radius: 4px;
  display: inline-block;

  ${(props) => getStyleByOrderStatus(props)}

  ${mediaSp`
    font-size: 1.2rem;
  `}
`

export const OrderStatus: React.FC<Props> = ({ className = '', status }) => {
  const getOrderStatusName = (orderStatus: OrderStatusType): string => {
    switch (orderStatus) {
      case OrderStatusType.Init:
        return '入金確認中'
      case OrderStatusType.Preparing:
        return '撮影準備中'
      case OrderStatusType.Shooting:
        return '撮影中'
      case OrderStatusType.Inspecting:
        return '検収中'
      case OrderStatusType.Inspected:
        return '検収完了'
      case OrderStatusType.Expired:
        return 'DL期間終了'
      case OrderStatusType.Cancel:
        return 'キャンセル'
      default:
        return ''
    }
  }
  return (
    <StyledOrderStatus className={className} status={status}>
      {getOrderStatusName(status)}
    </StyledOrderStatus>
  )
}
