import React from 'react'
import styled from 'styled-components'
import { TermsSubtitle, TermsContent } from '../../atoms'
import { mediaPc } from '../../../lib/media-query'

type Props = {
    subtitle?: string
    children?: React.ReactNode
    withoutSubtitle?: boolean
}

const Block = styled.div`
  margin-bottom: 5.6vw;

  ${mediaPc`
    margin-bottom: 21px;
    display: flex;
    justify-content: space-between;
  `}
`

export const TermsBlock: React.FC<Props> = ({ subtitle = '', children, withoutSubtitle = false }) => {
    return (
        <Block>
            <TermsSubtitle subtitle={subtitle} withoutSubtitle={withoutSubtitle} />
            <TermsContent withoutSubtitle={withoutSubtitle}>{children}</TermsContent>
        </Block>
    )
}
