import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { MypageAccountEditUserTpl, MypageLayout } from 'components/templates'

/* lib, types, const */
import { validateRequiredPostCode } from 'lib/validate'
import { getAddressFromPostCode } from 'lib/post-code-to-address'
import { useUserUpdateCurrentAccountMutation, useUserUpdateEmailMutation, User } from 'types/graphql'
import { AddressInput, FlashMessageType } from 'types/myTypes'

export const MypageAccountEditUser: React.FC = () => {
  const addressInitialInput: AddressInput = {
    name: '',
    name_kana: '',
    company_name: '',
    company_name_kana: '',
    department: '',
    store_name: '',
    telephone_number: '',
    post_code: '',
    prefecture: '',
    address: '',
    building_name: '',
  }

  const [addressInput, setAddressInput] = useState<AddressInput>(addressInitialInput)
  const [emailInput, setEmailInput] = useState<string>('')
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const navigate = useNavigate()
  const location = useLocation()
  const { state, pathname } = location as unknown as Location & { state: { user: User; addressInput?: AddressInput; email?: string } }

  const [userUpdateEmailMutation, { loading: userUpdateEmailMutationLoading }] = useUserUpdateEmailMutation({
    onCompleted: (data) => {
      if (!data) return
      const { user_id, expires, signature } = data.UserUpdateEmail
      navigate('/mypage/account/edit/auth-code', {
        state: {
          addressInput,
          email: emailInput,
          user_id,
          expires,
          signature,
        },
      })
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: e.message ?? '注文者情報の更新に失敗しました。' })
      Sentry.captureException(e)
    },
  })

  const [userUpdateCurrentAccountMutation, { loading: userUpdateCurrentAccountMutationLoading }] = useUserUpdateCurrentAccountMutation({
    onCompleted: (data) => {
      if (!data) return
      if (!data.UserUpdateCurrentAccount.is_changed_email) {
        navigate('/mypage/account', {
          state: {
            redirectFrom: pathname,
          },
        })
        return
      }

      userUpdateEmailMutation({
        variables: {
          input: {
            email: emailInput,
          },
        },
      })
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: e.message ?? '注文者情報の更新に失敗しました。' })
      Sentry.captureException(e)
    },
  })

  const onChangeState = async (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const { name, value } = e.target

    if (name === 'email') {
      setEmailInput(value)
    }
    setAddressInput({ ...addressInput, [name]: value })

    if (name === 'post_code') {
      if (!validateRequiredPostCode(value)) {
        const data = await getAddressFromPostCode(value)
        if (data) {
          setAddressInput({ ...addressInput, [name]: value, prefecture: data.address1, address: `${data.address2}${data.address3}` })
        }
      }
    }
  }

  const onClickEdit = (): void => {
    setFlashMessage(null)
    userUpdateCurrentAccountMutation({
      variables: {
        input: {
          name: addressInput.name,
          name_kana: addressInput.name_kana,
          company_name: addressInput.company_name,
          company_name_kana: addressInput.company_name_kana,
          department: addressInput.department,
          store_name: addressInput.store_name,
          telephone_number: addressInput.telephone_number,
          post_code: addressInput.post_code,
          prefecture: addressInput.prefecture,
          address: addressInput.address,
          building_name: addressInput.building_name,
          email: emailInput,
        },
      },
    })
  }

  useEffect(() => {
    // 認証コード入力画面下部「入力フォーム」のリンクから戻ってきた場合
    if (!!state.addressInput && !!state.email) {
      setAddressInput(state.addressInput)
      setEmailInput(state.email)
      return
    }

    const { business, email } = state.user
    // 返送先住所と共通コンポーネント使用のため分離
    setAddressInput(business as AddressInput)
    setEmailInput(email || '')
  }, [state.user, state.addressInput, state.email])

  return (
    <>
      <Meta title="注文者情報(請求先)変更" />
      {(userUpdateEmailMutationLoading || userUpdateCurrentAccountMutationLoading) && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <MypageLayout pageTitle="注文者情報(請求先)変更">
        <MypageAccountEditUserTpl
          addressInput={addressInput}
          emailInput={emailInput}
          onChangeState={onChangeState}
          onClickEdit={onClickEdit}
        />
      </MypageLayout>
    </>
  )
}
