import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  text: string
  href: string
  target?: string
  rel?: string
}

const StyledTextLink = styled.a`
  color: ${(props): string => props.theme.darkGray};
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration-line: underline;
  transition: 0.3s all;

  &:hover {
    opacity: 0.5;
  }
`

export const TextExternalLink: React.FC<Props> = ({ className = '', text, href, target = '_blank', rel = 'noreferrer' }) => {
  return (
    <StyledTextLink className={className} href={href} target={target} rel={rel}>
      {text}
    </StyledTextLink>
  )
}
