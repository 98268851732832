import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { OrderConfirmTpl } from 'components/templates'

/* lib, types, context */
import { useUserUpsertReturnAddressMutation, useUserCreateOrderMutation, UserCreateOrderInput, PaymentMethod } from 'types/graphql'
import { CreateOrder, FlashMessageType } from 'types/myTypes'
import { initialCreateOrder, CreateOrderContext } from 'context'

export const OrderConfirm: React.FC = () => {
  const [createOrder, setCreateOrder] = useState<CreateOrder>()
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const navigate = useNavigate()
  const location = useLocation()
  const { updateCreateOrder } = useContext(CreateOrderContext)

  const [userUpsertReturnAddressMutation, { loading: userUpsertReturnAddressMutationLoading }] = useUserUpsertReturnAddressMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data) return
      const { user, paymentMethod, estimatedShipmentDate, otherText, orderItems } = createOrder as CreateOrder
      const {
        name,
        name_kana,
        company_name,
        company_name_kana,
        department,
        store_name,
        telephone_number,
        post_code,
        prefecture,
        address,
        building_name,
      } = user

      const variables: { input: UserCreateOrderInput } = {
        input: {
          order_items: orderItems,
          business_return_address_id: data.UserUpsertReturnAddress.id as string,
          // バックエンドのenumに合わせるためtoLowerCase
          payment_method: paymentMethod.toLowerCase(),
          estimated_shipment_date: `${estimatedShipmentDate} 00:00:00`,
          remarks: otherText,
          name,
          name_kana,
          company_name,
          company_name_kana,
          department,
          store_name,
          telephone_number,
          post_code,
          prefecture,
          address,
          building_name,
        },
      }
      userCreateOrderMutation({
        variables,
      })
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: '注文できませんでした。' })
      Sentry.captureException(e)
    },
  })

  const [userCreateOrderMutation, { loading: userCreateOrderMutationLoading }] = useUserCreateOrderMutation({
    onCompleted: (data) => {
      if (!data || !data.UserCreateOrder) return
      updateCreateOrder(initialCreateOrder.createOrder)
      if (createOrder?.paymentMethod === PaymentMethod.Bank) {
        navigate(data.UserCreateOrder)
      }
      if (createOrder?.paymentMethod === PaymentMethod.Stripe) {
        window.location.href = data.UserCreateOrder
      }
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: e.message ?? '注文できませんでした。' })
      Sentry.captureException(e)
    },
  })

  const onClickDownloadCsv = (): void => {
    const csvFile = createOrder?.csvFile as File
    const blob = new Blob([csvFile], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)

    const downloadLink = document.createElement('a')
    downloadLink.setAttribute('href', url)
    downloadLink.setAttribute('download', csvFile.name)
    downloadLink.click()
  }

  const onClickBack = (): void => {
    navigate('/order')
  }

  const onClickOrder = (): void => {
    setFlashMessage(null)
    const { user, returnAddress, paymentMethod, estimatedShipmentDate, otherText, orderItems } = createOrder as CreateOrder
    const {
      name,
      name_kana,
      company_name,
      company_name_kana,
      department,
      store_name,
      telephone_number,
      post_code,
      prefecture,
      address,
      building_name,
    } = user

    if (!returnAddress.id) {
      // 新規注文で返送先住所未登録の場合
      userUpsertReturnAddressMutation({
        variables: {
          input: {
            name: returnAddress.name as string,
            name_kana: returnAddress.name_kana as string,
            company_name: returnAddress.company_name,
            company_name_kana: returnAddress.company_name_kana,
            department: returnAddress.department,
            store_name: returnAddress.store_name,
            telephone_number: returnAddress.telephone_number as string,
            post_code: returnAddress.post_code as string,
            prefecture: returnAddress.prefecture as string,
            address: returnAddress.address as string,
            building_name: returnAddress.building_name,
          },
        },
      })
      return
    }

    const variables: { input: UserCreateOrderInput } = {
      input: {
        order_items: orderItems,
        business_return_address_id: returnAddress.id as string,
        // バックエンドのenumに合わせるためtoLowerCase
        payment_method: paymentMethod.toLowerCase(),
        estimated_shipment_date: `${estimatedShipmentDate} 00:00:00`,
        remarks: otherText,
        name,
        name_kana,
        company_name,
        company_name_kana,
        department,
        store_name,
        telephone_number,
        post_code,
        prefecture,
        address,
        building_name,
      },
    }
    userCreateOrderMutation({
      variables,
    })
  }

  useEffect(() => {
    const { state } = location as { state: { createOrder: CreateOrder } }
    if (state) {
      setCreateOrder(state.createOrder as CreateOrder)
    }
  }, [location])

  const loading = userUpsertReturnAddressMutationLoading || userCreateOrderMutationLoading

  return (
    <>
      <Meta title="内容確認" />
      {loading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {createOrder && (
        <OrderConfirmTpl
          createOrder={createOrder}
          onClickDownloadCsv={onClickDownloadCsv}
          onClickBack={onClickBack}
          onClickOrder={onClickOrder}
        />
      )}
    </>
  )
}
