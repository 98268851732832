import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { LoginTpl } from 'components/templates'

/* lib, types, const */
import { LoginInput, FlashMessageType } from 'types/myTypes'
import { useLoginUserMutation } from 'types/graphql'

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const loginInitialInput: LoginInput = {
    email: '',
    password: '',
  }

  const [loginInput, setLoginInput] = useState<LoginInput>(loginInitialInput)
  const [redirectFrom, setRedirectFrom] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const location = useLocation()
  const { state } = location as { state: { redirectFrom: string } }

  const [, setCookie, removeCookie] = useCookies(['isLoggedIn'])

  const [loginUserMutation] = useLoginUserMutation({
    onCompleted: (data) => {
      setIsLoading(false)

      setCookie('isLoggedIn', 'true', { path: '/', expires: new Date(data.LoginUser.expires * 1000) })

      if (!!redirectFrom) {
        // stateが必要なページは真っ白になるので近いページに戻すように
        navigate(getRedirectTo(redirectFrom))
      } else {
        navigate('/mypage/order')
      }
    },
    onError: (e) => {
      setIsLoading(false)
      if (e.message === 'メールアドレスの認証を行なってください。') {
        // 新規登録時のコード認証を行わずにログインしようとした場合
        navigate('/registration', { state: { redirectFrom: '/login', email: loginInput.email } })
      }
      setFlashMessage({ type: 'error', message: e.message ?? 'ログインできませんでした' })
      Sentry.captureException(e)
    },
  })

  const onChangeState = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { name, value } = e.target
    setLoginInput({ ...loginInput, [name]: value })
  }

  const login = async (): Promise<void> => {
    setIsLoading(true)
    setFlashMessage(null)

    const { email, password } = loginInput
    await loginUserMutation({
      variables: {
        input: {
          email,
          password,
        },
      },
    })
  }

  const getRedirectTo = (redirectFrom: string): string => {
    switch (redirectFrom) {
      case '/order/confirm':
      case '/order/estimate':
      case '/order/invoice':
        return '/order'
      case '/mypage/account/edit/user':
      case '/mypage/account/edit/auth-code':
      case '/mypage/account/edit/return-address':
        return '/mypage/account'
      default:
        return '/mypage/order'
    }
  }

  useEffect(() => {
    if (!state) return
    if (!!state.redirectFrom) {
      // パスワード再設定からのリダイレクト
      if (state.redirectFrom === '/reset-password/') {
        setFlashMessage({
          type: 'success',
          message: 'パスワードの設定が完了しました。新しいパスワードでログインしてください。',
        })
        return
      }
      // 不正なtokenによるリダイレクト
      setRedirectFrom(state.redirectFrom)
      removeCookie('isLoggedIn', { path: '/' })
      if (state.redirectFrom === '/order') {
        setFlashMessage({ type: 'error', message: 'ログインまたは新規アカウント登録が必要です' })
      } else {
        setFlashMessage({ type: 'error', message: '再度ログインしてください' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  // state.redirect = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (state && !!state.redirectFrom) {
      navigate('/login', {
        replace: true,
        state: {
          redirectFrom: '',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    // 印刷用のページはuseNavigateだとヘッダー等表示されないのでパラメーターで処理
    const isRedirectedParams = new URLSearchParams(location.search).get('redirect')
    if (isRedirectedParams) {
      setFlashMessage({ type: 'error', message: 'ログインまたは新規アカウント登録が必要です' })
    }
  }, [location])

  // location.searchが存在するままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    const isRedirectedParams = new URLSearchParams(location.search).get('redirect')
    if (isRedirectedParams) {
      navigate('/login', {
        replace: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      <Meta title="ログイン" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <LoginTpl
        loginInput={loginInput}
        onChangeState={(e) => {
          onChangeState(e)
        }}
        onClickLoginButton={login}
      />
    </>
  )
}
