import React, { useState, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { useLocation } from 'react-router-dom'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { TopTpl, TopPlanModal, OrderCsvHowToModal } from 'components/templates'

/* lib, types */
import { FlashMessageType } from 'types/myTypes'
import { OrderPlan, OrderItemInput, Invoice, useGuestQuotationLazyQuery } from 'types/graphql'
import { validateCsv, validateOrderItem } from 'lib/validate'

export const Top: React.FC = () => {
  const [orderDetail, setOrderDetail] = useState<Invoice>()
  const [isCsvHowToModalOpen, setIsCsvHowToModalOpen] = useState<boolean>(false)
  const [csvInputError, setCsvInputError] = useState<string | null>(null)
  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false)
  const [modalPlan, setModalPlan] = useState<OrderPlan>(OrderPlan.Photo)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const { hash } = useLocation()

  const [guestQuotationLazyQuery, { loading }] = useGuestQuotationLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data || !data.GuestQuotation) return
      setOrderDetail(data.GuestQuotation)
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  /* csv関連処理 */
  // 入力方法モーダル表示
  const onClickOpenCsvHowtoModal = (): void => {
    setIsCsvHowToModalOpen(true)
  }

  // csvをOrderItemInput型の配列に変更
  const parseCsvToOrderItemInputArray = (csvFile: File): Promise<OrderItemInput[]> => {
    return new Promise<OrderItemInput[]>((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const csvContent = fileReader.result as string
        const csvLines = csvContent.split(/\r\n|\n|\r/g)
        const csvLinesArray = csvLines.map((line) => line.split(','))

        // csvの中身がない場合 or 見出し行のみの場合はreject
        if (csvLinesArray.length <= 1) reject('csvの内容が正しくありません。')

        // 1つ目の要素は見出し行なので削除
        csvLinesArray.shift()

        const orderItemInputArray: OrderItemInput[] = csvLinesArray.map((line) => {
          const [
            order_number,
            jan_code,
            product_code,
            product_category,
            color,
            style,
            cut_num,
            has_movie,
            // FIXME: 360度撮影復活したら
            // https://www.pivotaltracker.com/story/show/183552747
            // has_photo360,
            extension,
            photo_size,
            remarks,
          ] = line
          return {
            order_number: Number(order_number),
            jan_code,
            product_code,
            product_category,
            color,
            style,
            cut_num: cut_num ? Number(cut_num) : null,
            has_movie: !!has_movie,
            // FIXME: 360度撮影復活したら
            // https://www.pivotaltracker.com/story/show/183552747
            // has_photo360: !!has_photo360,
            extension: extension || null,
            photo_size: photo_size ? Number(photo_size) : null,
            remarks,
          }
        })
        resolve(orderItemInputArray)
      }
      fileReader.onerror = (e) => {
        reject('ファイルをアップロードできませんでした。')
        Sentry.captureException(e)
      }
      fileReader.readAsText(csvFile)
    })
  }

  // csvから注文情報を取得
  const getOrderDetailFromCsv = (file: File): void => {
    // csvファイルをAPIのinputの形に変更してAPI実行
    parseCsvToOrderItemInputArray(file)
      .then((orderItemInputArray) => {
        const validateResult = orderItemInputArray.map((orderItem) => {
          return validateOrderItem(orderItem)
        })
        const orderItemErrors = validateResult.filter((result) => result !== null)
        if (!!orderItemErrors.length) {
          setCsvInputError(orderItemErrors[0])
          return
        }

        guestQuotationLazyQuery({
          variables: {
            input: {
              order_items: orderItemInputArray,
            },
          },
        })
      })
      .catch((e) => {
        setFlashMessage({ type: 'error', message: e })
      })
  }

  const onChangeCsv = (file: File): void => {
    setFlashMessage(null)

    const errorMessage = validateCsv(file)
    setCsvInputError(errorMessage)
    if (!!errorMessage) return

    getOrderDetailFromCsv(file)
  }

  // アップロードしたCSVデータをリセット
  const onClickResetCsv = (): void => {
    setOrderDetail(undefined)
  }

  // csv入力方法モーダル閉じる
  const closeCsvHowToModal = (): void => {
    setIsCsvHowToModalOpen(false)
  }

  /* 撮影プランのモーダル関連処理 */
  const onClickOpenPlanModal = (type: OrderPlan): void => {
    setIsPlanModalOpen(true)
    setModalPlan(type)
  }

  const closePlanModal = (): void => {
    setIsPlanModalOpen(false)
  }

  useEffect(() => {
    if (!hash) return
    setTimeout(() => {
      const targetEl = document.getElementById(hash.slice(1))
      if (!targetEl) return
      targetEl.scrollIntoView({
        behavior: 'smooth',
      })
    }, 300)
  }, [hash])

  return (
    <>
      <Meta />
      {loading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <TopTpl
        orderDetail={orderDetail}
        csvInputError={csvInputError}
        onClickOpenCsvHowtoModal={onClickOpenCsvHowtoModal}
        onChangeCsv={onChangeCsv}
        onClickResetCsv={onClickResetCsv}
        onClickOpenPlanModal={onClickOpenPlanModal}
      />
      {isCsvHowToModalOpen && <OrderCsvHowToModal closeModal={closeCsvHowToModal} />}
      {isPlanModalOpen && <TopPlanModal closeModal={closePlanModal} plan={modalPlan} />}
    </>
  )
}
