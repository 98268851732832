import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { FlashMessage, Loading } from 'components/atoms'
import { MypageOrderDetailTpl, MypageLayout } from 'components/templates'

/* lib, types */
import { FlashMessageType } from 'types/myTypes'
import { useUserOrderDetailLazyQuery, useUserGenerateDownloadOrderCsvLinkLazyQuery, ReturnItem, Order, Invoice } from 'types/graphql'

export const MypageOrderDetail: React.FC = () => {
  const [order, setOrder] = useState<Order>()
  const [invoice, setInvoice] = useState<Invoice | null>()
  const [returnItem, setReturnItem] = useState<ReturnItem | null>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const params = useParams()

  const [userOrderDetailLazyQuery] = useUserOrderDetailLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data) return
      const order: Order = {
        id: data.UserOrderDetail.id,
        status: data.UserOrderDetail.status,
        estimated_shipment_date: data.UserOrderDetail.estimated_shipment_date,
        remarks: data.UserOrderDetail.remarks,
        is_issued_receipt: data.UserOrderDetail.is_issued_receipt,
        payment_method: data.UserOrderDetail.payment_method,
        payment_status: data.UserOrderDetail.payment_status,
        paid_at: data.UserOrderDetail.paid_at,
        created_at: data.UserOrderDetail.created_at,
        updated_at: data.UserOrderDetail.updated_at,
        download_expired_at: data.UserOrderDetail.download_expired_at,
      }
      setOrder(order)
      setInvoice(data.UserOrderDetail.invoice)
      setReturnItem(data.UserOrderDetail.return_item)
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userGenerateDownloadOrderCsvLinkLazyQuery] = useUserGenerateDownloadOrderCsvLinkLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data) return
      window.location.href = data.UserGenerateDownloadOrderCsvLink
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const onClickDownloadCsv = (): void => {
    setIsLoading(true)
    setFlashMessage(null)
    userGenerateDownloadOrderCsvLinkLazyQuery({
      variables: {
        order_id: params.id as string,
      },
    })
  }

  useEffect(() => {
    if (params.id) {
      userOrderDetailLazyQuery({
        variables: {
          order_id: params.id,
        },
      })
    }
  }, [params, userOrderDetailLazyQuery])

  return (
    <>
      <Meta title="注文詳細" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}

      <MypageLayout pageTitle="注文詳細">
        {order && returnItem && invoice && (
          <MypageOrderDetailTpl order={order} invoice={invoice} returnItem={returnItem} onClickDownloadCsv={onClickDownloadCsv} />
        )}
      </MypageLayout>
    </>
  )
}
