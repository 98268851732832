import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  title: string
}

const StyledPageTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;

  ${mediaSp`
    font-size: 2rem;
  `}
`

export const PageTitle: React.FC<Props> = ({ className = '', title }) => {
  return <StyledPageTitle className={className}>{title}</StyledPageTitle>
}
