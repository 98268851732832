import { Dispatch, SetStateAction, useState } from 'react'
import dayjs from 'dayjs'

/* lib, types, const */
import { OrderInputErrors } from 'types/myTypes'
import { validateRequiredDate } from 'lib/validate'

type OrderEstimatedShipmentDateArgs = {
  errors: OrderInputErrors
  setErrors: Dispatch<SetStateAction<OrderInputErrors>>
}

type OrderEstimatedShipmentDateStates = {
  isCalendarOpen: boolean
  estimatedShipmentDate: string | null
  setIsCalendarOpen: Dispatch<SetStateAction<boolean>>
  setEstimatedShipmentDate: Dispatch<SetStateAction<string | null>>
}

type OrderEstimatedShipmentDateValues = {
  onChangeEstimatedShipmentDate: (date: Date) => void
}

export type OrderEstimatedShipmentDateProps = OrderEstimatedShipmentDateStates & OrderEstimatedShipmentDateValues

export const useOrderEstimatedShipmentDate = (args: OrderEstimatedShipmentDateArgs): OrderEstimatedShipmentDateProps => {
  const { errors, setErrors } = args

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)
  const [estimatedShipmentDate, setEstimatedShipmentDate] = useState<string | null>(null)

  const onChangeEstimatedShipmentDate = (date: Date): void => {
    setEstimatedShipmentDate(dayjs(date).format('YYYY-MM-DD'))
    setIsCalendarOpen(false)
    setErrors({ ...errors, estimatedShipmentDate: validateRequiredDate(dayjs(date).format('YYYY-MM-DD')) })
  }

  return {
    /* state */
    isCalendarOpen,
    estimatedShipmentDate,
    setIsCalendarOpen,
    setEstimatedShipmentDate,
    /* value */
    onChangeEstimatedShipmentDate,
  }
}
