import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

/* components */
import { PageTitle, TextInput, Button, ErrorMessage } from 'components/atoms'
import { Checkbox } from 'components/molecules'

/* lib, types */
import { mediaSp } from 'lib/media-query'
import { validateRequired, validateRequiredEmail, validateRequiredPassword, validatePasswordAndPasswordConfirm } from 'lib/validate'
import { RegisterInput, RegisterInputErrors } from 'types/myTypes'

type Props = {
  registerInput: RegisterInput
  isCheckedTerms: string[]
  setRegisterState: (name: string, value: string) => void
  onChangeCheckedTerms: () => void
  onClickRegister: () => void
}

const Wrapper = styled.div`
  width: 480px;
  margin: 0 auto;
  padding: 80px 0 120px;

  .page-title {
    margin-bottom: 4.8rem;
    text-align: center;
  }

  .check-terms-wrapper {
    text-align: center;

    .note {
      width: 455px;
      font-size: 1.2rem;
      letter-spacing: 0.05em;
      margin: auto;
      padding-bottom: 24px;
      text-align: left;
      a {
        color: ${(props): string => props.theme.darkGray};
        text-decoration: underline;
      }
    }
    .check-terms {
      margin: 0 0 4.8rem;
      display: inline-block;
      label {
        font-weight: 500;
        font-size: 1.4rem;
      }
    }
  }

  .register-button {
    width: 31.2rem;
    margin: 0 auto;
    display: block;
  }

  ${mediaSp`
    width: 32.7rem;
    padding: 7.2rem 0 14rem;
    
    .check-terms-wrapper {
      .note {
        width: 100%;
        padding-bottom: 1.2rem;
      }
    }
  `}
`

const StyledInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;

  .password-confirm {
    margin-bottom: 32px;
  }

  ${mediaSp`
    .password-confirm {
      margin-bottom: 2.4rem;
    }
  `}
`

export const RegistrationTpl: React.FC<Props> = ({
  registerInput,
  isCheckedTerms,
  setRegisterState,
  onChangeCheckedTerms,
  onClickRegister,
}) => {
  const registerInitialErrorsState: RegisterInputErrors = {
    email: null,
    password: null,
    passwordConfirm: null,
  }

  const [registerErrors, setRegisterErrors] = useState<RegisterInputErrors>(registerInitialErrorsState)

  const validate = (name: string, value: string): void => {
    switch (name) {
      case 'email':
        setRegisterErrors({ ...registerErrors, [name]: validateRequiredEmail(value) })
        break
      case 'password':
        setRegisterErrors({
          ...registerErrors,
          password: validateRequiredPassword(value),
          passwordConfirm: validateRequiredPassword(value)
            ? registerErrors.passwordConfirm
            : validatePasswordAndPasswordConfirm(value, registerInput.passwordConfirm),
        })
        break
      case 'passwordConfirm':
        setRegisterErrors({
          ...registerErrors,
          [name]: validateRequired(value) || validatePasswordAndPasswordConfirm(registerInput.password, value),
        })
        break
      default:
        break
    }
  }

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setRegisterState(name, value)
    validate(name, value)
  }

  const isDisabled = (): boolean => {
    const { email, password, passwordConfirm } = registerInput
    const isRequiredItemsFilled = email && password && passwordConfirm && isCheckedTerms.includes('true')
    if (!isRequiredItemsFilled) return true

    const errorArray = Object.values(registerErrors).filter((error) => error !== null)
    if (errorArray.length > 0) return true

    return false
  }

  return (
    <Wrapper>
      <PageTitle title="新規アカウント登録" className="page-title" />
      <StyledInputWrapper>
        <TextInput
          name="email"
          value={registerInput.email}
          placeholder="メールアドレス"
          type="email"
          onChange={onChangeState}
          error={!!registerErrors.email}
        />
        {!!registerErrors.email && <ErrorMessage message={registerErrors.email} />}
      </StyledInputWrapper>
      <StyledInputWrapper>
        <TextInput
          name="password"
          value={registerInput.password}
          placeholder="パスワード"
          type="password"
          onChange={onChangeState}
          error={!!registerErrors.password}
        />
        {!!registerErrors.password && <ErrorMessage message={registerErrors.password} />}
      </StyledInputWrapper>
      <StyledInputWrapper className="password-confirm">
        <TextInput
          name="passwordConfirm"
          value={registerInput.passwordConfirm}
          placeholder="確認用パスワード"
          type="password"
          onChange={onChangeState}
          error={!!registerErrors.passwordConfirm}
        />
        {!!registerErrors.passwordConfirm && <ErrorMessage message={registerErrors.passwordConfirm} />}
      </StyledInputWrapper>
      <div className="check-terms-wrapper">
        <div className="note">
          本サービスのご利用には、
          <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
            利用規約
          </Link>
          と
          <Link to="/privacy" target="_blank" rel="noopener noreferrer">
            プライバシーポリシー
          </Link>
          をご確認いただき、
          <br className="pc" />
          同意していただく必要があります。
        </div>
        <Checkbox
          checkedValue={isCheckedTerms}
          options={[
            {
              id: 'true',
              value: 'true',
              label: '利用規約とプライバシーポリシーに同意する',
            },
          ]}
          onChange={onChangeCheckedTerms}
          className="check-terms"
        />
      </div>
      <Button buttonType="yellow" disabled={isDisabled()} onClick={onClickRegister} className="register-button">
        登録
      </Button>
    </Wrapper>
  )
}
