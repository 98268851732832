import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

/* lib, types, const */
import { mediaSp } from 'lib/media-query'
import { FAQ_URL } from 'const'
import { SERVICE_NAME } from 'const'

/* images */
import arrowTop from 'assets/images/icon/arrow-top.svg'
import { ReactComponent as LogoWhiteSvg } from 'assets/images/logo-white.svg'

type Props = {
  className?: string
}

const StyledFooter = styled.footer<Props>`
  width: 100%;

  .inner {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 2.3rem 3.2rem;
    display: flex;
    justify-content: space-between;

    .logo,
    .scroll-top {
      display: none;
    }
    nav {
      display: flex;
      gap: 3.2rem;

      a {
        font-size: 1.2rem;
        color: ${(props): string => props.theme.black};
        transition: 0.3s all;
        font-weight: 500;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    small {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${(props): string => props.theme.darkGray};
    }
  }
  ${mediaSp`
    background: ${(props): string => props.theme.footerBg};
    .inner {
      max-width: 100vw;
      padding: 2.8rem 2rem 0.4rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .logo {
        width: 14.4rem;
        height: 3.5rem;
        margin-bottom: 1.9rem;
        display: block;
        font-size: 2.5rem;
        color: ${(props): string => props.theme.white};
        font-weight: 800;
      }
      .scroll-top {
        width: 3.4rem;
        height: 3.4rem;
        background: ${(props): string => props.theme.white};
        display: block;
        border-radius: 50%;

        img {
          margin: 0 auto;
          height: 1.5rem;
        }
      }
      nav {
        width: 100%;
        margin-bottom: 1.9rem;
        display: block;

        a {
          display: block;
          line-height: 2.7rem;
          color: ${(props): string => props.theme.white};
        }
      }
      small {
        margin: 0 auto;
        font-size: 1rem;
        color: ${(props): string => props.theme.white};
      }

    }
  `}
`
export const Footer: React.FC<Props> = ({ className = '' }) => {
  const scrollTop = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <StyledFooter className={className}>
      <div className="inner">
        <LogoWhiteSvg className="logo" />

        <button className="scroll-top" onClick={scrollTop}>
          <img src={arrowTop} alt="scrolltop" />
        </button>

        <nav>
          <Link to="/terms-of-sale">特定商取引法に基づく表記</Link>
          <Link to="/terms-of-use">ご利用規約</Link>
          <Link to="/privacy">プライバシーポリシー</Link>
          <a href="https://rolo.jp/" target="_blank" rel="noreferrer">
            運営会社
          </a>
          <a className="link" href={FAQ_URL} target="_blank" rel="noopener noreferrer">
            よくあるご質問
          </a>
        </nav>

        <small>&copy;2022 {SERVICE_NAME}</small>
      </div>
    </StyledFooter>
  )
}
