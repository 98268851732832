import React, { useState, useCallback, useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import * as Theme from './theme'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import axios from 'axios'
import * as Sentry from '@sentry/react'

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

/* pages */
import {
  Top,
  Login,
  Registration,
  RegistrationAuthCode,
  ResetPasswordEmail,
  ResetPasswordCompleteEmail,
  ResetPassword,
  Order,
  OrderConfirm,
  OrderComplete,
  OrderEstimate,
  OrderInvoice,
  OrderReceipt,
  TermsOfSale,
  TermsOfUse,
  Privacy,
  NotFound,
  /* mypage */
  MypageOrders,
  MypageOrderDetail,
  MypageOrderDownload,
  MypageAccountTop,
  MypageAccountEditUser,
  MypageAccountEditAuthCode,
  MypageAccountEditReturnAddress,
  MypageAccountEditPassword,
  MypageAccountAddReturnAddress,
} from 'pages'
import { Header, SideButtons, Footer } from 'components/organisms'
import { ScrollToTop } from 'scroll-to-top'
import { ClearContext } from 'clear-context'

/* libs, const, config */
import { mediaSp } from 'lib/media-query'
import { CreateOrderContext, initialCreateOrder } from 'context'
import { CreateOrder } from './types/myTypes'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const baseStyle = require('./assets/style/base.css')
// グローバルスタイル設定
const GlobalStyle = createGlobalStyle`
  .sp {
    display: none;
    ${mediaSp`
      display: block;
    `}
  }
  .pc {
    display: block;
    ${mediaSp`
      display: none;
    `}
  }
  main {
    padding-top: 90px;
    min-height: calc(100vh - 64px);
    flex: 1;
    display: flex;
    flex-flow: column;
    &.is-document {
      padding-top: 40px;
    }
    & > div {
      flex: 1;
    }

    ${mediaSp`
      padding-top: 5.8rem;
      min-height: calc(100vh - 23.4rem);
    `}
  }
  ${baseStyle}
`

export const App: React.FC = () => {
  const [createOrder, setCreateOrder] = useState<CreateOrder | undefined>(initialCreateOrder.createOrder)
  const updateCreateOrder = useCallback((createOrder?: CreateOrder) => {
    setCreateOrder(createOrder)
  }, [])

  /* ヘッダー等非表示ページ */
  const { pathname } = window.location
  const documentPages = ['/order/estimate', '/order/invoice', '/order/receipt']
  const isDocumentPage = documentPages.some((value) => pathname.includes(value))

  /* apollo config */
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: process.env.REACT_APP_API_ENDPOINT,
      // これがないとtokenあるのにunauthenticatedが返ってくる
      credentials: 'include',
      // authorization headerでtokenを指定しなくてもログイン時にset-cookieでUserAuthTokenを返してくれる
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    },
  })

  useEffect(() => {
    /* sanctum auth */
    const getCsrfCookie = async (): Promise<void> => {
      try {
        const apiClient = axios.create({
          baseURL: process.env.REACT_APP_API_BASE_URL,
          withCredentials: true,
        })
        await apiClient.get('/sanctum/csrf-cookie')
      } catch (e) {
        Sentry.captureException(e)
      }
    }
    getCsrfCookie()
  }, [])

  return (
    <ApolloProvider client={client}>
      <CookiesProvider>
        <CreateOrderContext.Provider value={{ createOrder, updateCreateOrder }}>
          <ThemeProvider theme={Theme.theme}>
            <GlobalStyle theme={Theme.theme} />
            <BrowserRouter>
              <ScrollToTop />
              <ClearContext />
              {!isDocumentPage && <Header />}
              <main className={isDocumentPage ? 'is-document' : ''}>
                <Routes>
                  <Route path="/" element={<Top />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/registration" element={<Registration />} />
                  <Route path="/registration/auth-code" element={<RegistrationAuthCode />} />
                  <Route path="/reset-password/email" element={<ResetPasswordEmail />} />
                  <Route path="/reset-password/complete-email" element={<ResetPasswordCompleteEmail />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/order" element={<Order />} />
                  <Route path="/order/confirm" element={<OrderConfirm />} />
                  <Route path="/order/complete" element={<OrderComplete />} />
                  <Route path="/order/estimate" element={<OrderEstimate />} />
                  <Route path="/order/invoice" element={<OrderInvoice />} />
                  <Route path="/order/receipt/:id" element={<OrderReceipt />} />
                  <Route path="/mypage/order" element={<MypageOrders />} />
                  <Route path="/mypage/order/:id" element={<MypageOrderDetail />} />
                  <Route path="/mypage/order/download/:id" element={<MypageOrderDownload />} />
                  <Route path="/mypage/account" element={<MypageAccountTop />} />
                  <Route path="/mypage/account/edit/user" element={<MypageAccountEditUser />} />
                  <Route path="/mypage/account/edit/auth-code" element={<MypageAccountEditAuthCode />} />
                  <Route path="/mypage/account/edit/return-address" element={<MypageAccountEditReturnAddress />} />
                  <Route path="/mypage/account/edit/password" element={<MypageAccountEditPassword />} />
                  <Route path="/mypage/account/add/return-address" element={<MypageAccountAddReturnAddress />} />
                  <Route path="/terms-of-sale" element={<TermsOfSale />} />
                  <Route path="/terms-of-use" element={<TermsOfUse />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/order/failed" element={<Navigate replace to="/order" state={{ redirectFrom: '/order/failed' }} />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </main>
              {!isDocumentPage && <SideButtons />}
              {!isDocumentPage && <Footer />}
            </BrowserRouter>
          </ThemeProvider>
        </CreateOrderContext.Provider>
      </CookiesProvider>
    </ApolloProvider>
  )
}
export default App
