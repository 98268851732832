import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { FlashMessage, Loading } from 'components/atoms'
import { DocumentLayout, OrderReceiptTpl } from 'components/templates'

/* lib, types */
import { useValidateToken } from 'lib/validate-token'
import { useUserPublishReceiptMutation, Receipt } from 'types/graphql'
import { FlashMessageType } from 'types/myTypes'

export const OrderReceipt: React.FC = () => {
  const [receipt, setReceipt] = useState<Receipt>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const params = useParams()

  const [userPublishReceiptMutation] = useUserPublishReceiptMutation({
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data) return
      setReceipt(data.UserPublishReceipt)
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: e.message ?? 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  // ヘッダーなしのページなので個別に読み込み
  useValidateToken()

  useEffect(() => {
    if (!params.id) {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      return
    }

    userPublishReceiptMutation({
      variables: {
        input: {
          order_id: params.id,
        },
      },
    })
  }, [params.id, userPublishReceiptMutation])

  return (
    <>
      <Meta title="領収書" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      {receipt && (
        <DocumentLayout title={`領収書${receipt?.is_issued_receipt ? '(再発行)' : ''}`}>
          <OrderReceiptTpl receipt={receipt} />
        </DocumentLayout>
      )}
    </>
  )
}
