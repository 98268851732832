import { Dispatch, SetStateAction, useState } from 'react'

type OrderOtherTextStates = {
  otherText: string
  setOtherText: Dispatch<SetStateAction<string>>
}

type OrderOtherTextValues = {
  onChangeOtherText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export type OrderOtherTextProps = OrderOtherTextStates & OrderOtherTextValues

export const useOrderOtherText = (): OrderOtherTextProps => {
  const [otherText, setOtherText] = useState<string>('')

  const onChangeOtherText = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setOtherText(e.target.value)
  }

  return {
    /* state */
    otherText,
    setOtherText,
    /* value */
    onChangeOtherText,
  }
}
