import { Dispatch, SetStateAction, useState } from 'react'
import * as Sentry from '@sentry/react'

/* lib, types, const */
import {
  ReturnAddress,
  useUserUpsertReturnAddressMutation,
  useUserDeleteReturnAddressMutation,
  useUserReturnAddressesLazyQuery,
  Exact,
  UserReturnAddressesQuery,
} from 'types/graphql'
import { AddressInput, OrderInputErrors, FlashMessageType } from 'types/myTypes'
import {
  validateRequiredPostCode,
  validateKana,
  validateAddressInput,
  validateCompanyNameWithCompanyNameKana,
  validateCompanyNameKanaWithCompanyName,
} from 'lib/validate'
import { getAddressFromPostCode } from 'lib/post-code-to-address'
import { LazyQueryResult, QueryLazyOptions } from '@apollo/client'

type OrderReturnAddressArgs = {
  userInput: AddressInput
  addressInitialInput: AddressInput
  errors: OrderInputErrors
  setFlashMessage: Dispatch<SetStateAction<FlashMessageType | null>>
  setErrors: Dispatch<SetStateAction<OrderInputErrors>>
}

type OrderReturnAddressStates = {
  checkedReturnAddressId: string
  returnAddresses: ReturnAddress[]
  returnAddressOptions: { id?: string; name?: string; address: ReturnAddress; onChange: (value: string) => void }[]
  isCheckedSame: string[]
  returnAddressInputForNew: AddressInput
  returnAddressInputForEdit: AddressInput
  returnAddressInputForAdd: AddressInput
  setCheckedReturnAddressId: Dispatch<SetStateAction<string>>
  setReturnAddresses: Dispatch<SetStateAction<ReturnAddress[]>>
  setReturnAddressOptions: Dispatch<
    SetStateAction<{ id?: string; name?: string; address: ReturnAddress; onChange: (value: string) => void }[]>
  >
  setIsCheckedSame: Dispatch<SetStateAction<string[]>>
  setReturnAddressInputForNew: Dispatch<SetStateAction<AddressInput>>
  setReturnAddressInputForEdit: Dispatch<SetStateAction<AddressInput>>
  setReturnAddressInputForAdd: Dispatch<SetStateAction<AddressInput>>
}

type OrderReturnAddressValues = {
  userReturnAddressesLazyQueryLoading: boolean
  userUpsertReturnAddressMutationLoding: boolean
  userDeleteReturnAddressMutationLoading: boolean
  userReturnAddressesLazyQuery: (
    options?:
      | QueryLazyOptions<
          Exact<{
            [key: string]: never
          }>
        >
      | undefined,
  ) => Promise<
    LazyQueryResult<
      UserReturnAddressesQuery,
      Exact<{
        [key: string]: never
      }>
    >
  >
  onChangeCheckSame: () => void
  onChangeReturnAddressForNew: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => Promise<void>
  onClickEditReturnAddress: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void
  onChangeReturnAddressInputForEdit: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => Promise<void>
  onClickCompleteEditReturnAddress: (id: string) => void
  onChangeReturnAddressInputForAdd: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => Promise<void>
  onClickCancelAddReturnAddress: () => void
  onClickCompleteAddReturnAddress: () => void
  onClickDeleteReturnAddress: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void
}

export type OrderReturnAddressProps = OrderReturnAddressStates & OrderReturnAddressValues

export const useOrderReturnAddress = (args: OrderReturnAddressArgs): OrderReturnAddressProps => {
  const { userInput, addressInitialInput, errors, setFlashMessage, setErrors } = args

  const [checkedReturnAddressId, setCheckedReturnAddressId] = useState<string>('')
  const [returnAddresses, setReturnAddresses] = useState<ReturnAddress[]>([])
  const [returnAddressOptions, setReturnAddressOptions] = useState<
    { id?: string; name?: string; address: ReturnAddress; onChange: (value: string) => void }[]
  >([])
  const [isCheckedSame, setIsCheckedSame] = useState<string[]>(['false'])
  const [returnAddressInputForNew, setReturnAddressInputForNew] = useState<AddressInput>(addressInitialInput)
  const [returnAddressInputForEdit, setReturnAddressInputForEdit] = useState<AddressInput>(addressInitialInput)
  const [returnAddressInputForAdd, setReturnAddressInputForAdd] = useState<AddressInput>(addressInitialInput)

  const [userReturnAddressesLazyQuery, { loading: userReturnAddressesLazyQueryLoading }] = useUserReturnAddressesLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data) return
      setReturnAddresses(data.UserReturnAddresses as ReturnAddress[])
      if (data.UserReturnAddresses.length === 0) return
      const returnAddressOptions = data.UserReturnAddresses.map((address) => ({
        address,
        onChange: (value: string) => setCheckedReturnAddressId(value),
      }))
      setReturnAddressOptions(
        returnAddressOptions as { id?: string; name?: string; address: ReturnAddress; onChange: (value: string) => void }[],
      )

      // ページレンダリング時 or 選択している住所を削除した時は一番上の住所を選択するように
      const returnAddressIds = data.UserReturnAddresses.map((address) => address?.id)
      if (!returnAddressIds.includes(checkedReturnAddressId)) {
        setCheckedReturnAddressId(data.UserReturnAddresses[0]?.id as string)
      }
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userUpsertReturnAddressMutation, { loading: userUpsertReturnAddressMutationLoding }] = useUserUpsertReturnAddressMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      userReturnAddressesLazyQuery()
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: '返送先住所の変更に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userDeleteReturnAddressMutation, { loading: userDeleteReturnAddressMutationLoading }] = useUserDeleteReturnAddressMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      userReturnAddressesLazyQuery()
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: '削除に失敗しました' })
      Sentry.captureException(e)
    },
  })

  // 返送先がご注文者と同じ場合のチェック処理
  const onChangeCheckSame = (): void => {
    if (isCheckedSame.includes('false')) {
      setReturnAddressInputForNew(userInput)
      setIsCheckedSame(['true'])
    } else {
      setReturnAddressInputForNew(addressInitialInput)
      setIsCheckedSame(['false'])
    }
  }

  // 新規注文時バリデーション
  const validateReturnAddressForNew = (name: string, value: string): void => {
    switch (name) {
      case 'name':
      case 'prefecture':
      case 'address':
      case 'name_kana':
      case 'telephone_number':
      case 'post_code':
        setErrors({ ...errors, returnAddressForNew: { ...errors.returnAddressForNew, [name]: validateAddressInput(name, value) } })
        break
      case 'company_name':
        setErrors({
          ...errors,
          returnAddressForNew: {
            ...errors.returnAddressForNew,
            company_name: validateCompanyNameWithCompanyNameKana(value, returnAddressInputForNew.company_name_kana),
            company_name_kana: validateCompanyNameKanaWithCompanyName(value, returnAddressInputForNew.company_name_kana),
          },
        })
        break
      case 'company_name_kana':
        setErrors({
          ...errors,
          returnAddressForNew: {
            ...errors.returnAddressForNew,
            company_name: validateCompanyNameWithCompanyNameKana(returnAddressInputForNew.company_name, value),
            company_name_kana: validateCompanyNameKanaWithCompanyName(returnAddressInputForNew.company_name, value) || validateKana(value),
          },
        })
        break
      default:
        break
    }
  }

  // 新規注文時入力＆郵便番号から住所自動入力
  const onChangeReturnAddressForNew = async (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ): Promise<void> => {
    const { name, value } = e.target
    setReturnAddressInputForNew({ ...returnAddressInputForNew, [name]: value })
    validateReturnAddressForNew(name, value)

    if (name === 'post_code') {
      if (!validateRequiredPostCode(value)) {
        const data = await getAddressFromPostCode(value)
        if (data) {
          setReturnAddressInputForNew({
            ...returnAddressInputForNew,
            [name]: value,
            prefecture: data.address1,
            address: `${data.address2}${data.address3}`,
          })
        }
      }
    }
  }

  // 変更フォーム表示
  const onClickEditReturnAddress = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string): void => {
    // 変更ボタンクリックでStyledRadioWrapperのonClickが動いて選択肢が変わらないように
    e.stopPropagation()

    const targetAddress = returnAddresses.filter((address) => address.id === id)[0]
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: addressId, ...targetAddressWithoutId } = targetAddress
    setReturnAddressInputForEdit(targetAddressWithoutId as AddressInput)
  }

  // 変更フォーム入力
  const onChangeReturnAddressInputForEdit = async (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ): Promise<void> => {
    const { name, value } = e.target
    setReturnAddressInputForEdit({ ...returnAddressInputForEdit, [name]: value })

    if (name === 'post_code') {
      if (!validateRequiredPostCode(value)) {
        const data = await getAddressFromPostCode(value)
        if (data) {
          setReturnAddressInputForEdit({
            ...returnAddressInputForEdit,
            [name]: value,
            prefecture: data.address1,
            address: `${data.address2}${data.address3}`,
          })
        }
      }
    }
  }

  // 変更登録処理
  const onClickCompleteEditReturnAddress = (id: string): void => {
    setFlashMessage(null)
    userUpsertReturnAddressMutation({
      variables: {
        input: {
          business_return_address_id: id,
          name: returnAddressInputForEdit.name,
          name_kana: returnAddressInputForEdit.name_kana,
          company_name: returnAddressInputForEdit.company_name,
          company_name_kana: returnAddressInputForEdit.company_name_kana,
          department: returnAddressInputForEdit.department,
          store_name: returnAddressInputForEdit.store_name,
          telephone_number: returnAddressInputForEdit.telephone_number,
          post_code: returnAddressInputForEdit.post_code,
          prefecture: returnAddressInputForEdit.prefecture,
          address: returnAddressInputForEdit.address,
          building_name: returnAddressInputForEdit.building_name,
        },
      },
    })
  }

  // 追加フォーム入力
  const onChangeReturnAddressInputForAdd = async (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ): Promise<void> => {
    const { name, value } = e.target
    setReturnAddressInputForAdd({ ...returnAddressInputForAdd, [name]: value })

    if (name === 'post_code') {
      if (!validateRequiredPostCode(value)) {
        const data = await getAddressFromPostCode(value)
        if (data) {
          setReturnAddressInputForAdd({
            ...returnAddressInputForAdd,
            [name]: value,
            prefecture: data.address1,
            address: `${data.address2}${data.address3}`,
          })
        }
      }
    }
  }

  // 追加キャンセル
  const onClickCancelAddReturnAddress = (): void => {
    setReturnAddressInputForAdd(addressInitialInput)
  }

  // 追加登録処理
  const onClickCompleteAddReturnAddress = (): void => {
    setFlashMessage(null)
    setReturnAddressInputForAdd(addressInitialInput)
    userUpsertReturnAddressMutation({
      variables: {
        input: {
          name: returnAddressInputForAdd.name,
          name_kana: returnAddressInputForAdd.name_kana,
          company_name: returnAddressInputForAdd.company_name,
          company_name_kana: returnAddressInputForAdd.company_name_kana,
          department: returnAddressInputForAdd.department,
          store_name: returnAddressInputForAdd.store_name,
          telephone_number: returnAddressInputForAdd.telephone_number,
          post_code: returnAddressInputForAdd.post_code,
          prefecture: returnAddressInputForAdd.prefecture,
          address: returnAddressInputForAdd.address,
          building_name: returnAddressInputForAdd.building_name,
        },
      },
    })
  }

  // 削除処理
  const onClickDeleteReturnAddress = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string): void => {
    // 削除ボタンクリックでStyledRadioWrapperのonClickが動いて選択肢が変わらないように
    e.stopPropagation()

    const confirmRes = confirm('返送先住所を削除しますか？')
    if (!confirmRes) return

    setFlashMessage(null)
    userDeleteReturnAddressMutation({
      variables: {
        input: {
          business_return_address_id: id,
        },
      },
    })
  }

  return {
    /* state */
    checkedReturnAddressId,
    returnAddresses,
    returnAddressOptions,
    isCheckedSame,
    returnAddressInputForNew,
    returnAddressInputForEdit,
    returnAddressInputForAdd,
    setCheckedReturnAddressId,
    setReturnAddresses,
    setReturnAddressOptions,
    setIsCheckedSame,
    setReturnAddressInputForNew,
    setReturnAddressInputForEdit,
    setReturnAddressInputForAdd,
    /* value */
    userReturnAddressesLazyQueryLoading,
    userUpsertReturnAddressMutationLoding,
    userDeleteReturnAddressMutationLoading,
    userReturnAddressesLazyQuery,
    onChangeCheckSame,
    onChangeReturnAddressForNew,
    onClickEditReturnAddress,
    onChangeReturnAddressInputForEdit,
    onClickCompleteEditReturnAddress,
    onChangeReturnAddressInputForAdd,
    onClickCancelAddReturnAddress,
    onClickCompleteAddReturnAddress,
    onClickDeleteReturnAddress,
  }
}
