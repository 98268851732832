import dayjs from 'dayjs'
import { OrderItemInput, OrderPhotoExtension } from 'types/graphql'

export const validateRequired = (value: string): string | null => {
  let message = null

  if (value === '') {
    message = '必須項目です'
  }
  return message
}

export const validateKana = (kana: string): string | null => {
  const re = /^[ぁ-んー 　]+$/
  let message = null

  if (kana && !re.test(kana)) {
    message = 'ふりがなの形式が違います'
  }
  return message
}

export const validateRequiredKana = (kana: string): string | null => {
  let message = null

  if (validateRequired(kana)) {
    message = validateRequired(kana)
  } else if (validateKana(kana)) {
    message = validateKana(kana)
  }
  return message
}

export const validateRequiredTelephoneNumber = (telephoneNumber: string): string | null => {
  const re = /^0[0-9]{9,10}$/
  let message = null

  if (telephoneNumber === '') {
    message = '必須項目です'
  } else if (!re.test(telephoneNumber)) {
    message = '電話番号の形式が違います'
  }
  return message
}

export const validateRequiredPostCode = (postCode: string): string | null => {
  const re = /^\d{7}$/
  let message = null

  if (postCode === '') {
    message = '必須項目です'
  } else if (!re.test(postCode)) {
    message = '郵便番号の形式が違います'
  }
  return message
}

export const validateCsv = (file: File): string | null => {
  const type = file.type
  let message = null

  if (type !== 'text/csv') {
    message = '.csvファイルを選択してください'
  }

  return message
}

export const validateCompanyNameWithCompanyNameKana = (companyName?: string, companyNameKana?: string): string | null => {
  let message = null

  if (!companyName && companyNameKana) {
    message = '会社名(ふりがな)を入力した場合、会社名は必須です'
  }
  return message
}

export const validateCompanyNameKanaWithCompanyName = (companyName?: string, companyNameKana?: string): string | null => {
  let message = null

  if (companyName && !companyNameKana) {
    message = '会社名を入力した場合、会社名(ふりがな)は必須です'
  }
  return message
}

export const validateOrderItem = (orderItem: OrderItemInput): string | null => {
  const { cut_num, has_movie, extension } = orderItem

  const cutNumExists = !!cut_num && cut_num !== 0
  const correctExtensions = Object.values(OrderPhotoExtension).map((extension) => extension.toLocaleLowerCase())

  if (!cutNumExists && !has_movie) {
    return '商品撮影カット数、動画撮影のいずれかは必須です'
  }
  if (cutNumExists && !extension) {
    return '商品撮影カット数を入力した場合、拡張子は必須です'
  }
  if (!!extension && !correctExtensions.includes(extension)) {
    return '拡張子は「png」「jpeg」「tiff」の中からご指定ください'
  }

  return null
}

export const validateRequiredEmail = (email: string): string | null => {
  const re = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
  let message = null

  if (!email) {
    message = '必須項目です'
  } else if (!re.test(email)) {
    message = 'メールアドレスの形式が違います'
  }
  return message
}

export const validateRequiredDate = (date: string): string | null => {
  let message = null

  if (!date) {
    message = '必須項目です'
  } else if (!dayjs(date).isValid()) {
    message = '日付の形式が違います'
  }
  return message
}

export const validateRequiredPassword = (password: string): string | null => {
  // 8文字以上の半角英数字記号
  const re = /^([\x21-\x7e]{8,})$/
  let message = null

  if (password === '') {
    message = '必須項目です'
  } else if (!re.test(password)) {
    message = 'パスワードは半角英数字記号を8桁以上で入力してください'
  }
  return message
}

export const validatePasswordAndPasswordConfirm = (password: string, passwordConfirm?: string): string | null => {
  let message = null

  if (!!password && !!passwordConfirm && password !== passwordConfirm) {
    message = 'パスワードと確認用のパスワードが合致しません'
  }

  return message
}

export const validateRequiredAuthCode = (authCode: string): string | null => {
  const re = /^([0-9]{6})$/
  let message = null

  if (authCode === '') {
    message = '必須項目です'
  } else if (!re.test(authCode)) {
    message = '認証コードの形式が違います'
  }

  return message
}

export const validateAddressInput = (name: string, value: string): string | null => {
  switch (name) {
    case 'name':
    case 'prefecture':
    case 'address':
      return validateRequired(value)
    case 'name_kana':
      return validateRequiredKana(value)
    case 'telephone_number':
      return validateRequiredTelephoneNumber(value)
    case 'post_code':
      return validateRequiredPostCode(value)
    default:
      return null
  }
}
