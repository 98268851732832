import { createContext } from 'react'
import { CreateOrder as CreateOrderType } from './types/myTypes'

export type CreateOrder = {
  createOrder?: CreateOrderType
  updateCreateOrder: (order?: CreateOrderType) => void
}

export const initialCreateOrder: CreateOrder = {
  createOrder: undefined,
  updateCreateOrder: () => {},
}

export const CreateOrderContext = createContext(initialCreateOrder)
