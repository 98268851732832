import React from 'react'
import styled from 'styled-components'

/* images */
import checkboxIcon from 'assets/images/icon/checkbox.png'

type Props = {
  className?: string
  options: { id?: string; name?: string; value: string; label: string }[]
  checkedValue: string[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Wrapper = styled.div``

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`
const StyledCheckbox = styled.input`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.2rem;
  border: 1px solid ${(props): string => props.theme.black};
  border-radius: 4px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:checked {
    background: url(${checkboxIcon}) center no-repeat;
    background-size: 1.3rem 1.04rem;
  }
`

const StyledLabel = styled.label`
  position: relative;
  font-size: 1.2rem;
  line-height: 2.5rem;
  display: block;
  cursor: pointer;
`

export const Checkbox: React.FC<Props> = ({ className = '', options, checkedValue, onChange }) => {
  return (
    <Wrapper className={className}>
      {options.map((option) => (
        <CheckboxWrapper key={option.value}>
          <StyledCheckbox
            type="checkbox"
            checked={checkedValue.includes(option.value)}
            onChange={onChange}
            id={option.id}
            name={option.name}
            value={option.value}
          />
          <StyledLabel htmlFor={option.id}>{option.label}</StyledLabel>
        </CheckboxWrapper>
      ))}
    </Wrapper>
  )
}
