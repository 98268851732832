import React from 'react'
import styled, { css } from 'styled-components'
import { mediaPc } from '../../../lib/media-query'

type Props = {
    className?: string
    subtitle?: string
    withoutSubtitle?: boolean
}

const StyledTermsSubtitle = styled.h3<Props>`
  font-size: 3.4vw;
  color: ${(props): string => props.theme.black};
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 1.62;

  ${mediaPc`
    width: 17%;
    font-size: 13px;
  `}

  ${(props) =>
    props.withoutSubtitle &&
    css`
      display: none;
    `}
`

export const TermsSubtitle: React.FC<Props> = ({ className = '', subtitle = '', withoutSubtitle = false }) => {
    return (
        <StyledTermsSubtitle className={className} withoutSubtitle={withoutSubtitle}>
            {subtitle}
        </StyledTermsSubtitle>
    )
}
