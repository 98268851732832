import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

/* components */
import { CorporateInfo } from 'components/organisms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { Receipt } from 'types/graphql'

type Props = {
  receipt: Receipt
}

const Wrapper = styled.div`
  padding: 80px 15% 0;

  .address {
    margin-bottom: 40px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    .name-wrapper {
      display: flex;
      align-items: flex-end;

      .name-space {
        width: 25vw;
        margin-right: 10px;
        border-bottom: 1px solid ${(props): string => props.theme.black};
      }
    }
  }

  .total {
    width: 35vw;
    height: 7rem;
    margin: 0 auto 4rem;
    padding: 0 2rem;
    border: 1px solid ${(props): string => props.theme.black};
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
      font-size: 2.1rem;
    }
  }

  .bottom-area {
    margin-bottom: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .subtotal-wrapper {
      width: 30vw;
      padding: 1rem 0;
      border: solid ${(props): string => props.theme.gray};
      border-width: 0.1rem 0;

      .row {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        line-height: 1.7;

        &.tax {
          margin-bottom: 0.8rem;
        }
      }

      .proviso {
        font-size: 1.2rem;
      }
    }

    .corporate {
      p {
        font-size: 1.4rem;
        margin: 0 0 0.5rem;
      }
    }
  }

  ${mediaSp`
    padding: 7.2rem 2rem 0;

    .address {
      margin-bottom: 40px;
      font-size: 1.2rem;
      display: block;

      .name-wrapper {
        margin-bottom: 2rem;
        display: flex;
        align-items: flex-end;

        .name-space {
          width: 50vw;
          margin-right: 1rem;
        }
      }

      .date-wrapper {
        text-align: right;
      }
    }

    .total {
      width: 70vw;
    }

    .bottom-area {
      margin-bottom: 80px;
      display: block;

      .subtotal-wrapper {
        width: 100%;
        margin-bottom: 3rem;

        .proviso {
          font-size: 12px;
        }
      }

      .corporate {
        display: flex;
        justify-content: flex-end;
      }
    }
  `}

  @media print {
    padding: 80px 3vw 0;

    .title {
      margin-bottom: 3rem;
      text-align: center;
    }

    .address {
      margin-bottom: 30px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;

      .name-wrapper {
        display: flex;
        align-items: flex-end;

        .name-space {
          width: 30vw;
          margin-right: 10px;
          border-bottom: 1px solid ${(props): string => props.theme.black};
        }
      }
    }

    .total {
      width: 45vw;
      height: 7rem;
      margin: 0 auto 30px;
      padding: 0 2rem;
      border: 1px solid ${(props): string => props.theme.black};
      display: flex;
      justify-content: space-between;
      align-items: center;

      > p {
        font-size: 2.1rem;
      }
    }

    .bottom-area {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .subtotal-wrapper {
        width: 40vw;
        margin-bottom: 0;
        padding: 1rem 0;
        border: solid ${(props): string => props.theme.gray};
        border-width: 0.1rem 0;

        .row {
          display: flex;
          justify-content: space-between;
          font-size: 1.4rem;
          line-height: 1.7;

          &.tax {
            margin-bottom: 0.8rem;
          }
        }

        .proviso {
          font-size: 1.2rem;
        }
      }

      .corporate {
        display: block;

        p {
          font-size: 1.4rem;
          margin: 0 0 0.5rem;
        }
      }
    }
  }
`

export const OrderReceiptTpl: React.FC<Props> = ({ receipt }) => {
  return (
    <Wrapper>
      <div className="address">
        <div className="name-wrapper">
          <div className="name-space" />
          <p className="honorific-title">御中</p>
        </div>
        <div className="date-wrapper">
          <p>発行日: {dayjs().format('YYYY/MM/DD')}</p>
          <p>領収書番号: {receipt.receipt_number}</p>
        </div>
      </div>
      <div className="total">
        <p>合計金額</p>
        <p>{receipt.invoice?.total?.toLocaleString()}円</p>
      </div>
      <div className="bottom-area">
        <div className="subtotal-wrapper">
          <div className="row">
            <p>小計</p>
            <p>{receipt.invoice?.subtotal?.toLocaleString()}円</p>
          </div>
          <div className="row tax">
            <p>消費税</p>
            <p>{receipt.invoice?.consumption_tax?.toLocaleString()}円</p>
          </div>
          <div className="row">
            <p>(内訳)</p>
          </div>
          <div className="row">
            <p>10%対象</p>
            <p>
              {receipt.invoice?.subtotal?.toLocaleString()}円(消費税 {receipt.invoice?.consumption_tax?.toLocaleString()}円)
            </p>
          </div>
          <p className="proviso">商品撮影代</p>
        </div>
        <div className="corporate">
          <CorporateInfo showEmail={false} />
        </div>
      </div>
    </Wrapper>
  )
}
