import React from 'react'
import styled from 'styled-components'

/* components */
import { Pagination } from 'components/molecules'
import { OrderListItem } from 'components/organisms'

/* lib, types*/
import { UserOrder } from 'types/graphql'
import { mediaSp } from 'lib/media-query'

type Props = {
  orders: UserOrder[]
  currentPage: number
  lastPage: number
}

const Wrapper = styled.div`
  margin: 16px auto 0;
  padding: 0 0 12rem;
  .pagination {
    margin: 6.4rem 0 0;
  }
  .no-data {
    padding: 6.4rem 0 0;
    font-size: 16px;
  }

  ${mediaSp`
    width: 33.5rem;
    margin: 3.2rem auto 0;
    .no-data {
      padding: 4rem 0 0;
      font-size: 1.4rem;
    }
  `}
`

export const MypageOrdersTpl: React.FC<Props> = ({ orders, currentPage, lastPage }) => {
  return (
    <Wrapper>
      {orders && orders.length > 0 ? (
        <>
          {orders.map((order, index) => (
            <OrderListItem order={order} key={index} />
          ))}
          <Pagination className="pagination" currentPage={currentPage} lastPage={lastPage} />
        </>
      ) : (
        <div className="no-data">注文履歴はありません</div>
      )}
    </Wrapper>
  )
}
