import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'

/* components */
import { Button, TextInput, Selectbox } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { honorificTitleList } from 'lib/select-options'
import { Invoice, PaymentStatus } from 'types/graphql'

type Props = {
  className?: string
  orderId?: string
  orderDetail: Invoice
  orderPaymentStatus?: PaymentStatus
  isOrderFinished?: boolean
  showReset?: boolean
  companyName?: string
  name?: string
  onClickReset?: () => void
}

const Wrapper = styled.div`
  padding: 48px 0;
  background: ${(props): string => props.theme.lightGray};
  border-radius: 20px;

  .reset-button {
    margin: 0 34px 32px auto;
    width: 181px;
    height: 40px;
    font-size: 14px;
    display: block;
  }

  .inner {
    width: 730px;
    margin: 0 auto;

    .detail {
      margin-bottom: 21px;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;

      .name {
        width: 200px;
      }
      .quantity {
        width: 170px;
      }
      .unit-price {
        width: 180px;
      }
      .total {
        width: 180px;
      }

      thead {
        font-weight: 500;

        th {
          padding-bottom: 1.5rem;
          border-bottom: 0.2rem solid ${(props): string => props.theme.darkGray};

          &.quantity,
          &.unit-price {
            text-align: center;
          }
          &.total {
            text-align: right;
          }

          &.quantity,
          &.unit-price {
            padding-left: 90px;
          }
        }
      }

      tbody {
        tr {
          line-height: 72px;
          border-bottom: 0.1rem solid ${(props): string => props.theme.gray};

          td {
            &.quantity,
            &.unit-price,
            &.total {
              text-align: right;
            }
          }
        }
      }
    }

    .total {
      width: 240px;
      margin: 0 0 64px auto;

      .row {
        padding: 1.6rem 0;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        border-bottom: 0.1rem solid ${(props): string => props.theme.black};
        display: flex;
        justify-content: space-between;

        &:last-child {
          padding-bottom: 0;
          font-size: 24px;
          border-bottom: 0;
          p {
            font-weight: 500;
          }
        }
      }
    }

    .estimate {
      .input-wrapper {
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;

        .name-on-estimate {
          width: 528px;
        }

        .honorific-title {
          width: 176px;
        }
      }

      .issue-estimate-button {
        width: 277px;
        margin: 0 0 0 auto;
        display: block;
      }
    }

    .issue-buttons {
      margin: 0 0 0 auto;
      display: flex;
      justify-content: flex-end;

      .issue-button {
        width: 277px;

        & + .issue-button {
          margin: 0 0 0 26px;
        }
      }
    }
  }

  ${mediaSp`
    padding: 3.2rem 0 4rem;
    border-radius: 1rem;

    .reset-button {
      margin: 0 1.5rem 3.2rem auto;
      width: 14.1rem;
      height: 3.2rem;
      font-size: 1.1rem;
    }

    .inner {
      width: 100%;
      margin: 0 auto;

      .detail {
        margin-bottom: 2.4rem;
        padding: 0 2rem;
        font-size: 1.2rem;
        overflow-x: scroll;

        .name {
          width: 14rem;
          min-width: 14rem;
        }
        .quantity {
          width: 11.9rem;
          min-width: 11.9rem;
        }
        .unit-price {
          width: 12.6rem;
          min-width: 12.6rem;
        }
        .total {
          width: 12.6rem;
          min-width: 12.6rem;
        }

        thead {
          th {
            &.quantity,
            &.unit-price {
              padding-left: 6.3rem;
            }
          }
        }

        tbody {
          tr {
            line-height: 5.2rem;
          }
        }
      }

      .total {
        width: 29.5rem;
        margin: 0 auto 3.3rem;

        .row {
          font-size: 1.4rem;

          &:last-child {
            font-size: 1.6rem;
          }
        }
      }

      .estimate {
        .input-wrapper {
          width: 29.5rem;
          margin: 0 auto 1.6rem;
          display: block;

          .name-on-estimate {
            width: 100%;
            margin-bottom: 0.8rem;
          }

          .honorific-title {
            width: 11.6rem;
            margin-left: 17.9rem;
          }
        }

        .issue-estimate-button {
          width: 21.6rem;
          height: 4.8rem;
          margin: 0 auto;
        }
      }

      .issue-buttons {
        width: 21.6rem;
        margin: 0 auto;
        display: block;

        .issue-button {
          width: 100%;

          & + .issue-button {
            margin: 2.6rem 0 0 0;
          }
        }
      }
    }
  `}
`

export const OrderDetailTable: React.FC<Props> = ({
  className = '',
  orderId,
  orderDetail,
  orderPaymentStatus,
  isOrderFinished = false,
  showReset = false,
  companyName,
  name,
  onClickReset,
}) => {
  const [nameOnDocument, setNameOnDocument] = useState<string>(companyName ? companyName : name ? name : '')
  const [honorificTitle, setHonorificTitle] = useState<'御中' | '様'>(companyName ? '御中' : name ? '様' : '御中')
  const [, setCookie] = useCookies(['nameOnDocument', 'honorificTitle', 'orderDetail'])

  const saveCookie = (): void => {
    setCookie('nameOnDocument', JSON.stringify(nameOnDocument), { path: '/' })
    setCookie('honorificTitle', JSON.stringify(honorificTitle), { path: '/' })
    setCookie('orderDetail', JSON.stringify(orderDetail), { path: '/' })
  }

  const goToEstimate = (): void => {
    saveCookie()
    window.open(`/order/estimate`, '_blank', 'noreferrer')
  }

  const goToInvoice = (): void => {
    saveCookie()
    window.open(`/order/invoice`, '_blank', 'noreferrer')
  }

  const goToReceipt = (): void => {
    window.open(`/order/receipt/${orderId}`, '_blank', 'noreferrer')
  }

  return (
    <Wrapper className={className}>
      {showReset && (
        <Button buttonType="darkGray" onClick={onClickReset} className="reset-button">
          データをリセット
        </Button>
      )}
      <div className="inner">
        <div className="detail">
          <table>
            <thead>
              <tr>
                <th className="name">商品名</th>
                <th className="quantity">数量</th>
                <th className="unit-price">単価</th>
                <th className="total">金額</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="name">商品コード数</td>
                <td className="quantity">{orderDetail.product_code_count}点</td>
              </tr>
              {orderDetail.invoice_items?.map((item, index) => (
                <tr key={index}>
                  <td className="name">{item?.product_name}</td>
                  <td className="quantity">
                    {item?.quantity}
                    {item?.unit_name}
                  </td>
                  <td className="unit-price">¥ {item?.unit_price?.toLocaleString()}</td>
                  <td className="total">¥ {item?.price?.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="total">
          <div className="row">
            <p>小計</p>
            <p>¥ {orderDetail.subtotal?.toLocaleString()}</p>
          </div>
          <div className="row">
            <p>消費税</p>
            <p>¥ {orderDetail.consumption_tax?.toLocaleString()}</p>
          </div>
          <div className="row">
            <p>合計</p>
            <p>¥ {orderDetail.total?.toLocaleString()}</p>
          </div>
        </div>

        {!isOrderFinished ? (
          <div className="estimate">
            <div className="input-wrapper">
              <TextInput
                value={nameOnDocument}
                placeholder="株式会社○○　EC事業部"
                onChange={(e) => {
                  setNameOnDocument(e.target.value)
                }}
                className="name-on-estimate"
              />
              <Selectbox
                value={honorificTitle}
                options={honorificTitleList}
                onChange={(e) => setHonorificTitle(e.target.value as '御中' | '様')}
                className="honorific-title"
              />
            </div>
            <Button buttonType="black" onClick={goToEstimate} className="issue-estimate-button">
              見積書を発行
            </Button>
          </div>
        ) : (
          <div className="issue-buttons">
            <Button buttonType="black" onClick={goToInvoice} className="issue-button">
              請求書を発行
            </Button>
            {orderPaymentStatus && orderPaymentStatus !== PaymentStatus.Unpaid && (
              <Button buttonType="black" onClick={goToReceipt} className="issue-button">
                領収書を発行
              </Button>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  )
}
