import React from 'react'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { Link } from '../atoms'
import { TermsTitle, TermsBlock } from '../molecules'
import { APP_NAME, CONTACT_URL, APP_URL } from '../../const'
import { mediaPc } from '../../lib/media-query'

const Wrapper = styled.div`
  width: 100vw;
  background-color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.white};
  padding: 7.7vw 7.2vw;

  ${mediaPc`
  width: 100%;
    padding: 72px 20px 190px;
  `}
`

const Inner = styled.div`
  .terms-title {
    margin-bottom: 5.6vw;
  }

  ${mediaPc`
    max-width: 1000px;
    margin: 0 auto;

    .terms-title {
      margin-bottom: 72px;
    }
  `}
`

export const TermsOfSaleTpl: React.FC = () => {
    return (
        <Wrapper>
            <Inner>
                <TermsTitle title="特定商取引法に基づく表記" className="terms-title" />
                <TermsBlock subtitle="販売事業者">株式会社ROLO</TermsBlock>
                <TermsBlock subtitle="運営統括責任者">代表取締役CEO 今野 敬介</TermsBlock>
                <TermsBlock subtitle="所在地">東京都渋谷区西原３丁目１９−１０ アパートメント上原B1F</TermsBlock>
                <TermsBlock subtitle="お問い合わせ窓口">
                    株式会社ROLO {APP_NAME} 事業部
                    <br />
                    お問い合わせは
                    <Link url={CONTACT_URL} text="こちら" target="_blank" />
                </TermsBlock>
                <TermsBlock subtitle="販売サービス名">{APP_NAME}</TermsBlock>
                <TermsBlock subtitle="サイトURL">
                    <Link url={APP_URL} text="pictol-studio.com/" />
                </TermsBlock>
                <TermsBlock subtitle="商品代金以外に必要な料金の説明">消費税、送料：往復分、サービスご利用の際に発生するパケット通信料</TermsBlock>
                <TermsBlock subtitle="販売価格">商品情報に明記</TermsBlock>
                <TermsBlock subtitle="申し込みの有効期限">お申し込みから14日間</TermsBlock>
                <TermsBlock subtitle="不良品">規約内容に準ずる</TermsBlock>
                <TermsBlock subtitle="引き渡し時期">商品撮影から最短1営業日</TermsBlock>
                <TermsBlock subtitle="お支払い方法">
                    ①Ｓｔｒｉｐｅ
                    <br />
                    ・クレジットカード
                    <br />
                    ②Ｐａｉｄｙ
                    <br />
                    ・コンビニ支払
                    <br />
                    ・口座振替（銀行口座から自動引き落とし）
                    <br />
                    ・銀行振込
                </TermsBlock>
                <TermsBlock subtitle="お支払いのご案内">
                    Ｐａｉｄｙによるお支払いの場合、毎月請求確定分を月末締めで翌月１日に請求書を発行し、３日までにＥメール又はＳＮＳ（ショートメッセージ）にてご案内いたします。
                </TermsBlock>
                <TermsBlock subtitle="引渡方法・時期">
                    お客様の操作による購入が確定し、各種お支払いの利用が承認された後、3日以内に投稿者が商品（デジタルコンテンツ）を作成し、データにて送付いたします。
                </TermsBlock>
                <TermsBlock subtitle="代金支払の期日">
                    ①Ｓｔｒｉｐｅ
                    <br />
                    ・クレジットカード
                    <br />
                    各カード会社の定める時期に請求させていただきます
                    <br />
                    ②Ｐａｉｄｙ
                    <br />
                    ・コンビニ及び銀行振込
                    <br />
                    10日まで
                    <br />
                    ・口座振替
                    <br />
                    12日　※金融機関休業日の場合は、翌営業日1月・5月度は20日となる場合がございます
                </TermsBlock>
                <TermsBlock subtitle="支払手数料">
                    ①Ｓｔｒｉｐｅ
                    <br />
                    ・クレジットカード : 0円
                    <br />
                    ②Ｐａｉｄｙ
                    <br />
                    ・口座振替 : 0円
                    <br />
                    ・コンビニ支払 : 356円
                    <br />
                    ・銀行振込 : 金融機関により異なる
                    <br />
                    ※Ｐａｉｄｙはご利用回数に関わらず月一回のみ発生いたします。
                </TermsBlock>
                <TermsBlock subtitle="返品期限">
                    データ納品という商品の性格上、ご入金後の返品は原則として受け付けません。商品の性質上、返品はできません。商品に不具合がございましたら上記連絡先までご連絡ください。
                </TermsBlock>
                <TermsBlock subtitle="返品送料">
                    返品の際の送料はかかりません。ただし、CD-Rなどの媒体にて返品いただく場合の送料はお客様のご負担とさせていただきます。
                </TermsBlock>
            </Inner>
        </Wrapper>
    )
}
