import axios from 'axios'
import * as Sentry from '@sentry/react'

type AddressData = {
  address1: string
  address2: string
  address3: string
}

export const getAddressFromPostCode = async (value: string): Promise<void | AddressData> => {
  const url = `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`
  try {
    const data = await axios({
      method: 'GET',
      url,
    })
    /*
      変な郵便番号だとdata.data.resultsがnullで返ってくる
      対応していないが存在する郵便番号の可能性も考えて、7桁であれば住所自動入力はしないがエラーも出さない
    */
    if (!data.data.results) return
    return data.data.results[0]
  } catch (e) {
    Sentry.captureException(e)
  }
}
