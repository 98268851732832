import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

import { Invoice } from 'types/graphql'

type Props = {
  className?: string
  orderDetail: Invoice
}

const Wrapper = styled.div`
  width: 100%;
  .detail {
    margin-bottom: 21px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;

    .name {
      width: 487px;
    }
      .quantity {
        width: 150px;
      }
      .unit-price {
        width: 210px;
      }
      .total {
        width: 133px;
      }

      thead {
        font-weight: 500;

        th {
          padding-bottom: 10px;
          box-shadow: 0 2px 0 ${(props): string => props.theme.darkGray}, 0 3px 0 #fff, 0 5px 0 ${(props): string => props.theme.darkGray};
          text-align: center;
        }
      }

      tbody {
        border-bottom: 1px solid ${(props): string => props.theme.gray};
        tr {
          line-height: 60px;
          border-top: 1px solid ${(props): string => props.theme.gray};

          td {
            &.quantity,
            &.unit-price,
            &.total {
              text-align: right;
            }
          }
        }
      }
    }

    .total {
      width: 240px;
      margin: 0 0 64px auto;

      .row {
        padding: 1.2rem 0;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        border-bottom: 0.15rem solid ${(props): string => props.theme.black};
        display: flex;
        justify-content: space-between;

        &:last-child {
          padding-bottom: 0
          font-size: 24px;
          font-weight: 500;
        }
      }
    }

  ${mediaSp`
    padding: 0;
    border-radius: 1rem;
    .detail {
      margin-bottom: 2.4rem;
      overflow-x: scroll;
      table {
        width: 100%;
        .name {
          width: 14rem;
        }
        .quantity {
          width: 5.6rem;
        }
        .unit-price {
          width: 6.6rem;
        }
        .total {
          width: 4rem;
        }
        tbody {
          tr {
            line-height: 50px;
          }
        }
      }
    }
    .total {
      width: 11rem;
      margin: 0 0 3.3rem auto;

      .row {
        padding: 0.4rem 0;
        p {
          margin: 0;
        }
      }
    }
  `}
`

export const OrderDetailTableForDocument: React.FC<Props> = ({ className = '', orderDetail }) => {
  return (
    <Wrapper className={className}>
      <div className="detail">
        <table>
          <thead>
            <tr>
              <th className="name">商品名</th>
              <th className="quantity">数量</th>
              <th className="unit-price">単価</th>
              <th className="total">金額</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="name">商品コード数</td>
              <td className="quantity">{orderDetail.product_code_count}点</td>
            </tr>
            {orderDetail.invoice_items?.map((item, index) => (
              <tr key={index}>
                <td className="name">{item?.product_name}</td>
                <td className="quantity">
                  {item?.quantity}
                  {item?.unit_name}
                </td>
                <td className="unit-price">¥ {item?.unit_price?.toLocaleString()}</td>
                <td className="total">¥ {item?.price?.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="total">
        <div className="row">
          <p>小計</p>
          <p>¥ {orderDetail.subtotal?.toLocaleString()}</p>
        </div>
        <div className="row">
          <p>消費税</p>
          <p>¥ {orderDetail.consumption_tax?.toLocaleString()}</p>
        </div>
        <div className="row">
          <p>合計</p>
          <p>¥ {orderDetail.total?.toLocaleString()}</p>
        </div>
      </div>
    </Wrapper>
  )
}
