import React from 'react'

/* components */
import { Meta } from 'components/meta'
import { ResetPasswordCompleteEmailTpl } from 'components/templates'

export const ResetPasswordCompleteEmail: React.FC = () => {
  return (
    <>
      <Meta title="再設定メール送信完了" />
      <ResetPasswordCompleteEmailTpl />
    </>
  )
}
