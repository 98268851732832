import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import * as Sentry from '@sentry/react'

/* lib, types, const */
import { useUserValidateTokenLazyQuery } from 'types/graphql'

export const useValidateToken = (): void => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [cookie, setCookie] = useCookies(['isLoggedIn'])

  const [validateUserTokenLazyQuery] = useUserValidateTokenLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data || data.UserValidateToken.is_user !== true) return
      if (!cookie.isLoggedIn) {
        setCookie('isLoggedIn', 'true', { path: '/', expires: new Date(data.UserValidateToken.expires * 1000) })
      }
    },
    onError: (e) => {
      redirectToLogin()
      Sentry.captureException(e)
    },
  })

  const redirectToLogin = (): void => {
    // ログインが必要なページの時のみログインページにリダイレクト
    if (pathname.startsWith('/mypage') || pathname.startsWith('/order')) {
      // 印刷用のページはuseNavigateだとヘッダー等表示されないのでパラメーターで処理(見積書印刷ページはログイン必須ではないのでなし)
      const documentPages = ['/order/invoice', '/order/receipt']
      if (documentPages.some((value) => pathname.includes(value))) {
        window.location.href = '/login?redirect=1'
      } else {
        navigate('/login', { state: { redirectFrom: pathname } })
      }
    }
  }

  useEffect(() => {
    validateUserTokenLazyQuery()
  }, [validateUserTokenLazyQuery, pathname])
}
