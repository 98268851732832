import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sO`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any;
};

export enum AdminUserStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Leave */
  Leave = 'LEAVE'
}

export type Business = {
  __typename?: 'Business';
  /** 住所 */
  address?: Maybe<Scalars['String']>;
  /** 建物名・部屋番号 */
  building_name?: Maybe<Scalars['String']>;
  /** 会社名 */
  company_name?: Maybe<Scalars['String']>;
  /** 会社名(ふりがな) */
  company_name_kana?: Maybe<Scalars['String']>;
  /** 部署名 */
  department?: Maybe<Scalars['String']>;
  /** ビジネスID */
  id?: Maybe<Scalars['ID']>;
  /** 氏名 */
  name?: Maybe<Scalars['String']>;
  /** 氏名(ふりがな) */
  name_kana?: Maybe<Scalars['String']>;
  /** 郵便番号 */
  post_code?: Maybe<Scalars['String']>;
  /** 都道府県 */
  prefecture?: Maybe<Scalars['String']>;
  /** 店舗名 */
  store_name?: Maybe<Scalars['String']>;
  /** 電話番号 */
  telephone_number?: Maybe<Scalars['String']>;
};

export enum BusinessStatus {
  /** 有効 */
  Active = 'ACTIVE',
  /** 登録完了前状態 */
  Init = 'INIT',
  /** 退会 */
  Leave = 'LEAVE',
  /** 凍結 */
  Suspend = 'SUSPEND'
}

/** ダウンロードファイル情報 */
export type DownloadFileInfo = {
  __typename?: 'DownloadFileInfo';
  /** ファイル名 */
  name?: Maybe<Scalars['String']>;
  /** ファイルサイズ */
  size?: Maybe<Scalars['String']>;
  /** 単位 */
  unit?: Maybe<Scalars['String']>;
};

/** ダウンロードファイルリスト */
export type DownloadList = {
  __typename?: 'DownloadList';
  /** ダウンロードファイル */
  data: Array<DownloadFileInfo>;
  /** 最後のページ */
  last_page?: Maybe<Scalars['Int']>;
  /** ページ */
  page?: Maybe<Scalars['Int']>;
  /** アイテム数 */
  total?: Maybe<Scalars['Int']>;
};

export enum ExceptionCategory {
  /** System */
  System = 'SYSTEM',
  /** User */
  User = 'USER'
}

export type GuestApproveAuthorizationCodeInput = {
  /** コード */
  code: Scalars['String'];
  /** 有効期限 */
  expires: Scalars['Int'];
  /** 署名 */
  signature: Scalars['String'];
  /** ユーザID */
  user_id: Scalars['ID'];
};

export type GuestRegisterUserInput = {
  /** メールアドレス */
  email: Scalars['String'];
  /** パスワード */
  password: Scalars['String'];
};

export type GuestResetPasswordInput = {
  /** メールアドレス */
  email: Scalars['String'];
  /** パスワード */
  password: Scalars['String'];
  /** トークン */
  token: Scalars['String'];
};

export type GuestSendPasswordResetEmailInput = {
  /** メールアドレス */
  email: Scalars['String'];
};

export type Health = {
  __typename?: 'Health';
  livingDb: Scalars['Boolean'];
  now: Scalars['DateTime'];
};

export type Invoice = {
  __typename?: 'Invoice';
  address?: Maybe<Scalars['String']>;
  building_name?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  company_name_kana?: Maybe<Scalars['String']>;
  /** 消費税 */
  consumption_tax?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** 商品単位 */
  invoice_items?: Maybe<Array<Maybe<InvoiceItem>>>;
  name?: Maybe<Scalars['String']>;
  name_kana?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['ID']>;
  post_code?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  /** 商品コード数 */
  product_code_count?: Maybe<Scalars['Int']>;
  store_name?: Maybe<Scalars['String']>;
  /** 小計 */
  subtotal?: Maybe<Scalars['Int']>;
  telephone_number?: Maybe<Scalars['String']>;
  /** 合計 */
  total?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  /** 登録日時 */
  created_at?: Maybe<Scalars['DateTime']>;
  /** ID */
  id?: Maybe<Scalars['ID']>;
  /** 単位毎の合計金額 */
  price?: Maybe<Scalars['Int']>;
  /** 商品名 */
  product_name?: Maybe<Scalars['String']>;
  /** 単位毎の合計点数 */
  quantity?: Maybe<Scalars['Int']>;
  /** 単位 */
  unit_name?: Maybe<Scalars['String']>;
  /** 単価 */
  unit_price?: Maybe<Scalars['Int']>;
  /** 更新日時 */
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type LoginInput = {
  /** メールアドレス */
  email: Scalars['String'];
  /** パスワード */
  password: Scalars['String'];
};

export type LoginToken = {
  __typename?: 'LoginToken';
  expires: Scalars['Int'];
  token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** [ゲスト]登録のメールアドレス認証 */
  GuestApproveAuthorizationCode: User;
  /** [ユーザー]ユーザ登録するミューテーション */
  GuestRegisterUser: UserSignature;
  /** [ゲスト]パスワードを再設定するミューテーション */
  GuestResetPassword: Scalars['Boolean'];
  /** [ゲスト]パスワード再設定用リンクをメール送信するミューテーション */
  GuestSendPasswordResetEmail: Scalars['Boolean'];
  /** [ゲスト]ユーザーログイン */
  LoginUser: LoginToken;
  /** [ユーザー]ユーザからログアウトするミューテーション */
  LogoutUser: Scalars['Boolean'];
  /** [ユーザー]メールアドレス変更後のメールアドレス認証 */
  UserApproveAuthorizationCode: User;
  /** [ユーザー]注文作成 */
  UserCreateOrder?: Maybe<Scalars['String']>;
  /** [ユーザー]返送先住所を削除するミューテーション */
  UserDeleteReturnAddress: Scalars['ID'];
  /** [ユーザー]注文を検収済みにするミューテーション */
  UserInspectedOrder: Order;
  /** [ゲスト]ユーザーログイン */
  UserPublishReceipt: Receipt;
  /** [ユーザー]アカウント情報を変更するミューテーション */
  UserUpdateCurrentAccount: UpdateAccount;
  /** [ユーザー]メールアドレスを変更するミューテーション */
  UserUpdateEmail: UserSignature;
  /** [ユーザー]パスワードを変更するミューテーション */
  UserUpdatePassword: Scalars['Boolean'];
  /** [ユーザー]返送先住所を登録・更新するミューテーション */
  UserUpsertReturnAddress: ReturnAddress;
};


export type MutationGuestApproveAuthorizationCodeArgs = {
  input: GuestApproveAuthorizationCodeInput;
};


export type MutationGuestRegisterUserArgs = {
  input: GuestRegisterUserInput;
};


export type MutationGuestResetPasswordArgs = {
  input: GuestResetPasswordInput;
};


export type MutationGuestSendPasswordResetEmailArgs = {
  input: GuestSendPasswordResetEmailInput;
};


export type MutationLoginUserArgs = {
  input: LoginInput;
};


export type MutationUserApproveAuthorizationCodeArgs = {
  input: UserApproveAuthorizationCodeInput;
};


export type MutationUserCreateOrderArgs = {
  input: UserCreateOrderInput;
};


export type MutationUserDeleteReturnAddressArgs = {
  input: UserDeleteReturnAddressInput;
};


export type MutationUserInspectedOrderArgs = {
  order_id: Scalars['ID'];
};


export type MutationUserPublishReceiptArgs = {
  input: UserPublishReceiptInput;
};


export type MutationUserUpdateCurrentAccountArgs = {
  input: UserUpdateCurrentAccountInput;
};


export type MutationUserUpdateEmailArgs = {
  input: UserUpdateEmailInput;
};


export type MutationUserUpdatePasswordArgs = {
  input: UserUpdatePasswordInput;
};


export type MutationUserUpsertReturnAddressArgs = {
  input: UserUpsertReturnAddressInput;
};

export type Order = {
  __typename?: 'Order';
  created_at?: Maybe<Scalars['DateTime']>;
  /** ダウンロード有効期限 */
  download_expired_at?: Maybe<Scalars['DateTime']>;
  estimated_shipment_date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  /** 注文の請求内容 */
  invoice?: Maybe<Invoice>;
  is_issued_receipt?: Maybe<Scalars['Boolean']>;
  paid_at?: Maybe<Scalars['DateTime']>;
  payment_method?: Maybe<PaymentMethod>;
  payment_status?: Maybe<PaymentStatus>;
  remarks?: Maybe<Scalars['String']>;
  /** 返送先情報 */
  return_item?: Maybe<ReturnItem>;
  status?: Maybe<OrderStatus>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  created_at?: Maybe<Scalars['DateTime']>;
  cut_num?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  jan_code?: Maybe<Scalars['String']>;
  order_number?: Maybe<Scalars['ID']>;
  product_category?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  style?: Maybe<OrderStyle>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type OrderItemInput = {
  color: Scalars['String'];
  cut_num?: InputMaybe<Scalars['Int']>;
  extension?: InputMaybe<Scalars['String']>;
  has_movie: Scalars['Boolean'];
  jan_code?: InputMaybe<Scalars['String']>;
  order_number: Scalars['Int'];
  photo_size?: InputMaybe<Scalars['Int']>;
  product_category: Scalars['String'];
  product_code: Scalars['String'];
  remarks?: InputMaybe<Scalars['String']>;
  style: Scalars['String'];
};

export enum OrderMovieExtension {
  /** Mp4 */
  Mp4 = 'MP4'
}

export enum OrderPhoto360Extension {
  /** Zip */
  Zip = 'ZIP'
}

export enum OrderPhotoExtension {
  /** Jpeg */
  Jpeg = 'JPEG',
  /** Png */
  Png = 'PNG',
  /** Tiff */
  Tiff = 'TIFF'
}

export enum OrderPlan {
  /** Movie */
  Movie = 'MOVIE',
  /** Photo */
  Photo = 'PHOTO'
}

export enum OrderStatus {
  /** キャンセル */
  Cancel = 'CANCEL',
  /** DL期間終了 */
  Expired = 'EXPIRED',
  /** 入金確認中 */
  Init = 'INIT',
  /** 検収完了 */
  Inspected = 'INSPECTED',
  /** 検収中 */
  Inspecting = 'INSPECTING',
  /** 撮影準備中 */
  Preparing = 'PREPARING',
  /** 撮影中 */
  Shooting = 'SHOOTING'
}

export enum OrderStyle {
  /** 小物類 */
  Accessories = 'ACCESSORIES',
  /** 平置き */
  Flat = 'FLAT',
  /** トルソー */
  Torso = 'TORSO'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export enum PaymentMethod {
  /** 銀行振込 */
  Bank = 'BANK',
  /** Stripe */
  Stripe = 'STRIPE'
}

export enum PaymentStatus {
  /** 入金済 */
  Paid = 'PAID',
  /** 一部返金済 */
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  /** 返金済 */
  Refunded = 'REFUNDED',
  /** 未入金 */
  Unpaid = 'UNPAID'
}

export type Query = {
  __typename?: 'Query';
  /** [ユーザー]ログイン中のユーザー情報を取得 */
  CurrentUser?: Maybe<User>;
  /** [ゲスト]見積もりシミュレーション */
  GuestQuotation?: Maybe<Invoice>;
  /** [ゲスト]webサーバーのヘルスチェック */
  HealthCheck?: Maybe<Health>;
  /** [ユーザー]納品ファイルのダウンロード一覧を取得する */
  UserDownloadList: DownloadList;
  /** [ユーザー]ダウンロードリンクを生成する */
  UserGenerateDownloadLink: Scalars['String'];
  /** [ユーザー]注文CSVをダウンロード */
  UserGenerateDownloadOrderCsvLink: Scalars['String'];
  /** [ユーザー]注文CSVをアップロード */
  UserGenerateUploadOrderCsvLink: UploadLink;
  /** [ユーザー]一括ダウンロードリンクを生成する */
  UserGenerateZipDownloadLink: Scalars['String'];
  /** [ユーザー]注文詳細を取得する */
  UserOrderDetail: Order;
  /** [ユーザー]注文履歴を取得する */
  UserOrders?: Maybe<UserOrderPaginator>;
  /** [ユーザー]見積もりシミュレーション */
  UserQuotation?: Maybe<Invoice>;
  /** [ユーザー]所属するビジネスの返送先住所を取得する */
  UserReturnAddresses: Array<Maybe<ReturnAddress>>;
  /** [ユーザー]認証トークンの有効性を確認するクエリー */
  UserValidateToken: UserValidateToken;
};


export type QueryGuestQuotationArgs = {
  input: QuotationInput;
};


export type QueryUserDownloadListArgs = {
  input: UserDownloadListInput;
};


export type QueryUserGenerateDownloadLinkArgs = {
  input: UserGenerateDownloadLinkInput;
};


export type QueryUserGenerateDownloadOrderCsvLinkArgs = {
  order_id: Scalars['ID'];
};


export type QueryUserGenerateZipDownloadLinkArgs = {
  order_id: Scalars['ID'];
};


export type QueryUserOrderDetailArgs = {
  order_id: Scalars['ID'];
};


export type QueryUserOrdersArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserQuotationArgs = {
  input: QuotationInput;
};

export type QuotationInput = {
  order_items: Array<InputMaybe<OrderItemInput>>;
};

export type Receipt = {
  __typename?: 'Receipt';
  invoice?: Maybe<Invoice>;
  is_issued_receipt?: Maybe<Scalars['Boolean']>;
  receipt_number?: Maybe<Scalars['ID']>;
  receipt_published_at?: Maybe<Scalars['DateTime']>;
  received_by_name?: Maybe<Scalars['String']>;
  received_for_address?: Maybe<Scalars['String']>;
  received_for_name?: Maybe<Scalars['String']>;
  received_for_telephone?: Maybe<Scalars['String']>;
};

/** ビジネスの返送先住所 */
export type ReturnAddress = {
  __typename?: 'ReturnAddress';
  /** 返送先住所 */
  address?: Maybe<Scalars['String']>;
  /** 返送先建物名 */
  building_name?: Maybe<Scalars['String']>;
  /** 返送先会社名 */
  company_name?: Maybe<Scalars['String']>;
  /** 返送先会社名(かな) */
  company_name_kana?: Maybe<Scalars['String']>;
  /** 登録日 */
  created_at?: Maybe<Scalars['DateTime']>;
  /** 返送先部署名 */
  department?: Maybe<Scalars['String']>;
  /** 返送先住所ID */
  id?: Maybe<Scalars['ID']>;
  /** 返送先氏名 */
  name?: Maybe<Scalars['String']>;
  /** 返送先氏名(かな) */
  name_kana?: Maybe<Scalars['String']>;
  /** 返送先郵便番号 */
  post_code?: Maybe<Scalars['String']>;
  /** 返送先都道府県 */
  prefecture?: Maybe<Scalars['String']>;
  /** 返送先店舗名 */
  store_name?: Maybe<Scalars['String']>;
  /** 返送先電話番号 */
  telephone_number?: Maybe<Scalars['String']>;
  /** 更新日 */
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** 返送商品 */
export type ReturnItem = {
  __typename?: 'ReturnItem';
  /** 返送先住所 */
  address?: Maybe<Scalars['String']>;
  /** 到着日 */
  arrival_date?: Maybe<Scalars['DateTime']>;
  /** 返送先建物名 */
  building_name?: Maybe<Scalars['String']>;
  /** 返送先会社名 */
  company_name?: Maybe<Scalars['String']>;
  /** 返送先会社名(かな) */
  company_name_kana?: Maybe<Scalars['String']>;
  /** 返送先部署名 */
  department?: Maybe<Scalars['String']>;
  /** 返送商品ID */
  id?: Maybe<Scalars['ID']>;
  /** 返送先氏名 */
  name?: Maybe<Scalars['String']>;
  /** 返送先氏名(かな) */
  name_kana?: Maybe<Scalars['String']>;
  /** 返送先郵便番号 */
  post_code?: Maybe<Scalars['String']>;
  /** 返送先都道府県 */
  prefecture?: Maybe<Scalars['String']>;
  /** 発送日 */
  ship_date?: Maybe<Scalars['DateTime']>;
  /** 追跡番号 */
  shipping_code?: Maybe<Scalars['String']>;
  /** 運送サービス名 */
  shipping_service?: Maybe<Scalars['String']>;
  /** 返送ステータス */
  status?: Maybe<ReturnItemStatus>;
  /** 返送先店舗名 */
  store_name?: Maybe<Scalars['String']>;
  /** 返送先電話番号 */
  telephone_number?: Maybe<Scalars['String']>;
};

export enum ReturnItemStatus {
  /** 返送済 */
  Returned = 'RETURNED',
  /** 未返送 */
  Unreturned = 'UNRETURNED'
}

export enum ShippingService {
  /** 佐川急便 */
  Sagawa = 'SAGAWA'
}

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export enum ToolTables {
  /** 管理ユーザ */
  AdminUsers = 'ADMIN_USERS',
  /** ビジネス */
  Businesses = 'BUSINESSES',
  /** 返送先住所 */
  BusinessReturnAddresses = 'BUSINESS_RETURN_ADDRESSES',
  /** 納品物 */
  DeliveredItems = 'DELIVERED_ITEMS',
  /** 請求書 */
  Invoices = 'INVOICES',
  /** 請求詳細 */
  InvoiceItems = 'INVOICE_ITEMS',
  /** 注文 */
  Orders = 'ORDERS',
  /** 注文詳細 */
  OrderItems = 'ORDER_ITEMS',
  /** 支払いログ */
  PaymentLogs = 'PAYMENT_LOGS',
  /** 返送品 */
  ReturnItems = 'RETURN_ITEMS',
  /** Stripeレシート */
  StripeReceipts = 'STRIPE_RECEIPTS',
  /** ユーザ */
  Users = 'USERS'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

/** アカウント更新データ */
export type UpdateAccount = {
  __typename?: 'UpdateAccount';
  /** 変更依頼したメールアドレス */
  changed_email?: Maybe<Scalars['String']>;
  /** メールアドレスに変更があるか */
  is_changed_email: Scalars['Boolean'];
};

/** アップロードリンク */
export type UploadLink = {
  __typename?: 'UploadLink';
  /** パス */
  path?: Maybe<Scalars['String']>;
  /** URL */
  url?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  business?: Maybe<Business>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type UserApproveAuthorizationCodeInput = {
  /** コード */
  code: Scalars['String'];
  /** メールアドレス */
  email: Scalars['String'];
  /** 有効期限 */
  expires: Scalars['Int'];
  /** 署名 */
  signature: Scalars['String'];
  /** ユーザID */
  user_id: Scalars['ID'];
};

export type UserCreateOrderInput = {
  address: Scalars['String'];
  building_name?: InputMaybe<Scalars['String']>;
  business_return_address_id: Scalars['ID'];
  company_name?: InputMaybe<Scalars['String']>;
  company_name_kana?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  estimated_shipment_date?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  name_kana: Scalars['String'];
  order_items: Array<InputMaybe<OrderItemInput>>;
  payment_method: Scalars['String'];
  post_code: Scalars['String'];
  prefecture: Scalars['String'];
  remarks?: InputMaybe<Scalars['String']>;
  store_name?: InputMaybe<Scalars['String']>;
  telephone_number?: InputMaybe<Scalars['String']>;
};

export type UserDeleteReturnAddressInput = {
  /** 返送先住所ID */
  business_return_address_id: Scalars['ID'];
};

export type UserDownloadListInput = {
  /** 1ページあたりの数 */
  first: Scalars['Int'];
  /** 注文ID */
  order_id: Scalars['ID'];
  /** ページ */
  page: Scalars['Int'];
};

export type UserGenerateDownloadLinkInput = {
  /** ファイル名 */
  name: Scalars['String'];
  /** 注文ID */
  order_id: Scalars['ID'];
};

/** 注文履歴 */
export type UserOrder = {
  __typename?: 'UserOrder';
  /** 作成日時 */
  created_at?: Maybe<Scalars['DateTime']>;
  /** ダウンロード有効期限 */
  download_expired_at?: Maybe<Scalars['DateTime']>;
  /** 注文番号 */
  id?: Maybe<Scalars['ID']>;
  /** 注文ステータス */
  status?: Maybe<OrderStatus>;
};

/** A paginated list of UserOrder items. */
export type UserOrderPaginator = {
  __typename?: 'UserOrderPaginator';
  /** A list of UserOrder items. */
  data: Array<UserOrder>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type UserPublishReceiptInput = {
  /** 注文ID */
  order_id: Scalars['ID'];
};

/** ユーザー署名 */
export type UserSignature = {
  __typename?: 'UserSignature';
  /** 有効期限(UnixTime) */
  expires?: Maybe<Scalars['Int']>;
  /** 署名 */
  signature?: Maybe<Scalars['String']>;
  /** ユーザーID */
  user_id?: Maybe<Scalars['ID']>;
};

export enum UserStatus {
  /** 有効 */
  Active = 'ACTIVE',
  /** 登録完了前状態 */
  Init = 'INIT',
  /** 退会 */
  Leave = 'LEAVE',
  /** 凍結 */
  Suspend = 'SUSPEND'
}

export type UserUpdateCurrentAccountInput = {
  /** 住所 */
  address: Scalars['String'];
  /** 建物名・部屋番号 */
  building_name?: InputMaybe<Scalars['String']>;
  /** 会社名 */
  company_name?: InputMaybe<Scalars['String']>;
  /** 会社名(ふりがな) */
  company_name_kana?: InputMaybe<Scalars['String']>;
  /** 部署名 */
  department?: InputMaybe<Scalars['String']>;
  /** メールアドレス */
  email: Scalars['String'];
  /** 氏名 */
  name: Scalars['String'];
  /** 氏名(ふりがな) */
  name_kana: Scalars['String'];
  /** 郵便番号 */
  post_code: Scalars['String'];
  /** 都道府県 */
  prefecture: Scalars['String'];
  /** 店舗名 */
  store_name?: InputMaybe<Scalars['String']>;
  /** 電話番号 */
  telephone_number: Scalars['String'];
};

export type UserUpdateEmailInput = {
  /** 変更後のメールアドレス */
  email: Scalars['String'];
};

export type UserUpdatePasswordInput = {
  /** 現在のパスワード */
  current_password: Scalars['String'];
  /** 変更後のパスワード */
  password: Scalars['String'];
};

export type UserUpsertReturnAddressInput = {
  /** 返送先住所 */
  address: Scalars['String'];
  /** 返送先建物名 */
  building_name?: InputMaybe<Scalars['String']>;
  /** 返送先住所ID */
  business_return_address_id?: InputMaybe<Scalars['ID']>;
  /** 返送先会社名 */
  company_name?: InputMaybe<Scalars['String']>;
  /** 返送先会社名(かな) */
  company_name_kana?: InputMaybe<Scalars['String']>;
  /** 返送先部署名 */
  department?: InputMaybe<Scalars['String']>;
  /** 返送先氏名 */
  name: Scalars['String'];
  /** 返送先氏名(かな) */
  name_kana: Scalars['String'];
  /** 返送先郵便番号 */
  post_code: Scalars['String'];
  /** 返送先都道府県 */
  prefecture: Scalars['String'];
  /** 返送先店舗名 */
  store_name?: InputMaybe<Scalars['String']>;
  /** 返送先電話番号 */
  telephone_number: Scalars['String'];
};

export type UserValidateToken = {
  __typename?: 'UserValidateToken';
  /** 有効期限(UnixTime) */
  expires: Scalars['Int'];
  /** User型かどうか */
  is_user: Scalars['Boolean'];
};

export type GuestApproveAuthorizationCodeMutationVariables = Exact<{
  input: GuestApproveAuthorizationCodeInput;
}>;


export type GuestApproveAuthorizationCodeMutation = { __typename?: 'Mutation', GuestApproveAuthorizationCode: { __typename?: 'User', id?: string | null, email?: string | null } };

export type GuestRegisterUserMutationVariables = Exact<{
  input: GuestRegisterUserInput;
}>;


export type GuestRegisterUserMutation = { __typename?: 'Mutation', GuestRegisterUser: { __typename?: 'UserSignature', expires?: number | null, user_id?: string | null, signature?: string | null } };

export type GuestResetPasswordMutationVariables = Exact<{
  input: GuestResetPasswordInput;
}>;


export type GuestResetPasswordMutation = { __typename?: 'Mutation', GuestResetPassword: boolean };

export type GuestSendPasswordResetEmailMutationVariables = Exact<{
  input: GuestSendPasswordResetEmailInput;
}>;


export type GuestSendPasswordResetEmailMutation = { __typename?: 'Mutation', GuestSendPasswordResetEmail: boolean };

export type LoginUserMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', LoginUser: { __typename?: 'LoginToken', token: string, expires: number } };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { __typename?: 'Mutation', LogoutUser: boolean };

export type UserApproveAuthorizationCodeMutationVariables = Exact<{
  input: UserApproveAuthorizationCodeInput;
}>;


export type UserApproveAuthorizationCodeMutation = { __typename?: 'Mutation', UserApproveAuthorizationCode: { __typename?: 'User', id?: string | null, email?: string | null, business?: { __typename?: 'Business', id?: string | null, name?: string | null, name_kana?: string | null, telephone_number?: string | null, post_code?: string | null, prefecture?: string | null, address?: string | null, building_name?: string | null, company_name?: string | null, company_name_kana?: string | null, store_name?: string | null, department?: string | null } | null } };

export type UserCreateOrderMutationVariables = Exact<{
  input: UserCreateOrderInput;
}>;


export type UserCreateOrderMutation = { __typename?: 'Mutation', UserCreateOrder?: string | null };

export type UserDeleteReturnAddressMutationVariables = Exact<{
  input: UserDeleteReturnAddressInput;
}>;


export type UserDeleteReturnAddressMutation = { __typename?: 'Mutation', UserDeleteReturnAddress: string };

export type UserInspectedOrderMutationVariables = Exact<{
  order_id: Scalars['ID'];
}>;


export type UserInspectedOrderMutation = { __typename?: 'Mutation', UserInspectedOrder: { __typename?: 'Order', id?: string | null, status?: OrderStatus | null } };

export type UserPublishReceiptMutationVariables = Exact<{
  input: UserPublishReceiptInput;
}>;


export type UserPublishReceiptMutation = { __typename?: 'Mutation', UserPublishReceipt: { __typename?: 'Receipt', is_issued_receipt?: boolean | null, receipt_published_at?: any | null, receipt_number?: string | null, received_by_name?: string | null, received_for_name?: string | null, received_for_address?: string | null, received_for_telephone?: string | null, invoice?: { __typename?: 'Invoice', id?: string | null, order_id?: string | null, name?: string | null, name_kana?: string | null, company_name?: string | null, company_name_kana?: string | null, department?: string | null, store_name?: string | null, telephone_number?: string | null, post_code?: string | null, prefecture?: string | null, address?: string | null, building_name?: string | null, subtotal?: number | null, consumption_tax?: number | null, total?: number | null, created_at?: any | null, updated_at?: any | null, invoice_items?: Array<{ __typename?: 'InvoiceItem', id?: string | null, product_name?: string | null, unit_name?: string | null, unit_price?: number | null, quantity?: number | null, created_at?: any | null, updated_at?: any | null } | null> | null } | null } };

export type UserUpdateCurrentAccountMutationVariables = Exact<{
  input: UserUpdateCurrentAccountInput;
}>;


export type UserUpdateCurrentAccountMutation = { __typename?: 'Mutation', UserUpdateCurrentAccount: { __typename?: 'UpdateAccount', is_changed_email: boolean, changed_email?: string | null } };

export type UserUpdateEmailMutationVariables = Exact<{
  input: UserUpdateEmailInput;
}>;


export type UserUpdateEmailMutation = { __typename?: 'Mutation', UserUpdateEmail: { __typename?: 'UserSignature', user_id?: string | null, expires?: number | null, signature?: string | null } };

export type UserUpdatePasswordMutationVariables = Exact<{
  input: UserUpdatePasswordInput;
}>;


export type UserUpdatePasswordMutation = { __typename?: 'Mutation', UserUpdatePassword: boolean };

export type UserUpsertReturnAddressMutationVariables = Exact<{
  input: UserUpsertReturnAddressInput;
}>;


export type UserUpsertReturnAddressMutation = { __typename?: 'Mutation', UserUpsertReturnAddress: { __typename?: 'ReturnAddress', id?: string | null, name?: string | null, name_kana?: string | null, company_name?: string | null, company_name_kana?: string | null, department?: string | null, store_name?: string | null, telephone_number?: string | null, post_code?: string | null, prefecture?: string | null, address?: string | null, building_name?: string | null } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', CurrentUser?: { __typename?: 'User', id?: string | null, email?: string | null, business?: { __typename?: 'Business', name?: string | null, name_kana?: string | null, telephone_number?: string | null, post_code?: string | null, prefecture?: string | null, address?: string | null, building_name?: string | null, company_name?: string | null, company_name_kana?: string | null, store_name?: string | null, department?: string | null } | null } | null };

export type GuestQuotationQueryVariables = Exact<{
  input: QuotationInput;
}>;


export type GuestQuotationQuery = { __typename?: 'Query', GuestQuotation?: { __typename?: 'Invoice', id?: string | null, order_id?: string | null, name?: string | null, name_kana?: string | null, company_name?: string | null, company_name_kana?: string | null, department?: string | null, store_name?: string | null, telephone_number?: string | null, post_code?: string | null, prefecture?: string | null, address?: string | null, building_name?: string | null, created_at?: any | null, updated_at?: any | null, product_code_count?: number | null, subtotal?: number | null, consumption_tax?: number | null, total?: number | null, invoice_items?: Array<{ __typename?: 'InvoiceItem', id?: string | null, product_name?: string | null, quantity?: number | null, unit_name?: string | null, unit_price?: number | null, price?: number | null, created_at?: any | null, updated_at?: any | null } | null> | null } | null };

export type UserDownloadListQueryVariables = Exact<{
  input: UserDownloadListInput;
}>;


export type UserDownloadListQuery = { __typename?: 'Query', UserDownloadList: { __typename?: 'DownloadList', page?: number | null, last_page?: number | null, total?: number | null, data: Array<{ __typename?: 'DownloadFileInfo', name?: string | null, size?: string | null, unit?: string | null }> } };

export type UserGenerateDownloadLinkQueryVariables = Exact<{
  input: UserGenerateDownloadLinkInput;
}>;


export type UserGenerateDownloadLinkQuery = { __typename?: 'Query', UserGenerateDownloadLink: string };

export type UserGenerateDownloadOrderCsvLinkQueryVariables = Exact<{
  order_id: Scalars['ID'];
}>;


export type UserGenerateDownloadOrderCsvLinkQuery = { __typename?: 'Query', UserGenerateDownloadOrderCsvLink: string };

export type UserGenerateUploadOrderCsvLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type UserGenerateUploadOrderCsvLinkQuery = { __typename?: 'Query', UserGenerateUploadOrderCsvLink: { __typename?: 'UploadLink', url?: string | null, path?: string | null } };

export type UserGenerateZipDownloadLinkQueryVariables = Exact<{
  order_id: Scalars['ID'];
}>;


export type UserGenerateZipDownloadLinkQuery = { __typename?: 'Query', UserGenerateZipDownloadLink: string };

export type UserOrderDetailQueryVariables = Exact<{
  order_id: Scalars['ID'];
}>;


export type UserOrderDetailQuery = { __typename?: 'Query', UserOrderDetail: { __typename?: 'Order', id?: string | null, status?: OrderStatus | null, estimated_shipment_date?: any | null, remarks?: string | null, is_issued_receipt?: boolean | null, payment_method?: PaymentMethod | null, payment_status?: PaymentStatus | null, paid_at?: any | null, created_at?: any | null, updated_at?: any | null, download_expired_at?: any | null, invoice?: { __typename?: 'Invoice', address?: string | null, building_name?: string | null, company_name?: string | null, company_name_kana?: string | null, name?: string | null, name_kana?: string | null, order_id?: string | null, post_code?: string | null, prefecture?: string | null, department?: string | null, store_name?: string | null, telephone_number?: string | null, product_code_count?: number | null, subtotal?: number | null, consumption_tax?: number | null, total?: number | null, invoice_items?: Array<{ __typename?: 'InvoiceItem', product_name?: string | null, quantity?: number | null, unit_name?: string | null, unit_price?: number | null, price?: number | null } | null> | null } | null, return_item?: { __typename?: 'ReturnItem', status?: ReturnItemStatus | null, name?: string | null, name_kana?: string | null, company_name?: string | null, company_name_kana?: string | null, department?: string | null, store_name?: string | null, telephone_number?: string | null, post_code?: string | null, prefecture?: string | null, address?: string | null, building_name?: string | null, shipping_service?: string | null, shipping_code?: string | null } | null } };

export type UserOrdersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  first: Scalars['Int'];
}>;


export type UserOrdersQuery = { __typename?: 'Query', UserOrders?: { __typename?: 'UserOrderPaginator', data: Array<{ __typename?: 'UserOrder', id?: string | null, status?: OrderStatus | null, download_expired_at?: any | null, created_at?: any | null }>, paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: number | null, hasMorePages: boolean, lastItem?: number | null, lastPage: number, total: number, perPage: number } } | null };

export type UserQuotationQueryVariables = Exact<{
  input: QuotationInput;
}>;


export type UserQuotationQuery = { __typename?: 'Query', UserQuotation?: { __typename?: 'Invoice', id?: string | null, order_id?: string | null, name?: string | null, name_kana?: string | null, company_name?: string | null, company_name_kana?: string | null, department?: string | null, store_name?: string | null, telephone_number?: string | null, post_code?: string | null, prefecture?: string | null, address?: string | null, building_name?: string | null, created_at?: any | null, updated_at?: any | null, product_code_count?: number | null, subtotal?: number | null, consumption_tax?: number | null, total?: number | null, invoice_items?: Array<{ __typename?: 'InvoiceItem', id?: string | null, product_name?: string | null, quantity?: number | null, unit_name?: string | null, unit_price?: number | null, price?: number | null, created_at?: any | null, updated_at?: any | null } | null> | null } | null };

export type UserReturnAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserReturnAddressesQuery = { __typename?: 'Query', UserReturnAddresses: Array<{ __typename?: 'ReturnAddress', id?: string | null, name?: string | null, name_kana?: string | null, company_name?: string | null, company_name_kana?: string | null, department?: string | null, store_name?: string | null, telephone_number?: string | null, post_code?: string | null, prefecture?: string | null, address?: string | null, building_name?: string | null, created_at?: any | null, updated_at?: any | null } | null> };

export type UserValidateTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type UserValidateTokenQuery = { __typename?: 'Query', UserValidateToken: { __typename?: 'UserValidateToken', is_user: boolean, expires: number } };

export type HealthCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type HealthCheckQuery = { __typename?: 'Query', HealthCheck?: { __typename?: 'Health', now: any, livingDb: boolean } | null };


export const GuestApproveAuthorizationCodeGql = gql`
    mutation GuestApproveAuthorizationCode($input: GuestApproveAuthorizationCodeInput!) {
  GuestApproveAuthorizationCode(input: $input) {
    id
    email
  }
}
    `;
export type GuestApproveAuthorizationCodeMutationFn = Apollo.MutationFunction<GuestApproveAuthorizationCodeMutation, GuestApproveAuthorizationCodeMutationVariables>;

/**
 * __useGuestApproveAuthorizationCodeMutation__
 *
 * To run a mutation, you first call `useGuestApproveAuthorizationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestApproveAuthorizationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestApproveAuthorizationCodeMutation, { data, loading, error }] = useGuestApproveAuthorizationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestApproveAuthorizationCodeMutation(baseOptions?: Apollo.MutationHookOptions<GuestApproveAuthorizationCodeMutation, GuestApproveAuthorizationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestApproveAuthorizationCodeMutation, GuestApproveAuthorizationCodeMutationVariables>(GuestApproveAuthorizationCodeGql, options);
      }
export type GuestApproveAuthorizationCodeMutationHookResult = ReturnType<typeof useGuestApproveAuthorizationCodeMutation>;
export type GuestApproveAuthorizationCodeMutationResult = Apollo.MutationResult<GuestApproveAuthorizationCodeMutation>;
export type GuestApproveAuthorizationCodeMutationOptions = Apollo.BaseMutationOptions<GuestApproveAuthorizationCodeMutation, GuestApproveAuthorizationCodeMutationVariables>;
export const GuestRegisterUserGql = gql`
    mutation GuestRegisterUser($input: GuestRegisterUserInput!) {
  GuestRegisterUser(input: $input) {
    expires
    user_id
    signature
  }
}
    `;
export type GuestRegisterUserMutationFn = Apollo.MutationFunction<GuestRegisterUserMutation, GuestRegisterUserMutationVariables>;

/**
 * __useGuestRegisterUserMutation__
 *
 * To run a mutation, you first call `useGuestRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestRegisterUserMutation, { data, loading, error }] = useGuestRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<GuestRegisterUserMutation, GuestRegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestRegisterUserMutation, GuestRegisterUserMutationVariables>(GuestRegisterUserGql, options);
      }
export type GuestRegisterUserMutationHookResult = ReturnType<typeof useGuestRegisterUserMutation>;
export type GuestRegisterUserMutationResult = Apollo.MutationResult<GuestRegisterUserMutation>;
export type GuestRegisterUserMutationOptions = Apollo.BaseMutationOptions<GuestRegisterUserMutation, GuestRegisterUserMutationVariables>;
export const GuestResetPasswordGql = gql`
    mutation GuestResetPassword($input: GuestResetPasswordInput!) {
  GuestResetPassword(input: $input)
}
    `;
export type GuestResetPasswordMutationFn = Apollo.MutationFunction<GuestResetPasswordMutation, GuestResetPasswordMutationVariables>;

/**
 * __useGuestResetPasswordMutation__
 *
 * To run a mutation, you first call `useGuestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestResetPasswordMutation, { data, loading, error }] = useGuestResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<GuestResetPasswordMutation, GuestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestResetPasswordMutation, GuestResetPasswordMutationVariables>(GuestResetPasswordGql, options);
      }
export type GuestResetPasswordMutationHookResult = ReturnType<typeof useGuestResetPasswordMutation>;
export type GuestResetPasswordMutationResult = Apollo.MutationResult<GuestResetPasswordMutation>;
export type GuestResetPasswordMutationOptions = Apollo.BaseMutationOptions<GuestResetPasswordMutation, GuestResetPasswordMutationVariables>;
export const GuestSendPasswordResetEmailGql = gql`
    mutation GuestSendPasswordResetEmail($input: GuestSendPasswordResetEmailInput!) {
  GuestSendPasswordResetEmail(input: $input)
}
    `;
export type GuestSendPasswordResetEmailMutationFn = Apollo.MutationFunction<GuestSendPasswordResetEmailMutation, GuestSendPasswordResetEmailMutationVariables>;

/**
 * __useGuestSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useGuestSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestSendPasswordResetEmailMutation, { data, loading, error }] = useGuestSendPasswordResetEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestSendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<GuestSendPasswordResetEmailMutation, GuestSendPasswordResetEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuestSendPasswordResetEmailMutation, GuestSendPasswordResetEmailMutationVariables>(GuestSendPasswordResetEmailGql, options);
      }
export type GuestSendPasswordResetEmailMutationHookResult = ReturnType<typeof useGuestSendPasswordResetEmailMutation>;
export type GuestSendPasswordResetEmailMutationResult = Apollo.MutationResult<GuestSendPasswordResetEmailMutation>;
export type GuestSendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<GuestSendPasswordResetEmailMutation, GuestSendPasswordResetEmailMutationVariables>;
export const LoginUserGql = gql`
    mutation LoginUser($input: LoginInput!) {
  LoginUser(input: $input) {
    token
    expires
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserGql, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LogoutUserGql = gql`
    mutation LogoutUser {
  LogoutUser
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserGql, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const UserApproveAuthorizationCodeGql = gql`
    mutation UserApproveAuthorizationCode($input: UserApproveAuthorizationCodeInput!) {
  UserApproveAuthorizationCode(input: $input) {
    id
    email
    business {
      id
      name
      name_kana
      telephone_number
      post_code
      prefecture
      address
      building_name
      company_name
      company_name_kana
      store_name
      department
    }
  }
}
    `;
export type UserApproveAuthorizationCodeMutationFn = Apollo.MutationFunction<UserApproveAuthorizationCodeMutation, UserApproveAuthorizationCodeMutationVariables>;

/**
 * __useUserApproveAuthorizationCodeMutation__
 *
 * To run a mutation, you first call `useUserApproveAuthorizationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserApproveAuthorizationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userApproveAuthorizationCodeMutation, { data, loading, error }] = useUserApproveAuthorizationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserApproveAuthorizationCodeMutation(baseOptions?: Apollo.MutationHookOptions<UserApproveAuthorizationCodeMutation, UserApproveAuthorizationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserApproveAuthorizationCodeMutation, UserApproveAuthorizationCodeMutationVariables>(UserApproveAuthorizationCodeGql, options);
      }
export type UserApproveAuthorizationCodeMutationHookResult = ReturnType<typeof useUserApproveAuthorizationCodeMutation>;
export type UserApproveAuthorizationCodeMutationResult = Apollo.MutationResult<UserApproveAuthorizationCodeMutation>;
export type UserApproveAuthorizationCodeMutationOptions = Apollo.BaseMutationOptions<UserApproveAuthorizationCodeMutation, UserApproveAuthorizationCodeMutationVariables>;
export const UserCreateOrderGql = gql`
    mutation UserCreateOrder($input: UserCreateOrderInput!) {
  UserCreateOrder(input: $input)
}
    `;
export type UserCreateOrderMutationFn = Apollo.MutationFunction<UserCreateOrderMutation, UserCreateOrderMutationVariables>;

/**
 * __useUserCreateOrderMutation__
 *
 * To run a mutation, you first call `useUserCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateOrderMutation, { data, loading, error }] = useUserCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UserCreateOrderMutation, UserCreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserCreateOrderMutation, UserCreateOrderMutationVariables>(UserCreateOrderGql, options);
      }
export type UserCreateOrderMutationHookResult = ReturnType<typeof useUserCreateOrderMutation>;
export type UserCreateOrderMutationResult = Apollo.MutationResult<UserCreateOrderMutation>;
export type UserCreateOrderMutationOptions = Apollo.BaseMutationOptions<UserCreateOrderMutation, UserCreateOrderMutationVariables>;
export const UserDeleteReturnAddressGql = gql`
    mutation UserDeleteReturnAddress($input: UserDeleteReturnAddressInput!) {
  UserDeleteReturnAddress(input: $input)
}
    `;
export type UserDeleteReturnAddressMutationFn = Apollo.MutationFunction<UserDeleteReturnAddressMutation, UserDeleteReturnAddressMutationVariables>;

/**
 * __useUserDeleteReturnAddressMutation__
 *
 * To run a mutation, you first call `useUserDeleteReturnAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteReturnAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteReturnAddressMutation, { data, loading, error }] = useUserDeleteReturnAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDeleteReturnAddressMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteReturnAddressMutation, UserDeleteReturnAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteReturnAddressMutation, UserDeleteReturnAddressMutationVariables>(UserDeleteReturnAddressGql, options);
      }
export type UserDeleteReturnAddressMutationHookResult = ReturnType<typeof useUserDeleteReturnAddressMutation>;
export type UserDeleteReturnAddressMutationResult = Apollo.MutationResult<UserDeleteReturnAddressMutation>;
export type UserDeleteReturnAddressMutationOptions = Apollo.BaseMutationOptions<UserDeleteReturnAddressMutation, UserDeleteReturnAddressMutationVariables>;
export const UserInspectedOrderGql = gql`
    mutation UserInspectedOrder($order_id: ID!) {
  UserInspectedOrder(order_id: $order_id) {
    id
    status
  }
}
    `;
export type UserInspectedOrderMutationFn = Apollo.MutationFunction<UserInspectedOrderMutation, UserInspectedOrderMutationVariables>;

/**
 * __useUserInspectedOrderMutation__
 *
 * To run a mutation, you first call `useUserInspectedOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserInspectedOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInspectedOrderMutation, { data, loading, error }] = useUserInspectedOrderMutation({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useUserInspectedOrderMutation(baseOptions?: Apollo.MutationHookOptions<UserInspectedOrderMutation, UserInspectedOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserInspectedOrderMutation, UserInspectedOrderMutationVariables>(UserInspectedOrderGql, options);
      }
export type UserInspectedOrderMutationHookResult = ReturnType<typeof useUserInspectedOrderMutation>;
export type UserInspectedOrderMutationResult = Apollo.MutationResult<UserInspectedOrderMutation>;
export type UserInspectedOrderMutationOptions = Apollo.BaseMutationOptions<UserInspectedOrderMutation, UserInspectedOrderMutationVariables>;
export const UserPublishReceiptGql = gql`
    mutation UserPublishReceipt($input: UserPublishReceiptInput!) {
  UserPublishReceipt(input: $input) {
    is_issued_receipt
    receipt_published_at
    receipt_number
    received_by_name
    received_for_name
    received_for_address
    received_for_telephone
    invoice {
      id
      order_id
      invoice_items {
        id
        product_name
        unit_name
        unit_price
        quantity
        created_at
        updated_at
      }
      name
      name_kana
      company_name
      company_name_kana
      department
      store_name
      telephone_number
      post_code
      prefecture
      address
      building_name
      subtotal
      consumption_tax
      total
      created_at
      updated_at
    }
  }
}
    `;
export type UserPublishReceiptMutationFn = Apollo.MutationFunction<UserPublishReceiptMutation, UserPublishReceiptMutationVariables>;

/**
 * __useUserPublishReceiptMutation__
 *
 * To run a mutation, you first call `useUserPublishReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPublishReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPublishReceiptMutation, { data, loading, error }] = useUserPublishReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserPublishReceiptMutation(baseOptions?: Apollo.MutationHookOptions<UserPublishReceiptMutation, UserPublishReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserPublishReceiptMutation, UserPublishReceiptMutationVariables>(UserPublishReceiptGql, options);
      }
export type UserPublishReceiptMutationHookResult = ReturnType<typeof useUserPublishReceiptMutation>;
export type UserPublishReceiptMutationResult = Apollo.MutationResult<UserPublishReceiptMutation>;
export type UserPublishReceiptMutationOptions = Apollo.BaseMutationOptions<UserPublishReceiptMutation, UserPublishReceiptMutationVariables>;
export const UserUpdateCurrentAccountGql = gql`
    mutation UserUpdateCurrentAccount($input: UserUpdateCurrentAccountInput!) {
  UserUpdateCurrentAccount(input: $input) {
    is_changed_email
    changed_email
  }
}
    `;
export type UserUpdateCurrentAccountMutationFn = Apollo.MutationFunction<UserUpdateCurrentAccountMutation, UserUpdateCurrentAccountMutationVariables>;

/**
 * __useUserUpdateCurrentAccountMutation__
 *
 * To run a mutation, you first call `useUserUpdateCurrentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateCurrentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateCurrentAccountMutation, { data, loading, error }] = useUserUpdateCurrentAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateCurrentAccountMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateCurrentAccountMutation, UserUpdateCurrentAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateCurrentAccountMutation, UserUpdateCurrentAccountMutationVariables>(UserUpdateCurrentAccountGql, options);
      }
export type UserUpdateCurrentAccountMutationHookResult = ReturnType<typeof useUserUpdateCurrentAccountMutation>;
export type UserUpdateCurrentAccountMutationResult = Apollo.MutationResult<UserUpdateCurrentAccountMutation>;
export type UserUpdateCurrentAccountMutationOptions = Apollo.BaseMutationOptions<UserUpdateCurrentAccountMutation, UserUpdateCurrentAccountMutationVariables>;
export const UserUpdateEmailGql = gql`
    mutation UserUpdateEmail($input: UserUpdateEmailInput!) {
  UserUpdateEmail(input: $input) {
    user_id
    expires
    signature
  }
}
    `;
export type UserUpdateEmailMutationFn = Apollo.MutationFunction<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>;

/**
 * __useUserUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUserUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateEmailMutation, { data, loading, error }] = useUserUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>(UserUpdateEmailGql, options);
      }
export type UserUpdateEmailMutationHookResult = ReturnType<typeof useUserUpdateEmailMutation>;
export type UserUpdateEmailMutationResult = Apollo.MutationResult<UserUpdateEmailMutation>;
export type UserUpdateEmailMutationOptions = Apollo.BaseMutationOptions<UserUpdateEmailMutation, UserUpdateEmailMutationVariables>;
export const UserUpdatePasswordGql = gql`
    mutation UserUpdatePassword($input: UserUpdatePasswordInput!) {
  UserUpdatePassword(input: $input)
}
    `;
export type UserUpdatePasswordMutationFn = Apollo.MutationFunction<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;

/**
 * __useUserUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUserUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePasswordMutation, { data, loading, error }] = useUserUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>(UserUpdatePasswordGql, options);
      }
export type UserUpdatePasswordMutationHookResult = ReturnType<typeof useUserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationResult = Apollo.MutationResult<UserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;
export const UserUpsertReturnAddressGql = gql`
    mutation UserUpsertReturnAddress($input: UserUpsertReturnAddressInput!) {
  UserUpsertReturnAddress(input: $input) {
    id
    name
    name_kana
    company_name
    company_name_kana
    department
    store_name
    telephone_number
    post_code
    prefecture
    address
    building_name
  }
}
    `;
export type UserUpsertReturnAddressMutationFn = Apollo.MutationFunction<UserUpsertReturnAddressMutation, UserUpsertReturnAddressMutationVariables>;

/**
 * __useUserUpsertReturnAddressMutation__
 *
 * To run a mutation, you first call `useUserUpsertReturnAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpsertReturnAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpsertReturnAddressMutation, { data, loading, error }] = useUserUpsertReturnAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpsertReturnAddressMutation(baseOptions?: Apollo.MutationHookOptions<UserUpsertReturnAddressMutation, UserUpsertReturnAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpsertReturnAddressMutation, UserUpsertReturnAddressMutationVariables>(UserUpsertReturnAddressGql, options);
      }
export type UserUpsertReturnAddressMutationHookResult = ReturnType<typeof useUserUpsertReturnAddressMutation>;
export type UserUpsertReturnAddressMutationResult = Apollo.MutationResult<UserUpsertReturnAddressMutation>;
export type UserUpsertReturnAddressMutationOptions = Apollo.BaseMutationOptions<UserUpsertReturnAddressMutation, UserUpsertReturnAddressMutationVariables>;
export const CurrentUserGql = gql`
    query CurrentUser {
  CurrentUser {
    id
    email
    business {
      name
      name_kana
      telephone_number
      post_code
      prefecture
      address
      building_name
      company_name
      company_name_kana
      store_name
      department
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserGql, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserGql, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GuestQuotationGql = gql`
    query GuestQuotation($input: QuotationInput!) {
  GuestQuotation(input: $input) {
    id
    order_id
    name
    name_kana
    company_name
    company_name_kana
    department
    store_name
    telephone_number
    post_code
    prefecture
    address
    building_name
    created_at
    updated_at
    product_code_count
    invoice_items {
      id
      product_name
      quantity
      unit_name
      unit_price
      price
      created_at
      updated_at
    }
    subtotal
    consumption_tax
    total
  }
}
    `;

/**
 * __useGuestQuotationQuery__
 *
 * To run a query within a React component, call `useGuestQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestQuotationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestQuotationQuery(baseOptions: Apollo.QueryHookOptions<GuestQuotationQuery, GuestQuotationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuestQuotationQuery, GuestQuotationQueryVariables>(GuestQuotationGql, options);
      }
export function useGuestQuotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestQuotationQuery, GuestQuotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuestQuotationQuery, GuestQuotationQueryVariables>(GuestQuotationGql, options);
        }
export type GuestQuotationQueryHookResult = ReturnType<typeof useGuestQuotationQuery>;
export type GuestQuotationLazyQueryHookResult = ReturnType<typeof useGuestQuotationLazyQuery>;
export type GuestQuotationQueryResult = Apollo.QueryResult<GuestQuotationQuery, GuestQuotationQueryVariables>;
export const UserDownloadListGql = gql`
    query UserDownloadList($input: UserDownloadListInput!) {
  UserDownloadList(input: $input) {
    data {
      name
      size
      unit
    }
    page
    last_page
    total
  }
}
    `;

/**
 * __useUserDownloadListQuery__
 *
 * To run a query within a React component, call `useUserDownloadListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDownloadListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDownloadListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDownloadListQuery(baseOptions: Apollo.QueryHookOptions<UserDownloadListQuery, UserDownloadListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDownloadListQuery, UserDownloadListQueryVariables>(UserDownloadListGql, options);
      }
export function useUserDownloadListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDownloadListQuery, UserDownloadListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDownloadListQuery, UserDownloadListQueryVariables>(UserDownloadListGql, options);
        }
export type UserDownloadListQueryHookResult = ReturnType<typeof useUserDownloadListQuery>;
export type UserDownloadListLazyQueryHookResult = ReturnType<typeof useUserDownloadListLazyQuery>;
export type UserDownloadListQueryResult = Apollo.QueryResult<UserDownloadListQuery, UserDownloadListQueryVariables>;
export const UserGenerateDownloadLinkGql = gql`
    query UserGenerateDownloadLink($input: UserGenerateDownloadLinkInput!) {
  UserGenerateDownloadLink(input: $input)
}
    `;

/**
 * __useUserGenerateDownloadLinkQuery__
 *
 * To run a query within a React component, call `useUserGenerateDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGenerateDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGenerateDownloadLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserGenerateDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<UserGenerateDownloadLinkQuery, UserGenerateDownloadLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGenerateDownloadLinkQuery, UserGenerateDownloadLinkQueryVariables>(UserGenerateDownloadLinkGql, options);
      }
export function useUserGenerateDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGenerateDownloadLinkQuery, UserGenerateDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGenerateDownloadLinkQuery, UserGenerateDownloadLinkQueryVariables>(UserGenerateDownloadLinkGql, options);
        }
export type UserGenerateDownloadLinkQueryHookResult = ReturnType<typeof useUserGenerateDownloadLinkQuery>;
export type UserGenerateDownloadLinkLazyQueryHookResult = ReturnType<typeof useUserGenerateDownloadLinkLazyQuery>;
export type UserGenerateDownloadLinkQueryResult = Apollo.QueryResult<UserGenerateDownloadLinkQuery, UserGenerateDownloadLinkQueryVariables>;
export const UserGenerateDownloadOrderCsvLinkGql = gql`
    query UserGenerateDownloadOrderCsvLink($order_id: ID!) {
  UserGenerateDownloadOrderCsvLink(order_id: $order_id)
}
    `;

/**
 * __useUserGenerateDownloadOrderCsvLinkQuery__
 *
 * To run a query within a React component, call `useUserGenerateDownloadOrderCsvLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGenerateDownloadOrderCsvLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGenerateDownloadOrderCsvLinkQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useUserGenerateDownloadOrderCsvLinkQuery(baseOptions: Apollo.QueryHookOptions<UserGenerateDownloadOrderCsvLinkQuery, UserGenerateDownloadOrderCsvLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGenerateDownloadOrderCsvLinkQuery, UserGenerateDownloadOrderCsvLinkQueryVariables>(UserGenerateDownloadOrderCsvLinkGql, options);
      }
export function useUserGenerateDownloadOrderCsvLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGenerateDownloadOrderCsvLinkQuery, UserGenerateDownloadOrderCsvLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGenerateDownloadOrderCsvLinkQuery, UserGenerateDownloadOrderCsvLinkQueryVariables>(UserGenerateDownloadOrderCsvLinkGql, options);
        }
export type UserGenerateDownloadOrderCsvLinkQueryHookResult = ReturnType<typeof useUserGenerateDownloadOrderCsvLinkQuery>;
export type UserGenerateDownloadOrderCsvLinkLazyQueryHookResult = ReturnType<typeof useUserGenerateDownloadOrderCsvLinkLazyQuery>;
export type UserGenerateDownloadOrderCsvLinkQueryResult = Apollo.QueryResult<UserGenerateDownloadOrderCsvLinkQuery, UserGenerateDownloadOrderCsvLinkQueryVariables>;
export const UserGenerateUploadOrderCsvLinkGql = gql`
    query UserGenerateUploadOrderCsvLink {
  UserGenerateUploadOrderCsvLink {
    url
    path
  }
}
    `;

/**
 * __useUserGenerateUploadOrderCsvLinkQuery__
 *
 * To run a query within a React component, call `useUserGenerateUploadOrderCsvLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGenerateUploadOrderCsvLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGenerateUploadOrderCsvLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGenerateUploadOrderCsvLinkQuery(baseOptions?: Apollo.QueryHookOptions<UserGenerateUploadOrderCsvLinkQuery, UserGenerateUploadOrderCsvLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGenerateUploadOrderCsvLinkQuery, UserGenerateUploadOrderCsvLinkQueryVariables>(UserGenerateUploadOrderCsvLinkGql, options);
      }
export function useUserGenerateUploadOrderCsvLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGenerateUploadOrderCsvLinkQuery, UserGenerateUploadOrderCsvLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGenerateUploadOrderCsvLinkQuery, UserGenerateUploadOrderCsvLinkQueryVariables>(UserGenerateUploadOrderCsvLinkGql, options);
        }
export type UserGenerateUploadOrderCsvLinkQueryHookResult = ReturnType<typeof useUserGenerateUploadOrderCsvLinkQuery>;
export type UserGenerateUploadOrderCsvLinkLazyQueryHookResult = ReturnType<typeof useUserGenerateUploadOrderCsvLinkLazyQuery>;
export type UserGenerateUploadOrderCsvLinkQueryResult = Apollo.QueryResult<UserGenerateUploadOrderCsvLinkQuery, UserGenerateUploadOrderCsvLinkQueryVariables>;
export const UserGenerateZipDownloadLinkGql = gql`
    query UserGenerateZipDownloadLink($order_id: ID!) {
  UserGenerateZipDownloadLink(order_id: $order_id)
}
    `;

/**
 * __useUserGenerateZipDownloadLinkQuery__
 *
 * To run a query within a React component, call `useUserGenerateZipDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGenerateZipDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGenerateZipDownloadLinkQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useUserGenerateZipDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<UserGenerateZipDownloadLinkQuery, UserGenerateZipDownloadLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGenerateZipDownloadLinkQuery, UserGenerateZipDownloadLinkQueryVariables>(UserGenerateZipDownloadLinkGql, options);
      }
export function useUserGenerateZipDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGenerateZipDownloadLinkQuery, UserGenerateZipDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGenerateZipDownloadLinkQuery, UserGenerateZipDownloadLinkQueryVariables>(UserGenerateZipDownloadLinkGql, options);
        }
export type UserGenerateZipDownloadLinkQueryHookResult = ReturnType<typeof useUserGenerateZipDownloadLinkQuery>;
export type UserGenerateZipDownloadLinkLazyQueryHookResult = ReturnType<typeof useUserGenerateZipDownloadLinkLazyQuery>;
export type UserGenerateZipDownloadLinkQueryResult = Apollo.QueryResult<UserGenerateZipDownloadLinkQuery, UserGenerateZipDownloadLinkQueryVariables>;
export const UserOrderDetailGql = gql`
    query UserOrderDetail($order_id: ID!) {
  UserOrderDetail(order_id: $order_id) {
    id
    status
    estimated_shipment_date
    remarks
    is_issued_receipt
    payment_method
    payment_status
    paid_at
    created_at
    updated_at
    invoice {
      address
      building_name
      company_name
      company_name_kana
      name
      name_kana
      order_id
      post_code
      prefecture
      department
      store_name
      telephone_number
      product_code_count
      invoice_items {
        product_name
        quantity
        unit_name
        unit_price
        price
      }
      subtotal
      consumption_tax
      total
    }
    download_expired_at
    return_item {
      status
      name
      name_kana
      company_name
      company_name_kana
      department
      store_name
      telephone_number
      post_code
      prefecture
      address
      building_name
      shipping_service
      shipping_code
    }
  }
}
    `;

/**
 * __useUserOrderDetailQuery__
 *
 * To run a query within a React component, call `useUserOrderDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrderDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrderDetailQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useUserOrderDetailQuery(baseOptions: Apollo.QueryHookOptions<UserOrderDetailQuery, UserOrderDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrderDetailQuery, UserOrderDetailQueryVariables>(UserOrderDetailGql, options);
      }
export function useUserOrderDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrderDetailQuery, UserOrderDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrderDetailQuery, UserOrderDetailQueryVariables>(UserOrderDetailGql, options);
        }
export type UserOrderDetailQueryHookResult = ReturnType<typeof useUserOrderDetailQuery>;
export type UserOrderDetailLazyQueryHookResult = ReturnType<typeof useUserOrderDetailLazyQuery>;
export type UserOrderDetailQueryResult = Apollo.QueryResult<UserOrderDetailQuery, UserOrderDetailQueryVariables>;
export const UserOrdersGql = gql`
    query UserOrders($page: Int, $first: Int!) {
  UserOrders(page: $page, first: $first) {
    data {
      id
      status
      download_expired_at
      created_at
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      total
      perPage
    }
  }
}
    `;

/**
 * __useUserOrdersQuery__
 *
 * To run a query within a React component, call `useUserOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserOrdersQuery(baseOptions: Apollo.QueryHookOptions<UserOrdersQuery, UserOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrdersQuery, UserOrdersQueryVariables>(UserOrdersGql, options);
      }
export function useUserOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrdersQuery, UserOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrdersQuery, UserOrdersQueryVariables>(UserOrdersGql, options);
        }
export type UserOrdersQueryHookResult = ReturnType<typeof useUserOrdersQuery>;
export type UserOrdersLazyQueryHookResult = ReturnType<typeof useUserOrdersLazyQuery>;
export type UserOrdersQueryResult = Apollo.QueryResult<UserOrdersQuery, UserOrdersQueryVariables>;
export const UserQuotationGql = gql`
    query UserQuotation($input: QuotationInput!) {
  UserQuotation(input: $input) {
    id
    order_id
    name
    name_kana
    company_name
    company_name_kana
    department
    store_name
    telephone_number
    post_code
    prefecture
    address
    building_name
    created_at
    updated_at
    product_code_count
    invoice_items {
      id
      product_name
      quantity
      unit_name
      unit_price
      price
      created_at
      updated_at
    }
    subtotal
    consumption_tax
    total
  }
}
    `;

/**
 * __useUserQuotationQuery__
 *
 * To run a query within a React component, call `useUserQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuotationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserQuotationQuery(baseOptions: Apollo.QueryHookOptions<UserQuotationQuery, UserQuotationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuotationQuery, UserQuotationQueryVariables>(UserQuotationGql, options);
      }
export function useUserQuotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuotationQuery, UserQuotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuotationQuery, UserQuotationQueryVariables>(UserQuotationGql, options);
        }
export type UserQuotationQueryHookResult = ReturnType<typeof useUserQuotationQuery>;
export type UserQuotationLazyQueryHookResult = ReturnType<typeof useUserQuotationLazyQuery>;
export type UserQuotationQueryResult = Apollo.QueryResult<UserQuotationQuery, UserQuotationQueryVariables>;
export const UserReturnAddressesGql = gql`
    query UserReturnAddresses {
  UserReturnAddresses {
    id
    name
    name_kana
    company_name
    company_name_kana
    department
    store_name
    telephone_number
    post_code
    prefecture
    address
    building_name
    created_at
    updated_at
  }
}
    `;

/**
 * __useUserReturnAddressesQuery__
 *
 * To run a query within a React component, call `useUserReturnAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReturnAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReturnAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserReturnAddressesQuery(baseOptions?: Apollo.QueryHookOptions<UserReturnAddressesQuery, UserReturnAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserReturnAddressesQuery, UserReturnAddressesQueryVariables>(UserReturnAddressesGql, options);
      }
export function useUserReturnAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserReturnAddressesQuery, UserReturnAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserReturnAddressesQuery, UserReturnAddressesQueryVariables>(UserReturnAddressesGql, options);
        }
export type UserReturnAddressesQueryHookResult = ReturnType<typeof useUserReturnAddressesQuery>;
export type UserReturnAddressesLazyQueryHookResult = ReturnType<typeof useUserReturnAddressesLazyQuery>;
export type UserReturnAddressesQueryResult = Apollo.QueryResult<UserReturnAddressesQuery, UserReturnAddressesQueryVariables>;
export const UserValidateTokenGql = gql`
    query UserValidateToken {
  UserValidateToken {
    is_user
    expires
  }
}
    `;

/**
 * __useUserValidateTokenQuery__
 *
 * To run a query within a React component, call `useUserValidateTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserValidateTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserValidateTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserValidateTokenQuery(baseOptions?: Apollo.QueryHookOptions<UserValidateTokenQuery, UserValidateTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserValidateTokenQuery, UserValidateTokenQueryVariables>(UserValidateTokenGql, options);
      }
export function useUserValidateTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserValidateTokenQuery, UserValidateTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserValidateTokenQuery, UserValidateTokenQueryVariables>(UserValidateTokenGql, options);
        }
export type UserValidateTokenQueryHookResult = ReturnType<typeof useUserValidateTokenQuery>;
export type UserValidateTokenLazyQueryHookResult = ReturnType<typeof useUserValidateTokenLazyQuery>;
export type UserValidateTokenQueryResult = Apollo.QueryResult<UserValidateTokenQuery, UserValidateTokenQueryVariables>;
export const HealthCheckGql = gql`
    query HealthCheck {
  HealthCheck {
    now
    livingDb
  }
}
    `;

/**
 * __useHealthCheckQuery__
 *
 * To run a query within a React component, call `useHealthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthCheckQuery(baseOptions?: Apollo.QueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckGql, options);
      }
export function useHealthCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckGql, options);
        }
export type HealthCheckQueryHookResult = ReturnType<typeof useHealthCheckQuery>;
export type HealthCheckLazyQueryHookResult = ReturnType<typeof useHealthCheckLazyQuery>;
export type HealthCheckQueryResult = Apollo.QueryResult<HealthCheckQuery, HealthCheckQueryVariables>;