/* https://v5.reactrouter.com/web/guides/scroll-restoration/scroll-to-top */
import { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'

/* lib, types, context */
import { initialCreateOrder, CreateOrderContext } from 'context'

export const ClearContext = (): null => {
  const { pathname } = useLocation()
  const { createOrder, updateCreateOrder } = useContext(CreateOrderContext)

  useEffect(() => {
    if (pathname !== '/order' && pathname !== '/order/confirm' && pathname !== '/order/estimate') {
      if (!!createOrder) {
        updateCreateOrder(initialCreateOrder.createOrder)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return null
}
