import 'styled-components'

export const theme = {
  white: '#fff',
  whiteRgb: '255, 255, 255',
  lightGray: '#f8f8f8',
  gray: '#ddd',
  grayRgb: '221, 221, 221',
  darkGray: '#7b7b7b',
  darkGrayRgb: '123, 123, 123',
  black: '#000',
  blackRgb: '0, 0, 0',
  yellow: '#f0db00',
  yellowRgb: '240, 219, 0',
  blue: '#00afe2',
  green: '#00c1a4',
  red: '#fc456d',
  orange: '#ffac30',
  successText: '#004800',
  successBg: '#d4edd8',
  errorText: '#f5554b',
  errorBg: '#fddbd9',
  footerBg: '#414141',
  zIndex: {
    header: 100,
    sideButtons: 200,
    modal: 300,
    loading: 400,
    flashMessage: 500,
  },
} as const

type Theme = typeof theme

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}
