import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

/* components */
import { ErrorMessage, TextInput, Button, ButtonLink } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { LoginInput, LoginInputErrors } from 'types/myTypes'
import { validateRequiredPassword, validateRequiredEmail } from 'lib/validate'

type Props = {
  className?: string
  loginInput: LoginInput
  onChangeState: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickLoginButton: () => void
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 3.2rem;
`

const LoginArea = styled.div`
  margin-bottom: 4.8rem;
  padding-bottom: 6.4rem;
  border-bottom: 0.2rem solid ${(props): string => props.theme.gray};
  input {
    width: 48rem;
    max-width: 100%;

    &.password {
      margin-top: 2.4rem;
    }
  }
  .login {
    width: 31.2rem;
    max-width: 100%;
    margin: 4rem auto 1rem;
    display: block;
  }
  .forget-password {
    width: 13em;
    margin: 0 auto;
    color: ${(props): string => props.theme.darkGray};
    text-decoration: underline;
    transition: 0.3s all;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  ${mediaSp`
    padding-bottom: 5.6rem;

    .login {
      margin: 4.8rem auto 0.8rem;
    }
  `}
`
const RegistrationArea = styled.div`
  text-align: center;

  .sub-title {
    margin-bottom: 3.2rem;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
  }
  .registration {
    width: 31.2rem;
    max-width: 100%;
    margin: 0 auto;
  }
  ${mediaSp`
    .sub-title {
      margin-bottom: 4rem;
      font-size: 1.6rem;
    }
  `}
`

export const LoginForm: React.FC<Props> = ({ className = '', loginInput, onChangeState, onClickLoginButton }) => {
  const [loginInputErrors, setLoginInputErrors] = useState<LoginInputErrors>({
    email: null,
    password: null,
  })

  const validate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    switch (name) {
      case 'email':
        setLoginInputErrors({ ...loginInputErrors, [name]: validateRequiredEmail(value) })
        break
      case 'password':
        setLoginInputErrors({ ...loginInputErrors, [name]: validateRequiredPassword(value) })
        break
      default:
        break
    }
  }

  const isDisable = (): boolean => {
    const { email, password } = loginInput
    const isRequiredItemsFilled = email && password
    if (!isRequiredItemsFilled) return true

    const errorArray = Object.values(loginInputErrors).filter((error) => error !== null)
    if (errorArray.length > 0) return true

    return false
  }

  return (
    <Wrapper className={className}>
      <LoginArea>
        <TextInput
          className="email"
          type="email"
          value={loginInput.email}
          name="email"
          placeholder="メールアドレス"
          onChange={(e) => {
            onChangeState(e)
            validate(e)
          }}
          error={!!loginInputErrors.email}
        />
        {!!loginInputErrors.email && <ErrorMessage message={loginInputErrors.email} />}
        <TextInput
          className="password"
          type="password"
          value={loginInput.password}
          name="password"
          placeholder="パスワード"
          onChange={(e) => {
            onChangeState(e)
            validate(e)
          }}
          error={!!loginInputErrors.password}
        />
        {!!loginInputErrors.password && <ErrorMessage message={loginInputErrors.password} />}

        <Button className="login" buttonType="yellow" disabled={isDisable()} onClick={onClickLoginButton}>
          ログイン
        </Button>

        <Link to="/reset-password/email" className="forget-password">
          パスワードをお忘れの場合
        </Link>
      </LoginArea>

      <RegistrationArea>
        <p className="sub-title">初めての方はこちら</p>
        <ButtonLink className="registration" buttonType="black" href="/registration">
          新規アカウント登録
        </ButtonLink>
      </RegistrationArea>
    </Wrapper>
  )
}
