import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

/* images */
// FIXME 画像は仮
// https://www.pivotaltracker.com/story/show/181906495
import Sample01 from 'assets/images/top/shooting-sample/sample01.png'
import Sample02 from 'assets/images/top/shooting-sample/sample02.png'
import Sample03 from 'assets/images/top/shooting-sample/sample03.png'

const Wrapper = styled.div`
  .inner {
    padding: 8rem 2rem 12rem;
    background: ${(props): string => props.theme.lightGray};
    border-radius: 0px 100px 100px 0px;

    .section-title {
      margin-bottom: 3.2rem;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.5;
      text-align: center;
    }
    p {
      margin-bottom: 5.6rem;
      font-size: 1.6rem;
      line-height: 1.5;
      text-align: center;
    }
    .sample-list {
      width: 814px;
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 3.2rem;

      img {
        width: 250px;
        max-width: calc((100% - 6.4rem) / 3);
      }
    }
  }

  ${mediaSp`
    .inner {
      padding: 5.5rem 2rem 8rem;
      border-radius: 0px 3rem 3rem 0px;

      .section-title {
        margin-bottom: 1.2rem;
        font-size: 2.4rem;
      }
      p {
        margin-bottom: 3.2rem;
        font-size: 1.4rem;
      }
      .sample-list {
        width: 100%;
        gap: 1.2rem 1.1rem;

        img {
          width: 10.4rem;
          max-width: calc((100% - 2.2rem) / 3)
        }
      }
    }

  `}
`
// FIXME 画像は仮
// https://www.pivotaltracker.com/story/show/181906495
const sampleImageList = [
  {
    href: Sample01,
    alt: 'サンプル01',
  },
  {
    href: Sample02,
    alt: 'サンプル02',
  },
  {
    href: Sample03,
    alt: 'サンプル03',
  },
  {
    href: Sample01,
    alt: 'サンプル01',
  },
  {
    href: Sample02,
    alt: 'サンプル02',
  },
  {
    href: Sample03,
    alt: 'サンプル03',
  },
  {
    href: Sample01,
    alt: 'サンプル01',
  },
  {
    href: Sample02,
    alt: 'サンプル02',
  },
  {
    href: Sample03,
    alt: 'サンプル03',
  },
]

export const TopShootingSample: React.FC = () => {
  return (
    <Wrapper>
      <div className="inner">
        <h2 className="section-title">撮影サンプル</h2>
        <p>様々な商品の撮影に対応しております</p>
        <div className="sample-list">
          {sampleImageList.map((image, index) => (
            <img key={index} src={image.href} alt={image.alt} />
          ))}
        </div>
      </div>
    </Wrapper>
  )
}
