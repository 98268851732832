import React from 'react'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { FlashMessageType } from 'types/myTypes'

type Props = {
  className?: string
  flashMessage: FlashMessageType
}

type StyleProps = {
  type: 'success' | 'error'
}

const getColor = (props: ThemedStyledProps<StyleProps, DefaultTheme>): string => {
  switch (props.type) {
    case 'success':
      return `
        color: ${props.theme.successText};
        background: ${props.theme.successBg};
      `
    case 'error':
      return `
        color: ${props.theme.errorText};
        background: ${props.theme.errorBg};
      `
    default:
      return `
        color: ${props.theme.successText};
        background: ${props.theme.successBg};
      `
  }
}

const StyledFlashMessage = styled.div<StyleProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  z-index: ${(props): number => props.theme.zIndex.flashMessage};
  animation: slideDown 6s forwards;
  .inner {
    max-width: calc(100vw - 64px);
    min-width: 840px;
    padding: 24px;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;

    ${(props) => getColor(props)}
  }
  @keyframes slideDown {
    0% {
      top: 0px;
    }
    5% {
      top: 94px;
    }
    95% {
      top: 94px;
    }
    100% {
      top: -72px;
    }
  }

  ${mediaSp`
    top: 7.4rem;
    .inner {
      min-width: calc(100vw - 2.4rem);
      font-size: 1.3rem;
      padding: 1.5rem;
    }
    @keyframes slideDown {
      0% {
        top: 0;
      }
      5% {
        top: 7.4rem;;
      }
      95% {
        top: 7.4rem;;
      }
      100% {
        top: -4.95rem;
      }
    }
  `}
`

export const FlashMessage: React.FC<Props> = ({ className = '', flashMessage }) => {
  return (
    <StyledFlashMessage className={className} type={flashMessage.type}>
      <div className="inner">{flashMessage.message}</div>
    </StyledFlashMessage>
  )
}
