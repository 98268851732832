import React from 'react'
import { TermsOfUseTpl } from '../components/templates'
import { Meta } from 'components/meta'

export const TermsOfUse: React.FC = () => {
  return (
    <>
      <Meta title="ご利用規約" />
      <TermsOfUseTpl />
    </>
  )
}
