import React from 'react'
import styled from 'styled-components'

/* components */
import { ErrorMessage } from 'components/atoms'
import { TextInputWithLabel, SelectboxWithLabel } from 'components/molecules'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { prefList } from 'lib/select-options'
import { AddressInput, AddressInputErrors } from 'types/myTypes'

type Props = {
  className?: string
  addressInput: AddressInput
  emailInput?: string
  errors: AddressInputErrors
  emailError?: string | null
  onChangeState: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void
}

const Wrapper = styled.div`
  width: 100%;
`

const StyledFormItem = styled.div`
  width: 100%;
  margin-bottom: 3.2rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledFormPcFlexItem = styled(StyledFormItem)`
  display: flex;
  justify-content: space-between;

  > div {
    width: 47.6%;
  }

  &.small-inputs {
    justify-content: flex-start;

    .post-code {
      width: 177px;
      margin-right: 32px;
    }

    .prefecture {
      width: 240px;
    }
  }

  ${mediaSp`
    display: block;

    >div {
      width: 100%;
      margin-bottom: 3.2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.small-inputs {
      display: flex;

      >div {
        margin-bottom: 0;
      }

      .post-code {
        width: 10.5rem;
        margin-right: 2.4rem;
      }

      .prefecture {
        width: 17.8rem;
      }
    }
  `}
`

export const AddressForm: React.FC<Props> = ({ className = '', addressInput, emailInput, errors, emailError, onChangeState }) => {
  const prefOptions: { value: string; label: string }[] = [{ value: '', label: '選択してください' }]
  prefList.forEach((pref) => {
    prefOptions.push({ value: pref.value, label: pref.label })
  })

  return (
    <Wrapper className={className}>
      <StyledFormPcFlexItem>
        <div>
          <TextInputWithLabel
            labelText="氏名"
            required
            value={addressInput.name}
            name="name"
            placeholder="山田太郎"
            onChange={onChangeState}
            error={!!errors.name}
          />
          {!!errors.name && <ErrorMessage message={errors.name} />}
        </div>
        <div>
          <TextInputWithLabel
            labelText="氏名(ふりがな)"
            required
            value={addressInput.name_kana}
            name="name_kana"
            placeholder="やまだたろう"
            onChange={onChangeState}
            error={!!errors.name_kana}
          />
          {!!errors.name_kana && <ErrorMessage message={errors.name_kana} />}
        </div>
      </StyledFormPcFlexItem>
      {emailInput !== undefined && (
        <StyledFormItem>
          <TextInputWithLabel
            labelText="メールアドレス"
            required
            value={emailInput}
            name="email"
            onChange={onChangeState}
            error={!!emailError}
          />
          {!!emailError && <ErrorMessage message={emailError} />}
        </StyledFormItem>
      )}
      <StyledFormItem>
        <TextInputWithLabel
          labelText="電話番号"
          required
          value={addressInput.telephone_number}
          name="telephone_number"
          placeholder="09012345678"
          onChange={onChangeState}
          error={!!errors.telephone_number}
        />
        {!!errors.telephone_number && <ErrorMessage message={errors.telephone_number} />}
      </StyledFormItem>
      <StyledFormPcFlexItem className="small-inputs">
        <div className="post-code">
          <TextInputWithLabel
            labelText="郵便番号"
            required
            value={addressInput.post_code}
            name="post_code"
            placeholder="0000000"
            onChange={onChangeState}
            error={!!errors.post_code}
          />
          {!!errors.post_code && <ErrorMessage message={errors.post_code} />}
        </div>
        <div className="prefecture">
          <SelectboxWithLabel
            labelText="都道府県"
            required
            value={addressInput.prefecture || ''}
            name="prefecture"
            options={prefOptions}
            onChange={onChangeState}
            error={!!errors.prefecture}
          />
          {!!errors.prefecture && <ErrorMessage message={errors.prefecture} />}
        </div>
      </StyledFormPcFlexItem>
      <StyledFormItem>
        <TextInputWithLabel
          labelText="住所"
          required
          value={addressInput.address}
          name="address"
          placeholder="市区町村名 (例:千代田区神田1-1-1)"
          onChange={onChangeState}
          error={!!errors.address}
        />
        {!!errors.address && <ErrorMessage message={errors.address} />}
      </StyledFormItem>
      <StyledFormItem>
        <TextInputWithLabel
          labelText="建物名・部屋番号"
          value={addressInput.building_name || ''}
          name="building_name"
          placeholder="○○○ビル 101号室"
          onChange={onChangeState}
        />
      </StyledFormItem>
      <StyledFormPcFlexItem>
        <div>
          <TextInputWithLabel
            labelText="会社名"
            value={addressInput.company_name || ''}
            name="company_name"
            onChange={onChangeState}
            error={!!errors.company_name}
          />
          {!!errors.company_name && <ErrorMessage message={errors.company_name} />}
        </div>
        <div>
          <TextInputWithLabel
            labelText="会社名(ふりがな)"
            value={addressInput.company_name_kana || ''}
            name="company_name_kana"
            onChange={onChangeState}
            error={!!errors.company_name_kana}
          />
          {!!errors.company_name_kana && <ErrorMessage message={errors.company_name_kana} />}
        </div>
      </StyledFormPcFlexItem>
      <StyledFormItem>
        <TextInputWithLabel labelText="部署名" value={addressInput.department || ''} name="department" onChange={onChangeState} />
      </StyledFormItem>
      <StyledFormItem>
        <TextInputWithLabel labelText="店舗名" value={addressInput.store_name || ''} name="store_name" onChange={onChangeState} />
      </StyledFormItem>
    </Wrapper>
  )
}
