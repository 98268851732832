import React from 'react'
import styled from 'styled-components'

/* components */
import { Label, TextInput } from 'components/atoms'

type Props = {
  className?: string
  labelText: string
  required?: boolean
  type?: string
  name?: string
  value?: string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type StyleProps = {
  error?: boolean
}

const Wrapper = styled.div`
  width: 100%;

  .label {
    margin-bottom: 1.6rem;
    display: block;
  }
`

export const TextInputWithLabel: React.FC<Props & StyleProps> = ({
  className = '',
  labelText,
  required,
  type,
  name,
  value,
  placeholder,
  error,
  onChange,
}) => {
  return (
    <Wrapper className={className}>
      <Label text={labelText} required={required} className="label" />
      <TextInput type={type} name={name} placeholder={placeholder} value={value} error={error} onChange={onChange} />
    </Wrapper>
  )
}
