import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  currentStep: number
}

const Wrapper = styled.div<Props>`
  width: auto;
  margin: 0 auto;
  display: flex;
  border-radius: 0.8rem;
  overflow: hidden;
  .step-item {
    width: 24.7%;
    text-align: center;
    font-size: 2.2rem;
    line-height: 7.2rem;
    font-weight: 700;
    letter-spacing: 0.05em;
    position: relative;
    background: ${(props): string => props.theme.lightGray};

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 1.1rem;
      right: -2.3rem;
      width: 4.5rem;
      height: 4.5rem;
      background: ${(props): string => props.theme.lightGray};
      transform: rotate(40deg) skewY(15deg);
      border: 0.5rem solid ${(props): string => props.theme.white};
      border-width: 0.5rem 0.5rem 0 0;
      z-index: -1;
    }

    &.active {
      background: ${(props): string => props.theme.yellow};
      &:before {
        background: ${(props): string => props.theme.yellow};
      }
    }
    &:last-child {
      width: 25.9%;
      &:before {
        display: none;
      }
    }
  }
  ${mediaSp`
    .step-item {
      font-size: 1.2rem;
      line-height: 4rem;
      text-indent: 2rem;
      &:before {
        top: 0.6rem;
        right: -1.3rem;
        width: 2.4rem;
        height: 2.4rem;
        border: 0.2rem solid ${(props): string => props.theme.white};
        border-width: 0.2rem 0.2rem 0 0;
      }
      &:first-child,
      &:last-child {
        text-indent: 0;
      }
    }
  `}
`

export const OrderFormSteps: React.FC<Props> = ({ className = '', currentStep }) => {
  const steps = ['入力', '内容確認', '支払い', '完了']

  return (
    <Wrapper className={className} currentStep={currentStep}>
      {steps.map((text, index) => {
        return (
          <div key={index} className={currentStep >= index + 1 ? 'step-item active' : 'step-item'} style={{ zIndex: steps.length - index }}>
            {text}
          </div>
        )
      })}
    </Wrapper>
  )
}
