import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { ResetPasswordTpl } from 'components/templates'
import { Loading, FlashMessage } from 'components/atoms'

import { ResetPasswordInput, FlashMessageType } from 'types/myTypes'
import { useGuestResetPasswordMutation } from 'types/graphql'

export const ResetPassword: React.FC = () => {
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [guestResetPasswordMutation, { loading }] = useGuestResetPasswordMutation({
    onCompleted: (data) => {
      if (!data || !data.GuestResetPassword) return
      navigate('/login', {
        state: {
          redirectFrom: pathname,
        },
      })
    },
    onError: (e) => {
      Sentry.captureException(e)
      // 失敗時
      navigate('/reset-password/email', {
        state: {
          redirectFrom: pathname,
        },
      })
    },
  })

  const resetPasswordInitialState: ResetPasswordInput = {
    password: '',
    passwordConfirm: '',
  }

  const [resetPasswordInput, setResetPasswordInput] = useState<ResetPasswordInput>(resetPasswordInitialState)

  const setResetPasswordState = (name: string, value: string): void => {
    setResetPasswordInput({ ...resetPasswordInput, [name]: value })
  }

  const onClickEdit = async (): Promise<void> => {
    const params = new URLSearchParams(window.location.search)
    const email = params.get('email') || null
    const token = params.get('token') || null

    if (token === null || email === null) {
      return
    }
    setFlashMessage(null)
    await guestResetPasswordMutation({
      variables: {
        input: {
          email: email,
          password: resetPasswordInput.password,
          token: token,
        },
      },
    })
  }

  return (
    <>
      <Meta title="パスワード再設定" />
      {loading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <ResetPasswordTpl resetPasswordInput={resetPasswordInput} setResetPasswordState={setResetPasswordState} onClickEdit={onClickEdit} />
    </>
  )
}
