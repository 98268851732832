import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { MypageAccountEditReturnAddressTpl, MypageLayout } from 'components/templates'

/* lib, types, const */
import { validateRequiredPostCode } from 'lib/validate'
import { getAddressFromPostCode } from 'lib/post-code-to-address'
import { useUserUpsertReturnAddressMutation, ReturnAddress } from 'types/graphql'
import { AddressInput, FlashMessageType } from 'types/myTypes'

export const MypageAccountEditReturnAddress: React.FC = () => {
  const addressInitialInput: AddressInput = {
    name: '',
    name_kana: '',
    company_name: '',
    company_name_kana: '',
    department: '',
    store_name: '',
    telephone_number: '',
    post_code: '',
    prefecture: '',
    address: '',
    building_name: '',
  }

  const [returnAddressId, setReturnAddressId] = useState<string>()
  const [returnAddressInput, setReturnAddressInput] = useState<AddressInput>(addressInitialInput)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const navigate = useNavigate()
  const location = useLocation()
  const { state, pathname } = location as unknown as Location & { state: { returnAddress: ReturnAddress } }

  const [userUpsertReturnAddressMutation] = useUserUpsertReturnAddressMutation({
    onCompleted: () => {
      setIsLoading(false)
      navigate('/mypage/account', {
        state: {
          redirectFrom: pathname,
        },
      })
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: '更新に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const onChangeState = async (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const { name, value } = e.target
    setReturnAddressInput({ ...returnAddressInput, [name]: value })

    if (name === 'post_code') {
      if (!validateRequiredPostCode(value)) {
        const data = await getAddressFromPostCode(value)
        if (data) {
          setReturnAddressInput({
            ...returnAddressInput,
            [name]: value,
            prefecture: data.address1,
            address: `${data.address2}${data.address3}`,
          })
        }
      }
    }
  }

  const onClickEdit = (): void => {
    setIsLoading(true)
    setFlashMessage(null)
    userUpsertReturnAddressMutation({
      variables: {
        input: {
          business_return_address_id: returnAddressId,
          name: returnAddressInput.name,
          name_kana: returnAddressInput.name_kana,
          company_name: returnAddressInput.company_name,
          company_name_kana: returnAddressInput.company_name_kana,
          department: returnAddressInput.department,
          store_name: returnAddressInput.store_name,
          telephone_number: returnAddressInput.telephone_number,
          post_code: returnAddressInput.post_code,
          prefecture: returnAddressInput.prefecture,
          address: returnAddressInput.address,
          building_name: returnAddressInput.building_name,
        },
      },
    })
  }

  useEffect(() => {
    const { id, ...addressWithoutId } = state.returnAddress
    setReturnAddressId(id as string)
    setReturnAddressInput(addressWithoutId as AddressInput)
  }, [state.returnAddress])

  return (
    <>
      <Meta title="返送先住所変更" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <MypageLayout pageTitle="返送先住所変更">
        <MypageAccountEditReturnAddressTpl
          returnAddressInput={returnAddressInput}
          onChangeState={onChangeState}
          onClickEdit={onClickEdit}
        />
      </MypageLayout>
    </>
  )
}
