import React from 'react'
import styled, { css } from 'styled-components'
import { mediaPc } from '../../../lib/media-query'

type Props = {
    className?: string
    children?: React.ReactNode
    withoutSubtitle?: boolean
}

const StyledTermsContent = styled.p<Props>`
  font-size: 3.4vw;
  color: ${(props): string => props.theme.black};
  letter-spacing: 0.05em;
  line-height: 1.62;

  ${mediaPc`
  width: 78.1%;
  font-size: 13px;
  `}

  ${(props) =>
    props.withoutSubtitle &&
    css`
      ${mediaPc`
      width: 100%
    `}
    `}
`

export const TermsContent: React.FC<Props> = ({ className = '', children, withoutSubtitle = false }) => {
    return (
        <StyledTermsContent className={className} withoutSubtitle={withoutSubtitle}>
            {children}
        </StyledTermsContent>
    )
}
