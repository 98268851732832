import { Dispatch, SetStateAction, useState } from 'react'
import * as Sentry from '@sentry/react'

/* lib, types, const */
import { User, Business, useCurrentUserLazyQuery, CurrentUserQuery, Exact } from 'types/graphql'
import { AddressInput, OrderInputErrors, FlashMessageType } from 'types/myTypes'
import {
  validateRequiredPostCode,
  validateKana,
  validateAddressInput,
  validateCompanyNameWithCompanyNameKana,
  validateCompanyNameKanaWithCompanyName,
} from 'lib/validate'
import { getAddressFromPostCode } from 'lib/post-code-to-address'
import { QueryLazyOptions, LazyQueryResult } from '@apollo/client'

type OrderUserArgs = {
  addressInitialInput: AddressInput
  errors: OrderInputErrors
  setErrors: Dispatch<SetStateAction<OrderInputErrors>>
  setFlashMessage: Dispatch<SetStateAction<FlashMessageType | null>>
}

type OrderUserStates = {
  user?: User
  userInput: AddressInput
  setUser: Dispatch<SetStateAction<User | undefined>>
  setUserInput: Dispatch<SetStateAction<AddressInput>>
}

type OrderUserValues = {
  currentUserQueryLoading: boolean
  onChangeUser: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => Promise<void>
  currentUserLazyQuery: (
    options?:
      | QueryLazyOptions<
          Exact<{
            [key: string]: never
          }>
        >
      | undefined,
  ) => Promise<
    LazyQueryResult<
      CurrentUserQuery,
      Exact<{
        [key: string]: never
      }>
    >
  >
}

export type OrderUserProps = OrderUserStates & OrderUserValues

export const useOrderUser = (args: OrderUserArgs): OrderUserProps => {
  const { addressInitialInput, errors, setErrors, setFlashMessage } = args

  const [user, setUser] = useState<User>()
  const [userInput, setUserInput] = useState<AddressInput>(addressInitialInput)

  const [currentUserLazyQuery, { loading: currentUserQueryLoading }] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data || !data.CurrentUser) return
      setUser(data.CurrentUser)
      // 返送先に注文者情報をコピーするために先にinputに入れておく
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...businessWithoutId } = data.CurrentUser?.business as Business
      setUserInput(businessWithoutId as AddressInput)
    },
    onError: (e) => {
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  // 注文者情報のバリデーション
  const validateUser = (name: string, value: string): void => {
    switch (name) {
      case 'name':
      case 'prefecture':
      case 'address':
      case 'name_kana':
      case 'telephone_number':
      case 'post_code':
        setErrors({ ...errors, user: { ...errors.user, [name]: validateAddressInput(name, value) } })
        break
      case 'company_name':
        setErrors({
          ...errors,
          user: {
            ...errors.user,
            company_name: validateCompanyNameWithCompanyNameKana(value, userInput.company_name_kana),
            company_name_kana: validateCompanyNameKanaWithCompanyName(value, userInput.company_name_kana),
          },
        })
        break
      case 'company_name_kana':
        setErrors({
          ...errors,
          user: {
            ...errors.user,
            company_name: validateCompanyNameWithCompanyNameKana(userInput.company_name, value),
            company_name_kana: validateCompanyNameKanaWithCompanyName(userInput.company_name, value) || validateKana(value),
          },
        })
        break
      default:
        break
    }
  }

  // 注文者情報の変更＆郵便番号から住所自動入力
  const onChangeUser = async (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const { name, value } = e.target
    setUserInput({ ...userInput, [name]: value })
    validateUser(name, value)

    if (name === 'post_code') {
      if (!validateRequiredPostCode(value)) {
        const data = await getAddressFromPostCode(value)
        if (data) {
          setUserInput({ ...userInput, [name]: value, prefecture: data.address1, address: `${data.address2}${data.address3}` })
        }
      }
    }
  }

  return {
    /* state */
    user,
    userInput,
    setUser,
    setUserInput,
    /* value */
    currentUserQueryLoading,
    onChangeUser,
    currentUserLazyQuery,
  }
}
