import React from 'react'
import styled from 'styled-components'

/* components */
import { Button, TextButton, TextLink } from 'components/atoms'
import { TopOrderFlowItem } from 'components/molecules'

/* lib, types, const*/
import { mediaSp } from 'lib/media-query'
import { ORDER_TEMPLATE_CSV_DOWNLOAD_PATH, ORDER_TEMPLATE_CSV_DOWNLOAD_FILE_NAME } from 'const'

/* images */
import step01Img from 'assets/images/top/order-flow/step01.svg'
import step02Img from 'assets/images/top/order-flow/step02.svg'
import step03Img from 'assets/images/top/order-flow/step03.svg'
import step04Img from 'assets/images/top/order-flow/step04.svg'
import step05Img from 'assets/images/top/order-flow/step05.svg'
import { ReactComponent as DownloadIconSvg } from 'assets/images/icon/download.svg'
import { ReactComponent as ArrowSvg } from 'assets/images/top/order-flow/arrow.svg'

type Props = {
  onClickOpenCsvHowtoModal: () => void
}

const Wrapper = styled.div`
  background: ${(props): string => props.theme.lightGray};

  .inner {
    padding: 80px 0 120px;
    background: ${(props): string => props.theme.white};
    border-radius: 100px 0 0 100px;

    .content {
      width: 666px;
      margin: 0 auto;

      .section-title {
        margin-bottom: 57px;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
      }

      .arrow-wrapper {
        margin: 30px 0 25px;
        text-align: center;
      }

      .description {
        font-size: 1.4rem;
        line-height: 1.7;
        white-space: pre-wrap;
      }

      .step1 {
        .csv-download-button {
          width: 28.6rem;
          margin: 1.6rem 0 0.9rem;
          padding-left: 29px;
          font-size: 17px;
          position: relative;
          display: block;

          .icon {
            width: 15px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 3.6rem;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            fill: ${(props): string => props.theme.black};
            transition: 0.3s all;
          }
        }

        .howto-csv-modal-button {
          padding-left: 94px;
          font-size: 16px;
          line-height: 1.5;
        }
      }

      .step2 {
        .note {
          margin-bottom: 11px;
          padding-top: 0.5rem;
          font-size: 1.2rem;
          line-height: 1;
        }

        .to-registration {
          font-size: 16px;
          letter-spacing: 0.05em;
        }
      }
    }
  }

  ${mediaSp`
    .inner {
      padding: 5rem 0 8rem;
      border-radius: 3rem 0 0 3rem;

      .content {
        width: 33.5rem;

        .section-title {
          margin-bottom: 3.2rem;
          font-size: 2.4rem;
        }

        .arrow-wrapper {
          margin: 3.5rem 0 2rem;
        }

        .step1 {
          .csv-download-button {
            padding-left: 2.9rem;
            font-size: 1.5rem;

            .icon {
              width: 1.3rem;
              height: 1.3rem;
              left: 4.5rem;
            }
          }

          .howto-csv-modal-button {
            margin: 0 auto;
            padding-left: 0;
            font-size: 1.2rem;
          }
        }

        .step2 {
          .note {
            margin-bottom: 0.9rem;
          }

          .to-registration {
            font-size: 1.4rem;
          }
        }
      }
    }
  `}
`

export const TopOrderFlow: React.FC<Props> = ({ onClickOpenCsvHowtoModal }) => {
  return (
    <Wrapper>
      <div className="inner">
        <div className="content">
          <h2 className="section-title">ご依頼の流れ</h2>

          <TopOrderFlowItem
            step={1}
            stepTitle="CSVで簡単見積"
            stepImage={step01Img}
            imagePaddingTop={60}
            imagePaddingLeft={26}
            className="step1"
          >
            <p className="description">
              CSVファイルに注文内容をご入力いただき、
              <br />
              アップロードしていただくとその場で御見積が表示されます。
            </p>
            <a href={ORDER_TEMPLATE_CSV_DOWNLOAD_PATH} download={ORDER_TEMPLATE_CSV_DOWNLOAD_FILE_NAME}>
              <Button className="csv-download-button" buttonType="yellowBorder">
                <DownloadIconSvg className="icon" />
                注文用CSVダウンロード
              </Button>
            </a>
            <TextButton onClick={onClickOpenCsvHowtoModal} className="howto-csv-modal-button">
              CSV入力方法
            </TextButton>
          </TopOrderFlowItem>

          <div className="arrow-wrapper">
            <ArrowSvg />
          </div>

          <TopOrderFlowItem step={2} stepTitle="注文" stepImage={step02Img} imagePaddingTop={60} imagePaddingLeft={40} className="step2">
            <p className="description">
              見積内容に問題がなければ、
              <br />
              注文者情報、商品の発送予定日、返送先住所を入力いただき、
              <br className="pc" />
              注文を完了してください。
            </p>
            <p className="note">※ご注文には会員登録が必要です。</p>
            <TextLink text="会員登録ページ" href="/registration" className="to-registration" />
          </TopOrderFlowItem>

          <div className="arrow-wrapper">
            <ArrowSvg />
          </div>

          <TopOrderFlowItem step={3} stepTitle="進捗状況を確認" stepImage={step03Img} imagePaddingTop={38} imagePaddingLeft={40}>
            <p className="description">
              撮影の進捗状況はマイページからご確認いただけます。
              <br />
              納品まで少しお待ちください。
            </p>
          </TopOrderFlowItem>

          <div className="arrow-wrapper">
            <ArrowSvg />
          </div>

          <TopOrderFlowItem step={4} stepTitle="納品のお知らせ" stepImage={step04Img} imagePaddingTop={40} imagePaddingLeft={53}>
            <p className="description">メールにて納品のお知らせをお送りさせていただきます。</p>
          </TopOrderFlowItem>

          <div className="arrow-wrapper">
            <ArrowSvg />
          </div>

          <TopOrderFlowItem step={5} stepTitle="ダウンロード" stepImage={step05Img} imagePaddingTop={52} imagePaddingLeft={40}>
            <p className="description">納品画像はマイページから ダウンロードが可能です。</p>
          </TopOrderFlowItem>
        </div>
      </div>
    </Wrapper>
  )
}
