import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import { useCookies } from 'react-cookie'
import * as Sentry from '@sentry/react'

/* components */
import { Button, ButtonLink, Loading, FlashMessage, TextButton } from 'components/atoms'

/* lib, types*/
import { mediaSpHeader, mediaSp } from 'lib/media-query'
import { useValidateToken } from 'lib/validate-token'
import { useLogoutUserMutation } from 'types/graphql'
import { FlashMessageType } from 'types/myTypes'
import { FAQ_URL } from 'const'

/* images */
import { ReactComponent as LogoutSvg } from 'assets/images/icon/logout.svg'
import { ReactComponent as LogoSvg } from 'assets/images/logo.svg'

const MenuButton = styled.div`
  position: relative;
  width: 43px;
  height: 29px;
  cursor: pointer;
  top: 35px;
  z-index: ${(props): number => props.theme.zIndex.header + 2};

  span {
    position: absolute;
    width: 100%;
    height: 0.3rem;
    background-color: ${(props): string => props.theme.black};
    border-radius: 0.2rem;
    transition: all 0.5s;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 13px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
  &.active {
    span {
      &:nth-of-type(1) {
        -webkit-transform: translateY(13px) rotate(45deg);
        transform: translateY(13px) rotate(45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        -webkit-transform: translateY(-13px) rotate(-45deg);
        transform: translateY(-13px) rotate(-45deg);
      }
    }
  }
  ${mediaSp`
    width: 2.5rem;
    height: 2.1rem;
    top: 2rem;
    span{
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 0.9rem;
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }
    &.active {
      span {
        &:nth-of-type(1) {
          -webkit-transform: translateY(0.9rem) rotate(45deg);
          transform: translateY(0.9rem) rotate(45deg);
        }
        &:nth-of-type(3) {
          -webkit-transform: translateY(-0.9rem) rotate(-45deg);
          transform: translateY(-0.9rem) rotate(-45deg);
        }
      }
    }
  `}
`
const ModalMenu = styled.div`
  position: fixed;
  top: 0;
  z-index: ${(props): number => props.theme.zIndex.header + 1};
  display: none;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: ${(props): string => props.theme.lightGray};
  transition: opacity 0.5s, display 0.1s;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  nav {
    padding: 32px 0 64px;
    margin: 90px auto 0;
    max-width: 1024px;
    width: 81%;
    height: calc(100vh - 122px);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    ul {
      display: block;
      li {
        padding: 32px 40px;
        border-bottom: 1px solid ${(props): string => props.theme.gray};
        &:last-child {
          border-bottom: 0px;
        }
        button {
          text-decoration: none;
        }
        > * {
          font-size: 18px;
          font-weight: 500;
          color: ${(props): string => props.theme.black};
          &:hover {
            opacity: 0.4;
          }
        }
      }
      .mypage-link {
        padding: 32px 0 0 40px;
        border-bottom: 1px solid ${(props): string => props.theme.gray};
        p {
          font-size: 18px;
          font-weight: 500;
          color: ${(props): string => props.theme.black};
          transition: all 0.3s;
        }
      }
    }
  }

  ${mediaSp`
    nav {
      margin: 5.8rem auto 0;
      padding: 2.4rem 0 0;
      width: 33.5rem;
      height: calc(100vh - 8.2rem);
      ul {
        display: block;
        li {
          padding: 2.4rem 2rem;
          border-bottom: 1px solid ${(props: ThemeProps<DefaultTheme>): string => props.theme.gray};
          &:last-child {
            border-bottom: 0px;
          }
          > * {
            font-size: 1.6rem;
          }
        }
        .mypage-link {
          padding: 2.4rem 0 0 2rem;
          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  `}
`

const StyledHeader = styled.header`
  width: 100vw;
  z-index: ${(props): number => props.theme.zIndex.header};
  position: fixed;
  background-color: ${(props): string => props.theme.white};
  .sp {
    display: none;
  }

  .row {
    width: 100%;
    height: 90px;
    padding: 0 32px;
    display: flex;
    justify-content: end;

    h1 {
      padding: 25px 0 0;
      font-size: 38px;
      font-weight: 800;
      line-height: 1.5;
      margin-right: auto;
      cursor: pointer;
      z-index: ${(props): number => props.theme.zIndex.header + 2};
      transition: all 0.3s;
      &:hover {
        opacity: 0.4;
      }
      .logo {
        width: 206px;
        height: 51px;
      }
    }

    nav {
      padding: 41px 0 0;
      ul {
        display: flex;
        margin: 0 24px 0 0;
        li {
          padding: 0 24px;
          a {
            padding: 0 0 23px;
            font-size: 18px;
            font-weight: 500;
            color: ${(props): string => props.theme.black};
            transition: all 0.3s;
            cursor: pointer;
            position: relative;
            &:after {
              position: absolute;
              bottom: 0px;
              left: -8px;
              content: '';
              width: calc(100% + 16px);
              height: 4px;
              opacity: 0;
              background-color: ${(props): string => props.theme.yellow};
              transition: all 0.3s;
            }
            &:hover {
              &:after {
                opacity: 1;
              }
            }
          }
          &.active {
            a {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .button {
      height: 56px;
      border-radius: 28px;
      margin: 22px 0 0;
      font-size: 18px;
      &.login-button {
        width: 136px;
      }
      &.logout-button {
        width: 56px;
        padding: 3px 3px 0 0px;
        .logout-icon {
          width: 27px;
        }
      }
    }
  }
  ${mediaSpHeader`
    .pc {
      display: none;
    }
    .sp {
      display: block;
    }
  `}

  ${mediaSp`
    .row {
      height: 5.8rem;
      padding: 0 1.2rem;
      h1 {
        padding: 1.1rem 0 0;
        font-size: 2.5rem;
        .logo {
          width: 13.7rem;
          height: 3.4rem;
        }
      }
    }
  `}
`

export const Header: React.FC = () => {
  const [isVisibleSpMenu, setIsVisibleSpMenu] = useState<boolean>(false)
  const [scrollValue, setScrollValue] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const [cookie, , removeCookie] = useCookies(['isLoggedIn'])

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [logoutUserMutation] = useLogoutUserMutation({
    onCompleted: () => {
      setIsLoading(false)
      removeCookie('isLoggedIn', { path: '/' })
      navigate('/login')
      setIsVisibleSpMenu(false)
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: 'ログアウトに失敗しました' })
      Sentry.captureException(e)
    },
  })

  const onClickHeaderLogo = (): void => {
    if (isVisibleSpMenu) {
      setIsVisibleSpMenu(false)
    }

    if (pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      navigate('/')
    }
  }

  const toggleOpenMenu = (): void => {
    setIsVisibleSpMenu(!isVisibleSpMenu)
    if (isVisibleSpMenu) {
      window.scroll({
        top: scrollValue,
      })
    } else {
      setScrollValue(window.scrollY)
    }
  }

  const closeMenu = (): void => {
    setIsVisibleSpMenu(false)
  }

  const logout = (): void => {
    setIsLoading(true)
    setFlashMessage(null)
    logoutUserMutation()
  }

  // ヘッダーのログインボタン表示切り替えのため
  useValidateToken()

  return (
    <>
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <StyledHeader>
        <div className="row">
          <h1 onClick={() => onClickHeaderLogo()}>
            <LogoSvg className="logo" />
          </h1>
          <nav className="pc">
            <ul>
              <li>
                <Link to="/#plan" onClick={closeMenu}>
                  撮影プラン
                </Link>
              </li>
              <li>
                <Link to="/#order-flow" onClick={closeMenu}>
                  ご依頼の流れ
                </Link>
              </li>
              {!!cookie.isLoggedIn ? (
                <>
                  <li className={pathname.match('/mypage/order') ? 'active' : ''}>
                    <Link to="/mypage/order">注文履歴</Link>
                  </li>
                  <li className={pathname.match('/mypage/account') ? 'active' : ''}>
                    <Link to="/mypage/account">アカウント情報</Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link to="/#estimate" onClick={closeMenu}>
                    見積りシミュレーション
                  </Link>
                </li>
              )}
            </ul>
          </nav>
          {!!cookie.isLoggedIn ? (
            <Button className="button logout-button pc" buttonType="yellowBorder" onClick={() => logout()}>
              <LogoutSvg className="logout-icon" />
            </Button>
          ) : (
            <ButtonLink className="button login-button pc" buttonType="yellowBorder" href="/login">
              ログイン
            </ButtonLink>
          )}
          <MenuButton className={isVisibleSpMenu ? 'active sp' : 'sp'} onClick={() => toggleOpenMenu()}>
            <span></span>
            <span></span>
            <span></span>
          </MenuButton>
        </div>

        <ModalMenu className={isVisibleSpMenu ? 'active sp' : 'sp'}>
          <nav>
            <ul>
              <li>
                <a className="link" href="/">
                  TOP
                </a>
              </li>
              {!!cookie.isLoggedIn ? (
                <div className="mypage-link">
                  <p>マイページ</p>
                  <li>
                    <a className="link" href="/mypage/order">
                      注文履歴
                    </a>
                  </li>
                  <li>
                    <a className="link" href="/mypage/account">
                      アカウント情報
                    </a>
                  </li>
                </div>
              ) : (
                <li>
                  <a className="link" href="/login">
                    ログイン
                  </a>
                </li>
              )}
              <li>
                <Link to="/#plan" onClick={closeMenu}>
                  撮影プラン
                </Link>
              </li>
              <li>
                <Link to="/#order-flow" onClick={closeMenu}>
                  ご依頼の流れ
                </Link>
              </li>
              {!cookie.isLoggedIn && (
                <li>
                  <Link to="/#estimate" onClick={closeMenu}>
                    見積りシミュレーション
                  </Link>
                </li>
              )}
              <li>
                <a className="link" href={FAQ_URL} target="_blank" rel="noopener noreferrer">
                  よくあるご質問
                </a>
              </li>
              {!!cookie.isLoggedIn && (
                <li>
                  <TextButton onClick={() => logout()}>ログアウト</TextButton>
                </li>
              )}
            </ul>
          </nav>
        </ModalMenu>
      </StyledHeader>
    </>
  )
}
