import React from 'react'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  buttonType: 'black' | 'yellow' | 'darkGray' | 'blackBorder' | 'yellowBorder'
  disabled?: boolean
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const getStyleByButtonType = (props: ThemedStyledProps<Props, DefaultTheme>): string => {
  if (props.buttonType === 'black') {
    return `
      color: ${props.theme.white};
      background: ${props.theme.black};

      &:hover {
        background: rgba(${props.theme.blackRgb}, 0.7);
      }
    `
  }
  if (props.buttonType === 'yellow') {
    return `
      background: ${props.theme.yellow};

      &:hover {
        background: rgba(${props.theme.yellowRgb}, 0.5);
      }
    `
  }
  if (props.buttonType === 'darkGray') {
    return `
      color: ${props.theme.white};
      background: ${props.theme.darkGray};
      
      &:hover {
        background: rgba(${props.theme.darkGrayRgb}, 0.7);
      }
    `
  }
  if (props.buttonType === 'blackBorder') {
    return `
      border: 3px solid ${props.theme.black};

      &:hover {
        color: ${props.theme.white};
        background: ${props.theme.black};
      }
    `
  }
  if (props.buttonType === 'yellowBorder') {
    return `
      border: 3px solid ${props.theme.yellow};

      &:hover {
        background: ${props.theme.yellow};
      }
    `
  }
  return ''
}

const StyledButton = styled.button<Props>`
  width: 100%;
  height: 60px;
  color: ${(props): string => props.theme.black};
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  border-radius: 30px;
  background: ${(props): string => props.theme.white};
  box-sizing: border-box;
  transition: 0.3s all;

  ${(props) => getStyleByButtonType(props)}

  &:disabled {
    color: ${(props): string => props.theme.darkGray};
    background: ${(props): string => props.theme.gray};
    border: none;
  }

  ${mediaSp`
    font-size: 1.6rem;
    height: 5.2rem;
    border-radius: 2.6rem;
  `}
`

export const Button: React.FC<Props> = ({ className = '', buttonType, disabled, children, onClick }) => {
  return (
    <StyledButton className={className} buttonType={buttonType} disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  )
}
