import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

type Props = {
  className?: string
  text: string
  href: string
  state?: object
}

const StyledTextLink = styled.div`
  a {
    color: ${(props): string => props.theme.darkGray};
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
    text-decoration-line: underline;
    transition: 0.3s all;

    &:hover {
      opacity: 0.5;
    }
  }
`

export const TextLink: React.FC<Props> = ({ className = '', text, href, state }) => {
  return (
    <StyledTextLink className={className}>
      <Link to={href} state={state}>
        {text}
      </Link>
    </StyledTextLink>
  )
}
