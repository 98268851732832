import React from 'react'
import styled from 'styled-components'

/* components */
import { TopPlanItem } from 'components/molecules'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { OrderPlan } from 'types/graphql'

/* images */
import photoDummy from 'assets/images/dummy/photo-dummy.svg'
// FIXME: 360度撮影が追加されたら戻す
// https://www.pivotaltracker.com/story/show/183552747
// import photo360Dummy from 'assets/images/dummy/photo-360-dummy.svg'
import movieDummy from 'assets/images/dummy/movie-dummy.svg'
// FIXME: 着用イメージ撮影が追加されたら追加
// https://www.pivotaltracker.com/story/show/183552751
// import wearingPhotoDummy from 'assets/images/dummy/wearing-photo-dummy.svg'

type Props = {
  onClickOpenModal: (type: OrderPlan) => void
}

const Wrapper = styled.div`
  .inner {
    padding: 80px 0 120px;
    background: ${(props): string => props.theme.lightGray};
    border-radius: 0px 100px 100px 0px;

    .content {
      width: 98%;
      max-width: 800px;
      margin: 0 auto;

      .section-title {
        margin-bottom: 56px;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
      }

      .row {
        display: flex;
        justify-content: space-between;

        & + .row {
          margin-top: 68px;
        }
        .text-wrapper {
          width: 370px;
          padding: 16px 0 0;
        }
      }

      .description {
        margin-bottom: 48px;
        font-size: 16px;
        line-height: 1.7;
        text-align: justify;
      }

      .price-wrapper {
        text-align: right;

        & + .price-wrapper {
          margin-top: 32px;
        }

        .condition {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.7;
          text-align: left;
        }

        .price {
          margin-right: 3%;
          font-size: 32px;
          line-height: 27px;
          font-weight: 800;
          display: inline-block;
          position: relative;
          z-index: 1;

          .unit {
            margin-right: 15px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.7;
          }

          &:after {
            width: 114%;
            height: 7px;
            background: ${(props): string => props.theme.yellow};
            border-radius: 3.5px;
            content: '';
            position: absolute;
            bottom: 5px;
            left: -7%;
            z-index: -1;
          }
        }
      }
    }
  }

  ${mediaSp`
    .inner {
      padding: 6rem 0 8rem;
      border-radius: 0px 3rem 3rem 0px;

      .content {
        width: 34.5rem;
        max-width: 34.5rem;

        .section-title {
          margin-bottom: 3.2rem;
          font-size: 2.4rem;
        }

        .row {
          display: block;

          & + .row {
            margin-top: 5.6rem;
          }

          >div {
            margin-bottom: 5.6rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
          .text-wrapper {
            width: 100%;
            .description {
              margin-bottom: 1.2rem;
              font-size: 1.4rem;
            }

            .price-wrapper {
              & + .price-wrapper {
                margin-top: 2.2rem;
              }

              .condition {
                font-size: 1.4rem;
              }

              .price {
                font-size: 2.4rem;
                line-height: 1.5;

                .unit {
                  margin-right: 0.9rem;
                  font-size: 1.4rem;
                }

                &:after {
                  height: 0.5rem;
                  border-radius: 0.25rem;
                  bottom: 0.5rem;
                }
              }
            }
          }
        }
      }
    }

  `}
`

export const TopPlan: React.FC<Props> = ({ onClickOpenModal }) => {
  return (
    <Wrapper>
      <div className="inner">
        <div className="content">
          <h2 className="section-title">撮影プラン</h2>
          <div className="row">
            <TopPlanItem plan={OrderPlan.Photo} planImage={photoDummy} onClickOpenModal={onClickOpenModal}>
              <div className="text-wrapper">
                <p className="description">
                  商品ごとに角度やカット数のご指定が可能です。 背景を透過加工し、その後の画像展開にも便利なデータをお届けします。
                </p>
                <div className="price-wrapper">
                  <p className="condition">1商品あたり 3カット以下の場合</p>
                  <p className="price">
                    <span className="unit">1カット</span>¥ 360
                  </p>
                </div>
                <div className="price-wrapper">
                  <p className="condition">1商品あたり 4カット以上の場合</p>
                  <p className="price">
                    <span className="unit">1カット</span>¥ 320
                  </p>
                </div>
              </div>
            </TopPlanItem>
            {/* FIXME: 360度撮影が追加されたら戻す */}
            {/* https://www.pivotaltracker.com/story/show/183552747 */}
            {/* <TopPlanItem plan={OrderPlan.Photo_360} planImage={photo360Dummy} onClickOpenModal={onClickOpenModal}>
              <p className="description">
                30度ずつ商品を撮影し、360度一周をウェブサイトに掲載できます。背景を透過加工し、画像データと併せてVR展開にも便利なデータをお届けします。
              </p>
              <div className="price-wrapper">
                <p className="price">
                  <span className="unit">1商品</span>¥ 1,980
                </p>
              </div>
            </TopPlanItem> */}
          </div>
          <div className="row">
            <TopPlanItem plan={OrderPlan.Movie} planImage={movieDummy} onClickOpenModal={onClickOpenModal}>
              <div className="text-wrapper">
                <p className="description">
                  360度のおよそ10秒の動画を撮影します。ウェブサイトに掲載しやすいmp4形式にてデータをお届けします。※背景は無加工になります。
                </p>
                <div className="price-wrapper">
                  <p className="price">
                    {/* FIXME: 動画の値段 */}
                    {/* https://www.pivotaltracker.com/story/show/182151109 */}
                    <span className="unit">1商品</span>¥ 1,980
                  </p>
                </div>
              </div>
            </TopPlanItem>
            {/* FIXME: バックエンドのenumに着用イメージ撮影が追加されたらplanの値修正する */}
            {/* https://www.pivotaltracker.com/story/show/183552751 */}
            {/* <TopPlanItem plan={OrderPlan.Photo} planImage={wearingPhotoDummy} onClickOpenModal={onClickOpenModal}>
              <p className="description">
                着用モデルに商品を着せて撮影します。顔全体は写さず、顎から下の画像を納品させていただきます。
                <br />※ バストアップ、全身(顎下)、靴の場合は足元
              </p>
              <div className="price-wrapper">
                <p className="condition">1商品あたり 3カット以下の場合</p>
                <p className="price">
                  <span className="unit">1カット</span>¥ 980
                </p>
              </div>
              <div className="price-wrapper">
                <p className="condition">1商品あたり 4カット以上の場合</p>
                <p className="price">
                  <span className="unit">1カット</span>¥ 780
                </p>
              </div>
            </TopPlanItem> */}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
