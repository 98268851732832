import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

/* images */
import pagePrevIcon from 'assets/images/icon/page-prev.png'
import pageNextIcon from 'assets/images/icon/page-next.png'

type Props = {
  className?: string
  currentPage: number
  lastPage: number
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;

  .pagination-icon {
    width: 1rem;
    cursor: pointer;
  }

  .page-nums-wrapper {
    display: flex;
    align-items: center;
  }

  ${mediaSp`
    gap: 2.4rem;
  `}
`

const StyledPaginationItem = styled.div`
  width: 48px;
  height: 48px;
  font-size: 18px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover:not(&.dots),
  &.current {
    color: ${(props): string => props.theme.white};
    background: ${(props): string => props.theme.black};
  }

  &.current {
    cursor: initial;
  }

  &.dots {
    cursor: initial;
  }

  ${mediaSp`
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem;
    line-height: 4rem;
  `}
`

export const Pagination: React.FC<Props> = ({ className = '', currentPage, lastPage }) => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const params = new URLSearchParams(search)

  const getPageNumText = (page: number): string => {
    // 一桁なら0を前に補う
    return `0${page}`.slice(page < 10 ? -2 : -`${page}`.length)
  }

  const onClickPrev = (): void => {
    params.set('page', `${currentPage - 1}`)
    navigate({
      pathname,
      search: params.toString(),
    })
  }

  const onClickNext = (): void => {
    params.set('page', `${currentPage + 1}`)
    navigate({
      pathname,
      search: params.toString(),
    })
  }

  const onClickPage = (page: number): void => {
    params.set('page', `${page}`)
    navigate({
      pathname,
      search: params.toString(),
    })
  }

  const paginationItemTexts: string[] = []
  if (lastPage <= 7) {
    ;[...Array(lastPage)].map((_, i) => paginationItemTexts.push(getPageNumText(i + 1)))
  }
  if (lastPage > 7) {
    if (currentPage <= 4) {
      ;[...Array(5)].map((_, i) => paginationItemTexts.push(getPageNumText(i + 1)))
      paginationItemTexts.push('...', getPageNumText(lastPage))
    }
    if (currentPage > 4 && currentPage < lastPage - 3) {
      paginationItemTexts.push(getPageNumText(1), '...')
      for (let i = currentPage - 1; i < currentPage + 2; i++) {
        paginationItemTexts.push(getPageNumText(i))
      }
      paginationItemTexts.push('...', getPageNumText(lastPage))
    }
    if (currentPage >= lastPage - 3) {
      paginationItemTexts.push('01', '...')
      for (let i = lastPage - 4; i < lastPage + 1; i++) {
        paginationItemTexts.push(getPageNumText(i))
      }
    }
  }

  // 1ページしかなかったらページネーションごと非表示に
  if (lastPage === 1) return <></>
  return (
    <Wrapper className={className}>
      {currentPage !== 1 && <img src={pagePrevIcon} onClick={onClickPrev} alt="prev" className="pagination-icon" />}

      <div className="page-nums-wrapper">
        {paginationItemTexts.map((text, index) => (
          <StyledPaginationItem
            key={index}
            onClick={() => {
              text === '...' ? undefined : onClickPage(Number(text))
            }}
            className={getPageNumText(currentPage) === text ? 'current' : text === '...' ? 'dots' : ''}
          >
            {text}
          </StyledPaginationItem>
        ))}
      </div>
      {currentPage !== lastPage && <img src={pageNextIcon} onClick={onClickNext} alt="next" className="pagination-icon" />}
    </Wrapper>
  )
}
