import React, { useState } from 'react'
import styled from 'styled-components'

/* components */
import { SubHeading, Button } from 'components/atoms'
import { AddressForm } from 'components/organisms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import {
  validateKana,
  validateAddressInput,
  validateCompanyNameWithCompanyNameKana,
  validateCompanyNameKanaWithCompanyName,
} from 'lib/validate'
import { AddressInput, AddressInputErrors } from 'types/myTypes'

type Props = {
  returnAddressInput: AddressInput
  onChangeState: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void
  onClickAdd: () => void
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 0 120px;

  .sub-heading {
    margin-bottom: 40px;
  }

  .address-form {
    margin-bottom: 64px;
  }

  .add-btn {
    width: 31.2rem;
    margin: 0 auto;
    display: block;
  }

  ${mediaSp`
    width: 33.5rem;
    max-width: 33.5rem;
    padding: 0rem 0 12rem;

    .sub-heading: {
      margin-bottom: 3.2rem;
    }

    .address-form {
      margin-bottom: 5.6rem;
    }
  `}
`

export const MypageAccountAddReturnAddressTpl: React.FC<Props> = ({ returnAddressInput, onChangeState, onClickAdd }) => {
  const [addressInputErrors, setAddressInputErrors] = useState<AddressInputErrors>({
    name: null,
    name_kana: null,
    company_name: null,
    company_name_kana: null,
    telephone_number: null,
    post_code: null,
    prefecture: null,
    address: null,
  })

  const validate = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    switch (name) {
      case 'name':
      case 'prefecture':
      case 'address':
      case 'name_kana':
      case 'telephone_number':
      case 'post_code':
        setAddressInputErrors({ ...addressInputErrors, [name]: validateAddressInput(name, value) })
        break
      case 'company_name':
        setAddressInputErrors({
          ...addressInputErrors,
          company_name: validateCompanyNameWithCompanyNameKana(value, returnAddressInput.company_name_kana),
          company_name_kana: validateCompanyNameKanaWithCompanyName(value, returnAddressInput.company_name_kana),
        })
        break
      case 'company_name_kana':
        setAddressInputErrors({
          ...addressInputErrors,
          company_name: validateCompanyNameWithCompanyNameKana(returnAddressInput.company_name, value),
          company_name_kana: validateCompanyNameKanaWithCompanyName(returnAddressInput.company_name, value) || validateKana(value),
        })
        break
      default:
        break
    }
  }

  const isDisable = (): boolean => {
    const { name, name_kana, telephone_number, post_code, prefecture, address } = returnAddressInput
    const isRequiredItemsFilled = name && name_kana && telephone_number && post_code && prefecture && address
    if (!isRequiredItemsFilled) return true

    const errorArray = Object.values(addressInputErrors).filter((error) => error !== null)
    if (errorArray.length > 0) return true

    return false
  }

  return (
    <Wrapper>
      <SubHeading text="返送先住所" className="sub-heading" />
      <AddressForm
        addressInput={returnAddressInput}
        errors={addressInputErrors}
        onChangeState={(e) => {
          onChangeState(e)
          validate(e)
        }}
        className="address-form"
      />
      <Button buttonType="yellow" disabled={isDisable()} onClick={onClickAdd} className="add-btn">
        登録
      </Button>
    </Wrapper>
  )
}
