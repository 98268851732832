import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

/* components */
// FIXME: 360度撮影が追加されたら戻す
// https://www.pivotaltracker.com/story/show/183552747
// import { Image360View } from 'components/organisms'

/* lib, types */
import { mediaSp } from 'lib/media-query'
import { OrderPlan } from 'types/graphql'

/* images */
// FIXME: 360度撮影が追加されたら戻す
// https://www.pivotaltracker.com/story/show/183552747
// import { ReactComponent as Image360IconSvg } from 'assets/images/icon/image-360.svg'
import { ReactComponent as ModalCloseIconSvg } from 'assets/images/icon/modal-close.svg'
import { ReactComponent as PhotoDummySvg } from 'assets/images/dummy/photo-dummy.svg'
import { ReactComponent as MoviePlayIconSvg } from 'assets/images/icon/movie-play.svg'

import videoDummy from 'assets/images/dummy/video-dummy.mp4'

type Props = {
  plan: OrderPlan
  closeModal: () => void
}

const Wrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(${(props): string => props.theme.blackRgb}, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props): number => props.theme.zIndex.modal};
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay-for-close {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: ${(props): number => props.theme.zIndex.modal};
  }

  .close-button {
    position: absolute;
    width: 35px;
    height: 32px;
    top: 40px;
    right: 40px;
    z-index: ${(props): number => props.theme.zIndex.modal};
    cursor: pointer;
  }

  > .contents {
    width: 35.9vw;
    height: 35.9vw;
    max-width: 518px;
    max-height: 518px;
    background: ${(props): string => props.theme.white};
    z-index: ${(props): number => props.theme.zIndex.modal + 1};

    > .inner {
      width: 100%;
      height: 100%;

      > svg {
        width: 100%;
        height: 100%;
      }

      .image-360-wrapper {
        position: relative;
        height: 100%;

        .icon {
          position: absolute;
          top: 13px;
          right: 15px;
        }

        .image-360-view {
          /* FIXME: 360度の画像が仮じゃなくなったら要調整 */
          /* https://www.pivotaltracker.com/story/show/183552747 */
          height: 100%;
        }
      }

      /* FIXME: 動画の仕様は完全に仮。実際のサイズ等わかったら考える */
      /* https://www.pivotaltracker.com/story/show/181906495 */
      .video-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;

        .video {
          width: 100%;
          height: 100%;
        }

        .play-button {
          position: absolute;
          top: calc(50% - 5.8rem);
          left: calc(50% - 5.8rem);
          width: 11.6rem;
          height: 11.6rem;
          opacity: 0.6;
          transition: 0.3s all;
          &.playing {
            opacity: 0;
          }
        }
      }
    }
  }

  ${mediaSp`
    .close-button {
      width: 3rem;
      height: 2.8rem;
      top: 1.6rem;
      right: 1.6rem;
    }

    >.contents {
      width: 80.5vw;
      height: 80.5vw;
      max-width: 80.5vw;
      max-height: 80.5vw;

      >.inner {
        .image-360-wrapper {
          .icon {
            width: 5.5rem;
            height: 5.5rem;
            top: 1rem;
            right: 1.2rem;
          }
        }
      }
    }
  `}
`

export const TopPlanModal: React.FC<Props> = ({ plan, closeModal }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isMoviePlaying, setIsMoviePlaying] = useState<boolean>(false)

  const onClick = (): void => {
    if (isMoviePlaying) {
      setIsMoviePlaying(false)
      videoRef.current?.pause()
    } else {
      setIsMoviePlaying(true)
      videoRef.current?.play()
    }
  }

  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'

    return () => {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  })

  return (
    <Wrapper>
      <div className="overlay-for-close" onClick={closeModal} />
      <div className="close-button" onClick={closeModal}>
        <ModalCloseIconSvg />
      </div>
      <div className="contents">
        <div className="inner">
          {/* FIXME: バックエンドのenumに着用イメージ撮影が追加されたらそれも追加する */}
          {/* https://www.pivotaltracker.com/story/show/183552751 */}

          {/* 仮画像 */}
          {/* https://www.pivotaltracker.com/story/show/181906495 */}
          {plan === OrderPlan.Photo && <PhotoDummySvg />}
          {/* FIXME: 360度撮影が追加されたら戻す */}
          {/* https://www.pivotaltracker.com/story/show/183552747 */}
          {/* {plan === OrderPlan.Photo_360 && (
            <div className="image-360-wrapper">
              <Image360IconSvg className="icon" />
              <Image360View className="image-360-view" />
            </div>
          )} */}
          {plan === OrderPlan.Movie && (
            <div className="video-wrapper" onClick={onClick}>
              {/* FIXME: 動画の仕様は完全に仮。実際のサイズ等わかったら考える */}
              {/* https://www.pivotaltracker.com/story/show/181906495 */}
              <video src={videoDummy} ref={videoRef} controls={false} className="video" />
              <MoviePlayIconSvg className={`play-button ${isMoviePlaying ? 'playing' : ''} `} />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}
