import React from 'react'
import { Link } from 'react-router-dom'
import styled, { ThemedStyledProps, DefaultTheme } from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  disabled?: boolean
  href: string
  state?: object
  children: React.ReactNode
}

type StyleProps = {
  buttonType: 'black' | 'yellow' | 'darkGray' | 'blackBorder' | 'yellowBorder'
}

const getStyleByButtonType = (props: ThemedStyledProps<StyleProps, DefaultTheme>): string => {
  if (props.buttonType === 'black') {
    return `
      color: ${props.theme.white};
      background: ${props.theme.black};

      &:hover {
        background: rgba(${props.theme.blackRgb}, 0.7);
      }
    `
  }
  if (props.buttonType === 'yellow') {
    return `
      background: ${props.theme.yellow};

      &:hover {
        background: rgba(${props.theme.yellowRgb}, 0.5);
      }
    `
  }
  if (props.buttonType === 'darkGray') {
    return `
      color: ${props.theme.white};
      background: ${props.theme.darkGray};
      
      &:hover {
        background: rgba(${props.theme.darkGrayRgb}, 0.7);
      }
    `
  }
  if (props.buttonType === 'blackBorder') {
    return `
      border: 3px solid ${props.theme.black};
      line-height: 54px;

      &:hover {
        color: ${props.theme.white};
        background: ${props.theme.black};
      }
    `
  }
  if (props.buttonType === 'yellowBorder') {
    return `
      border: 3px solid ${props.theme.yellow};
      line-height: 54px;

      &:hover {
        background: ${props.theme.yellow};
      }
    `
  }
  return ''
}

const StyledButtonLink = styled.div<StyleProps>`
  > a {
    width: 100%;
    height: 60px;
    color: ${(props): string => props.theme.black};
    font-size: 20px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.05em;
    border-radius: 30px;
    display: block;
    box-sizing: border-box;
    transition: 0.3s all;

    ${(props) => getStyleByButtonType(props)}

    &.disabled {
      color: ${(props): string => props.theme.darkGray};
      background: ${(props): string => props.theme.gray};
      border: none;
    }
  }

  ${mediaSp`
    >a {
      font-size: 1.6rem;
      height: 5.2rem;
      line-height: 5.2rem;
      border-radius: 2.6rem;
    }
  `}
`

export const ButtonLink: React.FC<Props & StyleProps> = ({ className = '', buttonType, disabled, href, state, children }) => {
  return (
    <StyledButtonLink buttonType={buttonType}>
      <Link to={href} state={state} className={`${className}${disabled ? ' disabled' : ''}`}>
        {children}
      </Link>
    </StyledButtonLink>
  )
}
