import React, { useState } from 'react'
import styled from 'styled-components'

/* components */
import { PageTitle, ErrorMessage, TextInput, Button } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { ResetPasswordEmailInputError } from 'types/myTypes'
import { validateRequiredEmail } from 'lib/validate'

type Props = {
  className?: string
  email: string
  onChangeState: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickSendEmail: () => void
}

const Wrapper = styled.div`
  .inner {
    width: 100%;
    margin: 0 auto;
    padding: 8rem 2rem 23.8rem;

    .page-title {
      margin-bottom: 4rem;
      text-align: center;
    }
    .text {
      margin-bottom: 3.2rem;
      font-size: 1.6rem;
      line-height: 1.7;
      text-align: center;
    }
    .input-area {
      width: 48rem;

      margin: 0 auto 4rem;
      .email {
        width: 48rem;
        margin: 0 auto;
        display: block;
      }
    }

    .send-email {
      width: 31.2rem;
      margin: 0 auto;
      display: block;
    }
  }

  ${mediaSp`
    .inner {
      padding: 7.2rem 2.4rem 14rem;

      .page-title {
        margin-bottom: 3.2rem;
      }

      .text {
        margin-bottom: 4.8rem;
        font-size: 1.4rem;
      }
      .input-area {
        width: 100%;
        margin: 0 auto 4.8rem;

        .email {
          width: 100%;
        }
      }
    }
  `}
`

export const ResetPasswordEmailTpl: React.FC<Props> = ({ className = '', email, onClickSendEmail, onChangeState }) => {
  const [emailInputError, setEmailInputError] = useState<ResetPasswordEmailInputError>(null)

  const validate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setEmailInputError(validateRequiredEmail(value))
  }

  const isDisable = (): boolean => {
    if (!email || emailInputError) return true
    return false
  }

  return (
    <Wrapper className={className}>
      <div className="inner">
        <PageTitle title="パスワード再設定" className="page-title" />

        <p className="text">
          ご登録いただいているメールアドレスを
          <br className="sp" />
          ご入力してください。
          <br />
          パスワード再設定用のURLが
          <br className="sp" />
          ご登録のメールアドレスに送信されます。
        </p>

        <div className="input-area">
          <TextInput
            className="email"
            type="email"
            value={email}
            name="email"
            placeholder="メールアドレス"
            onChange={(e) => {
              onChangeState(e)
              validate(e)
            }}
            error={!!emailInputError}
          />
          {!!emailInputError && <ErrorMessage message={emailInputError} />}
        </div>

        <Button className="send-email" buttonType="yellow" disabled={isDisable()} onClick={onClickSendEmail}>
          メールを送信
        </Button>
      </div>
    </Wrapper>
  )
}
