import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  name?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const StyledTextarea = styled.textarea`
  width: 100%;
  height: auto;
  min-height: 20rem;
  padding: 14px 24px;
  font-size: 18px;
  line-height: 1.75;
  border-radius: 0.8rem;
  border: 2px solid ${(props): string => props.theme.gray};
  transition: 0.3s all;

  &:focus {
    outline: 0;
    border: 2px solid ${(props): string => props.theme.black};
  }

  ${mediaSp`
    padding: 1.4rem 1.6rem;
    font-size: 1.4rem;
  
  `}
`

export const Textarea: React.FC<Props> = ({ className = '', name, value, onChange }) => {
  return <StyledTextarea className={className} name={name} value={value} onChange={onChange} />
}
