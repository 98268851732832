import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { OrderPlan } from 'types/graphql'

/* images */
import movieIcon from 'assets/images/icon/movie.svg'
import { ReactComponent as ClickIconSvg } from 'assets/images/icon/click.svg'

type Props = {
  className?: string
  plan: OrderPlan
  planImage: string
  children: React.ReactNode
  onClickOpenModal: (type: OrderPlan) => void
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 722px;
  margin: 0 auto 0;
  text-align: center;
  .plan-name {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    position: relative;
    display: inline-block;

    &:before {
      width: 15px;
      height: 15px;
      content: '';
      position: absolute;
      top: 50%;
      left: -28px;
      transform: translate(-50%, -50%) rotate(-45deg);
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
      background: ${(props): string => props.theme.yellow};
      border-radius: 4px;
    }
  }

  .plan-item-inner {
    display: flex;
    justify-content: space-between;
    .plan-image-wrapper {
      width: 300px;
      height: 300px;
      position: relative;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        opacity: 0.5;
      }
      &.movie {
        &:before {
          width: 30px;
          height: 30px;
          content: '';
          position: absolute;
          top: 22px;
          left: 13px;
          background: url(${movieIcon}) center no-repeat;
          background-color: ${(props): string => props.theme.gray};
          border-radius: 50%;
          z-index: 2;
        }
      }

      .plan-image {
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 0.4rem 0.4rem rgba(${(props): string => props.theme.blackRgb}, 0.25));
      }

      .modal-open-btn {
        width: 78px;
        height: 78px;
        background: ${(props): string => props.theme.yellow};
        border-radius: 50%;
        position: absolute;
        bottom: -20px;
        right: -20px;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
        }
      }
    }
  }

  ${mediaSp`
    width: 34.5rem;

    .plan-name {
      margin-bottom: 2.2rem;
      font-size: 1.6rem;

      &:before {
        width: 0.8rem;
        height: 0.8rem;
        left: -1.6rem;
        border-radius: 0.2rem;
      }
    }
    .plan-item-inner {
      display: block;
      .plan-image-wrapper {
        width: 20rem;
        height: 20rem;
        margin: 0 auto 3.8rem;

        &.movie {
          &:before {
            width: 2.3rem;
            height: 2.3rem;
            top: 1.4rem;
            left: 0.7rem;
            background-size: 0.7rem 0.8rem;
          }
        }

        .modal-open-btn {
          width: 4.8rem;
          height: 4.8rem;
          bottom: -2.4rem;
          right: -2.4rem;

          .icon {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }
  `}
`

export const TopPlanItem: React.FC<Props> = ({ className = '', plan, planImage, children, onClickOpenModal }) => {
  const getPlanName = (plan: OrderPlan): string => {
    switch (plan) {
      case OrderPlan.Photo:
        return '商品撮影'

      // FIXME: バックエンドに360度撮影が復活したら追加
      // https://www.pivotaltracker.com/story/show/183552747
      // case OrderPlan.Photo_360:
      //   return '360度撮影'
      case OrderPlan.Movie:
        return '動画撮影'

      // FIXME: バックエンドのenumに着用イメージ撮影が追加されたら追加
      // https://www.pivotaltracker.com/story/show/183552751
      // case OrderPlan.~~:
      //   return '着用イメージ撮影'
      default:
        return ''
    }
  }
  return (
    <Wrapper className={className}>
      <p className="plan-name">{getPlanName(plan)}</p>
      <div className="plan-item-inner">
        <div
          className={plan === OrderPlan.Movie ? 'movie plan-image-wrapper' : 'plan-image-wrapper'}
          onClick={() => {
            onClickOpenModal(plan)
          }}
        >
          <img src={planImage} alt={getPlanName(plan)} className="plan-image" />
          <div className="modal-open-btn">
            <ClickIconSvg className="icon" />
          </div>
        </div>
        {children}
      </div>
    </Wrapper>
  )
}
