import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { MypageLayout, MypageAccountEditPasswordTpl } from 'components/templates'

/* lib, types*/
import { EditPasswordInput, FlashMessageType } from 'types/myTypes'
import { useUserUpdatePasswordMutation } from 'types/graphql'

export const MypageAccountEditPassword: React.FC = () => {
  const editPasswordInitialInput: EditPasswordInput = {
    current_password: '',
    new_password: '',
    new_password_confirm: '',
  }
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [editPasswordInput, setEditPasswordInput] = useState<EditPasswordInput>(editPasswordInitialInput)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const [userUpdatePasswordMutation, { loading }] = useUserUpdatePasswordMutation({
    onCompleted: () => {
      navigate('/mypage/account', {
        state: {
          redirectFrom: pathname,
        },
      })
    },
    onError: (e) => {
      Sentry.captureException(e)
      setFlashMessage({ type: 'error', message: e.message ?? 'パスワードの更新に失敗しました。' })
    },
  })

  const onChangeState = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { name, value } = e.target
    setEditPasswordInput({ ...editPasswordInput, [name]: value })
  }

  const onClickEdit = async (): Promise<void> => {
    setFlashMessage(null)
    await userUpdatePasswordMutation({
      variables: {
        input: {
          current_password: editPasswordInput.current_password,
          password: editPasswordInput.new_password,
        },
      },
    })
  }

  return (
    <>
      <Meta title="パスワード変更" />
      {loading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <MypageLayout pageTitle="パスワード変更">
        <MypageAccountEditPasswordTpl editPasswordInput={editPasswordInput} onChangeState={onChangeState} onClickEdit={onClickEdit} />
      </MypageLayout>
    </>
  )
}
