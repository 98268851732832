import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  text: string
  required?: boolean
}

const StyledLabel = styled.label`
  color: ${(props): string => props.theme.black};
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.05em;

  span {
    margin-left: 7px;
    color: ${(props): string => props.theme.errorText};
    font-size: 16px;
    line-height: 1.9;
    letter-spacing: 0.04em;
    vertical-align: top;
    display: inline-block;
  }

  ${mediaSp`
    font-size: 1.4rem;

    span {
      margin-left: 0.4rem;
      font-size: 1.2rem;
    }
  `}
`

export const Label: React.FC<Props> = ({ className = '', text, required }) => {
  return (
    <StyledLabel className={className}>
      {text}
      {required && <span>*</span>}
    </StyledLabel>
  )
}
