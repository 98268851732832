import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  type?: string
  name?: string
  value?: string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type StyleProps = {
  error?: boolean
}

const StyledInput = styled.input<StyleProps>`
  width: 100%;
  height: 56px;
  padding: 0 24px;
  color: ${(props): string => props.theme.black};
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  border: 2px solid ${(props): string => (props.error ? props.theme.errorText : props.theme.gray)};
  border-radius: 0.8rem;
  background: ${(props): string => props.theme.white};
  transition: 0.3s all;
  box-sizing: border-box;

  &:focus {
    outline: 0;
    border: 2px solid ${(props): string => (props.error ? props.theme.errorText : props.theme.black)};
  }

  &::placeholder {
    color: ${(props): string => props.theme.gray};
  }

  ${mediaSp`
    height: 5rem;
    padding: 0 1.6rem;
    font-size: 1.4rem;
  `}
`

export const TextInput: React.FC<Props & StyleProps> = ({
  className = '',
  type = 'text',
  name = '',
  placeholder = '',
  value = '',
  error = false,
  onChange = () => {},
}) => {
  return (
    <StyledInput
      className={className}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value || ''}
      error={error}
      onChange={onChange}
    />
  )
}
