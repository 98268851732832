import React from 'react'
import styled from 'styled-components'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

type Props = {
  className?: string
  text: string
}

const StyledSubHeading = styled.h3`
  color: ${(props): string => props.theme.black};
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.05em;
  padding: 0 0 18px;
  border-bottom: 3px solid ${(props): string => props.theme.yellow};

  ${mediaSp`
    font-size: 1.6rem;
    padding:0 0 0.5rem;
    border-bottom: 0.3rem solid ${(props): string => props.theme.yellow};
  `}
`

export const SubHeading: React.FC<Props> = ({ className = '', text }) => {
  return <StyledSubHeading className={className}>{text}</StyledSubHeading>
}
