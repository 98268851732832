import React from 'react'
import styled from 'styled-components'

/* components */
import { ButtonLink } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'

const Wrapper = styled.div`
  padding: 110px 0 0;
  text-align: center;
  .error-code {
    font-size: 80px;
    font-family: 'Roboto';
    font-weight: 700;
  }
  .error-text {
    font-weight: 800;
    font-size: 38px;
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    padding: 1.6rem 0 0.8rem;
  }
  .text {
    width: 638px;
    margin: 0 auto 4.8rem;
    font-size: 16px;
    text-align: left;
  }
  .to-home-button {
    width: 31.2rem;
    margin: 0 auto;
    display: block;
  }
  ${mediaSp`
    padding: 6rem 0;
    .error-code {
      font-size: 5rem;
    }
    .error-text {
      font-size: 2.4rem;
    }
    .title {
      font-size: 1.8rem;
    }
    .text {
      width: 32.2rem;
      font-size: 1.2rem;
    }
    .to-home-button {
      width: 31.2rem;
    }
  `}
`
export const NotFoundTpl: React.FC = () => {
  return (
    <Wrapper>
      <p className="error-code">404</p>
      <p className="error-text">NOT FOUND</p>
      <p className="title">お探しのページは見つかりませんでした</p>
      <p className="text">
        指定したURLが間違っているか、お探しのページが削除・更新された可能性があります。
        <br />
        不具合等ございましたら、お手数ですがお問い合わせページからご連絡ください。
      </p>

      <ButtonLink buttonType="black" href="/" className="to-home-button">
        HOMEに戻る
      </ButtonLink>
    </Wrapper>
  )
}
