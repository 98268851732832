import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { FlashMessage, Loading } from 'components/atoms'
import { MypageLayout, MypageOrderDownloadTpl } from 'components/templates'

/* lib, types */
import {
  useUserDownloadListLazyQuery,
  useUserOrderDetailLazyQuery,
  useUserGenerateDownloadLinkLazyQuery,
  useUserGenerateZipDownloadLinkLazyQuery,
  useUserInspectedOrderMutation,
  DownloadFileInfo,
  Order,
} from 'types/graphql'
import { FlashMessageType } from 'types/myTypes'

export const MypageOrderDownload: React.FC = () => {
  const [deliveredItems, setDeliveredItems] = useState<DownloadFileInfo[]>([])
  const [order, setOrder] = useState<Order>()
  const [itemTotal, setItemTotal] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const params = useParams()
  const { search } = useLocation()

  const [userDownloadListLazyQuery] = useUserDownloadListLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data) return
      setDeliveredItems(data.UserDownloadList.data)
      setLastPage(data.UserDownloadList.last_page || 1)
      setItemTotal(data.UserDownloadList.total || 1)
    },
    onError: (e) => {
      setIsLoading(false)
      Sentry.captureException(e)
      if (e.message === 'ダウンロード可能な注文ステータスではありません。') return
      setFlashMessage({ type: 'error', message: e.message ?? 'データの取得に失敗しました' })
    },
  })

  const [userOrderDetailLazyQuery] = useUserOrderDetailLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data) return
      const order: Order = {
        id: data.UserOrderDetail.id,
        status: data.UserOrderDetail.status,
        estimated_shipment_date: data.UserOrderDetail.estimated_shipment_date,
        remarks: data.UserOrderDetail.remarks,
        is_issued_receipt: data.UserOrderDetail.is_issued_receipt,
        payment_method: data.UserOrderDetail.payment_method,
        payment_status: data.UserOrderDetail.payment_status,
        paid_at: data.UserOrderDetail.paid_at,
        created_at: data.UserOrderDetail.created_at,
        updated_at: data.UserOrderDetail.updated_at,
        download_expired_at: data.UserOrderDetail.download_expired_at,
      }
      setOrder(order)
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userGenerateZipDownloadLinkLazyQuery] = useUserGenerateZipDownloadLinkLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data) return
      window.location.href = data.UserGenerateZipDownloadLink
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userGenerateDownloadLinkQuery] = useUserGenerateDownloadLinkLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data) return
      window.location.href = data.UserGenerateDownloadLink
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const [userInspectedOrderMutation] = useUserInspectedOrderMutation({
    onCompleted: () => {
      setIsLoading(false)
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: e.message ?? '検収完了処理に失敗しました' })
      Sentry.captureException(e)
    },
  })

  const onClickDownloadAllItems = (): void => {
    setIsLoading(true)
    setFlashMessage(null)
    userGenerateZipDownloadLinkLazyQuery({
      variables: {
        order_id: params.id as string,
      },
    })
  }

  const onClickDownloadItem = (name: string): void => {
    setIsLoading(true)
    setFlashMessage(null)
    userGenerateDownloadLinkQuery({
      variables: {
        input: {
          order_id: params.id as string,
          name: name,
        },
      },
    })
  }

  const onClickFinishInspection = (): void => {
    const confirmRes = confirm('検収を完了しますか？')
    if (!confirmRes) return

    setIsLoading(true)
    setFlashMessage(null)
    userInspectedOrderMutation({
      variables: {
        order_id: params.id as string,
      },
    })
  }

  useEffect(() => {
    setIsLoading(true)
    const pageParam = new URLSearchParams(search).get('page') || 1
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }
    if (params.id) {
      userDownloadListLazyQuery({
        variables: {
          input: {
            order_id: params.id,
            first: 20,
            page: Number(pageParam),
          },
        },
      })
    }
  }, [params, search, userDownloadListLazyQuery])

  useEffect(() => {
    setIsLoading(true)
    if (params.id) {
      userOrderDetailLazyQuery({
        variables: {
          order_id: params.id,
        },
      })
    }
  }, [params, userOrderDetailLazyQuery])

  return (
    <>
      <Meta title="ダウンロード" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <MypageLayout pageTitle="ダウンロード">
        {order && deliveredItems && (
          <MypageOrderDownloadTpl
            order={order}
            deliveredItems={deliveredItems}
            currentPage={currentPage}
            lastPage={lastPage}
            itemTotal={itemTotal}
            onClickDownloadItem={onClickDownloadItem}
            onClickDownloadAllItems={onClickDownloadAllItems}
            onClickFinishInspection={onClickFinishInspection}
          />
        )}
      </MypageLayout>
    </>
  )
}
