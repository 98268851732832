import React from 'react'
import styled from 'styled-components'

/* components */
import { ButtonLink, SubHeading, TextButton, TextLink } from 'components/atoms'
import { TableRow, SubHeadingWithTextButton } from 'components/molecules'
import { AddressTable } from 'components/organisms'

/* lib, types, const */
import { mediaSp } from 'lib/media-query'
import { User, ReturnAddress } from 'types/graphql'
import { RETURN_ADDRESS_LIMIT_NUM } from 'const'

type Props = {
  className?: string
  user: User
  returnAddresses: ReturnAddress[]
  onClickDeleteReturnAddress: (id: string) => void
  onClickLogout: () => void
}

const Wrapper = styled.div`
  padding: 64px 0 120px;

  ${mediaSp`
    padding: 0rem 0 12rem;
  `}
`

const StyledItem = styled.div`
  margin-bottom: 6.4rem;

  &:last-child {
    margin-bottom: 0;
  }

  .content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .table-row-wrapper {
      width: 100%;
    }

    > .button-wrapper {
      padding-top: 3.2rem;

      .button {
        width: 256px;
        height: 48px;
        line-height: 48px;
        margin: 0 0 0 auto;
        font-size: 1.6rem;
        display: block;
      }
    }
  }

  .return-address-subheading {
    margin-bottom: 40px;
  }

  .return-address-note {
    margin: 0 0 16px 0;
    color: ${(props): string => props.theme.errorText};
    font-size: 16px;
    line-height: 1.5;
    text-align: right;
    letter-spacing: 0.05em;
  }

  .no-data {
    padding: 0 0 0 13px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.05em;
  }

  ${mediaSp`
    .content {
      width: 100%;
      max-width: 100%;

      >.button-wrapper {
        padding-top: 32px;

        .button {
          width: 31.2rem;
          height: 5.2rem;
          line-height: 5.2rem;
          margin: 0 auto;
        }
      }
    }

    .return-address-subheading {
      margin-bottom: 2.4rem;
    }

    .return-address-note {
      margin: 0 0 0.9rem 0;
      font-size: 1.2rem;
    }

    .no-data {
      padding: 0;
      font-size: 1.4rem;
    }
  `}
`

const StyledReturnAddressWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 32px 40px 32px 48px;
  border: solid ${(props): string => props.theme.gray};
  border-width: 0 1px 1px 1px;
  display: flex;
  justify-content: space-between;

  &:first-of-type {
    border-width: 1px;
  }

  .return-address-wrapper,
  .button-wrapper {
    display: flex;
    align-items: center;
  }

  .return-address-wrapper {
    .name {
      width: 147px;
      margin-right: 40px;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.05em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .address {
      width: 354px;
      margin-right: 40px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.05em;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .button-wrapper {
    width: 123px;
    display: flex;
    justify-content: space-between;

    .button {
      font-size: 18px;
      a {
        font-size: 18px;
      }
    }
  }

  ${mediaSp`
    max-width: 100%;
    padding: 2rem;
    display: block;

    .return-address-wrapper {
      margin-bottom: 2.2rem;
      display: block;

      .name {
        width: auto;
        margin-right: 0;
        margin-bottom: 0.4rem;
        font-size: 1.6rem;
        line-height: 1.5;
      }

      .address {
        width: 100%;
        font-size: 1.2rem;
      }
    }

    .button-wrapper {
      width: auto;
      padding-left: 22rem;

      .button {
        font-size: 1.4rem;

        a {
          font-size: 1.4rem;
        }
      }
    }
  `}
`

export const MypageAccountTopTpl: React.FC<Props> = ({
  className = '',
  user,
  returnAddresses,
  onClickDeleteReturnAddress,
  onClickLogout,
}) => {
  return (
    <Wrapper className={className}>
      <StyledItem>
        <SubHeadingWithTextButton headingText="注文者情報(請求先)" buttonText="ログアウト" onClick={onClickLogout} />
        <div className="content">
          {user.business?.name ? (
            <AddressTable address={user.business} email={user.email as string} />
          ) : (
            <table className="table-row-wrapper">
              <tbody>
                <TableRow label="メールアドレス">{user.email}</TableRow>
              </tbody>
            </table>
          )}
          <div className="button-wrapper">
            <ButtonLink
              buttonType="black"
              href="/mypage/account/edit/user"
              state={{
                id: user.id,
                user,
              }}
              className="button"
            >
              注文者情報を変更する
            </ButtonLink>
          </div>
        </div>
      </StyledItem>
      <StyledItem>
        <SubHeading text="パスワード" />
        <div className="content">
          <table className="table-row-wrapper">
            <tbody>
              <TableRow label="パスワード">********</TableRow>
            </tbody>
          </table>
          <div className="button-wrapper">
            <ButtonLink buttonType="black" href="/mypage/account/edit/password" className="button">
              パスワードを変更する
            </ButtonLink>
          </div>
        </div>
      </StyledItem>
      <StyledItem>
        <SubHeading text="返送先住所" className="return-address-subheading" />
        <div className="content">
          {returnAddresses.length > 0 ? (
            <>
              <p className="return-address-note">返送先住所は5件までです</p>
              {returnAddresses.map((address, index) => (
                <StyledReturnAddressWrapper key={index}>
                  <div className="return-address-wrapper">
                    <p className="name">{address.name}</p>
                    <div className="address">
                      <p>&#12306;{address.post_code}</p>
                      <p>
                        {address.prefecture}
                        {address.address}
                      </p>
                      {address.building_name && <p>{address.building_name}</p>}
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <TextLink
                      href="/mypage/account/edit/return-address"
                      state={{ returnAddress: address }}
                      text="変更"
                      className="button"
                    />
                    {index !== 0 && (
                      <TextButton
                        onClick={() => {
                          onClickDeleteReturnAddress(address.id as string)
                        }}
                        className="button"
                      >
                        削除
                      </TextButton>
                    )}
                  </div>
                </StyledReturnAddressWrapper>
              ))}
            </>
          ) : (
            <p className="no-data">登録されている住所はありません</p>
          )}
          {returnAddresses.length < RETURN_ADDRESS_LIMIT_NUM && (
            <div className="button-wrapper">
              <ButtonLink buttonType="black" href="/mypage/account/add/return-address" className="button">
                新しく返送先を追加する
              </ButtonLink>
            </div>
          )}
        </div>
      </StyledItem>
    </Wrapper>
  )
}
