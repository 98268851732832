import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { Loading, FlashMessage } from 'components/atoms'
import { RegistrationTpl } from 'components/templates'

/* lib, types */
import { RegisterInput, FlashMessageType } from 'types/myTypes'
import { useGuestRegisterUserMutation } from 'types/graphql'

export const Registration: React.FC = () => {
  const registerInitialState: RegisterInput = {
    email: '',
    password: '',
    passwordConfirm: '',
  }

  const [registerInput, setRegisterInput] = useState<RegisterInput>(registerInitialState)
  const [isCheckedTerms, setIsCheckedTerms] = useState<string[]>(['false'])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location as { state: { email?: string; redirectFrom?: string } }

  const [guestRegisterUserMutation] = useGuestRegisterUserMutation({
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data) return
      const { email, password } = registerInput
      const { expires, user_id, signature } = data.GuestRegisterUser
      navigate('/registration/auth-code', {
        state: {
          email,
          password,
          expires,
          user_id,
          signature,
        },
      })
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: e.message ?? '新規登録できませんでした' })
      Sentry.captureException(e)
    },
  })

  const setRegisterState = (name: string, value: string): void => {
    setRegisterInput({ ...registerInput, [name]: value })
  }

  const onChangeCheckedTerms = (): void => {
    if (isCheckedTerms.includes('false')) {
      setIsCheckedTerms(['true'])
    } else {
      setIsCheckedTerms(['false'])
    }
  }

  const onClickRegister = async (): Promise<void> => {
    setIsLoading(true)
    setFlashMessage(null)

    const { email, password } = registerInput
    await guestRegisterUserMutation({
      variables: {
        input: {
          email,
          password,
        },
      },
    })
  }

  useEffect(() => {
    if (!state) return

    // 認証コード入力画面下部「入力フォーム」のリンクから戻ってきた場合
    // 新規登録時のコード認証を行わずにログインしようとした場合
    if (!!state.email) {
      setRegisterInput({ ...registerInput, email: state.email })
    }

    // 新規登録時のコード認証を行わずにログインしようとした場合
    if (!!state.redirectFrom) {
      if (state.redirectFrom === '/login') {
        setFlashMessage({ type: 'error', message: '再度登録し、メールアドレス認証を行なってください。' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // state.* = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (!!state && !!state.redirectFrom) {
      navigate('/registration', {
        replace: true,
        state: {
          redirectFrom: '',
        },
      })
    }
  }, [navigate, state])

  return (
    <>
      <Meta title="新規アカウント登録" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <RegistrationTpl
        registerInput={registerInput}
        isCheckedTerms={isCheckedTerms}
        setRegisterState={setRegisterState}
        onChangeCheckedTerms={onChangeCheckedTerms}
        onClickRegister={onClickRegister}
      />
    </>
  )
}
