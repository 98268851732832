import mediaQuery from 'styled-media-query'

// @ts-ignore
export const mediaPc = mediaQuery.greaterThan('769px')

// @ts-ignore
export const mediaTab = mediaQuery.between('429px', '768px')

// @ts-ignore
export const mediaSp = mediaQuery.lessThan('768px')

// @ts-ignore
export const mediaSpHeader = mediaQuery.lessThan('960px')
