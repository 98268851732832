import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
}

const Wrapper = styled.div`
  height: 100%;
  img {
    cursor: grab;
    /* FIXME 仮画像用。実際の画像は正方形なので削除する。360度撮影ができたら。 */
    /* https://www.pivotaltracker.com/story/show/183552747 */
    object-fit: cover;
    height: 100%;
  }
  &.is-dragging {
    img {
      cursor: grabbing;
    }
  }
`

export const Image360View: React.FC<Props> = ({ className }) => {
  const [isPermitDrag, setIsPermitDrag] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [dragStartIndex, setdragStartIndex] = useState<number>(0)
  const [dragStart, setDragStart] = useState<number>(0)

  const pixelsPerDegree = 3
  const imageLength = 23

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (isPermitDrag) {
      e.persist()
      setIsDragging(true)
      setdragStartIndex(imageIndex)
      setDragStart(e.screenX)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseUp = (): void => {
    setIsDragging(false)
  }

  const updateImageIndex = (currentPosition: number): void => {
    const pixelsPerImage = pixelsPerDegree * (360 / imageLength)

    const dx = (dragStart - currentPosition) / pixelsPerImage
    let index = Math.floor(dx) % imageLength

    if (index < 0) {
      index = imageLength + index + 1
    }
    index = (index + dragStartIndex) % imageLength

    if (index !== imageIndex) {
      setImageIndex(index)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseMove = (e: MouseEvent): void => {
    if (isDragging) {
      updateImageIndex(e.screenX)
    }
  }

  const preventDragHandler = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
  }

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseMove, handleMouseUp])

  const imageAround = (): void => {
    let imageNum = 0
    const interval = setInterval(() => {
      if (imageNum < imageLength) {
        setImageIndex((prevImageIndex) => prevImageIndex + 1)
        imageNum++
      } else {
        clearInterval(interval)
        setIsPermitDrag(true)
      }
    }, 50)
  }

  useEffect(() => {
    imageAround()
  }, [])

  return (
    <Wrapper onMouseDown={(e) => onMouseDown(e)} onDragStart={(e) => preventDragHandler(e)} className={className}>
      <div className={isDragging ? `image-360 is-dragging` : `image-360`}>
        <img src={require(`assets/images/360view/shoe-${imageIndex}.png`)} alt="image-360" />
      </div>
    </Wrapper>
  )
}
