import React from 'react'
import styled from 'styled-components'

/* components */
import { ButtonLink, OrderStatus } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { UserOrder, OrderStatus as OrderStatusType } from 'types/graphql'
import dayjs from 'dayjs'

/* images */
import { ReactComponent as NextIconSvg } from 'assets/images/icon/next-icon.svg'

type Props = {
  order: UserOrder
}

const Wrapper = styled.div`
  padding: 32px 48px 32px 40px;
  border-bottom: 1px solid ${(props): string => props.theme.gray};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text-wrapper {
    display: flex;
    align-items: center;
    .status {
      font-size: 1.2rem;
      width: 8.3rem;
      height: 3.4rem;
      padding: 0.8rem 0;
      margin: 0 32px 0 0;
      text-align: center;
    }
    .text-block {
      .order-at {
        font-size: 1.4rem;
        font-weight: 500;
        color: ${(props): string => props.theme.darkGray};
        padding: 0 0 1px;
      }
      .order-number {
        font-size: 24px;
        font-weight: 400;
        font-family: 'Roboto';
      }
    }
  }
  .button-wapper {
    display: flex;
    align-items: self-end;
    .download-button-wrapper {
      width: 135px;
      margin: 0 16px 0 0;
      text-align: center;
      .expired-at {
        font-size: 0.1rem;
        padding: 0 0 0.4rem;
        color: ${(props): string => props.theme.darkGray};
      }
      .button {
        width: 100%;
        .next-icon {
          fill: ${(props): string => props.theme.black};
        }
      }
    }
    .button {
      position: relative;
      display: block;
      width: 110px;
      height: 3.4rem;
      line-height: 3.4rem;
      font-size: 1.2rem;
      padding: 0 6px 0 0;
      .next-icon {
        position: absolute;
        top: 12px;
        right: 12px;
        fill: ${(props): string => props.theme.white};
        width: 1rem;
        height: 1rem;
      }
    }
  }

  ${mediaSp`
      padding: 1.6rem;
      border: 1px solid ${(props): string => props.theme.gray};
      display: block;
      border-radius: 0.8rem;
      margin: 0 0 2.4rem;

      .text-wrapper {
        align-items: start;
        margin: 0 0 4.4rem;
        .status {
          margin: 0 1.4rem 0 0;
        }
        .text-block {
          .order-at {
            padding: 0;
          }
          .order-number {
            font-size: 2.1rem;
          }
        }
      }
      .button-wapper {
        justify-content: end;
        .download-button-wrapper {
          width: 14.2rem;
          margin: 0 1.8rem 0 0;
          .button {
            padding: 0 1.3rem 0 0;
          }
        }
      .button {
        width: 14.2rem;
        padding: 0 0.3rem 0 0;
        .next-icon {
          position: absolute;
          top: 1.2rem;
          right: 1.6rem;
        }
      }
    }
  `}
`
export const OrderListItem: React.FC<Props> = ({ order }) => {
  return (
    <Wrapper>
      <div className="text-wrapper">
        <OrderStatus className="status" status={order.status ? order.status : OrderStatusType.Init} />
        <div className="text-block">
          <p className="order-at">{dayjs(order.created_at).format('YYYY/MM/DD')}</p>
          <p className="order-number">{order.id}</p>
        </div>
      </div>
      <div className="button-wapper">
        {(order.status === OrderStatusType.Inspecting || order.status === OrderStatusType.Inspected) && (
          <div className="download-button-wrapper">
            {/* 検収中は有効期限が設定されないため */}
            {order.status === OrderStatusType.Inspected && (
              <p className="expired-at">有効期限: {dayjs(order.download_expired_at).format('YYYY/MM/DD')}</p>
            )}
            <ButtonLink className="button" buttonType="yellow" href={`/mypage/order/download/${order.id}`}>
              ダウンロード
              <NextIconSvg className="next-icon" />
            </ButtonLink>
          </div>
        )}
        <ButtonLink className="button" buttonType="black" href={`/mypage/order/${order.id}`}>
          注文詳細
          <NextIconSvg className="next-icon" />
        </ButtonLink>
      </div>
    </Wrapper>
  )
}
