import React, { useState } from 'react'
import styled from 'styled-components'

/* components */
import { PageTitle, ErrorMessage, TextInput, Button } from 'components/atoms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { ResetPasswordInput, ResetPasswordInputErrors } from 'types/myTypes'
import { validateRequiredPassword, validatePasswordAndPasswordConfirm, validateRequired } from 'lib/validate'

type Props = {
  className?: string
  resetPasswordInput: ResetPasswordInput
  setResetPasswordState: (name: string, value: string) => void
  onClickEdit: () => void
}

const Wrapper = styled.div`
  .inner {
    width: 100%;
    margin: 0 auto;
    padding: 8rem 2rem 23.8rem;
    .page-title {
      margin-bottom: 4rem;
      text-align: center;
    }
    .text {
      margin-bottom: 3.2rem;
      font-size: 1.6rem;
      line-height: 1.7;
      text-align: center;
      .sp {
        display: none;
      }
    }
    .input-area {
      width: 48rem;
      margin: 0 auto 4rem;
      .email {
        width: 48rem;
        margin: 0 auto;
        display: block;
      }
    }
    .password-reset {
      width: 31.2rem;
      margin: 0 auto;
      display: block;
    }
  }
  ${mediaSp`
    .inner {
      padding: 7.2rem 2.4rem 14rem;
      .page-title {
        margin-bottom: 3.2rem;
      }
      .text {
        margin-bottom: 4.8rem;
        font-size: 1.4rem;
      }
      .input-area {
        width: 100%;
        margin: 0 auto 2.4rem;
        .confirm {
          margin: 0 auto 4.8rem;
        }
      }
    }
  `}
`

export const ResetPasswordTpl: React.FC<Props> = ({ className = '', resetPasswordInput, onClickEdit, setResetPasswordState }) => {
  const resetPasswordInitialErrorsState: ResetPasswordInputErrors = {
    password: '',
    passwordConfirm: '',
  }
  const [resetPasswordInputErrors, setResetPasswordInputErrors] = useState<ResetPasswordInputErrors>(resetPasswordInitialErrorsState)

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setResetPasswordState(name, value)
    validate(name, value)
  }

  const validate = (name: string, value: string): void => {
    switch (name) {
      case 'password':
        setResetPasswordInputErrors({
          ...resetPasswordInputErrors,
          password: validateRequiredPassword(value),
          passwordConfirm: validateRequiredPassword(value)
            ? resetPasswordInputErrors.passwordConfirm
            : validatePasswordAndPasswordConfirm(value, resetPasswordInput.passwordConfirm),
        })
        break
      case 'passwordConfirm':
        setResetPasswordInputErrors({
          ...resetPasswordInputErrors,
          [name]: validateRequired(value) || validatePasswordAndPasswordConfirm(resetPasswordInput.password, value),
        })
        break
      default:
        break
    }
  }

  const isDisable = (): boolean => {
    const { password, passwordConfirm } = resetPasswordInput
    const isRequiredItemsFilled = password && passwordConfirm
    if (!isRequiredItemsFilled) return true

    const errorArray = Object.values(resetPasswordInputErrors).filter((error) => error !== null)
    if (errorArray.length > 0) return true

    return false
  }

  return (
    <Wrapper className={className}>
      <div className="inner">
        <PageTitle title="パスワード再設定" className="page-title" />

        <p className="text">新しいパスワードを入力してください</p>

        <div className="input-area">
          <TextInput
            className="password"
            type="password"
            value={resetPasswordInput.password}
            name="password"
            placeholder="パスワード"
            onChange={onChangeState}
            error={!!resetPasswordInputErrors.password}
          />
          {!!resetPasswordInputErrors.password && <ErrorMessage message={resetPasswordInputErrors.password} />}
        </div>
        <div className="input-area confirm">
          <TextInput
            className="password-confirm"
            type="password"
            value={resetPasswordInput.passwordConfirm}
            name="passwordConfirm"
            placeholder="確認用パスワード"
            onChange={onChangeState}
            error={!!resetPasswordInputErrors.passwordConfirm}
          />
          {!!resetPasswordInputErrors.passwordConfirm && <ErrorMessage message={resetPasswordInputErrors.passwordConfirm} />}
        </div>
        <Button className="password-reset" buttonType="yellow" disabled={isDisable()} onClick={onClickEdit}>
          パスワードを再設定する
        </Button>
      </div>
    </Wrapper>
  )
}
