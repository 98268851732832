import React from 'react'
import styled from 'styled-components'

/* components */
import { PageTitle } from 'components/atoms'
import { LoginForm } from 'components/organisms'

/* lib, types*/
import { mediaSp } from 'lib/media-query'
import { LoginInput } from 'types/myTypes'

type Props = {
  className?: string
  loginInput: LoginInput
  onChangeState: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickLoginButton: () => void
}

const Wrapper = styled.div`
  .inner {
    width: 100%;
    max-width: 52rem;
    margin: 0 auto;
    padding: 9rem 2rem 11.8rem;

    .page-title {
      margin-bottom: 4rem;
      text-align: center;
    }
  }

  ${mediaSp`
    .inner {
      max-width: 100%;
      padding: 7.2rem 2.4rem 14.4rem;

      .page-title {
        margin-bottom: 4.8rem;
      }
    }
  `}
`

export const LoginTpl: React.FC<Props> = ({ className = '', loginInput, onChangeState, onClickLoginButton }) => {
  return (
    <Wrapper className={className}>
      <div className="inner">
        <PageTitle title="ログイン" className="page-title" />
        <LoginForm loginInput={loginInput} onChangeState={onChangeState} onClickLoginButton={onClickLoginButton} />
      </div>
    </Wrapper>
  )
}
