import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

/* components */
import { Meta } from 'components/meta'
import { FlashMessage, Loading } from 'components/atoms'
import { MypageLayout, MypageOrdersTpl } from 'components/templates'

/* lib, types */
import { useUserOrdersLazyQuery, UserOrder } from 'types/graphql'
import { FlashMessageType } from 'types/myTypes'

export const MypageOrders: React.FC = () => {
  const [orders, setOrders] = useState<UserOrder[] | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [flashMessage, setFlashMessage] = useState<FlashMessageType | null>(null)

  const { search, state } = useLocation() as { search: string; state: { registrationFinish: boolean } }
  const navigate = useNavigate()

  const [userOrdersLazyQuery] = useUserOrdersLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false)
      if (!data || !data.UserOrders) return
      setOrders(data.UserOrders.data)
      setLastPage(data.UserOrders.paginatorInfo.lastPage)
    },
    onError: (e) => {
      setIsLoading(false)
      setFlashMessage({ type: 'error', message: 'データの取得に失敗しました' })
      Sentry.captureException(e)
    },
  })

  useEffect(() => {
    setIsLoading(true)
    const pageParam = new URLSearchParams(search).get('page')
    if (pageParam) {
      setCurrentPage(Number(pageParam))
    }

    userOrdersLazyQuery({
      variables: {
        first: 20,
        page: Number(pageParam),
      },
    })
  }, [search, userOrdersLazyQuery])

  useEffect(() => {
    if (state) {
      // 新規登録後のページ遷移
      if (!!state.registrationFinish) {
        setFlashMessage({ type: 'success', message: 'アカウント登録が完了しました' })
      }
    }
  }, [state])

  // state.registrationFinish = trueのままだとリロード時に毎回フラッシュメッセージが表示されるのでreplace
  useEffect(() => {
    if (state && !!state.registrationFinish) {
      navigate('/mypage/order', {
        replace: true,
        state: {
          registrationFinish: '',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <>
      <Meta title="注文履歴一覧" />
      {isLoading && <Loading />}
      {flashMessage && <FlashMessage flashMessage={flashMessage} />}
      <MypageLayout pageTitle="注文履歴一覧">
        {orders && <MypageOrdersTpl orders={orders} currentPage={currentPage} lastPage={lastPage} />}
      </MypageLayout>
    </>
  )
}
