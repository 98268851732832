import React from 'react'
import styled from 'styled-components'

/* components */
// import { Image360View } from 'components/organisms'

/* libs, const, config */
import { mediaSp, mediaSpHeader } from 'lib/media-query'
import { SERVICE_NAME } from 'const'

/* images */
// FIXME 画像は仮
// https://www.pivotaltracker.com/story/show/181906495
import MainVisualImage from 'assets/images/top/main-visual.png'

const Wrapper = styled.div`
  background: ${(props): string => props.theme.lightGray};
  height: calc(100vh - 90px);
  .inner {
    height: 100%;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props): string => props.theme.white};
    border-radius: 0 0 0 100px;
    .text {
      z-index: 1;
      h2 {
        position: relative;
        font-size: 56px;
        font-weight: 700;
        letter-spacing: 0;
        padding: 0 0 47px;
        &:after {
          position: absolute;
          top: 63px;
          left: 0;
          z-index: -1;
          content: '';
          width: calc(100% - 26px);
          height: 10px;
          border-radius: 5px;
          background: ${(props): string => props.theme.yellow};
          animation: UnderlineAnimation 1.7s forwards;
        }
      }

      @keyframes UnderlineAnimation {
        0% {
          width: 0;
        }
        30% {
          width: 0;
        }
        100% {
          width: calc(100% - 26px);
        }
      }
      p {
        opacity: 0;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.05em;
        animation: slideUp 0.5s ease 1.7s forwards;
        @keyframes slideUp {
          from {
            opacity: 0;
            transform: translateY(40px);
          }
          to {
            opacity: 1;
            transform: translateY(0px);
          }
        }
        &.attention {
          font-size: 1.2rem;
          font-weight: 400;
          padding: 15px 0 0;
        }
      }
    }
    .image-wrapper {
      position: relative;
      width: 510px;
      height: 510px;
      .image-360-icon {
        position: absolute;
        top: -21px;
        right: -8px;
        width: 6.5rem;
        height: 6.5rem;
      }
    }
  }
  ${mediaSpHeader`
    height: auto;
      .inner {
        padding: 90px 32px 109px;
        .text {
          padding: 0;
          h2 {
            font-size: 3.8vw;
          }
          p {
            font-size: 1.6vw;
          }
        }
        .image-wrapper {
          width: 42vw;
          height: 42vw;
        }
      }
  `}

  ${mediaSp`
    height: auto;
    .inner {
      padding: 0 1.7rem 8rem;
      flex-direction: column-reverse;
      background: ${(props): string => props.theme.white};
      border-radius: 0 0 0 3rem;
      .text {
        margin: 2.1rem 0 0;
        h2 {
          font-size: 2.7rem;
          padding: 0 0 2.7rem;
          &:after {
            position: absolute;
            top: 3rem;
            width: calc(100% - 2.6rem);
            height: 0.6rem;
            border-radius: 0.3rem;
          }
        }
        p {
          font-size: 1.4rem;
          &.attention {
            padding: 1.1rem 0 0;
          }
        }
      }
      .image-wrapper {
        width: 25.7rem;
        height: 25.7rem;
        margin: 4.2rem auto 0;
        .image-360-icon {
          top: -3.2rem;
          right: -4.1rem;
        }
      }
    }
  `}
`

export const MainVisual: React.FC = () => {
  return (
    <Wrapper>
      <div className="inner">
        <div className="text">
          <h2>撮りたい商品を送るだけ。</h2>
          <p>
            最短即日納品で対応可能！
            <br />
            1商品のみのご依頼もOKです。
          </p>
          <p className="attention">※ 撮影の混み具合によっては少しお待ちいただく可能性があります。</p>
        </div>
        <div className="image-wrapper">
          <img src={MainVisualImage} alt={SERVICE_NAME} />
        </div>
        {/* FIXME: 360度撮影が追加されたら戻す */}
        {/* https://www.pivotaltracker.com/story/show/183552747 */}
        {/* <div className="image-wrapper">
          <img className="image-360-icon" src={image360Icon} alt="360°" />
          <Image360View />
        </div> */}
      </div>
    </Wrapper>
  )
}
