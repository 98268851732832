import React from 'react'
import styled from 'styled-components'

/* components */
import { Button } from 'components/atoms'

/* lib, types, const*/
import { mediaSp } from 'lib/media-query'
import { CONTACT_URL, CONTACT_TEL, CONTACT_TEL_FOR_LINK, FAQ_URL } from 'const'

/* images */
import { ReactComponent as ContactIcon } from 'assets/images/icon/contact.svg'
import { ReactComponent as FaqIcon } from 'assets/images/icon/faq.svg'
import { ReactComponent as TelIcon } from 'assets/images/icon/tel.svg'

const Wrapper = styled.div`
  padding: 8rem 0 10.8rem;
  background: ${(props): string => props.theme.lightGray};

  .inner {
    width: 750px;
    margin: 0 auto 6.4rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 94%;
      position: absolute;
      left: 50%;
      top: 4%;
      background: ${(props): string => props.theme.gray};
      display: block;
    }

    .contact,
    .faq {
      text-align: center;

      .title {
        font-size: 4rem;
        font-weight: 700;
        line-height: 1.5;
        text-align: center;
        letter-spacing: 0.05em;
        &:first-letter {
          color: ${(props): string => props.theme.yellow};
        }
      }
      p {
        margin-bottom: 4rem;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
      }
      button {
        width: 100%;
        margin: 0 auto;
        padding: 0 3.8rem;
        display: flex;
        align-items: center;
        jutify-content: space-between;
        letter-spacing: 0;

        .icon {
          width: 26px;
          margin-right: 1rem;
        }
      }
    }
    .contact {
      width: 270px;

      button {
        width: 233px;
      }
    }
    .faq {
      width: 256px;
    }
  }
  .tel {
    width: 366px;
    margin: 0 auto;

    .text {
      margin: 0 auto 1.1rem;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
    }
    .tel-number {
      margin: 0 auto;
      padding: 0 0.7rem;
      font-size: 4rem;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.05em;
      position: relative;
      z-index: 1;

      .icon {
        margin-right: 1.2rem;
      }
      &::after {
        width: 100%;
        height: 7px;
        background: ${(props): string => props.theme.yellow};
        border-radius: 3.5px;
        display: block;
        position: absolute;
        left: 0;
        bottom: 12px;
        content: '';
        z-index: -1;
      }
    }
    .business-hours {
      margin: 0 auto;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
      letter-spacing: 0.05em;
    }
  }

  ${mediaSp`
    padding: 0 4.2rem 7rem;
    background: ${(props): string => props.theme.white};

    .inner {
      width: 100%;
      margin: 0 auto 5.7rem;

      &::after {
        width: 23rem;
        height: 1px;
        left: 3rem;
        top: 50%;
      }

      .contact,
      .faq {
        width: 100%;
        margin: 0 auto;

        .title {
          font-size: 2.4rem;
        }
        p {
          margin-bottom: 1.6rem;
          font-size: 1.2rem;
          letter-spacing: 0.05em;
        }
        button {
          width: 15.4rem;
          height: 3.7rem;
          margin: 0 auto 3.2rem;
          letter-spacing: 0;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 3.7rem;
          box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.25);
          .icon {
            width: 2rem;
          }
        }

      }
      .contact {
        button {
          padding: 0 2.8rem 0 2.4rem;
        }
      }
      .faq {
        padding-top: 3.2rem;

        button {
          padding: 0 2.5rem 0 2rem;
          margin-bottom: 0;

          .icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
    .tel {
      width: 100%;

      .text {
        margin: 0 auto 1.4rem;
        font-size: 1.2rem;
      }
      .tel-number {
        padding: 0 0.7rem;
        font-size: 3rem;
        line-height: 4.5rem;
        color: ${(props): string => props.theme.black};
        .icon {
          width: auto;
          height: 2.3rem;
          margin-right: 0.7rem;
        }
        &::after {
          width: 28.1rem;
          height: 0.5rem;
          border-radius: 0.25rem;
          left: 0;
          bottom: 1rem;
        }
      }
    }
  `}
`

export const TopContact: React.FC = () => {
  const goToContact = (): void => {
    window.open(CONTACT_URL, '_blank', 'noreferrer')
  }
  const goToFaq = (): void => {
    window.open(FAQ_URL, '_blank', 'noreferrer')
  }
  return (
    <Wrapper>
      <div className="inner">
        <div className="contact">
          <h2 className="title">Contact</h2>
          <p>撮影に関するお問い合わせはこちら</p>
          <Button buttonType="black" onClick={goToContact}>
            <ContactIcon className="icon" />
            お問い合わせ
          </Button>
        </div>
        <div className="faq">
          <h2 className="title">FAQ</h2>
          <p>よくある質問はこちら</p>
          <Button buttonType="black" onClick={goToFaq}>
            <FaqIcon className="icon" />
            よくあるご質問
          </Button>
        </div>
      </div>
      <div className="tel">
        <p className="text">お電話でのお問い合わせはこちらからお願いします</p>
        <p className="tel-number pc">
          <TelIcon className="icon" />
          {CONTACT_TEL}
        </p>
        <a className="tel-number sp" href={`tel:${CONTACT_TEL_FOR_LINK}`}>
          <TelIcon className="icon" />
          {CONTACT_TEL}
        </a>
        <p className="business-hours">営業時間:10:00〜18:00(平日のみ)</p>
      </div>
    </Wrapper>
  )
}
