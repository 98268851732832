import React from 'react'

/* components */
import { Meta } from 'components/meta'
import { NotFoundTpl } from 'components/templates'

export const NotFound: React.FC = () => {
  return (
    <>
      <Meta title="404 NOT FOUND" />
      <NotFoundTpl />
    </>
  )
}
